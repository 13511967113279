import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProSummary } from '../../../atoms/SummaryWindow/SummaryWindow';
import { Components } from '../../../utils/types';

export const PeopleSummaryWindow = ({ people }: { people?: Components.Schemas.PersonDTO[] }) => {
    const { t } = useTranslation();
    return (
        <ProSummary.Window name={t('windows.summary')}>
            <ProSummary.Attr name={t('windows.totalPeople')} icon={ProIconData.person_fill} val={people?.length} />
            <ProSummary.Attr
                icon={ProIconData.person_fill}
                name={t('windows.companies')}
                val={people?.reduce((sum, iter) => {
                    return iter.company ? `${sum} ${iter.company},` : sum;
                }, '')}
            />
            <ProSummary.Attr
                icon={ProIconData.package_fill}
                name={t('windows.units')}
                val={people?.reduce((sum, iter) => {
                    if (iter.company) {
                        return `${sum} ${iter.organisationalUnit},`;
                    }
                    return sum;
                }, '')}
            />
        </ProSummary.Window>
    );
};
