import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './../../statics/4o.svg';

export const ProLogo = ({ big = false, className }: { big?: boolean; className?: string }) => (
    <div className={classNames('ProLogo', big && 'ProLogo__big', className)}>
        <Link to="/app">
            <img src={Logo} alt="4opm logo" />
        </Link>
    </div>
);
