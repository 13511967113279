import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL, DATA_API_BASE, DOCUMENT_API_BASE } from '../../api/api';
import { handleImageUpload } from '../../api/fileUpload';
import { useGenericAPI } from '../../api/useGenericApi';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProIcon } from '../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProSpinner } from '../../atoms/ProSpinner/Spinner';
import { ProIntent, ProSize } from '../../utils/enums';
import { showErrorToast } from '../../utils/helpers';
import { Components } from '../../utils/types';

// const getNewUserImageDoc = (
//     portfolio?: Components.Schemas.PortfolioDTO,
//     personId?: string
// ): Components.Schemas.DocumentDTO | undefined => {
//     if (portfolio)
//         return { portfolioId: portfolio?.id ?? '0', objectID: personId ?? '000', documentType: 'PersonImage' };
//     else {
//         // showErrorToast('no portfolio loaded');
//         return undefined;
//     }
// };

type Props = {
    imageDocumentId?: string | null;
    disabled: boolean;
};

export const UserImage = ({ disabled, imageDocumentId }: Props) => {
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [image, setImage] = useState<Blob>();
    const { t } = useTranslation();

    const { data, error, loading } = useGenericAPI<Components.Schemas.DocumentDTO>(
        DOCUMENT_API_BASE,
        imageDocumentId ?? undefined
    );

    const deleteImage = () => {
        if (data) {
            console.log('delete image');
        }
    };

    const getImage = async () => {
        const jwtObj = localStorage.getItem('auth');
        try {
            const res = await axios.get(`${API_BASE_URL}${DATA_API_BASE}/${data?.id}`, {
                headers: { Authorization: `Bearer ${jwtObj}` },
                responseType: 'blob',
            });
            return res.data;
        } catch (e) {
            console.log('cant get image');
            throw e;
        }
    };

    useEffect(() => {
        if (data) {
            getImage()
                .then((img) => {
                    // console.log('image', img, img.size);
                    // console.log('image data', !!image, !!data, !!isUploadLoading, !!loading);

                    if (img.size !== 0) {
                        setImage(img);
                    }
                })
                .catch((e) => {
                    throw e;
                });
        }
    }, [data]);

    const uploadDropeddFile = async (eventFiles: FileList | null) => {
        if (data?.id && eventFiles) {
            setIsUploadLoading(true);
            await handleImageUpload(eventFiles, data?.id)
                .then(() => {
                    getImage().then((img) => {
                        setImage(img);
                        // showSuccessToast('image uploaded');
                    });
                })
                .catch((e) => {
                    showErrorToast('cant get user image');
                    throw e;
                })
                .finally(() => {
                    setIsUploadLoading(false);
                });
        }
    };

    return (
        <>
            Image
            <div className="ProUserImage">
                <div className="ProUserImage__image">
                    {!image && (!data || !isUploadLoading) && !loading && (
                        <>
                            <label htmlFor="file">
                                <ProIcon
                                    icon={ProIconData.user_full}
                                    size={ProSize.XLarge}
                                    className="ProUserImage__placement"
                                />
                            </label>

                            <input
                                type="file"
                                name="pic"
                                accept="image/*"
                                onChange={async (e) => uploadDropeddFile(e.target.files)}
                                className={'Fileupload'}
                                id="file"
                                onDrop={async (e) => uploadDropeddFile(e.currentTarget.files)}
                                disabled={disabled}
                            />
                        </>
                    )}
                    {isUploadLoading || (loading && <ProSpinner />)}
                    {image && <img src={URL.createObjectURL(image)} alt={'Avatar'} />}
                </div>
                <div className="ProUserImage__buttons">
                    <label htmlFor="file">
                        <ProIconButton
                            icon={ProIconDataButtons.pen_s}
                            size={ProSize.XSmall}
                            mainIntent={ProIntent.Primary}
                            onClick={() => {}}
                            tooltip={t('buttons.save')}
                            disabled={disabled}
                        />
                    </label>

                    <ProIconButton
                        icon={ProIconDataButtons.bin_s}
                        size={ProSize.Small}
                        mainIntent={ProIntent.Error}
                        onClick={deleteImage}
                        tooltip={t('buttons.delete')}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};
