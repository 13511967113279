import React, { ChangeEvent, useState } from 'react';
import { handleFileUpload } from '../../api/fileUpload';
import { ProIcon } from '../../atoms/Icons/Icon';
import { ProIconData } from '../../atoms/Icons/IconData';
import { ProSpinner } from '../../atoms/ProSpinner/Spinner';
import { ProSize } from '../../utils/enums';
import { showErrorToast } from '../../utils/helpers';
import { Components } from '../../utils/types';

type Props = {
    files?: Components.Schemas.RequirementDocumentDTO[] | null;
    objectId: string;
    portfolioId?: string;
    sync?: () => void;
};

export const ProFileList = ({ files, objectId, portfolioId, sync }: Props) => {
    const [isUploadLoading, setIsUploadLoading] = useState(false);

    const downloadFile = (id?: string) => {}; //TODO: Implement

    const uploadFile = (eventFiles?: FileList | null) => {
        setIsUploadLoading(true);

        if (eventFiles) {
            handleFileUpload(eventFiles, { objectId: objectId, portfolioId: portfolioId ?? '0' })
                .then(() => {
                    sync && sync();
                })
                .catch(() => {
                    showErrorToast('Cant upload file');
                })
                .finally(() => {
                    setIsUploadLoading(false);
                });
        } else {
            showErrorToast('Cant upload file');
        }
    };

    const uploadSelectedFile = async (event: ChangeEvent<HTMLInputElement>) => {
        uploadFile(event.target.files);
    };

    const uploadDropeddFile = async (event: React.DragEvent<HTMLInputElement>) => {
        uploadFile(event.currentTarget.files);
    };

    return (
        <div className="FileList">
            <span className="FileList__name">Files</span>
            <div className="FileList__content">
                <ul>
                    {files?.map((cur) => (
                        <li key={`file-${cur.id}`} onClick={() => downloadFile(cur.id)}>
                            <ProIcon icon={ProIconData.upload} />
                            {cur.name}
                        </li>
                    ))}
                    {(!files || files.length === 0) && <>No files</>}
                </ul>
                <div className="FileList__upload">
                    {isUploadLoading ? (
                        <ProSpinner />
                    ) : (
                        <input
                            type="file"
                            name="file"
                            accept="pdf/*"
                            onChange={uploadSelectedFile}
                            id="file"
                            onDrop={uploadDropeddFile}
                        />
                    )}
                    <label htmlFor="file">
                        <ProIcon icon={ProIconData.upload} size={ProSize.Large} />
                        Upload File
                    </label>
                </div>
            </div>
        </div>
    );
};
