import React from 'react';
import { ProDropdown } from './Dropdown';

type Props = {
    value?: boolean;
    name: string;
    disabled?: boolean;
    handleChange: (val: boolean) => void;
    trueName?: string;
    falseName?: string;
};

export const ProBooleanDropdown = ({
    name,
    handleChange,
    value,
    disabled,
    trueName = 'true',
    falseName = 'false',
}: Props) => {
    return (
        <ProDropdown
            setValue={(inp) => handleChange(inp === trueName)}
            disabled={disabled}
            items={[trueName, falseName]}
            name={name}
            value={value ? trueName : falseName}
        />
    );
};
