import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { MaterialPage } from '../../PortfolioManagement/BaseData/Material';
import { PortfolioPeoplePage } from '../../PortfolioManagement/People/PortfolioPeoplePage';
import { SingleTeamPage } from '../Team/SingleTeamPage';
import { ProjectCostPage } from './Cost';

export const CostSelectionFrame = () => {
    const { selectionMode, contextId } = useContext(SelectionContext);
    const { t } = useTranslation();

    return (
        <>
            <SingleTeamPage
                isHidden={selectionMode !== SelectionType.Person}
                pageTitle={t('windows.selectTeamMemer')}
                teamId={contextId}
            />
            <PortfolioPeoplePage
                isHidden={selectionMode !== SelectionType.HumanResource}
                pageTitle={t('windows.selectPerson')}
            />
            <MaterialPage isHidden={selectionMode !== SelectionType.Material} pageTitle={t('windows.selectMaterial')} />
            <ProjectCostPage isHidden={selectionMode !== undefined} />
        </>
    );
};
