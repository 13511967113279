import React from 'react';
import { TFunction } from 'react-i18next';
import { ActionCell } from '../../../../atoms/Cells/ActionCell';
import { ProIconData } from '../../../../atoms/Icons/IconData';
import { TableColData } from '../../../../atoms/Table/Table';
import { translateStatus } from '../../../../components/DetailWindow/breadCrump.helpers';
import { SelectionType } from '../../../../contexts/SelectionContext';
import { WindowDisplayState } from '../../../../modules/Windows/WBSElement/WBSElement.helpers';
import { Components } from '../../../../utils/types';
import {
    RequirementTableNameCell,
    getReqCellIcon,
    getReqStatusIcon,
    isReqNotBacklog,
    isReqNotTask,
    isReqOrSimilar,
    isTaskNotReq,
} from '../components/RequirementTableNameCell';

const reqStates = [
    'InFunnel',
    'NotReady',
    'Ready',
    'Scheduled',
    'UnderDevelopment',
    'ReadyForReview',
    'OnHold',
    'Done',
    'Rejected',
];

export const orderReqStates = (
    states?: Components.Schemas.RequirementStateDTO[] | null
): Components.Schemas.RequirementStateDTO[] => {
    const ret = reqStates
        .map((cur) => states?.find((akt) => akt.requirementStateType === cur))
        .filter((cur) => cur !== undefined);

    return (ret as Components.Schemas.RequirementStateDTO[]) ?? [];
};

export const getRequirmentTableData = ({
    filteredData,
    getNodeIsOpen,
    selectTableRow,
    toggleOpen,
    isDisplayed,
    selectionMode,
    taskIsDisplayed,
    t,
    mode,
    renderSelectionButton,
    taskMode,
    createRequirement,
    deleteAction,
    openNewTask,
}: {
    filteredData: Components.Schemas.RequirementDTO[];
    toggleOpen: (id: string) => void;
    selectTableRow: (i: number) => void;
    getNodeIsOpen: (i: number) => boolean | undefined;
    isDisplayed: boolean;
    taskIsDisplayed: boolean;
    selectionMode: SelectionType | undefined;
    t: TFunction;
    mode: WindowDisplayState;
    taskMode: WindowDisplayState;
    renderSelectionButton: ((elm: any) => JSX.Element) | undefined;
    createRequirement: (i: number) => void;
    deleteAction: (i: number) => void;
    openNewTask: (id: string) => void;
}): TableColData[] => [
    {
        name: 'Requirement',
        // name: t('table.cols.name'),
        cellRender: (i) => {
            return (
                <RequirementTableNameCell
                    icon={getReqCellIcon(filteredData![i])}
                    name={filteredData![i]?.name ?? 'error'}
                    openAction={() => {
                        toggleOpen(filteredData[i].id!);
                    }}
                    selectAction={() => {
                        selectTableRow(i);
                    }}
                    level={filteredData![i]?.level ?? 0}
                    open={getNodeIsOpen(i)}
                />
            );
        },
        className: 'TableRowName ProTable__levels',
        colSpace: 'extend',
    },
    {
        name: 'Status',
        cellRender: (i) => {
            if (isReqOrSimilar(filteredData[i]))
                return (
                    <>
                        {getReqStatusIcon(filteredData[i].requirementType, filteredData[i].requirementState?.name)}
                        {translateStatus(t, filteredData[i].requirementState?.name)}
                    </>
                );
            return `${filteredData[i].degreeOfCompletion} %`;
        },
        colSpace: isDisplayed || taskIsDisplayed ? 3 : 4,
        className: 'ProTable__statusIcon',
    },
    {
        name: 'Responsible',
        cellRender: (i) =>
            isReqNotBacklog(filteredData[i])
                ? `${filteredData[i].responsiblePerson?.firstName ?? ''} ${
                      filteredData[i].responsiblePerson?.lastName ?? '-'
                  }`
                : '',
        colSpace: 4,
        hidden: () => !(isDisplayed || taskIsDisplayed),
    },
    {
        name: 'Scope',
        cellRender: (i) => {
            if (isTaskNotReq(filteredData[i])) return '-';
            return `${filteredData[i].storyPoints} SP`;
        },
        colSpace: 2,
        hidden: () => !(isDisplayed || taskIsDisplayed),
    },
    {
        name: 'Benefit',
        cellRender: (i) => {
            if (isTaskNotReq(filteredData[i])) return '-';
            return `${filteredData[i].businessValue} BP`;
        },
        colSpace: 2,
        hidden: () => !(isDisplayed || taskIsDisplayed),
    },
    {
        name: 'Priority',
        cellRender: (i) => (isReqNotBacklog(filteredData[i]) ? filteredData[i].requirementPriorityState?.name : ''),
        colSpace: 2,
        hidden: () => !(isDisplayed || taskIsDisplayed),
    },
    {
        name: t('table.cols.action'),
        cellRender: (i) => {
            if (isReqNotTask(filteredData[i])) {
                return (
                    <>
                        {selectionMode === SelectionType.WBSToReq &&
                            renderSelectionButton &&
                            renderSelectionButton(filteredData[i])}
                        {selectionMode === SelectionType.Move &&
                            (filteredData[i].requirementType === 'Epic' ||
                                filteredData[i].requirementType === 'Specification' ||
                                filteredData[i].requirementType === 'TeamBacklog') &&
                            renderSelectionButton &&
                            renderSelectionButton(filteredData[i])}
                        <ActionCell
                            i={i}
                            selectTableRow={() => selectTableRow(i)}
                            detailWindowMode={[mode, taskMode]}
                            createElement={
                                filteredData[i].requirementType === 'UserStory' ||
                                filteredData[i].requirementType === 'Bug' ||
                                filteredData[i].requirementType === 'ChangeRequest'
                                    ? undefined
                                    : () => {
                                          createRequirement(i);
                                      }
                            }
                            deleteAction={() => {
                                deleteAction(i);
                            }}
                            createSecondElementName={t('buttons.add', { var: t('types.task') })}
                            createElementName={t('buttons.add', { var: t('types.requirement') })}
                            createSecondElement={() => openNewTask(filteredData[i].id!)}
                            secondElementeIcon={ProIconData.add_task}
                        />
                    </>
                );
            }
            return (
                <ActionCell
                    i={i}
                    selectTableRow={() => selectTableRow(i)}
                    detailWindowMode={[mode, taskMode]}
                    deleteAction={() => {
                        deleteAction(i);
                    }}
                />
            );
        },
        colSpace: selectionMode === SelectionType.WBSToReq || selectionMode === SelectionType.Move ? 7 : 4,
    },
    {
        cellRender: (i) => <>{filteredData[i].wbsElement ? <>{filteredData[i].wbsElement?.name}</> : '-'}</>,
        colSpace: 3,
        name: 'Assigned WBS',
        hidden: () => selectionMode === SelectionType.WBSToReq,
    },
];
