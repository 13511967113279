import { Button } from '@blueprintjs/core';
import { Placement, Tooltip2 } from '@blueprintjs/popover2';
import classnames from 'classnames';
import React from 'react';
import { ProIconType } from '../../constants/types';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIcon } from '../Icons/Icon';

type Props = {
    className?: string;
    mainIntent?: ProIntent;
    hoverIntent?: ProIntent;
    lineIntent?: ProIntent;
    text: string;
    onClick?: () => void;
    leftIcon?: ProIconType;
    rightIcon?: ProIconType;
    tooltip?: string;
    disabled?: boolean;
    getDisabled?: () => boolean;
    placement?: Placement;
    size?: ProSize;
    hidden?: boolean;
};

export const ProButton = ({
    className,
    mainIntent = ProIntent.Secondary,
    text,
    onClick,
    leftIcon,
    tooltip,
    disabled,
    hoverIntent,
    lineIntent,
    placement,
    size,
    hidden,
    getDisabled,
    rightIcon,
}: Props) => {
    const renderCont = () => (
        <Button
            className={classnames(
                'ProButton ProButton--textButton',
                className,
                `ProButton--main-${mainIntent}`,
                hoverIntent && `ProButton--hover-${hoverIntent}`,
                lineIntent && `ProButton--line-${lineIntent}`,
                size && `ProButton--size-${size}`,
                disabled && `ProButton__disabled`,
                getDisabled && getDisabled() && `ProButton__disabled`,
                hidden && 'ProButton__hidden'
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {leftIcon && <ProIcon icon={leftIcon} size={size ?? ProSize.Small} className="ProButton__icon--left" />}
            <span>{text}</span>
            {rightIcon && <ProIcon icon={rightIcon} size={size ?? ProSize.Small} className="ProButton__icon--right" />}
        </Button>
    );
    return tooltip === undefined ? (
        renderCont()
    ) : (
        <Tooltip2
            content={tooltip}
            className="ProButton-Tooltip"
            usePortal
            lazy
            hoverCloseDelay={300}
            hoverOpenDelay={300}
            placement={placement}
        >
            {renderCont()}
        </Tooltip2>
    );
};
