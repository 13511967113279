import React from 'react';
import { TeamWorkPackagesFrame } from '../../PersonalManagement/WorkPackages/WorkPackages';

export const TeamWorkPackagesPage = () => {
    return <TeamWorkPackagesFrame />;
    // const { t } = useTranslation();
    // return (
    //     <ProPage
    //         breadCrump={getBaseBCItemForType([ApplicationElementType.TeamManagement], t)}
    //         name={t('navigation.teamMan.workPackages')}
    //     >
    //         <NotImplemented message={t('navigation.teamMan.workPackages')} />
    //     </ProPage>
    // );
};
