/* eslint-disable no-useless-catch */
import axios from 'axios';
import { Components } from '../utils/types';

export const LOGIN_BASE_URL = '/Account/Login';
export const ACCOUNT_BASE_URL = '/Account';
export const USER_ENV_BASE_URL = '/Account/Environment';
export const PROJECTS_BASE_URL = '/Project';
export const WBS_BASE_URL = '/WBSStructure';
export const WBS_ELEMENT_BASE_URL = '/WBSElement';
export const PORTFOLIO_API_BASE = '/Portfolio';
export const TEAM_URL_API_BASE = '/Team';
export const TEAM_POSITION_API_BASE = '/TeamPosition';
export const COST_FUNCTION_API_BASE = '/CostFunctionRole';
export const MATERIAL_DEFINITION_API_BASE = '/MaterialDefinition';
export const UNITS_API_BASE = '/Unit';
export const FUNCTIONS_API_BASE = '/CostFunctionCriteriaFunction';
export const LEVEL_API_BASE = '/CostFunctionCriteriaLevel';
export const PERSON_API_BASE = '/Person';
export const TEAM_API_BASE = '/Team';
export const HUMAN_RESOUCE_API_BASE = '/HumanResource';
export const HUMAN_RESOUCE_ASSIGNMENT_API_BASE = '/HumanResourceAssignment';
export const MATERIAL_ASSIGNMENT_API_BASE = '/MaterialAssignment';
export const DEPENDENCY_API_BASE = '/Dependency';
export const DATA_API_BASE = '/Document/Data';
export const DOCUMENT_API_BASE = '/Document';
export const REQUIREMENTS_API_BASE = '/Requirement';
export const PROJECT_REQUIREMENTS_API_BASE = `${REQUIREMENTS_API_BASE}/Project`;
export const REQUIREMENTSTRUCTURE_API_BASE = `/RequirementStructure`;
export const TASKLIST_API_BASE = `/TaskList`;
export const TASK_API_BASE = `/Task`;
export const PERSONAL_TASK_API_BASE = `${TASK_API_BASE}/Personal`;

export const LOGIN_START_PAGE = '/app/personal/workPackages';
// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_BASE_URL =
    process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:8090/api'
        : window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '') +
          '/api';

export const APP_BASE_URL =
    process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:3000'
        : window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '');

export const apiLogin = async (
    username: string,
    password: string,
    remember: boolean
): Promise<Components.Schemas.ApplicationUserDTO> => {
    try {
        const res = await axios.post<Components.Schemas.ApplicationUserDTO>(`${API_BASE_URL}${LOGIN_BASE_URL}`, {
            email: username,
            password: password,
            rememberMe: remember,
        });

        if (res.data.token) {
            localStorage.setItem('auth', res.data.token);
        }
        return res.data;
    } catch (e) {
        // Todo: Handle error better by inspecting and give something nice back
        throw e;
    }
};

export const apiGetAccount = async (): Promise<Components.Schemas.ApplicationUserDTO> => {
    const jwtObj = localStorage.getItem('auth');
    try {
        const res = await axios.get<Components.Schemas.ApplicationUserDTO>(`${API_BASE_URL}${ACCOUNT_BASE_URL}`, {
            headers: { Authorization: `Bearer ${jwtObj}` },
        });
        if (res.data.token) {
            localStorage.setItem('auth', res.data.token);
        }
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const apiGetProjects = async (id: string): Promise<Components.Schemas.ProjectDTO> => {
    // const jwtObj = sessionStorage.getItem('auth');
    const jwtObj = localStorage.getItem('auth');

    try {
        const res = await axios.get<Components.Schemas.ProjectDTO>(`${API_BASE_URL}${PROJECTS_BASE_URL}/${id}`, {
            headers: { Authorization: `Bearer ${jwtObj}` },
        });

        return res.data;
    } catch (e) {
        throw e;
    }
};

export const apiGetProjectRequirements = async (id: string): Promise<Components.Schemas.RequirementDTO[]> => {
    const jwtObj = localStorage.getItem('auth');

    try {
        const res = await axios.get<Components.Schemas.RequirementDTO[]>(
            `${API_BASE_URL}${PROJECT_REQUIREMENTS_API_BASE}/${id}`,
            {
                headers: { Authorization: `Bearer ${jwtObj}` },
            }
        );

        return res.data;
    } catch (e) {
        throw e;
    }
};

export const deleteWBSElement = async (id: string) => {
    // const jwtObj = sessionStorage.getItem('auth');
    const jwtObj = localStorage.getItem('auth');

    return await axios.delete<Components.Schemas.WBSElementDeltaDTO>(`${API_BASE_URL}${WBS_ELEMENT_BASE_URL}/${id}`, {
        headers: { Authorization: `Bearer ${jwtObj}` },
    });
};

export const deleteWBSDependency = async (id: string) => {
    const jwtObj = localStorage.getItem('auth');

    return await axios.delete<Components.Schemas.DependencyDTO>(`${API_BASE_URL}${DEPENDENCY_API_BASE}/${id}`, {
        headers: { Authorization: `Bearer ${jwtObj}` },
    });
};

export const updateWBSDependency = async (dep: Components.Schemas.DependencyDTO) => {
    const jwtObj = localStorage.getItem('auth');

    return await axios.put<Components.Schemas.DependencyDTO>(`${API_BASE_URL}${DEPENDENCY_API_BASE}/${dep.id}`, dep, {
        headers: { Authorization: `Bearer ${jwtObj}` },
    });
};

export const updateHumanResourceAssignment = async (dep: Components.Schemas.HumanResourceAssignmentDTO) => {
    const jwtObj = localStorage.getItem('auth');

    return await axios.put<Components.Schemas.HumanResourceAssignmentDTO>(
        `${API_BASE_URL}${HUMAN_RESOUCE_ASSIGNMENT_API_BASE}/${dep.id}`,
        dep,
        {
            headers: { Authorization: `Bearer ${jwtObj}` },
        }
    );
};
export const deleteHumanResourceAssignment = async (dep: Components.Schemas.HumanResourceAssignmentDTO) => {
    const jwtObj = localStorage.getItem('auth');

    return await axios.delete<Components.Schemas.HumanResourceAssignmentDTO>(
        `${API_BASE_URL}${HUMAN_RESOUCE_ASSIGNMENT_API_BASE}/${dep.id}`,
        {
            headers: { Authorization: `Bearer ${jwtObj}` },
        }
    );
};

export const updateMaterialAssignment = async (dep: Components.Schemas.MaterialAssignmentDTO) => {
    const jwtObj = localStorage.getItem('auth');

    return await axios.put<Components.Schemas.HumanResourceAssignmentDTO>(
        `${API_BASE_URL}${MATERIAL_ASSIGNMENT_API_BASE}/${dep.id}`,
        dep,
        {
            headers: { Authorization: `Bearer ${jwtObj}` },
        }
    );
};

export const deleteMaterialAssignment = async (dep: Components.Schemas.HumanResourceAssignmentDTO) => {
    const jwtObj = localStorage.getItem('auth');

    return await axios.delete<Components.Schemas.HumanResourceAssignmentDTO>(
        `${API_BASE_URL}${MATERIAL_ASSIGNMENT_API_BASE}/${dep.id}`,
        {
            headers: { Authorization: `Bearer ${jwtObj}` },
        }
    );
};

export const updateUser = async (dep: Components.Schemas.ApplicationUserDTO) => {
    const jwtObj = localStorage.getItem('auth');

    return await axios.put<Components.Schemas.HumanResourceAssignmentDTO>(
        `${API_BASE_URL}${MATERIAL_ASSIGNMENT_API_BASE}/${dep.id}`,
        dep,
        {
            headers: { Authorization: `Bearer ${jwtObj}` },
        }
    );
};

export const noop = () => {};
