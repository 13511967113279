import { TFunction } from 'i18next';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGenericAPI } from '../../api/useGenericApi';
import { showErrorToast } from '../../utils/helpers';
import { WindowDisplayState } from './WBSElement/WBSElement.helpers';

export type DetailWindowProps<T> = {
    loading: boolean;
    isDisabled: boolean;
    isDisplayed: boolean;
    setFormData: (value: SetStateAction<T | undefined>) => void;
    mode: WindowDisplayState;
    setMode: (value: SetStateAction<WindowDisplayState>) => void;
    deleteAction?: () => void;
    saveAction: (elm?: T) => Promise<void> | void;
    createAction?: () => void;
    formData?: T;
    error?: string;
    cancelChanges: () => void;
    closeAction: () => void;
    t: TFunction;
    externalSync?: () => void;
    internalSync?: () => void;
};

export type GenericDetailWindowProps<T> = {
    emptyItem: T;
    apiConstant: string;
    genericDetailWindow: ({
        createAction,
        deleteAction,
        formData,
        isDisabled,
        isDisplayed,
        loading,
        mode,
        saveAction,
        setFormData,
        setMode,
        error,
        cancelChanges,
        closeAction,
    }: DetailWindowProps<T>) => JSX.Element;
    closeAction: () => void;
    selectedItemId?: string;
    externalSync?: () => Promise<void> | void;
    selectAction?: (id: string) => void; //make it mendatory to select new created elements
};

export function useGenericDetailWindow<T extends { id?: string }>({
    apiConstant,
    closeAction,
    emptyItem,
    genericDetailWindow,
    externalSync,
    selectedItemId,
    selectAction,
}: GenericDetailWindowProps<T>) {
    const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
    const [mode, setMode] = useState<WindowDisplayState>('view');
    const { t } = useTranslation();

    const { createRequest, data, deleteRequest, error, loading, updateRequest, sync } = useGenericAPI<T>(
        apiConstant,
        selectedItemId
    );

    const [formData, setFormData] = useState<T | undefined>(data);

    useEffect(() => {
        if (!data) setFormData(emptyItem);
        if (mode === 'new') setFormData(emptyItem);
        else setFormData(data);
    }, [data, mode]);

    const open = (newMode: WindowDisplayState, element?: T) => {
        setIsDisplayed(newMode !== 'hidden');
        setMode(newMode);
        if (element) setFormData(element);
    };

    const saveAction = (elm?: T) => {
        console.log('elm', elm);
        if (elm && elm.id !== undefined) {
            // console.log('saved given', elm);
            updateRequest(elm).then(() => {
                setMode('view');
                externalSync && externalSync();
            });
            return;
        }
        if (formData) {
            // console.log('saved form data');
            updateRequest(formData).then(() => {
                setMode('view');
                externalSync && externalSync();
            });
        }
    };

    const createAction = () => {
        if (formData)
            createRequest(formData).then((res) => {
                setMode('view');

                externalSync &&
                    isAsync(externalSync) &&
                    externalSync().then(() => {
                        selectAction && selectAction(res.id!);
                    });

                externalSync && !isAsync(externalSync) && externalSync();
                closeAction(); // todo: eig nicht ideal, es es müsste die gerade kreirte tabellenzeile ausgewählt sein
            });
    };

    const deleteAction = () => {
        if (formData)
            deleteRequest()
                .then(() => {
                    setIsDisplayed(false);
                    externalSync &&
                        isAsync(externalSync) &&
                        externalSync().then(() => {
                            closeAction();
                        });
                })
                .catch(() => {
                    showErrorToast('Cant delete');
                });
    };

    const isDisabled = mode === 'view';

    const renderDetailWindow = () =>
        genericDetailWindow({
            createAction,
            deleteAction,
            formData,
            isDisabled,
            isDisplayed,
            loading,
            mode,
            saveAction,
            setFormData,
            setMode,
            error,
            cancelChanges: () => {
                setMode('view');
            },
            closeAction,
            t,
            externalSync,
            internalSync: sync,
        });

    return { open, renderDetailWindow, isDisplayed, mode, sync, data, loading };
}

export const isAsync = (item: () => Promise<void> | void): item is () => Promise<void> => {
    return (item as () => Promise<void>).constructor.name === 'AsyncFunction';
};
