import classNames from 'classnames';
import React, { useContext } from 'react';
import { ProAttrField, ProHeaderAttrField } from '../../../atoms/AttrField/AttrField';
import { ProDropdown } from '../../../atoms/AttrField/Dropdown';
import { ProNumberField } from '../../../atoms/AttrField/NumberField';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { UserContext } from '../../../contexts/UserContext';
import { Components } from '../../../utils/types';
import { DetailWindowProps } from '../GenericDetailWindow';
import { getWindowDisplayStateMode } from '../WBSElement/WBSElement.helpers';

export const MaterialDetailWindow = ({
    cancelChanges,
    closeAction,
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    createAction,
    deleteAction,
    error,
    formData,
    t,
}: DetailWindowProps<Components.Schemas.MaterialDefinitionDTO>) => {
    const { portfolio } = useContext(UserContext);
    return (
        <ProDetailWindow
            title={
                isDisabled ? (
                    <h2
                        className={classNames(
                            formData?.name?.length &&
                                formData?.name?.length >= 180 &&
                                'ProDetailWindow--header--title__large'
                        )}
                    >
                        {formData?.name ?? t('windows.details')}
                    </h2>
                ) : (
                    <ProHeaderAttrField
                        name="Name"
                        value={formData?.name ?? String(t('windows.details'))}
                        disabled={false}
                        handleChange={(input) =>
                            setFormData((old) => ({ ...old, name: String(input).substring(0, 180) }))
                        }
                    />
                )
            }
            breadCrump={getBaseBCItemForType([ApplicationElementType.Material], t)}
            displayState={isDisplayed}
            icon={ProIconData.person_fill}
            buttonSection={
                <WindowButtonsForMode
                    cancelAction={cancelChanges}
                    closeAction={closeAction}
                    createAction={createAction}
                    deleteAction={deleteAction}
                    saveAction={saveAction}
                    setMode={setMode}
                    mode={mode}
                    t={t}
                />
            }
            error={error}
            loading={loading}
            intent={getWindowDisplayStateMode(mode)}
        >
            <ProRow>
                <ProCol span={6}>
                    <ProNumberField
                        name={t('windows.pricePerUnit')}
                        disabled={isDisabled}
                        value={formData?.price}
                        handleChange={(value) => setFormData((cur) => ({ ...cur, price: Number(value) }))}
                    />
                </ProCol>
                <ProCol span={6}>
                    <ProDropdown
                        name={t('windows.unit')}
                        disabled={isDisabled}
                        value={formData?.priceUnit?.name}
                        items={portfolio?.units?.map((cur) => cur.name ?? '-')}
                        setValue={(value) =>
                            setFormData((cur) => ({
                                ...cur,
                                priceUnit: portfolio?.units?.find((cur) => cur.name === value),
                            }))
                        }
                    />
                </ProCol>
            </ProRow>

            <ProRow>
                <ProCol span={12}>
                    <ProAttrField
                        name={t('windows.description')}
                        disabled={isDisabled}
                        value={formData?.description}
                        handleChange={(value) => setFormData((cur) => ({ ...cur, description: String(value) }))}
                    />
                </ProCol>
            </ProRow>
        </ProDetailWindow>
    );
};
