import React from 'react';
import { useTranslation } from 'react-i18next';
import { Components } from '../../../utils/types';
import { ProjectTreshold } from './ProjectTreshold';

type ProjectTresholdSettingsProps = {
    data: Components.Schemas.ProjectSettingDTO;
    setData: (value: Components.Schemas.ProjectSettingDTO) => void;
    isDisabled: boolean;
};

export const ProjectTresholdSettings = ({ data, isDisabled, setData }: ProjectTresholdSettingsProps) => {
    const { t } = useTranslation();
    return (
        <>
            {/* <ProjectTrasholdDescription /> */}
            <ProjectTreshold
                name={t('windows.timeThreshold')}
                isDisabled={isDisabled}
                extremeMin={data.timeExtremeMinimumTreshold}
                setExtremeMin={(val) => setData({ ...data, timeExtremeMinimumTreshold: val })}
                criticalMin={data.timeCriticalMinimumTreshold}
                setCriticalMin={(val) => setData({ ...data, timeCriticalMinimumTreshold: val })}
                cautionMin={data.timeCautionMinimumTreshold}
                setCautionMin={(val) => setData({ ...data, timeCautionMinimumTreshold: val })}
                cautionMax={data.timeCautionMaximumTreshold}
                setCautionMax={(val) => setData({ ...data, timeCautionMaximumTreshold: val })}
                criticalMax={data.timeCriticalMaximumTreshold}
                setCriticalMax={(val) => setData({ ...data, timeCriticalMaximumTreshold: val })}
                extremeMax={data.timeExtremeMaximumTreshold}
                setExtremeMax={(val) => setData({ ...data, timeExtremeMaximumTreshold: val })}
            />
            <ProjectTreshold
                name={t('windows.costThreshold')}
                isDisabled={isDisabled}
                extremeMin={data.costExtremeMinimumTreshold}
                setExtremeMin={(val) => setData({ ...data, costExtremeMinimumTreshold: val })}
                criticalMin={data.costCriticalMinimumTreshold}
                setCriticalMin={(val) => setData({ ...data, costCriticalMinimumTreshold: val })}
                cautionMin={data.costCautionMinimumTreshold}
                setCautionMin={(val) => setData({ ...data, costCautionMinimumTreshold: val })}
                cautionMax={data.costCautionMaximumTreshold}
                setCautionMax={(val) => setData({ ...data, costCautionMaximumTreshold: val })}
                criticalMax={data.costCriticalMaximumTreshold}
                setCriticalMax={(val) => setData({ ...data, costCriticalMaximumTreshold: val })}
                extremeMax={data.costExtremeMaximumTreshold}
                setExtremeMax={(val) => setData({ ...data, costExtremeMaximumTreshold: val })}
            />
            <ProjectTreshold
                name={t('windows.qualityThreshold')}
                isDisabled={isDisabled}
                criticalMin={data.qualityCriticalMinimumTreshold}
                setCriticalMin={(val) => setData({ ...data, qualityCriticalMinimumTreshold: val })}
                cautionMin={data.qualityCautionMinimumTreshold}
                setCautionMin={(val) => setData({ ...data, qualityCautionMinimumTreshold: val })}
                cautionMax={data.qualityCautionMaximumTreshold}
                setCautionMax={(val) => setData({ ...data, qualityCautionMaximumTreshold: val })}
                criticalMax={data.qualityCriticalMaximumTreshold}
                setCriticalMax={(val) => setData({ ...data, qualityCriticalMaximumTreshold: val })}
            />
            <ProjectTreshold
                name={t('windows.scopeThreshold')}
                isDisabled={isDisabled}
                criticalMin={data.scopeCriticalMinimumTreshold}
                setCriticalMin={(val) => setData({ ...data, scopeCriticalMinimumTreshold: val })}
                cautionMin={data.scopeCautionMinimumTreshold}
                setCautionMin={(val) => setData({ ...data, scopeCautionMinimumTreshold: val })}
                cautionMax={data.scopeCautionMaximumTreshold}
                setCautionMax={(val) => setData({ ...data, scopeCautionMaximumTreshold: val })}
                criticalMax={data.scopeCriticalMaximumTreshold}
                setCriticalMax={(val) => setData({ ...data, scopeCriticalMaximumTreshold: val })}
            />
            <ProjectTreshold
                name={t('windows.benefitThreshold')}
                isDisabled={isDisabled}
                criticalMin={data.benefitCriticalMinimumTreshold}
                setCriticalMin={(val) => setData({ ...data, benefitCriticalMinimumTreshold: val })}
                cautionMin={data.benefitCautionMinimumTreshold}
                setCautionMin={(val) => setData({ ...data, benefitCautionMinimumTreshold: val })}
                cautionMax={data.benefitCautionMaximumTreshold}
                setCautionMax={(val) => setData({ ...data, benefitCautionMaximumTreshold: val })}
                criticalMax={data.benefitCriticalMaximumTreshold}
                setCriticalMax={(val) => setData({ ...data, benefitCriticalMaximumTreshold: val })}
            />
        </>
    );
};
