import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProAttrField } from '../../../../atoms/AttrField/AttrField';
import { ProButton } from '../../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../../atoms/Button/ProIconButton';
import { ProIconDataButtons } from '../../../../atoms/Icons/IconData';
import { SelectionContext, SelectionType } from '../../../../contexts/SelectionContext';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { Components } from '../../../../utils/types';

export const RequirementsResponsiblePersonField = ({
    data,
    isDisabled,
    setData,
    type,
}: {
    data: Components.Schemas.RequirementDTO;
    isDisabled: boolean;
    setData: Dispatch<SetStateAction<Components.Schemas.RequirementDTO | undefined>>;
    type: 'contact' | 'responsible';
}) => {
    const { t } = useTranslation();
    const { setRenderSelectionButton, setSelectedElementId, turnSelectionOff, setSelectionMode } =
        useContext(SelectionContext);

    const setSelectFunction = (person: Components.Schemas.PersonDTO) => {
        if (type === 'contact') {
            setData((cur) => ({ ...cur, contactPerson: person }));
        } else {
            setData((cur) => ({ ...cur, responsiblePerson: person }));
        }
    };

    const selectFunction = () => {
        setSelectedElementId(data?.responsiblePerson?.id);
        setSelectionMode(SelectionType.HumanResource);

        setRenderSelectionButton(() => (humanResource: Components.Schemas.HumanResourceDTO) => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        setSelectFunction(humanResource);
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
    };

    useEffect(() => {
        setRenderSelectionButton(() => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
    }, [setRenderSelectionButton]);

    const value = useMemo(() => {
        switch (type) {
            case 'contact':
                if (data.contactPerson) return data.contactPerson?.firstName ?? 'unnamed';
                else return 'not defined';
            case 'responsible':
                if (data.responsiblePerson) return data.responsiblePerson?.firstName ?? 'unnamed';
                else return 'not defined';
            default:
                return 'not defined';
        }
    }, [data.contactPerson, data.responsiblePerson, type]);

    return (
        <ProAttrField
            name={type === 'responsible' ? t('windows.responsiblePerson') : t('windows.contactPerson')}
            value={value}
        >
            <ProIconButton
                tooltip={
                    type === 'responsible' ? t('buttons.assignResponsiblePerson') : t('buttons.assignContactPerson')
                }
                icon={ProIconDataButtons.add_xs}
                onClick={selectFunction}
                size={ProSize.XSmall}
                mainIntent={isDisabled ? ProIntent.LightBlue : ProIntent.Success}
                disabled={isDisabled}
                placement="top"
                small
            />
        </ProAttrField>
    );
};
