import { TFunction } from 'i18next';
import React from 'react';
import { ProValue } from '../../../../../atoms/Value/ProValue';
import { Components } from '../../../../../utils/types';
import { getCostConstraintType } from './CostConstraintField';

export const getCostConstraintTypeDescription = (data: Components.Schemas.WBSElementDTO, t: TFunction) => {
    const type = getCostConstraintType(data, t);

    return (
        <>
            {type?.name}
            {type && type.constraintCostType && type.constraintCostType !== 'None' && (
                <ProValue type="value" entity="€" value={data.constraintCost} className="ProTableDate" />
            )}
        </>
    );
};
