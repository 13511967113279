import { Icon, MenuItem } from '@blueprintjs/core';
import { Placement, Tooltip2 } from '@blueprintjs/popover2';
import { Select } from '@blueprintjs/select';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { ProIconType } from '../../constants/types';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIcon } from '../Icons/Icon';

type Props = {
    name: string;
    items: string[];
    onChange(item: string): void;
    intent?: ProIntent;
    size?: ProSize;
    isDisabled?: boolean;
    icon?: ProIconType;
    selectedItem?: string;
    tooltipPlacement?: Placement;
    className?: string;
};

export const ProSelector = ({
    name,
    intent = ProIntent.Primary,
    size = ProSize.Medium,
    items,
    onChange,
    isDisabled = false,
    icon,
    selectedItem,
    tooltipPlacement = 'bottom',
    className,
}: Props) => {
    const ProSelect = Select.ofType<string>();

    const onItemRender = useMemo(
        () => (item: string) =>
            (
                <MenuItem
                    text={item}
                    active={item === selectedItem}
                    shouldDismissPopover={false}
                    onClick={() => onChange(item)}
                    className="ProSelector--item"
                    key={`key_${item}`}
                />
            ),
        [selectedItem]
    );
    return (
        <Tooltip2
            content={`choose the ${name}`}
            usePortal
            lazy
            hoverCloseDelay={300}
            hoverOpenDelay={300}
            placement={tooltipPlacement}
            className={className}
        >
            <div
                className={classNames(
                    'ProSelector',
                    isDisabled && 'ProSelector--disabled',
                    `ProSelector--size-${size}`
                )}
            >
                {items.length <= 1 ? (
                    <div className="ProSelector--value">
                        {icon && <ProIcon icon={icon} />} {selectedItem}
                    </div>
                ) : (
                    <div className={classNames('ProSelector__select', `ProButton--main-${intent}`)}>
                        {icon && <ProIcon icon={icon} />}
                        <ProSelect
                            items={items ?? []}
                            onItemSelect={onChange}
                            itemRenderer={onItemRender}
                            itemPredicate={(query, item): boolean =>
                                item.toLocaleLowerCase().includes(query.toLocaleLowerCase())
                            }
                            disabled={isDisabled || (items && items.length <= 0)}
                            activeItem={selectedItem}
                            scrollToActiveItem
                            filterable={false}
                            className="ProSelector--wraper"
                            popoverProps={{
                                minimal: true,
                                fill: true,
                                targetTagName: 'div',
                                wrapperTagName: 'div',
                                className: 'ProSelector--popover',
                                portalClassName: 'ProSelector--portal',
                            }}
                        >
                            <div className="ProSelector--input">
                                {selectedItem || 'not set'}
                                <Icon icon="double-caret-vertical" className="ProAttrField__icon" />
                            </div>
                        </ProSelect>
                    </div>
                )}
            </div>
        </Tooltip2>
    );
};
