import { Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';
import { MenuItemType } from '../../constants/types';
import { useRouting } from '../../hooks/useRouting';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIcon } from '../Icons/Icon';

type Props = {
    item: MenuItemType;
    onClick: () => void;
    isActive?: boolean;
    size?: ProSize;
    title?: string;
};

export const ProMenuIcon = ({ item, isActive, size = ProSize.Large, title, onClick }: Props) => {
    const { navigateSection } = useRouting();

    const getCurIcon = () => {
        if (isActive && item.activeIcon) {
            return item.activeIcon;
        } else {
            return item.icon;
        }
    };
    const getHighlightClass = () => {
        if (isActive && item.activeIcon) {
            return 'ProMenuIcon--active';
        } else if (isActive) {
            return 'ProMenuIcon--active  ProMenuIcon--active-hightlight';
        } else {
            return '';
        }
    };

    return (
        <div
            // onClick={() => navigateSection(item.section)}
            onClick={onClick}
            className={classNames('ProMenuItem', item.disabled && 'ProMenuIcon--disabled')}
        >
            <div className={classNames('ProMenuIcon', getHighlightClass())}>
                <Tooltip content={item.name} className="ProButton-Tooltip" position="right">
                    <ProIcon icon={getCurIcon()} size={size} intent={isActive ? ProIntent.Primary : ProIntent.Light} />
                </Tooltip>
            </div>
            {title && <h3>{title}</h3>}
        </div>
    );
};
