import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProNumberField } from '../../../atoms/AttrField/NumberField';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';

type ProjectTresholdProps = {
    name: string;
    isDisabled: boolean;
    extremeMin?: number;
    setExtremeMin?: (val: number) => void;
    criticalMin?: number;
    setCriticalMin: (val: number) => void;
    cautionMin?: number;
    setCautionMin: (val: number) => void;
    cautionMax?: number;
    setCautionMax: (val: number) => void;
    criticalMax?: number;
    setCriticalMax: (val: number) => void;
    extremeMax?: number;
    setExtremeMax?: (val: number) => void;
};

export const ProjectTreshold = ({
    isDisabled,
    cautionMax,
    cautionMin,
    criticalMax,
    criticalMin,
    extremeMax,
    extremeMin,
    setCautionMax,
    setCautionMin,
    setCriticalMax,
    setCriticalMin,
    setExtremeMax,
    setExtremeMin,
    name,
}: ProjectTresholdProps) => {
    const { t } = useTranslation();
    return (
        <div className="TresholdSettings">
            <h3>{name}</h3>
            <ProRow>
                <div className="Bar">
                    <div className={classNames('Bar--extreme', !extremeMax && 'Bar--noIndicator')}>
                        extreme
                        <div className="Bar--critical">
                            extreme
                            <div className="Bar--caution">extreme</div>
                        </div>
                    </div>
                </div>
            </ProRow>
            <ProRow>
                <ProCol span={2} verticalAlign="top">
                    {setExtremeMin !== undefined && (
                        <ProNumberField
                            info={t('windows.extremeMin')}
                            value={extremeMin}
                            disabled={isDisabled}
                            handleChange={(input) => setExtremeMin(Number(input))}
                            entity="%"
                            entityLengh="s"
                        />
                    )}
                </ProCol>
                <ProCol span={2} verticalAlign="top">
                    <ProNumberField
                        // name={t('windows.criticalMin')}
                        info={t('windows.criticalMinDesc')}
                        value={criticalMin}
                        disabled={isDisabled}
                        handleChange={(input) => setCriticalMin(Number(input))}
                        entity="%"
                        entityLengh="s"
                    />
                </ProCol>
                <ProCol span={2} verticalAlign="top">
                    <ProNumberField
                        info={t('windows.cautionMin')}
                        value={cautionMin}
                        disabled={isDisabled}
                        handleChange={(input) => setCautionMin(Number(input))}
                        entity="%"
                        entityLengh="s"
                    />
                </ProCol>
                <ProCol span={2} verticalAlign="top">
                    <ProNumberField
                        info={t('windows.cautionMax')}
                        value={cautionMax}
                        disabled={isDisabled}
                        handleChange={(input) => setCautionMax(Number(input))}
                        entity="%"
                        entityLengh="s"
                    />
                </ProCol>
                <ProCol span={2} verticalAlign="top">
                    <ProNumberField
                        info={t('windows.criticalMax')}
                        value={criticalMax}
                        disabled={isDisabled}
                        handleChange={(input) => setCriticalMax(Number(input))}
                        entity="%"
                        entityLengh="s"
                    />
                </ProCol>
                <ProCol span={2} verticalAlign="top">
                    {setExtremeMax !== undefined && (
                        <ProNumberField
                            info={t('windows.extremeMax')}
                            value={extremeMax}
                            disabled={isDisabled}
                            handleChange={(input) => setExtremeMax(Number(input))}
                            entity="%"
                            entityLengh="s"
                        />
                    )}
                </ProCol>
            </ProRow>
        </div>
    );
};

export const ProjectTrasholdDescription = () => {
    const { t } = useTranslation();
    return (
        <ProRow className="TresholdDescription">
            <ProCol span={2} verticalAlign="top">
                {t('windows.extremeMin')}
            </ProCol>
            <ProCol span={2} verticalAlign="top">
                {t('windows.criticalMinDesc')}
            </ProCol>
            <ProCol span={2} verticalAlign="top">
                {t('windows.cautionMin')}
            </ProCol>
            <ProCol span={2} verticalAlign="top">
                {t('windows.cautionMax')}
            </ProCol>
            <ProCol span={2} verticalAlign="top">
                {t('windows.criticalMax')}
            </ProCol>
            <ProCol span={2} verticalAlign="top">
                {t('windows.extremeMax')}
            </ProCol>
        </ProRow>
    );
};
