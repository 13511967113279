// eslint-disable-file react-hooks/exhaustive-deps
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL, HUMAN_RESOUCE_ASSIGNMENT_API_BASE } from '../../api/api';
import { ProButton } from '../../atoms/Button/ProButton';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProCol } from '../../atoms/Grid/Col';
import { ProRow } from '../../atoms/Grid/Row';
import { ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProjectContext } from '../../contexts/ProjectContext';
import { SelectionContext, SelectionType } from '../../contexts/SelectionContext';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';
import { TeamMemberWindow } from './TeamMemberWindow';

type Props = {
    name: string;
    dependencies?: Components.Schemas.HumanResourceAssignmentDTO[] | null;
    wbsElement?: Components.Schemas.WBSElementDeltaDTO;
    syncElement: () => void;
    disabled: boolean;
};

export const TeamMemberList = ({ dependencies, name, wbsElement, syncElement, disabled }: Props) => {
    const {
        setRenderSelectionButton,
        setSelectedElementId,
        setContextId,
        setSelectionMode,
        selectionMode,
        turnSelectionOff,
    } = useContext(SelectionContext);
    const { selectedProject } = useContext(ProjectContext);
    const { t } = useTranslation();

    const setSelectFunction = async (humanResource: Components.Schemas.HumanResourceDTO) => {
        const newDependency: Components.Schemas.HumanResourceAssignmentDTO = {
            humanResource: humanResource,
            projectId: selectedProject?.id,
            wbsElement: wbsElement,
        };
        const jwtObj = localStorage.getItem('auth');

        try {
            await axios.post<Components.Schemas.HumanResourceAssignmentDTO>(
                `${API_BASE_URL}${HUMAN_RESOUCE_ASSIGNMENT_API_BASE}`,
                { ...newDependency },
                {
                    headers: { Authorization: `Bearer ${jwtObj}` },
                }
            );
            syncElement();
        } catch (e) {
            throw e;
        }
    };

    const selectFunction = () => {
        setSelectedElementId(wbsElement?.id);
        setSelectionMode(SelectionType.Person);

        setContextId(wbsElement?.team?.id);

        setRenderSelectionButton(() => (humanResource: Components.Schemas.HumanResourceDTO) => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={() => setSelectionMode(undefined)}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        setSelectFunction(humanResource);
                        setSelectionMode(undefined);
                    }}
                />
            </div>
        ));
    };

    useEffect(() => {
        setRenderSelectionButton(() => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        syncElement();
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
    }, [setRenderSelectionButton]);

    return (
        <div className="ProAttrField ProAttrField__dependencies">
            <div className="ProAttrField__head">
                <span className="ProAttrField__name">
                    <h3>{name}</h3>
                </span>
                <span className="ProAttrField__buttons">
                    <ProIconButton
                        tooltip={
                            wbsElement?.team === undefined || wbsElement?.team === null
                                ? t('error.noData.noAssignedTeam')
                                : t('buttons.addElement')
                        }
                        icon={ProIconDataButtons.add_xs}
                        onClick={selectFunction}
                        size={ProSize.XSmall}
                        mainIntent={ProIntent.Success}
                        disabled={
                            disabled ||
                            wbsElement?.team === undefined ||
                            wbsElement?.team === null ||
                            selectionMode === SelectionType.HumanResource
                        }
                    />
                </span>
            </div>
            {dependencies && dependencies.length !== 0 && (
                <div className="ProAttrField__dependencies--header">
                    <ProRow>
                        <ProCol span={7}>{t('table.cols.name')}</ProCol>
                        {/* <ProCol span={2}>{t('table.cols.amount')}</ProCol> */}
                        <ProCol span={2}>{'Planned effort'}</ProCol>
                        <ProCol span={3}></ProCol>
                    </ProRow>
                </div>
            )}
            {dependencies?.map((cur) => (
                <TeamMemberWindow
                    dependency={cur}
                    key={`dependency_${cur.id}`}
                    disabled={disabled}
                    sync={syncElement}
                />
            ))}
            {dependencies && dependencies.length === 0 && (
                <ProRow className="ProAttrField__dependencies--box ProAttrField__dependencies--box__empty">
                    {t('error.noData.teamMember')}
                </ProRow>
            )}
        </div>
    );
};
