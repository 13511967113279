import { Position, Toaster } from '@blueprintjs/core';
import { TFunction } from 'i18next';
import { WindowDisplayState } from '../modules/Windows/WBSElement/WBSElement.helpers';
import { Components } from './types';

const AppToaster = Toaster.create({
    className: 'proToaster',
    position: Position.TOP_RIGHT,
});

export const showSuccessToast = (msg: string) => {
    AppToaster.show({ message: msg, intent: 'success' });
};

export const showErrorToast = (msg: string) => {
    AppToaster.show({ message: msg, intent: 'danger' });
};

export const showWarningToast = (msg: string) => {
    AppToaster.show({ message: msg, intent: 'warning' });
};

export const showInfoToast = (msg: string) => {
    AppToaster.show({ message: msg, intent: 'warning' });
};

export const getHiddenForWindowDisplayState = (mode: WindowDisplayState | WindowDisplayState[]): boolean => {
    if (Array.isArray(mode)) return mode.find((cur) => cur === 'edit' || cur === 'new') !== undefined;

    return mode === 'edit' || mode === 'new';
};

export const getPersonsName = (t: TFunction, person?: Components.Schemas.PersonDTO): string => {
    if (person?.firstName && person.lastName) return `${person?.firstName} - ${person?.lastName}`;

    if (person?.firstName) return `${person?.firstName}`;

    if (person?.lastName) return `${person?.lastName}`;

    if (!person) return t('windows.noData');
    return t('windows.noPerson');
};
