import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HUMAN_RESOUCE_API_BASE, TEAM_API_BASE } from '../../../api/api';
import { deleteAPIRequest } from '../../../api/useGenericApi';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { DetaiWindowWrapper } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { useTeamsDetailRouting } from '../../../hooks/useTeamsDetailRouting';
import { proLinkStructure } from '../../../modules/Menu/Navigate';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { TeamsSummaryWindow } from '../../../modules/Windows/SummaryDetails/TeamSummaryWindow';
import { showErrorToast } from '../../../utils/helpers';
import {
    HumanResourceDTO,
    ProjectActionCell,
    ProjectDTO,
    TeamDTO,
    getTableRowClass,
    isHumanResource,
    isProject,
    isTeam,
} from './Team.helpers';
import { TeamPageItemCell } from './modules/TeamPageItemCell';
import { useTeamPageData } from './useTeamPageData';

export const ProjectTeamPage = ({ isHidden = false, pageTitle }: { isHidden?: boolean; pageTitle?: string }) => {
    const [selectedTableRow, setSelectedTableRow] = useState<number | undefined>(0);
    const { selectionMode, renderSelectionButton } = useContext(SelectionContext);
    const { t } = useTranslation();

    const deselectTableRow = () => {
        setSelectedTableRow(undefined);
        setSelectedHuman(undefined);
        openTeam('hidden');
        openHuman('hidden');
    };

    const {
        allElements,
        selectedProject,
        setSelectedHuman,
        openTeam,
        openHuman,
        syncProjects,
        teamMode,
        humanMode,
        renderTeamDetailWindow,
        renderHumanDetailWindow,
        selectedTeam,
        setSelectedTeam,
        selectedHuman,
        loading,
        teams,
    } = useTeamPageData(deselectTableRow);

    const selectTableRow = (elm: TeamDTO | ProjectDTO | HumanResourceDTO) => {
        const i = allElements.findIndex((cur) => cur.id === elm.id);

        setSelectedTableRow(i);
        setSelectedHuman(undefined);
        openTeam('hidden');
        openHuman('hidden');

        if (isHumanResource(elm)) {
            setSelectedHuman(elm);
            openHuman('view');
        }
        if (isTeam(elm)) {
            setSelectedTeam(elm);
            openTeam('view');
        }
    };

    const addTeam = () => {
        deselectTableRow();
        openTeam('new');
    };

    const addHumanResource = () => {
        setSelectedTableRow(undefined);
        openHuman('new');
    };

    const deleteHuman = (item: HumanResourceDTO) => {
        deleteAPIRequest(item.id!, HUMAN_RESOUCE_API_BASE)
            .then(() => {
                syncProjects();
            })
            .catch(() => {
                showErrorToast('Cant delete human resource');
            });
    };

    const deleteTeam = (item: TeamDTO) => {
        deleteAPIRequest(item.id!, TEAM_API_BASE)
            .then(() => {
                syncProjects();
            })
            .catch(() => {
                showErrorToast('Cant delete team');
            });
    };

    const toggleOpenTeamWindow = (team: TeamDTO) => {
        if (selectedTeam?.id === team.id) setSelectedTeam(undefined);
        else setSelectedTeam(team);
    };

    useTeamsDetailRouting({
        basePath: proLinkStructure.projectManagement.teamStructure.link,
        tableData: allElements,
        selectElement: (elm) => selectTableRow(elm),
        selectedHumanId: selectedHuman?.id,
        selectedPortfolioId: selectedProject?.id,
        selectedTeamId: selectedTeam?.id,
    });

    const tableData: TableColData[] = [
        {
            name: t('table.cols.name'),
            cellRender: (i) => {
                const item = allElements[i];
                if (isProject(item))
                    return <TeamPageItemCell elm={item} onClick={() => selectTableRow(item)} open={true} />;

                if (isHumanResource(item))
                    return (
                        <TeamPageItemCell
                            elm={item}
                            onClick={() => selectTableRow(item)}
                            icon={ProIconData.person_fill}
                            level={2}
                        />
                    );

                if (isTeam(item))
                    return (
                        <TeamPageItemCell
                            elm={item}
                            onClick={() => {
                                selectTableRow(item);
                                toggleOpenTeamWindow(item);
                            }}
                            open={item.id! === selectedTeam?.id}
                            icon={ProIconData.team_fill}
                            level={1}
                            openAction={() => {
                                toggleOpenTeamWindow(item);
                            }}
                        />
                    );
            },
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) => {
                const item = allElements[i];

                if (isProject(item))
                    return <ProjectActionCell addAction={addTeam} windowMode={[teamMode, humanMode]} t={t} />;

                if (isHumanResource(item))
                    return selectionMode === SelectionType.HumanResource ? (
                        renderSelectionButton && renderSelectionButton(item)
                    ) : (
                        <ActionCell
                            i={i}
                            selectTableRow={() => selectTableRow(item)}
                            detailWindowMode={[teamMode, humanMode]}
                            deleteAction={() => deleteHuman(item)}
                            deleteMessage="HRs can only be deleted if nothing is connected to it"
                        />
                    );

                if (isTeam(item))
                    return (
                        <ActionCell
                            i={i}
                            selectTableRow={() => {
                                selectTableRow(item);
                            }}
                            detailWindowMode={[teamMode, humanMode]}
                            createElement={addHumanResource}
                            deleteAction={() => deleteTeam(item)}
                            deleteMessage="Teams can only be deleted if nothing is connected to it"
                        />
                    );
            },
            colSpace: 4,
        },
    ];

    if (!loading && !selectedProject) {
        return <ProNoDataOrRightsPage message={[t('error.noData.noPortfolio')]} />;
    }

    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [{ type: ApplicationElementType.ProjectMan, name: selectedProject?.name }],
                t
            )}
            name={pageTitle ?? t('navigation.projectMan.teamStruct')}
            icon={ProIconData.team_fill}
            isHidden={isHidden}
            displyProjectSelector
            loading={loading}
        >
            <ProTable
                data={tableData}
                rowsCount={allElements.length}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => getTableRowClass(allElements[i])}
            />
            <DetaiWindowWrapper hidden={[false]}>
                <TeamsSummaryWindow teams={teams} />

                {renderTeamDetailWindow()}
                {renderHumanDetailWindow()}
            </DetaiWindowWrapper>
        </ProPage>
    );
};
