import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { PortfolioPeoplePage } from '../../PortfolioManagement/People/PortfolioPeoplePage';
import { TeamRequirementsManagementPage } from '../../TeamManagement/Requirements/Requirements';
import { ProjectQualityPage } from './ProjectQualityPage';

export const QualitySelectionFrame = () => {
    const { selectionMode, pageTitle } = useContext(SelectionContext);
    const { t } = useTranslation();
    return (
        <>
            <PortfolioPeoplePage
                isHidden={selectionMode !== SelectionType.HumanResource}
                pageTitle={t('windows.selectPerson')}
            />
            <TeamRequirementsManagementPage isHidden={selectionMode !== SelectionType.WBSToReq} pageTitle={pageTitle} />
            <ProjectQualityPage isHidden={selectionMode !== undefined} />
        </>
    );
};
