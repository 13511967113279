import React, { ReactNode } from 'react';
import { ProCol } from '../Grid/Col';
import { ProRow } from '../Grid/Row';
import { ProAttrField } from './AttrField';

type ProAttrFieldForSubElementsProps = {
    values?: (string | null | undefined)[];
    name?: string;
    buttons?: ReactNode;
    getInlineButtons?: (elm: string) => ReactNode;
};

export const ProAttrFieldForSubElements = ({
    name,
    values,
    buttons,
    getInlineButtons,
}: ProAttrFieldForSubElementsProps) => (
    <ProRow>
        <div className="ProAttrField ProAttrField__dependencies">
            <div className="ProAttrField__head">
                <span className="ProAttrField__name">
                    <h3>{name}</h3>
                </span>
                <span className="ProAttrField__buttons">{buttons}</span>
            </div>

            {values && values.length !== 0 ? (
                values.map((cur) => (
                    <ProRow className="ProAttrField__dependencies--box" key={`key_${cur}`}>
                        <ProCol span={11}>
                            <ProAttrField value={cur} />
                        </ProCol>
                        <ProCol span={1} className="ProAttrField__dependencies__buttons">
                            {getInlineButtons && getInlineButtons(cur ?? '')}
                        </ProCol>
                    </ProRow>
                ))
            ) : (
                <ProAttrField value="no Data" />
            )}
        </div>
    </ProRow>
);
