import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePersonalTasks } from '../../../api/usePersonalTasks';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { DetaiWindowWrapper } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { useDetailRouting } from '../../../hooks/useDetailRouting';
import { proLinkStructure } from '../../../modules/Menu/Navigate';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';
import { usePersonalTaskDetailsWindow } from './components/usePersonalTasksDetailWindow';

export const PersonalTasksPage = () => {
    const { t } = useTranslation();
    const [selectedTask, setSelectedTask] = useState<Components.Schemas.TaskDTO>();
    const [selectedTableRow, setSelectedTableRow] = useState<number>();

    const { data: tasks, error, loading, sync } = usePersonalTasks();
    const { selectedProject } = useContext(ProjectContext);

    const selectTask = (task?: Components.Schemas.TaskDTO) => {
        if (task) {
            const currentPosition = tasks?.findIndex((cur) => cur.id === task.id);
            setSelectedTask(task);
            setSelectedTableRow(currentPosition);
            openTask('view', task);
        }
    };

    const selectTaskFromId = (id: string) => {
        const currentTask = tasks?.find((cur) => cur.id === id);
        selectTask(currentTask);
    };

    const deselectTableRow = () => {
        setSelectedTableRow(undefined);
        setSelectedTask(undefined);
        openTask('hidden');
    };

    //Todo: think about getting rid of it in favor of selectElement
    const selectTableRow = (i: number) => {
        const currenTask = tasks && tasks[i];
        selectTask(currenTask);
    };

    const createTask = () => {
        openTask('new');
    };

    const deleteTask = (id: string) => {
        //Todo: implement
    };

    const {
        isDisplayed: taskIsDisplayed,
        mode: taskMode,
        open: openTask,
        renderDetailWindow: renderTaskDetailWindow,
    } = usePersonalTaskDetailsWindow({
        closeAction: deselectTableRow,
        selectAction: selectTaskFromId,
        externalSync: sync,
        id: selectedTask?.id,
        taskListId: '????',
    });

    const routingError = useDetailRouting({
        link: proLinkStructure.personalManagement.tasks.link,
        path: proLinkStructure.personalManagement.tasks.path,
        selectElement: selectTaskFromId,
        data: tasks,
        selectedElement: selectedTask,
    });

    const peopleData: TableColData[] = [
        {
            name: t('table.cols.name'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable__levelBars ">
                            <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                        </div>
                        <span className="ProTable__name" onClick={() => selectTableRow(i)}>
                            <ProIcon size={ProSize.Small} icon={ProIconData.project_fill} />
                            {selectedProject?.name ?? 'selected Project'}
                        </span>
                    </>
                ) : (
                    <>
                        <div className="ProTable__levelBars ">
                            <div className="ProTable__levelBar ProTable__levelBar-3">3</div>
                        </div>
                        <span className="ProTable__name" onClick={() => selectTableRow(i)}>
                            <ProIcon size={ProSize.Small} icon={ProIconData.checkBox_stroke} />
                            {tasks[i - 1].name ?? 'error'}
                        </span>
                    </>
                ),
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable--actionCell">
                            <ProButton
                                leftIcon={ProIconDataButtons.add_xs}
                                onClick={() => createTask()}
                                tooltip={t('buttons.add', { var: t('types.inside') })}
                                mainIntent={ProIntent.Transpared}
                                hoverIntent={ProIntent.Success}
                                lineIntent={ProIntent.Success}
                                disabled={getHiddenForWindowDisplayState([taskMode])}
                                size={ProSize.XSmall}
                                text={t('buttons.add', { var: t('types.task') })}
                                placement="bottom"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <ActionCell
                            i={i}
                            selectTableRow={selectTableRow}
                            detailWindowMode={taskMode}
                            deleteAction={() => deleteTask(tasks[i - 1].id!)}
                        />
                    </>
                ),
            colSpace: 4,
        },
    ];

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.PersonalManagement], t)}
            name={t('navigation.personalMan.tasks')}
            error={error}
            loading={loading}
        >
            <ProTable
                data={peopleData}
                rowsCount={tasks.length + 1}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
            />

            <DetaiWindowWrapper hidden={[taskIsDisplayed]}>{renderTaskDetailWindow()}</DetaiWindowWrapper>
        </ProPage>
    );
};
