import { TFunction } from 'i18next';
import { ProIconData } from '../atoms/Icons/IconData';
import { RoutingHistorySections } from '../hooks/useRouting';
import { Personal_BASE, Portfolio_BASE, Project_BASE, proLinkStructure, Team_BASE } from '../modules/Menu/Navigate';
import { MenuItemType } from './types';

//Todo: Define icons for menu points

export const getPersonal_MenuData = (t: TFunction): MenuItemType[] => [
    {
        name: t('navigation.personalMan.welcome'),
        icon: ProIconData.person_fill,
        link: proLinkStructure.personalManagement.welcome.link,
        disabled: true,
        description: t('information.personalMan.welcome'),
        section: RoutingHistorySections.personalManagement,
    },
    {
        name: 'spacer',
        icon: ProIconData.add_stroke,
        link: '#',
        description: '',
        section: RoutingHistorySections.personalManagement,
    },
    {
        name: 'spacer',
        icon: ProIconData.add_stroke,
        link: '#',
        description: '',
        section: RoutingHistorySections.personalManagement,
    },
    {
        name: t('navigation.personalMan.workPackages'),
        icon: ProIconData.package_stroke,
        link: proLinkStructure.personalManagement.workPackages.link,
        description: t('information.personalMan.workPackages'),
        section: RoutingHistorySections.personalManagement,
    },
    {
        name: t('navigation.personalMan.tasks'),
        icon: ProIconData.checkBox_stroke,
        link: proLinkStructure.personalManagement.tasks.link,
        description: t('information.personalMan.tasks'),
        section: RoutingHistorySections.personalManagement,
    },
    {
        name: t('navigation.personalMan.simulation'),
        icon: ProIconData.forecast_outline,
        link: proLinkStructure.personalManagement.simulation.link,
        disabled: true,
        description: t('information.personalMan.simulation'),
        section: RoutingHistorySections.personalManagement,
    },
];

export const getTeam_MenuData = (t: TFunction): MenuItemType[] => [
    {
        name: t('navigation.teamMan.team'),
        icon: ProIconData.team_fill,
        link: proLinkStructure.teamManagement.team.link,
        description: t('information.teamMan.team'),
        section: RoutingHistorySections.teamManagement,
    },
    {
        name: t('navigation.teamMan.stakeholders'),
        icon: ProIconData.steakholder_fill,
        link: proLinkStructure.teamManagement.stakeholders.link,
        disabled: true,
        description: t('information.teamMan.stakeholders'),
        section: RoutingHistorySections.teamManagement,
    },
    {
        name: t('navigation.teamMan.requirements'),
        icon: ProIconData.scope_fill,
        link: proLinkStructure.teamManagement.requirements.link,
        description: t('information.teamMan.requirements'),
        section: RoutingHistorySections.teamManagement,
    },
    {
        name: t('navigation.teamMan.workPackages'),
        icon: ProIconData.package_stroke,
        link: proLinkStructure.teamManagement.workPackages.link,
        description: t('information.teamMan.workPackages'),
        section: RoutingHistorySections.teamManagement,
    },
    {
        name: t('navigation.teamMan.tasks'),
        icon: ProIconData.checkBox_stroke,
        link: proLinkStructure.teamManagement.tasks.link,
        disabled: true,
        description: t('information.teamMan.tasks'),
        section: RoutingHistorySections.teamManagement,
    },
    {
        name: t('navigation.teamMan.ceremonies'),
        icon: ProIconData.ceremonies_stroke,
        link: proLinkStructure.teamManagement.ceremonies.link,
        description: t('information.teamMan.ceremonies'),
        section: RoutingHistorySections.teamManagement,
    },
];

export const getProject_MenuData = (t: TFunction): MenuItemType[] => [
    {
        name: t('navigation.projectMan.teamStruct'),
        icon: ProIconData.team_fill,
        link: proLinkStructure.projectManagement.teamStructure.link,
        description: t('information.projectMan.teamStruct'),
        section: RoutingHistorySections.projectManagement,
    },
    {
        name: t('navigation.projectMan.riskMan'),
        icon: ProIconData.risk_fill,
        link: proLinkStructure.projectManagement.riskManagement.link,
        disabled: true,
        description: t('information.projectMan.riskMan'),
        section: RoutingHistorySections.projectManagement,
    },
    {
        name: t('navigation.projectMan.qualityMan'),
        icon: ProIconData.quality_fill,
        link: proLinkStructure.projectManagement.quality.link,
        description: t('information.projectMan.qualityMan'),
        section: RoutingHistorySections.projectManagement,
    },
    {
        name: t('navigation.projectMan.timeMan'),
        icon: ProIconData.time_fill,
        link: proLinkStructure.projectManagement.time.link,
        description: t('information.projectMan.timeMan'),
        section: RoutingHistorySections.projectManagement,
    },
    {
        name: t('navigation.projectMan.costMan'),
        icon: ProIconData.cost_fill,
        link: proLinkStructure.projectManagement.cost.link,
        description: t('information.projectMan.costMan'),
        section: RoutingHistorySections.projectManagement,
    },
    {
        name: t('navigation.projectMan.integrationMan'),
        icon: ProIconData.integration_fill,
        link: proLinkStructure.projectManagement.integration.link,
        description: t('information.projectMan.integrationMan'),
        section: RoutingHistorySections.projectManagement,
    },
];

export const getPortfolio_MenuData = (t: TFunction): MenuItemType[] => [
    {
        name: t('navigation.portfolioMan.people'),
        icon: ProIconData.team_fill,
        link: proLinkStructure.portfolioManagement.people.link,
        description: t('information.portfolioMan.peopleAndPositions.people'),
        section: RoutingHistorySections.portfolioManagement,
    },
    {
        name: t('navigation.portfolioMan.baseData.base'),
        icon: ProIconData.base_data,
        link: proLinkStructure.portfolioManagement.baseData.link,
        description: t('information.portfolioMan.peopleAndPositions.base'),
        section: RoutingHistorySections.portfolioManagement,
        sublinks: [
            {
                name: t('navigation.portfolioMan.baseData.positions'),
                icon: ProIconData.team_fill,
                link: proLinkStructure.portfolioManagement.baseData.teamPositions.link,
                description: t('information.portfolioMan.peopleAndPositions.positions'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: t('navigation.portfolioMan.baseData.functions'),
                icon: ProIconData.base_data,
                link: proLinkStructure.portfolioManagement.baseData.functions.link,
                description: t('information.portfolioMan.baseData.functions'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: t('navigation.portfolioMan.baseData.level'),
                icon: ProIconData.base_data,
                link: proLinkStructure.portfolioManagement.baseData.level.link,
                description: t('information.portfolioMan.baseData.level'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: t('navigation.portfolioMan.baseData.units'),
                icon: ProIconData.skale_fill,
                link: proLinkStructure.portfolioManagement.baseData.units.link,
                description: t('information.portfolioMan.capacityMan.units'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: t('navigation.portfolioMan.capacityMan.roles'),
                icon: ProIconData.team_fill,
                link: proLinkStructure.portfolioManagement.baseData.functionalRoles.link,
                description: t('information.portfolioMan.capacityMan.roles'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: 'spacer',
                icon: ProIconData.team_fill,
                link: proLinkStructure.portfolioManagement.baseData.functionalRoles.link,
                description: t('information.portfolioMan.capacityMan.roles'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: t('navigation.portfolioMan.baseData.material'),
                icon: ProIconData.material_fill,
                link: proLinkStructure.portfolioManagement.baseData.material.link,
                description: t('information.portfolioMan.capacityMan.material'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: 'spacer',
                icon: ProIconData.team_fill,
                link: proLinkStructure.portfolioManagement.baseData.functionalRoles.link,
                description: t('information.portfolioMan.capacityMan.roles'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: t('navigation.portfolioMan.baseData.agile'),
                icon: ProIconData.personal_management_fill,
                link: proLinkStructure.portfolioManagement.baseData.agile.link,
                description: t('information.portfolioMan.capacityMan.agile'),
                section: RoutingHistorySections.portfolioManagement,
            },
            {
                name: t('navigation.portfolioMan.baseData.stakeholder'),
                icon: ProIconData.steakholder_fill,
                link: proLinkStructure.portfolioManagement.baseData.units.link,
                description: t('information.portfolioMan.capacityMan.units'),
                section: RoutingHistorySections.portfolioManagement,
            },
        ],
    },
    {
        name: t('navigation.portfolioMan.projecs'),
        icon: ProIconData.project_fill,
        link: proLinkStructure.portfolioManagement.projects.link,
        description: t('information.portfolioMan.projecs'),
        section: RoutingHistorySections.portfolioManagement,
    },
    {
        name: t('navigation.portfolioMan.roadmap'),
        icon: ProIconData.roadmap_stroke,
        link: proLinkStructure.portfolioManagement.roadmap.link,
        disabled: true,
        description: t('information.portfolioMan.roadmap'),
        section: RoutingHistorySections.portfolioManagement,
    },
    {
        name: t('navigation.portfolioMan.capacityMan.base'),
        icon: ProIconData.cost_fill,
        link: proLinkStructure.portfolioManagement.capacity.link,
        description: t('information.portfolioMan.capacityMan.base'),
        section: RoutingHistorySections.portfolioManagement,
        sublinks: [
            {
                name: t('navigation.portfolioMan.capacityMan.capacity'),
                icon: ProIconData.cost_fill,
                link: proLinkStructure.portfolioManagement.capacity.capacity.link,
                description: t('information.portfolioMan.capacityMan.capacity'),
                section: RoutingHistorySections.portfolioManagement,
            },
        ],
    },
    {
        name: t('navigation.portfolioMan.portfolio'),
        icon: ProIconData.portfolio_fill,
        link: proLinkStructure.portfolioManagement.integration.link,
        disabled: true,
        description: t('information.portfolioMan.portfolio'),
        section: RoutingHistorySections.portfolioManagement,
    },
];

export const getBASE_MenuData = (t: TFunction): MenuItemType[] => [
    {
        name: t('navigation.personalMan.base'),
        icon: ProIconData.personal_management_stroke,
        link: Personal_BASE,
        activeIcon: ProIconData.personal_management_fill,
        sublinks: getPersonal_MenuData(t),
        section: RoutingHistorySections.personalManagement,
    },
    {
        name: t('navigation.teamMan.base'),
        icon: ProIconData.team_management_stroke,
        link: Team_BASE,
        activeIcon: ProIconData.team_management_fill,
        sublinks: getTeam_MenuData(t),
        section: RoutingHistorySections.teamManagement,
    },
    {
        name: t('navigation.projectMan.base', { break: '-' }),
        icon: ProIconData.project_management_stroke,
        link: Project_BASE,
        activeIcon: ProIconData.project_management_fill,
        sublinks: getProject_MenuData(t),
        section: RoutingHistorySections.projectManagement,
    },
    {
        name: t('navigation.portfolioMan.base', { break: '-' }),
        icon: ProIconData.portfolio_management_stroke,
        link: Portfolio_BASE,
        activeIcon: ProIconData.portfolio_management_fill,
        sublinks: getPortfolio_MenuData(t),
        section: RoutingHistorySections.portfolioManagement,
    },
];
