import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REQUIREMENTSTRUCTURE_API_BASE, REQUIREMENTS_API_BASE } from '../../../../api/api';
import { deleteAPIRequest, useGenericAPI } from '../../../../api/useGenericApi';
import { ActionCell } from '../../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../../atoms/Icons/Icon';
import { ProTable, TableColData } from '../../../../atoms/Table/Table';
import { ApplicationElementType, getBaseBCItemForType } from '../../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../../components/Page/Page';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import { ProSize } from '../../../../utils/enums';
import { showErrorToast } from '../../../../utils/helpers';
import { Components } from '../../../../utils/types';
import {
    getReqCellIcon,
    getReqStatusIcon,
    isReqNotBacklog,
    isTaskNotReq,
} from '../../Requirements/components/RequirementTableNameCell';
import { RequirementContextProvider } from '../../Requirements/context/RequirementContext';
import {
    RequirementDetailWindowType,
    useRequirementDetailsFrame,
} from '../../Requirements/modules/RequirementDetailsFrame';

export const BackglogGroomingPage = () => {
    const { t } = useTranslation();
    const { selectedProject, loading: projectLoading, error: projectError, selectedTeam } = useContext(ProjectContext);
    const [selectedRequirement, setSelectedRequirement] = useState<Components.Schemas.RequirementDTO>();
    const [selectedTableRow, setSelectedTableRow] = useState<number>();

    const deselectTableRow = () => {
        setSelectedTableRow(undefined);
        setSelectedRequirement(undefined);
        open('hidden');
    };

    const selectElement = (id: string) => {
        const req = requirements?.find((cur) => cur.id === id);

        open('view');
        setSelectedRequirement(req);
    };

    const selectedRequStruct = useMemo(
        () => selectedProject?.requirementStructures?.find((cur) => cur.version === 'Forecast'),
        [selectedProject]
    );

    const {
        data: requirementStructure,
        error,
        loading,
        sync,
    } = useGenericAPI<Components.Schemas.RequirementStructureDTO>(
        `${REQUIREMENTSTRUCTURE_API_BASE}/${selectedRequStruct?.id}/Team`,
        selectedTeam?.id
    );

    const { isDisplayed, mode, open, renderDetailWindow } = useRequirementDetailsFrame({
        closeAction: deselectTableRow,
        id: selectedRequirement?.id,
        selectAction: selectElement,
        externalSync: sync,
        type: RequirementDetailWindowType.Grooming,
    });

    const requirements = useMemo(
        () =>
            requirementStructure?.requirements
                ?.filter(
                    (cur) =>
                        cur.requirementType === 'Bug' ||
                        cur.requirementType === 'ChangeRequest' ||
                        cur.requirementType === 'UserStory'
                )
                .filter(
                    (cur) => cur.requirementState?.name === 'in funnel' || cur.requirementState?.name === 'not ready'
                ) ?? [],
        [requirementStructure]
    );

    const deleteAction = (i: number) => {
        const req = requirements[i];
        if (req.id) {
            deleteAPIRequest(req.id ?? '0', REQUIREMENTS_API_BASE)
                .then(() => {
                    sync();
                })
                .catch(() => {
                    showErrorToast('Cant delete element.');
                });
        }
    };

    //Todo: in helper auslagern
    // Display tasks here?
    const tableData: TableColData[] = [
        {
            name: 'Requirement',
            cellRender: (i) => {
                return (
                    <>
                        <div className="ProTable__levelBars">
                            <div className={`ProTable__levelBar ProTable__levelBar-1`}>1</div>
                        </div>

                        {/* <div className={classNames('ProTable__level ProTable__level--empty')}> </div> */}

                        <span className="ProTable__name" onClick={() => selectElement(requirements[i].id!)}>
                            <ProIcon size={ProSize.Small} icon={getReqCellIcon(requirements[i])} />
                            {requirements[i].name ?? 'not named'}
                        </span>
                    </>
                );
            },
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: 'Status',
            cellRender: (i) => (
                <>
                    {getReqStatusIcon(requirements[i].requirementType, requirements[i].requirementState?.name)}
                    {/* {translateStatus(t, requirements[i].requirementState?.name)} */}
                </>
            ),
            colSpace: isDisplayed ? 3 : 4,
            className: 'ProTable__statusIcon',
        },
        {
            name: 'Responsible',
            cellRender: (i) =>
                isReqNotBacklog(requirements[i])
                    ? `${requirements[i].responsiblePerson?.firstName ?? ''} ${
                          requirements[i].responsiblePerson?.lastName ?? '-'
                      }`
                    : '',
            colSpace: 4,
            hidden: () => !isDisplayed,
        },
        {
            name: 'Scope',
            cellRender: (i) => {
                if (isTaskNotReq(requirements[i])) return '-';
                return `${requirements[i].storyPoints} SP`;
            },
            colSpace: 2,
            hidden: () => !isDisplayed,
        },
        {
            name: 'Benefit',
            cellRender: (i) => {
                if (isTaskNotReq(requirements[i])) return '-';
                return `${requirements[i].businessValue} BP`;
            },
            colSpace: 2,
            hidden: () => !isDisplayed,
        },
        {
            name: 'Priority',
            cellRender: (i) => (isReqNotBacklog(requirements[i]) ? requirements[i].requirementPriorityState?.name : ''),
            colSpace: 2,
            hidden: () => !isDisplayed,
        },
        {
            cellRender: (i) => <>{requirements[i].wbsElement ? <>{requirements[i].wbsElement?.name}</> : '-'}</>,
            colSpace: 3,
            name: 'Assigned WBS',
            hidden: () => !isDisplayed,
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) => (
                <ActionCell
                    i={i}
                    selectTableRow={() => selectElement(requirements[i].id!)}
                    detailWindowMode={[mode]}
                    deleteAction={() => {
                        deleteAction(i);
                    }}
                />
            ),

            colSpace: 4,
        },
    ];

    //set the corresponding row to active when selected item changed
    useEffect(() => {
        // +1 -1 is a hack to make findIndex => -1 into 0 wich is nullable
        const row = requirements.findIndex((cur) => cur.id === selectedRequirement?.id) + 1;
        setSelectedTableRow(row - 1);
    }, [requirements, selectedRequirement]);

    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [
                    ApplicationElementType.TeamManagement,
                    { type: ApplicationElementType.TeamManagement, name: selectedTeam?.name },
                ],
                t
            )}
            name={'Backlog grooming'}
            loading={loading}
            //Todo: sum of story points
        >
            <RequirementContextProvider>
                {selectedProject && tableData && (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <ProTable
                            data={tableData}
                            rowsCount={requirements?.length ?? 1}
                            selectedTableRow={selectedTableRow}
                            getRowClass={(i) => `ProTable--row__level-1 `}
                        />
                    </div>
                )}
                <div
                    className={classNames(
                        'ProApp--detailWindowSection',
                        isDisplayed && 'ProApp--detailWindowSection__expanded'
                    )}
                >
                    {renderDetailWindow()}
                </div>
            </RequirementContextProvider>
        </ProPage>
    );
};
