import classNames from 'classnames';
import React from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { ProIcon } from '../../atoms/Icons/Icon';
import { ProIconData } from '../../atoms/Icons/IconData';
import { getIconForTypeInNameCell } from '../../modules/Windows/WBSElement/WBSElement.helpers';
import { Components } from '../../utils/types';
import { getChildren, isNodeOpen } from './wbsTabeHelpers';

type DeltaDTO = Components.Schemas.WBSElementDeltaDTO;

type Props = {
    i: number;
    displayData: DeltaDTO[];
    baseData?: Components.Schemas.WBSStructureDTO;
    openNodes: DeltaDTO[];
    toggleOpenNode: (node?: DeltaDTO) => void;
};

export const NameCell = ({ baseData, displayData, i, openNodes, toggleOpenNode }: Props) => {
    const history = useHistory();
    const match = useRouteMatch();

    const navigate = () => {
        const to = generatePath(match.path, { wbsElementId: displayData[i].id });
        history.push(to);
    };
    return (
        <>
            <div className="ProTable__levelBars ">
                <div className={classNames('ProTable__levelBar', `ProTable__levelBar-${displayData![i]?.level}`)}>
                    {displayData![i]?.level}
                </div>
            </div>

            {baseData?.wbsElements &&
            displayData &&
            displayData[i] &&
            getChildren(displayData![i], baseData.wbsElements).length !== 0 ? (
                <div
                    className={classNames(
                        'ProTable__level',
                        isNodeOpen(openNodes, displayData![i]?.id) && 'ProTable__level--open'
                    )}
                    onClick={() => toggleOpenNode(displayData![i])}
                >
                    <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                </div>
            ) : (
                <div className={classNames('ProTable__level ProTable__level--empty')}> </div>
            )}

            <span
                className="ProTable__name"
                // onClick={() => selectTableRow(i)}
                onClick={navigate}
            >
                {displayData[i]?.wbsElementType && getIconForTypeInNameCell(displayData[i].wbsElementType)}
                {displayData![i]?.name}
            </span>
        </>
    );
};
