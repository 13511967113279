import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Components } from '../utils/types';
import { UserContext } from './UserContext';

type ProjectContextType = {
    selectedProject: Components.Schemas.ProjectDTO | undefined;
    setSelectedProject: (project: Components.Schemas.ProjectDTO | undefined) => void;
    selectedTeam: Components.Schemas.TeamDTO | undefined;
    teams: Components.Schemas.TeamDTO[] | undefined;
    setSelectedTeam: (team: Components.Schemas.TeamDTO | undefined) => void;
    projects: Components.Schemas.ProjectDTO[] | undefined | null;
    sync: () => Promise<void>;
    error: boolean;
    loading: boolean;
    readyCiteriaList?: Components.Schemas.TaskListDTO;
    acceptanceCiteriaList?: Components.Schemas.TaskListDTO;
};

const getSelectedProjectFromStorage = () => {
    return localStorage.getItem('selectedProject');
};

const setSelectedProjectToStorage = (id?: string) => {
    return localStorage.setItem('selectedProject', id ?? 'undefined');
};

const getSelectedTeamFromStorage = () => {
    return localStorage.getItem('selectedTeam');
};

const setSelectedTeamToStorage = (id?: string) => {
    return localStorage.setItem('selectedTeam', id ?? 'undefined');
};

export const ProjectContext = createContext<ProjectContextType>({} as unknown as ProjectContextType);

export const ProjectContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const { projects, sync, loading, error } = useContext(UserContext);
    const [selectedProject, setSelectedProjectInternal] = useState<Components.Schemas.ProjectDTO>();
    const [selectedTeam, setSelectedTeamInternal] = useState<Components.Schemas.TeamDTO>();

    const teams = selectedProject?.teams?.filter((cur) => cur.teamType !== 'ProjectTeam');

    // set inital project
    useEffect(() => {
        const storageId = getSelectedProjectFromStorage();
        const storageProject = projects?.find((cur) => cur.id === storageId);
        if (storageProject) {
            setSelectedProjectInternal(storageProject);
        } else {
            setSelectedProjectInternal(projects && projects![0]);
        }
    }, [projects]);

    // set inital team
    useEffect(() => {
        if (selectedProject && selectedProject?.teams) {
            const storageId = getSelectedTeamFromStorage();
            const storageTeam = selectedProject?.teams.find((cur) => cur.id === storageId);

            if (storageTeam) {
                setSelectedTeamInternal(storageTeam);
            } else {
                setSelectedTeamInternal(selectedProject?.teams[0]);
            }
        }
    }, [selectedProject]);

    const setSelectedProject = (project: Components.Schemas.ProjectDTO | undefined) => {
        setSelectedProjectToStorage(project?.id);
        setSelectedProjectInternal(project);
    };

    const setSelectedTeam = (team: Components.Schemas.TeamDTO | undefined) => {
        setSelectedTeamToStorage(team?.id);
        setSelectedTeamInternal(team);
    };

    const acceptanceCiteriaList = useMemo(
        () => selectedProject?.taskList?.find((cur) => cur.tasklistType === 'AcceptanceCiteria'),
        [selectedProject]
    );
    const readyCiteriaList = useMemo(
        () => selectedProject?.taskList?.find((cur) => cur.tasklistType === 'ReadyCiteria'),
        [selectedProject]
    );

    return (
        <ProjectContext.Provider
            value={{
                projects,
                selectedProject,
                setSelectedProject,
                sync,
                loading,
                selectedTeam,
                setSelectedTeam,
                error,
                readyCiteriaList,
                acceptanceCiteriaList,
                teams,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};
