import classNames from 'classnames';
import React, { Children, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ProIcon } from '../../atoms/Icons/Icon';
import { ProSpinner } from '../../atoms/ProSpinner/Spinner';
import { ProIconType } from '../../constants/types';
import { ProIntent } from '../../utils/enums';
import { ProTab } from './DetailWindowTab';
import { BreadCrumpItem, renderBreadcrump } from './breadCrump.helpers';

type Props = PropsWithChildren<{
    title: string | ReactNode;
    icon: ProIconType;
    displayState: boolean;
    buttonSection?: ReactNode;
    intent?: ProIntent;
    error?: string;
    loading?: boolean;
    breadCrump?: BreadCrumpItem[];
}>;

export const ProDetailWindow = ({
    buttonSection,
    icon,
    children,
    displayState,
    title,
    intent,
    loading = false,
    error,
    breadCrump,
}: Props) => {
    const listChildren = Children.toArray(children);
    const otherContent = listChildren.filter((child) => !isElementOfType(child, ProTab));
    const hasTabs = listChildren.find((child) => isElementOfType(child, ProTab));
    const tabs = listChildren.map((cur) => getTabTitle(cur));
    const [activeTab, setActiveTab] = useState(tabs && tabs[0]);

    const history = useHistory();
    const { search } = useLocation();

    useEffect(() => {
        const tab = new URLSearchParams(search).get('tab');
        const tabIndex = activeTab && tabs.findIndex((cur) => cur === activeTab);
        if (tab) {
            setActiveTab(tab);
        } else if (!tabIndex || tabIndex < 0) setActiveTab(tabs[0]);
    }, [search, tabs]);

    const changeTab = (tab: string | null) => {
        setActiveTab(tab);

        if (tab)
            history.push({
                search: `?tab=${tab}`,
            });
    };

    return (
        <div className={classNames('ProDetailWindow', displayState && 'ProDetailWindow__display')}>
            <div className={classNames('ProDetailWindow--header', intent && `ProDetailWindow--intent-${intent}`)}>
                <div className="ProDetailWindow--box">
                    <div className="ProDetailWindow--buttons">{buttonSection}</div>
                    <div className="ProDetailWindow--breadCrump">
                        {breadCrump ? renderBreadcrump(breadCrump) : 'no path'}
                    </div>
                </div>
                <div className={classNames('ProDetailWindow--header--title')}>
                    <ProIcon icon={icon} intent={ProIntent.Light} />
                    {title}
                </div>
                {hasTabs && (
                    <div className="ProDetailWindow--header--tabs">
                        <ul>
                            {tabs.map((cur) => (
                                <li
                                    key={`header-tabs-${cur}`}
                                    className={classNames(activeTab === cur && 'active')}
                                    onClick={() => changeTab(cur)}
                                >
                                    {cur}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <div className={classNames('ProDetailWindow--content', hasTabs && 'ProDetailWindow--content__withTabs')}>
                {loading && <ProSpinner />}
                {error && !loading && <p>Error</p>}
                {!error &&
                    !loading &&
                    listChildren.find((cur) => isElementOfType(cur, ProTab) && cur.props.name === activeTab)}
                {!error && !loading && otherContent}
            </div>
        </div>
    );
};

type DetaiWindowWrapperProps = PropsWithChildren<{
    hidden: boolean[];
}>;

export const DetaiWindowWrapper = ({ hidden, children }: DetaiWindowWrapperProps) => (
    <div
        className={classNames(
            'ProApp--detailWindowSection',
            hidden.find((cur) => cur) && 'ProApp--detailWindowSection__expanded'
        )}
    >
        {children}
    </div>
);

export function isElementOfType<P = {}>(
    element: any,
    ComponentType: React.ComponentType<P>
): element is React.ReactElement<P> {
    return (
        element != null &&
        element.type != null &&
        ((element.type.displayName != null && element.type.displayName === ComponentType.displayName) ||
            (element.type.name != null && element.type.name === ComponentType.name))
    );
}

export const getTabTitle = (child: ReactNode) => {
    if (isElementOfType(child, ProTab)) {
        const { name } = child.props;
        return name;
    }
    return null;
};
