import { TFunction } from 'i18next';
import React, { SetStateAction } from 'react';
import { ProNumberField } from '../../../../atoms/AttrField/NumberField';
import { ProCol } from '../../../../atoms/Grid/Col';
import { ProRow } from '../../../../atoms/Grid/Row';
import { Components } from '../../../../utils/types';
import { isReqOrSimilar } from '../components/RequirementTableNameCell';

type Props = {
    t: TFunction;
    formData: Components.Schemas.RequirementDTO;
    isDisabled: boolean;
    setFormData: (value: SetStateAction<Components.Schemas.RequirementDTO | undefined>) => void;
};

export const ReqDetailWindowAnalyseTab = ({ t, isDisabled, formData, setFormData }: Props) => (
    <ProRow>
        <ProCol span={12} verticalAlign="top">
            <ProRow>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.forecastedScope')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={formData?.storyPoints}
                        handleChange={(val) => setFormData((cur) => ({ ...cur, storyPoints: Number(val) }))}
                        entity={'SP'}
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.plannedScope')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={'not given'}
                        entity={'SP'}
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.scopeDeviation')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={'not given'}
                        allignLeft
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.forecastedBenefit')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={formData?.businessValue}
                        handleChange={(val) => setFormData((cur) => ({ ...cur, businessValue: Number(val) }))}
                        entity={'BP'}
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.plannedBenefit')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={'not given'}
                        entity={'BP'}
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.benefitDeviation')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={'not given'}
                        allignLeft
                    />
                </ProCol>
            </ProRow>
            <ProRow spacer>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.forecastedQuality')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={formData?.quality}
                        handleChange={(val) => setFormData((cur) => ({ ...cur, quality: Number(val) }))}
                        entity={'%'}
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.plannedQuality')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={'not given'}
                        entity={'%'}
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.qualityDeviation')}
                        disabled={isDisabled || !isReqOrSimilar(formData)}
                        value={'not given'}
                        allignLeft
                    />
                </ProCol>
            </ProRow>
        </ProCol>
    </ProRow>
);
