import classNames from 'classnames';
import React from 'react';
import { ProIconType } from '../../constants/types';
import { ProIntent } from '../../utils/enums';
import { ProIconButton } from '../Button/ProIconButton';

type Props = {
    icon: ProIconType;
    onClick(): void;
    tooltip: string;
    disabled?: boolean;
    rotation?: '0' | '90' | '270' | '180';
    className?: string;
};

export const ProMenuButton = ({ icon, onClick, disabled, tooltip, rotation, className }: Props) => (
    <ProIconButton
        icon={icon}
        tooltip={tooltip}
        onClick={onClick}
        className={classNames(
            'ProMenuButton',
            disabled && 'ProMenuButton--disabled',
            className,
            `ProMenuButton__rotation-${rotation}`
        )}
        mainIntent={ProIntent.PrimaryLight}
        hoverIntent={ProIntent.Secondary}
    />
);
