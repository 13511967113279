import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    className?: string;
    spacer?: boolean;
    verticalAlign?: 'top' | 'center' | 'bottom' | 'spaceAround';
}>;

export const ProRow = ({ children, className, spacer = false, verticalAlign = 'top' }: Props) => (
    <div className={classNames('ProRow', spacer && 'ProRow__space', className, `ProCol--${verticalAlign}`)}>
        {children}
    </div>
);
