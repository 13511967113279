import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWBSStructure } from '../../../../api/useWBSStructure';
import { ProButton } from '../../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../../atoms/Button/ProIconButton';
import { ProIconData, ProIconDataButtons } from '../../../../atoms/Icons/IconData';
import { ProjectStatus } from '../../../../atoms/ProjectStatus/ProjectStatus';
import { ProTable, TableColData } from '../../../../atoms/Table/Table';
import { ProValue } from '../../../../atoms/Value/ProValue';
import { ApplicationElementType, getBaseBCItemForType } from '../../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../../components/Page/Page';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import { ProNoDataOrRightsPage } from '../../../../modules/ProErrorPage/NoDataOrRights';
import { getIconForTypeInNameCell } from '../../../../modules/Windows/WBSElement/WBSElement.helpers';
import { useWBSDetailWindow } from '../../../../modules/Windows/WBSElement/WBSElementHook/WBSDetailFrame';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../../utils/helpers';
import { Components } from '../../../../utils/types';

export const SprintSelectionPage = ({
    setSelectedSprint,
}: {
    setSelectedSprint: Dispatch<SetStateAction<Components.Schemas.WBSElementDeltaDTO | undefined>>;
}) => {
    const { t } = useTranslation();
    const { selectedProject, loading, selectedTeam } = useContext(ProjectContext);

    const [selectedWBSStructureId, setSelectedWBSStructureId] = useState<string>();
    const [selectedDeltaWBSStructureId, setSelectedDeltaWBSStructureId] = useState<string>();
    const [selectedWBSElement, setSelectedWBSElement] = useState<Components.Schemas.WBSElementDeltaDTO>();
    const [selectedTableRow, setSelectedTableRow] = useState<number>();

    const deselectWBSElement = () => {
        setSelectedWBSElement(undefined);
        setSelectedTableRow(undefined);
    };

    useEffect(() => {
        setSelectedWBSStructureId(selectedProject?.wbsStructures?.find((cur) => cur.name === 'Forecast')?.id);
        setSelectedDeltaWBSStructureId(selectedProject?.wbsStructures?.find((cur) => cur.name === 'Plan')?.id);
    }, [selectedProject]);

    const {
        data: selectedWBSStructure,
        error: wbsStructureError,
        loading: wbsStructureLoading,
        sync: syncWBSScructure,
    } = useWBSStructure(selectedWBSStructureId, selectedDeltaWBSStructureId);

    const {
        open,
        renderWBSDetailWindow,
        isDisplayed,
        mode: detailWindowMode,
    } = useWBSDetailWindow('Quality', deselectWBSElement, selectedWBSElement, syncWBSScructure);

    const filteredData = selectedWBSStructure?.wbsElements?.filter((cur) => cur.wbsElementType === 'Sprint') || [];

    // useEffect(() => {
    //     open('view', false);
    // }, [selectedWBSStructure]);

    const selectTableRow = (id: number) => {
        if (selectedWBSStructure) {
            setSelectedWBSElement(filteredData![id]);
            open('view');
            setSelectedTableRow(id);
        }
    };

    const tableData: TableColData[] = [
        {
            name: 'Sprint',
            cellRender: (i) => (
                <>
                    <div className="ProTable__levelBars ">
                        <div className={classNames('ProTable__levelBar', `ProTable__levelBar-1`)}>1</div>
                    </div>

                    <span className="ProTable__name" onClick={() => selectTableRow(i)}>
                        {filteredData[i]?.wbsElementType && getIconForTypeInNameCell(filteredData[i].wbsElementType)}
                        {filteredData![i]?.name}
                    </span>
                </>
            ),
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) => (
                <div className="ProTable--actionCell">
                    <ProButton
                        text={'Plann this sprint'}
                        mainIntent={ProIntent.Success}
                        onClick={() => setSelectedSprint(filteredData[i])}
                    />
                </div>
            ),
            className: `ProTable__buttons`,
            colSpace: 4,
        },
        {
            name: t('table.cols.status'),
            cellRender: (i) => <ProjectStatus delta={filteredData[i]} id={filteredData[i]?.id} />,
            className: 'ProTable__status',
            colSpace: 3,
        },
        {
            name: t('table.cols.responsible'),
            cellRender: (i) =>
                filteredData![i]?.responsiblePerson
                    ? `${filteredData![i]?.responsiblePerson?.firstName ?? ''} ${
                          filteredData![i]?.responsiblePerson?.lastName ?? 'no data'
                      }`
                    : '',
            hidden: () => !isDisplayed,
            colSpace: 4,
        },
        {
            name: t('table.cols.complete'),
            cellRender: (i) => <ProValue type="value" entity="%" value={filteredData![i]?.degreeOfCompletion} />,
            hidden: () => !isDisplayed,
            colSpace: 3,
            rightAligned: true,
        },

        {
            name: t('table.cols.constraint'),
            cellRender: (i) => filteredData![i]?.constraintQualityType,
            hidden: () => !isDisplayed,
            colSpace: 4,
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) => (
                <div className={classNames('ProTable--actionCell')}>
                    <ProIconButton
                        icon={ProIconDataButtons.arrow_right_xs}
                        tooltip={t('buttons.open')}
                        mainIntent={ProIntent.Transpared}
                        hoverIntent={ProIntent.Secondary}
                        size={ProSize.XSmall}
                        onClick={() => selectTableRow(i)}
                        lineIntent={ProIntent.Secondary}
                        disabled={getHiddenForWindowDisplayState(detailWindowMode)}
                        placement="bottom"
                    />
                </div>
            ),
            className: `ProTable__buttons`,
            colSpace: 1,
        },
    ];

    if (!loading && (!selectedTeam || !selectedProject)) {
        return <ProNoDataOrRightsPage message={[t('error.noData.noTeam'), t('error.noData.noPortfolio')]} />;
    }
    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [
                    ApplicationElementType.TeamManagement,
                    { type: ApplicationElementType.TeamManagement, name: selectedTeam?.name },
                ],
                t
            )}
            name={'Please select a sprint to plann'}
            error={wbsStructureError}
            loading={wbsStructureLoading}
            icon={ProIconData.quality_fill}
            displyWBSSelector
            displyProjectSelector
        >
            {selectedWBSStructure && tableData && (
                <ProTable
                    data={tableData}
                    rowsCount={filteredData?.length ?? 0}
                    selectedTableRow={selectedTableRow}
                    getRowClass={(i) => `ProTable--row__level-0`}
                />
            )}
            <div
                className={classNames(
                    'ProApp--detailWindowSection',
                    isDisplayed && 'ProApp--detailWindowSection__expanded'
                )}
            >
                {renderWBSDetailWindow()}
            </div>
        </ProPage>
    );
};
