import classNames from 'classnames';
import React from 'react';
import { ProHeaderAttrField, ProTextField } from '../../../atoms/AttrField/AttrField';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { Components } from '../../../utils/types';
import { DetailWindowProps } from '../GenericDetailWindow';
import { getWindowDisplayStateMode } from '../WBSElement/WBSElement.helpers';

export const TeamDetailWindow = ({
    createAction,
    deleteAction,
    formData,
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    error,
    cancelChanges,
    closeAction,
    t,
}: DetailWindowProps<Components.Schemas.TeamDTO>) => {
    return (
        <ProDetailWindow
            title={
                mode === 'view' ? (
                    <h2
                        className={classNames(
                            formData?.name?.length &&
                                formData?.name?.length >= 180 &&
                                'ProDetailWindow--header--title__large'
                        )}
                    >
                        {formData?.name ?? t('windows.details')}
                    </h2>
                ) : (
                    <ProHeaderAttrField
                        name={t('windows.name')}
                        value={formData?.name ?? String(t('windows.details'))}
                        disabled={false}
                        handleChange={(input) =>
                            setFormData((old) => ({ ...old, name: String(input).substring(0, 180) }))
                        }
                    />
                )
            }
            displayState={isDisplayed}
            icon={ProIconData.team_fill}
            buttonSection={
                <WindowButtonsForMode
                    cancelAction={cancelChanges}
                    closeAction={closeAction}
                    createAction={createAction}
                    deleteAction={deleteAction}
                    mode={mode}
                    setMode={setMode}
                    saveAction={() => saveAction(formData)}
                    t={t}
                />
            }
            intent={getWindowDisplayStateMode(mode)}
            error={mode !== 'new' ? undefined : error}
            loading={loading}
            breadCrump={getBaseBCItemForType([ApplicationElementType.TeamStruc, ApplicationElementType.Team], t)}
        >
            <ProRow spacer>
                <ProCol span={12}>
                    <ProTextField
                        name={t('windows.description')}
                        value={formData?.description}
                        disabled={isDisabled}
                        handleChange={(input) => setFormData((old) => ({ ...old, description: String(input) }))}
                    />
                </ProCol>
            </ProRow>
        </ProDetailWindow>
    );
};
