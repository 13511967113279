import React, { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProIconData } from '../../atoms/Icons/IconData';
import { ProSelector } from '../../atoms/ProSelector/ProSelector';
import { ProjectContext } from '../../contexts/ProjectContext';
import { WBSCompName, WBSCompNames, WBSContext } from '../../contexts/WBSContext';
import { ProIntent, ProSize } from '../../utils/enums';
import { UserSettings } from './UserSetings';

type Props = {
    displyWBSSelector: boolean;
    displyProjectSelector: boolean;
    displyTeamSelector: boolean;
    filter?: ReactNode;
};

export const PageSettings = ({ displyProjectSelector, displyWBSSelector, displyTeamSelector, filter }: Props) => {
    const { setSelectedProject, projects, selectedProject, setSelectedTeam, selectedTeam, teams } =
        useContext(ProjectContext);
    const { selectWBS } = useContext(WBSContext);
    const { t } = useTranslation();

    const [userDialogIsOpen, setUserDialogIsOpen] = useState(false);
    const [selectedComp, setSelectedComp] = useState<WBSCompName>('Forecast vs Plan');

    const setWBS = (newVal: string) => {
        switch (newVal) {
            case 'Forecast vs Plan':
                selectWBS('Forecast vs Plan');
                setSelectedComp('Forecast vs Plan');
                break;
            case 'Plan vs Baseline':
                selectWBS('Plan vs Baseline');
                setSelectedComp('Plan vs Baseline');
                break;
            case 'Simulation vs Baseline':
                selectWBS('Simulation vs Baseline');
                setSelectedComp('Simulation vs Baseline');
                break;
            default:
                selectWBS('Forecast vs Plan');
                setSelectedComp('Forecast vs Plan');
                break;
        }
    };

    return (
        <div className="PageSettings">
            {filter && <div className="PageSettings--selector">{filter}</div>}
            {selectedProject && displyTeamSelector && (
                <ProSelector
                    name={t('windows.selectedTeam')}
                    items={teams?.map((cur) => cur.name ?? 'unnamed') ?? ['no team']}
                    onChange={(newVal) => setSelectedTeam(selectedProject?.teams?.find((akt) => akt.name === newVal))}
                    size={ProSize.Medium}
                    icon={ProIconData.team_fill}
                    selectedItem={selectedTeam?.name ?? undefined}
                    tooltipPlacement="left"
                    className="PageSettings--selector"
                />
            )}
            {projects && displyProjectSelector && (
                <ProSelector
                    name={t('windows.selectedProject')}
                    items={projects?.map((cur) => cur.name ?? '') ?? []}
                    onChange={(newVal) => setSelectedProject(projects?.find((cur) => cur.name === newVal))}
                    size={ProSize.Medium}
                    icon={ProIconData.project_fill}
                    selectedItem={selectedProject?.name ?? '0'}
                    tooltipPlacement="left"
                    className="PageSettings--selector"
                />
            )}
            {displyWBSSelector && (
                <ProSelector
                    name={t('windows.selectedWBS')}
                    items={WBSCompNames}
                    onChange={(event) => setWBS(event)}
                    size={ProSize.Medium}
                    icon={ProIconData.windows_stroke}
                    selectedItem={selectedComp}
                    tooltipPlacement="left"
                />
            )}

            <ProIconButton
                icon={ProIconData.user_outline}
                tooltip={t('buttons.user')}
                onClick={() => setUserDialogIsOpen(true)}
                mainIntent={ProIntent.Primary}
                className="PageSettings--userButton"
                placement="bottom"
            />
            <UserSettings setUserDialogIsOpen={setUserDialogIsOpen} userDialogIsOpen={userDialogIsOpen} />
        </div>
    );
};
