export const AppBase = '/app';
export const Personal_BASE = `${AppBase}/personal`;
export const Home_BASE = `${AppBase}/`;
export const Team_BASE = `${AppBase}/teams`;
export const Project_BASE = `${AppBase}/project`;
export const Portfolio_BASE = `${AppBase}/portfolio`;

export const navigate = () => {};

export const proLinkStructure = {
    personalManagement: {
        link: Personal_BASE,
        welcome: {
            link: `${Personal_BASE}/welcome`,
        },
        workPackages: {
            link: `${Personal_BASE}/workPackages`,
            path: `${Personal_BASE}/workPackages/:id?`,
        },
        tasks: {
            link: `${Personal_BASE}/tasks`,
            path: `${Personal_BASE}/tasks/:id?`,
        },
        simulation: {
            link: `${Personal_BASE}/simulation`,
        },
    },
    teamManagement: {
        link: Team_BASE,
        team: {
            link: `${Team_BASE}/team`,
            path: `${Team_BASE}/team/:id?`,
        },
        stakeholders: {
            link: `${Team_BASE}/stakeholders`,
        },
        requirements: {
            link: `${Team_BASE}/requirements`,
            path: `${Team_BASE}/requirements/:id?`,
        },
        workPackages: {
            link: `${Team_BASE}/workPackages`,
        },
        tasks: {
            link: `${Team_BASE}/tasks`,
        },
        ceremonies: {
            link: `${Team_BASE}/ceremonies`,
            grooming: {
                link: `${Team_BASE}/ceremonies/grooming`,
            },
            planning: {
                link: `${Team_BASE}/ceremonies/planning`,
            },
            daily: {
                link: `${Team_BASE}/ceremonies/daily`,
            },
            review: {
                link: `${Team_BASE}/ceremonies/review`,
            },
            forecast: {
                link: `${Team_BASE}/ceremonies/forecast`,
            },
            management: {
                link: `${Team_BASE}/ceremonies/management`,
            },
            steering: {
                link: `${Team_BASE}/ceremonies/steering`,
            },
        },
    },
    projectManagement: {
        link: Project_BASE,
        teamStructure: {
            link: `${Project_BASE}/teamStructure`,
            path: `${Project_BASE}/teamStructure/:teamId?/:humanResourceId?`,
        },
        riskManagement: {
            link: `${Project_BASE}/risk`,
        },
        quality: {
            link: `${Project_BASE}/quality`,
            path: `${Project_BASE}/quality/:wbsElementId?`,
        },
        time: {
            link: `${Project_BASE}/time`,
            path: `${Project_BASE}/time/:wbsElementId?`,
        },

        cost: {
            link: `${Project_BASE}/cost`,
            path: `${Project_BASE}/cost/:wbsElementId?`,
        },
        integration: {
            link: `${Project_BASE}/integration`,
        },
    },
    portfolioManagement: {
        link: Portfolio_BASE,
        people: {
            link: `${Portfolio_BASE}/people/people`,
            path: `${Portfolio_BASE}/people/people/:id?`,
        },
        baseData: {
            link: `${Portfolio_BASE}/baseData`,
            teamPositions: {
                link: `${Portfolio_BASE}/baseData/teamPositions`,
            },
            material: {
                link: `${Portfolio_BASE}/baseData/material`,
                path: `${Portfolio_BASE}/baseData/material/:id?`,
            },
            functions: {
                link: `${Portfolio_BASE}/baseData/functions`,
                path: `${Portfolio_BASE}/baseData/functions/:id?`,
            },
            level: {
                link: `${Portfolio_BASE}/baseData/level`,
                path: `${Portfolio_BASE}/baseData/level/:id?`,
            },
            units: {
                link: `${Portfolio_BASE}/baseData/units`,
            },
            stakeholder: {
                link: `${Portfolio_BASE}/baseData/units`,
            },
            agile: {
                link: `${Portfolio_BASE}/baseData/agile`,
                ready: `${Portfolio_BASE}/baseData/agile/ready`,
                accaptance: `${Portfolio_BASE}/baseData/agile/accaptance`,
            },
            functionalRoles: {
                link: `${Portfolio_BASE}/baseData/functionalRoles`,
            },
        },
        projects: {
            link: `${Portfolio_BASE}/projects`,
        },
        roadmap: {
            link: `${Portfolio_BASE}/roadmap`,
        },
        capacity: {
            link: `${Portfolio_BASE}/capacity`,
            capacity: {
                link: `${Portfolio_BASE}/capacity/capacity`,
            },
        },
        integration: {
            link: `${Portfolio_BASE}/integration`,
        },
    },
};
