import { InputGroup, TextArea } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { ChangeEvent, PropsWithChildren } from 'react';
import { ProIntent } from '../../utils/enums';

type Props = {
    value?: string | number | null;
    name?: string;
    handleChange?(value: string | number): void;
    disabled?: boolean;
    fullWidth?: boolean;
    large?: boolean;
    intent?: ProIntent;
    className?: string;
    type?: 'text' | 'password';
};

export const ProAttrField = ({
    name,
    value,
    disabled = true,
    handleChange,
    children,
    fullWidth,
    intent,
    className,
    type = 'text',
}: PropsWithChildren<Props>) => (
    <div className={classNames('ProAttrField', fullWidth && 'ProAttrField--fullWith', className)}>
        {name && (
            <div className="ProAttrField__head">
                <span className="ProAttrField__name">{name}</span>
            </div>
        )}
        <div className={classNames('ProAttrField__content', intent && `ProInput--intent-${intent}`)}>
            <InputGroup
                placeholder={name}
                value={value ? String(value) : undefined}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    !disabled && handleChange && handleChange(e.currentTarget.value)
                }
                className={classNames('ProInput ProAttrField__input', disabled && 'ProAttrField--disabled')}
                fill={false}
                disabled={disabled}
                type={type}
            />
            {children}
        </div>
    </div>
);

export const ProHeaderAttrField = ({ name, value, disabled = true, handleChange }: Props) => (
    <div className="ProAttrField">
        <InputGroup
            placeholder={name}
            value={String(value)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                !disabled && handleChange && handleChange(e.currentTarget.value)
            }
            className={`ProInput ProAttrField__input ProAttrField__header ${disabled && 'ProAttrField--disabled'}`}
            fill={false}
            disabled={disabled}
        />
    </div>
);

export const ProTextField = ({ name, value, disabled = true, handleChange, fullWidth, intent }: Props) => (
    <div
        className={classNames(
            'ProAttrField',
            disabled && 'ProAttrField--disabled',
            fullWidth && 'ProAttrField--fullWith',
            intent && `ProInput--intent-${intent}`
        )}
    >
        <span className="ProAttrField__name">{name}</span>
        <TextArea
            placeholder={name}
            value={value ?? 'no data'}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                !disabled && handleChange && handleChange(e.target.value)
            }
            className={classNames('ProInput ProAttrField__input')}
            growVertically={true}
            fill
            disabled={disabled}
        />
    </div>
);

export const ProAttr = {
    Val: ProAttrField,
    Password: (props: Props) => <ProAttrField {...props} type="password" />,
    Text: ProTextField,
};
