import axios from 'axios';
import { useEffect, useState } from 'react';
import { showErrorToast } from '../utils/helpers';
import { Components } from '../utils/types';
import { API_BASE_URL, WBS_ELEMENT_BASE_URL } from './api';

export const useWBSElement = (WBSElement?: Components.Schemas.WBSElementDeltaDTO, newMode?: boolean) => {
    const { id, wbsElementDelta } = WBSElement ?? {};
    const { id: deltaId } = wbsElementDelta ?? {};

    const jwtObj = localStorage.getItem('auth');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [data, setData] = useState<Components.Schemas.WBSElementDTO>();

    const clear = () => {
        setData(WBSElement);
        setLoading(false);
        setError(undefined);
    };

    const sync = async () => {
        if (newMode && WBSElement) {
            setData(WBSElement);
            setLoading(false);
            setError(undefined);
            return;
        }

        if (deltaId !== undefined) {
            setLoading(true);
            setError(undefined);

            axios
                .get<Components.Schemas.WBSElementDeltaDTO>(
                    `${API_BASE_URL}${WBS_ELEMENT_BASE_URL}/${id}/Delta/${deltaId}`,
                    {
                        headers: { Authorization: `Bearer ${jwtObj}` },
                    }
                )
                .then((res) => {
                    setData(res.data);
                    setError(undefined);
                })
                .catch((e) => {
                    setError(String(e));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (id) {
            setLoading(true);
            setError(undefined);

            axios
                .get<Components.Schemas.WBSElementDeltaDTO>(`${API_BASE_URL}${WBS_ELEMENT_BASE_URL}/${id}`, {
                    headers: { Authorization: `Bearer ${jwtObj}` },
                })
                .then((res) => {
                    setData(res.data);
                    setError(undefined);
                })
                .catch((e) => {
                    setError(String(e));
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        return { data, error, loading };
    };

    useEffect(() => {
        sync();
    }, [id]);

    const updateWBSElement = async (newData: Components.Schemas.WBSElementDTO) => {
        setLoading(true);
        setError(undefined);
        try {
            const res = await axios.put<Components.Schemas.WBSElementDeltaDTO>(
                `${API_BASE_URL}${WBS_ELEMENT_BASE_URL}/${id}`,
                { ...newData },
                {
                    headers: { Authorization: `Bearer ${jwtObj}` },
                }
            );
            setData(res.data);
            setLoading(false);
            setError(undefined);
        } catch (e) {
            setLoading(false);
            setError(String(e));
            showErrorToast(`cant update element - Error: ${e}`);
            throw e;
        }
    };
    const deleteWBSElement = async () => {
        setLoading(true);
        setError(undefined);
        try {
            const res = await axios.delete<Components.Schemas.WBSElementDeltaDTO>(
                `${API_BASE_URL}${WBS_ELEMENT_BASE_URL}/${id}`,
                {
                    headers: { Authorization: `Bearer ${jwtObj}` },
                }
            );
            setData(res.data);
            setLoading(false);
            setError(undefined);
        } catch (e) {
            setLoading(false);
            setError(String(e));
            showErrorToast(`cant delete element - Error: ${e}`);
            throw e;
        }
    };
    const createWBSElement = async (newData: Components.Schemas.WBSElementDTO) => {
        setLoading(true);
        setError(undefined);
        try {
            const res = await axios.post<Components.Schemas.WBSElementDeltaDTO>(
                `${API_BASE_URL}${WBS_ELEMENT_BASE_URL}`,
                { ...newData },
                {
                    headers: { Authorization: `Bearer ${jwtObj}` },
                }
            );
            setData(res.data);
            setLoading(false);
            setError(undefined);
        } catch (e) {
            setLoading(false);
            setError(String(e));
            showErrorToast(`cant create element - Error: ${e}`);
            throw e;
        }
    };

    return { data, error, loading, updateWBSElement, deleteWBSElement, createWBSElement, sync, clear };
};
