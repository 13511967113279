import { Icon, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIcon } from '../Icons/Icon';
import { ProIconDataButtons } from '../Icons/IconData';

type Props<T> = {
    name?: string | null;
    items?: T[] | null;
    value?: T | null;
    setValue(val: T): void;
    disabled?: boolean;
    filterable?: boolean;
    definitionLink?: string;
};

export const ProDropdown = ({
    name,
    value,
    disabled = true,
    items,
    setValue,
    filterable = false,
    definitionLink,
}: Props<string>) => {
    const ProSelect = Select.ofType<string>();

    const onItemRender = (item: string) => (
        <MenuItem
            text={item}
            active={item === value}
            shouldDismissPopover={false}
            onClick={() => setValue(item)}
            key={`${name}-dropdown_key-${item}`}
        />
    );

    return (
        <div className={classNames('ProAttrField', disabled && 'ProAttrField--disabled')}>
            {name && (
                <div className="ProAttrField__head">
                    <span className="ProAttrField__name">{name}</span>
                    {definitionLink && (
                        <Link to={definitionLink}>
                            <ProIcon
                                icon={ProIconDataButtons.arrow_right_xs}
                                intent={ProIntent.Gray}
                                size={ProSize.XSmall}
                                hoverIntent={ProIntent.Primary}
                            />
                        </Link>
                    )}
                </div>
            )}
            <ProSelect
                items={items ?? []}
                onItemSelect={setValue}
                itemRenderer={onItemRender}
                className={'select ProAttrField__input'}
                itemPredicate={(query, item): boolean => item.toLocaleLowerCase().includes(query.toLocaleLowerCase())}
                // disabled={disabled || (items && items.length <= 0)}
                disabled={disabled}
                filterable={filterable}
                activeItem={value}
                scrollToActiveItem
                popoverProps={{ minimal: true, fill: true, targetTagName: 'div', wrapperTagName: 'div' }}
            >
                <div className="ProAttrField__dropdownSelected">
                    {value || 'not set'}
                    {!disabled && <Icon icon="double-caret-vertical" className="ProAttrField__icon" />}
                </div>
            </ProSelect>
        </div>
    );
};
