import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REQUIREMENTSTRUCTURE_API_BASE, REQUIREMENTS_API_BASE } from '../../../api/api';
import { deleteAPIRequest, useGenericAPI } from '../../../api/useGenericApi';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { findAllParents, getVisibleNodesWithSubtasks } from '../../../components/WBSTable/wbsTabeHelpers';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { SelectionContext } from '../../../contexts/SelectionContext';
import { useDetailRouting } from '../../../hooks/useDetailRouting';
import { proLinkStructure } from '../../../modules/Menu/Navigate';
import { ProIntent } from '../../../utils/enums';
import { showErrorToast } from '../../../utils/helpers';
import { Components } from '../../../utils/types';
import { useTaskDetailsWindowForRequirements } from '../Tasks/components/useTaskDetailsWindowForRequirements';
import { isTaskNotReq } from './components/RequirementTableNameCell';
import { RequirementContextProvider } from './context/RequirementContext';
import { RequirementDetailWindowType, useRequirementDetailsFrame } from './modules/RequirementDetailsFrame';
import { getRequirmentTableData } from './modules/requirementHelpers';

export const TeamRequirementsManagementPage = ({
    isHidden = false,
    pageTitle,
}: {
    isHidden?: boolean;
    pageTitle?: string;
}) => {
    const { t } = useTranslation();
    const { selectedProject, loading: projectLoading, error: projectError, selectedTeam } = useContext(ProjectContext);
    const [selectedTableRow, setSelectedTableRow] = useState<number>();
    const [selectedRequirement, setSelectedRequirement] = useState<Components.Schemas.RequirementDTO>();
    const [selectedTask, setSelectedTask] = useState<Components.Schemas.TaskDTO>();
    const [newElement, setNewElement] = useState<Components.Schemas.RequirementDTO>();
    const { selectionMode, renderSelectionButton } = useContext(SelectionContext);

    const [requirements, setRequirements] = useState<Components.Schemas.RequirementDTO[]>([]);

    const selectedRequStruct = useMemo(
        () => selectedProject?.requirementStructures?.find((cur) => cur.version === 'Forecast'),
        [selectedProject]
    );

    const {
        data: requirementStructure,
        error,
        loading,
        sync,
    } = useGenericAPI<Components.Schemas.RequirementStructureDTO>(
        `${REQUIREMENTSTRUCTURE_API_BASE}/${selectedRequStruct?.id}/Team`,
        selectedTeam?.id
    );

    const deselectTableRow = () => {
        setSelectedTableRow(undefined);
        setSelectedRequirement(undefined);
        open('hidden');
        openTask('hidden');
        setUrlEmpty();
    };

    const selectElement = (id: string) => {
        const req = requirements.find((cur) => cur.id === id);
        const allTasks = requirements.map((cur) => cur.taskLists?.find((akt) => akt.tasklistType === 'Userlist'));
        const task = refinedData.find((cur) => cur.id === id) || allTasks.find((akt) => akt?.id === id);

        if (req) {
            open('view');
            openTask('hidden');
            setSelectedRequirement(req);
            setSelectedTask(undefined);
        } else if (task) {
            setSelectedRequirement(undefined);
            setSelectedTask(task);
            open('hidden');
            openTask('view');
        }
    };

    const selectTableRow = (i: number) => {
        selectElement(refinedData[i].id!);
    };

    const {
        isDisplayed,
        mode,
        open,
        renderDetailWindow,
        data: selectedRequirementDetails,
    } = useRequirementDetailsFrame({
        closeAction: deselectTableRow,
        id: selectedRequirement?.id,
        selectAction: selectElement,
        externalSync: sync,
        newElement,
        type: RequirementDetailWindowType.Default,
    });

    const openNewTask = (id: string) => {
        selectElement(id);
        open('hidden');
        openTask('new');
    };

    const {
        isDisplayed: taskIsDisplayed,
        mode: taskMode,
        open: openTask,
        renderDetailWindow: renderTaskDetailWindow,
    } = useTaskDetailsWindowForRequirements({
        closeAction: deselectTableRow,
        selectAction: selectElement,
        externalSync: sync,
        id: selectedTask?.id,
        selectedRequirement: selectedRequirement,
    });

    const toggleOpen = (id: string) => {
        const req = requirementStructure?.requirements?.find((cur) => cur.id === id);
        if (req && openNodes.includes(req)) {
            setOpenNodes((cur) => cur.filter((akt) => akt.id !== id));
        } else if (req) setOpenNodes((cur) => [...cur, req]);
    };

    const getNodeIsOpen: (i: number) => boolean | undefined = (i: number) => {
        const req = refinedData && refinedData[i];
        const task = req?.taskLists?.find((cur) => cur.tasklistType === 'Userlist')?.tasks;
        if (refinedData && refinedData[i] && !task) {
            if (
                requirementStructure?.requirements &&
                !requirementStructure.requirements.find((cur) => cur.parentId === req.id)
            )
                return undefined;
            else return openNodes.find((cur) => req.id === cur.id) ? true : false;
        } else if (task && task.length > 0) {
            return openNodes.find((cur) => req.id === cur.id) ? true : false;
        } else return undefined;
    };

    const deleteAction = (i: number) => {
        const req = refinedData[i];
        if (req.id) {
            deleteAPIRequest(req.id ?? '0', REQUIREMENTS_API_BASE)
                .then(() => {
                    sync();
                    setUrlEmpty();
                })
                .catch(() => {
                    showErrorToast('Cant delete element.');
                });
        }
    };

    const getEmptyItem = (parent?: Components.Schemas.RequirementDTO): Components.Schemas.RequirementDTO => ({
        projectId: selectedProject?.id,
        name: 'New reqirement',
        parentId: parent?.id,
        requirementType: 'UserStory',
        requirementStructureId: parent?.requirementStructureId,
    });

    const createRequirement = (i: number) => {
        const req = refinedData[i];
        if (req?.id) {
            setNewElement(getEmptyItem(req));
        } else {
            setNewElement(getEmptyItem(selectedProject));
        }
        openTask('hidden');
        open('new');
        setSelectedRequirement(undefined);
        setSelectedTask(undefined);
        setSelectedTableRow(i);
    };

    const { setUrlEmpty } = useDetailRouting({
        link: proLinkStructure.teamManagement.requirements.link,
        path: proLinkStructure.teamManagement.requirements.path,
        selectElement: (id) => {
            if (!taskIsDisplayed) selectElement(id);
        },
        data: requirements,
        selectedElement: selectedRequirement || selectedTask,
    });

    // Table logic

    const [refinedData, setRefinedData] = useState<Components.Schemas.RequirementDTO[]>([]);
    const [openNodes, setOpenNodes] = useState<Components.Schemas.RequirementDTO[]>([]);

    //Open nodes here is just for the inital process
    const addToOpenNode = useCallback((req: (Components.Schemas.RequirementDTO | undefined)[]) => {
        const filteredReq: Components.Schemas.RequirementDTO[] = req?.filter(
            (cur): cur is Components.Schemas.RequirementDTO => !!cur
        );

        setOpenNodes((cur) => [...cur, ...filteredReq]);
    }, []);

    useEffect(() => {
        setRequirements(requirementStructure?.requirements ?? []);
    }, [requirementStructure]);

    useEffect(() => {
        const elementIsAlreadyOpen = openNodes.filter((cur) => cur.id === selectedRequirement?.id).length > 0;

        if (selectedRequirement && !elementIsAlreadyOpen) {
            addToOpenNode([selectedRequirement]);
            const parents = findAllParents(selectedRequirement, requirements);
            addToOpenNode(parents);
        }
    }, [addToOpenNode, requirements, selectedRequirement]);

    useEffect(() => {
        const projectBacklog = requirements.find((cur) => cur.requirementType === 'ProjectBacklog');
        const teamBacklog = requirements.find((cur) => cur.requirementType === 'TeamBacklog');
        addToOpenNode([projectBacklog, teamBacklog]);
    }, [addToOpenNode, requirements]);

    useEffect(() => {
        if (requirements && selectedProject) {
            const firstNode = requirements[0];
            const newData = firstNode && getVisibleNodesWithSubtasks(firstNode, requirements, openNodes);
            setRefinedData(newData ?? []);
        }
    }, [requirements, openNodes, selectedProject]);

    //set the corresponding row to active when selected item changed
    useEffect(() => {
        // +1 -1 is a hack to make findIndex => -1 into 0 wich is nullable
        const row =
            refinedData.findIndex((cur) => cur.id === selectedRequirement?.id) + 1 ||
            refinedData.findIndex((cur) => cur.id === selectedTask?.id) + 1;
        setSelectedTableRow(row - 1);
    }, [refinedData, selectedRequirement, selectedTask]);

    const [filteredData, setFilteredData] = useState<Components.Schemas.RequirementDTO[]>([]);
    const [filter, setFilter] = useState<string>();

    const toggleFilterDone = () => {
        setFilter((cur) => (cur ? undefined : 'done'));
    };

    useEffect(() => {
        const res = refinedData.filter((cur) => cur.requirementState?.name !== 'done');
        setFilteredData(filter ? res : refinedData);
    }, [refinedData, filter]);

    // End

    const tableData: TableColData[] = getRequirmentTableData({
        createRequirement,
        deleteAction,
        filteredData,
        getNodeIsOpen,
        isDisplayed,
        mode,
        openNewTask,
        renderSelectionButton,
        selectionMode,
        selectTableRow,
        t,
        taskIsDisplayed,
        taskMode,
        toggleOpen,
    });

    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [{ type: ApplicationElementType.TeamManagement, name: selectedTeam?.name }],
                t
            )}
            name={pageTitle || t('navigation.teamMan.requirements')}
            error={error || projectError ? 'error' : undefined}
            loading={projectLoading || loading}
            icon={ProIconData.scope_fill}
            displyProjectSelector
            isHidden={isHidden}
            displyTeamSelector
            // filter={
            //     <ProButton
            //         text={filter ? 'show all' : 'hide finished'}
            //         onClick={() => toggleFilterDone()}
            //         mainIntent={filter ? ProIntent.Gray : ProIntent.Primary}
            //     />
            // }
        >
            <>
                <RequirementContextProvider>
                    {selectedProject && tableData && (
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <ProButton
                                text={filter ? 'show all' : 'hide finished'}
                                onClick={() => toggleFilterDone()}
                                mainIntent={filter ? ProIntent.Gray : ProIntent.Primary}
                                className="ProTable__filterButton"
                            />
                            <ProTable
                                data={tableData}
                                rowsCount={filteredData?.length ?? 1}
                                selectedTableRow={selectedTableRow}
                                getRowClass={(i) =>
                                    `ProTable--row__level-${(refinedData[i]?.level ?? 1) - 1} ${
                                        refinedData[i] &&
                                        isTaskNotReq(refinedData[i]) &&
                                        'ProTable--row__levelIntent-yellow'
                                    }`
                                }
                            />
                        </div>
                    )}
                    <div
                        className={classNames(
                            'ProApp--detailWindowSection',
                            (isDisplayed || taskIsDisplayed) && 'ProApp--detailWindowSection__expanded'
                        )}
                    >
                        {renderDetailWindow()}
                        {renderTaskDetailWindow()}
                    </div>
                </RequirementContextProvider>
            </>
        </ProPage>
    );
};
