import { useEffect } from 'react';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { HumanResourceDTO, ProjectDTO, TeamDTO } from '../routes/ProjectManagement/Team/Team.helpers';

type Props<T> = {
    basePath: string;
    tableData?: (TeamDTO | ProjectDTO | HumanResourceDTO)[];
    selectElement: (elm: T) => void;
    selectedTeamId?: string;
    selectedHumanId?: string;
    selectedPortfolioId?: string;
};

export const useTeamsDetailRouting = ({
    basePath,
    tableData,
    selectElement,
    selectedHumanId,
    selectedPortfolioId,
    selectedTeamId,
}: Props<TeamDTO | ProjectDTO | HumanResourceDTO>) => {
    const route = useRouteMatch();
    const history = useHistory();
    const { humanResourceId, teamId } = useParams<{ teamId?: string; humanResourceId?: string }>();

    //select team if teamId changes or its portfolio
    useEffect(() => {
        if (selectedTeamId && selectedPortfolioId && selectedTeamId !== teamId) {
            const team = tableData?.find((cur) => cur.id === teamId);
            if (team) {
                selectElement(team);
            }
        }
    }, [teamId]);

    // select human if id changes
    useEffect(() => {
        if (selectedHumanId && selectedHumanId !== humanResourceId) {
            const human = tableData?.find((cur) => cur.id === humanResourceId);
            if (human) {
                selectElement(human);
            }
        }
    }, [humanResourceId, tableData]);

    // change path when team updates or its portfolio
    useEffect(() => {
        if (teamId !== selectedTeamId && selectedTeamId) {
            console.log('path generated for ', selectedTeamId);
            const newPath =
                route.path === basePath
                    ? `${basePath}/${selectedTeamId}`
                    : generatePath(route.path, { teamId: selectedTeamId });

            history.push({
                pathname: newPath,
            });
        }
    }, [selectedTeamId, tableData]);

    // change path when human updates
    useEffect(() => {
        if (humanResourceId !== selectedHumanId && selectedTeamId && selectedHumanId) {
            console.log('path generated for ', selectedTeamId, selectedHumanId);
            const newPath =
                route.path === basePath
                    ? `${basePath}/${selectedTeamId}/${selectedHumanId}`
                    : generatePath(route.path, { teamId: selectedTeamId, humanResourceId: selectedHumanId });

            history.push({
                pathname: newPath,
            });
        }
    }, [selectedTeamId, tableData, selectedHumanId]);
};
