import classNames from 'classnames';
import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ProAttrField } from '../../../atoms/AttrField/AttrField';
import { ProBooleanDropdown } from '../../../atoms/AttrField/ProBooleanDropdown';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { AccountWindow } from '../../../components/AccountWindow/AccountWindow';
import { ProDetailWindow } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { UserImage } from '../../../components/UserImage/UserImage';
import { Components } from '../../../utils/types';
import { DetailWindowProps } from '../GenericDetailWindow';
import { getWindowDisplayStateMode } from '../WBSElement/WBSElement.helpers';

type PersonDetailContentProps = {
    isDisabled: boolean;
    setFormData: (value: SetStateAction<Components.Schemas.PersonDTO | undefined>) => void;
    formData?: Components.Schemas.PersonDTO;
};

export const PersonDetailContent = ({ isDisabled, setFormData, formData }: PersonDetailContentProps) => {
    const { t } = useTranslation();
    return (
        <>
            <ProRow>
                <ProCol span={4}>
                    <UserImage disabled={isDisabled} imageDocumentId={formData?.imageDocumentId} />
                </ProCol>
                <ProCol span={8}>
                    <ProRow>
                        <ProCol span={6}>
                            <ProAttrField
                                name={t('windows.firstName')}
                                disabled={isDisabled}
                                value={formData?.firstName}
                                handleChange={(value) => setFormData((cur) => ({ ...cur, firstName: String(value) }))}
                            />
                        </ProCol>
                        <ProCol span={6}>
                            <ProAttrField
                                name={t('windows.lastName')}
                                disabled={isDisabled}
                                value={formData?.lastName}
                                handleChange={(value) => setFormData((cur) => ({ ...cur, lastName: String(value) }))}
                            />
                        </ProCol>
                    </ProRow>
                    <ProRow>
                        <ProCol span={6}>
                            <ProAttrField
                                name={t('windows.company')}
                                disabled={isDisabled}
                                value={formData?.company}
                                handleChange={(value) => setFormData((cur) => ({ ...cur, company: String(value) }))}
                            />
                        </ProCol>
                        <ProCol span={6}>
                            <ProAttrField
                                name={t('windows.organisationalUnit')}
                                disabled={isDisabled}
                                value={formData?.organisationalUnit}
                                handleChange={(value) =>
                                    setFormData((cur) => ({ ...cur, organisationalUnit: String(value) }))
                                }
                            />
                        </ProCol>
                    </ProRow>
                    <ProRow>
                        <ProCol span={6}>
                            <ProAttrField
                                name={'Origin'}
                                disabled={isDisabled}
                                value={'no value'}
                                // handleChange={(value) => setFormData((cur) => ({ ...cur, company: String(value) }))}
                            />
                        </ProCol>
                        <ProCol span={6}>
                            <ProAttrField
                                name={'Communication Link'}
                                disabled={isDisabled}
                                value={formData?.communicationLink}
                                handleChange={(value) =>
                                    setFormData((cur) => ({ ...cur, communicationLink: String(value) }))
                                }
                            />
                        </ProCol>
                    </ProRow>
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={8}>
                    <ProAttrField
                        name={t('windows.email')}
                        disabled={isDisabled}
                        value={formData?.eMail}
                        handleChange={(value) => setFormData((cur) => ({ ...cur, eMail: String(value) }))}
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProBooleanDropdown
                        name={'Status'}
                        trueName="inactive"
                        falseName="active"
                        disabled={isDisabled}
                        value={formData?.deleted}
                        handleChange={(value) => setFormData((cur) => ({ ...cur, deleted: value }))}
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={6}>
                    <ProAttrField
                        name={t('windows.telephone')}
                        disabled={isDisabled}
                        value={formData?.telephoneNumber}
                        handleChange={(value) => setFormData((cur) => ({ ...cur, telephoneNumber: String(value) }))}
                    />
                </ProCol>
                <ProCol span={6}>
                    <ProAttrField
                        name={'Mobile number'}
                        disabled={isDisabled}
                        value={formData?.mobileNumber}
                        handleChange={(value) => setFormData((cur) => ({ ...cur, mobileNumber: String(value) }))}
                    />
                </ProCol>
            </ProRow>
            <ProRow spacer>
                <ProCol span={12}>
                    <ProAttrField
                        name={t('windows.teams')}
                        disabled={true}
                        value={formData?.teams?.map((cur) => cur.name).join(', ')}
                    />
                </ProCol>
            </ProRow>

            <div className="ProAttrField__dependencies--header">
                <ProRow>
                    <ProCol span={4}>User:</ProCol>
                </ProRow>
            </div>
            {formData && <AccountWindow disabled={false} t={t} account={formData.applicationUser} person={formData} />}
        </>
    );
};

export const PersonDetailWindow = ({
    createAction,
    deleteAction,
    formData,
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    error,
    cancelChanges,
    closeAction,
    t,
}: DetailWindowProps<Components.Schemas.PersonDTO>) => {
    const userName = `${formData?.firstName} ${formData?.lastName}`;

    return (
        <ProDetailWindow
            title={
                <h2
                    className={classNames(
                        userName.length && userName.length >= 180 && 'ProDetailWindow--header--title__large'
                    )}
                >
                    {userName ?? t('windows.details')}
                </h2>
            }
            breadCrump={getBaseBCItemForType([ApplicationElementType.People], t)}
            displayState={isDisplayed}
            icon={ProIconData.person_fill}
            buttonSection={
                <WindowButtonsForMode
                    cancelAction={cancelChanges}
                    closeAction={closeAction}
                    createAction={createAction}
                    deleteAction={deleteAction}
                    saveAction={saveAction}
                    setMode={setMode}
                    mode={mode}
                    t={t}
                />
            }
            error={error}
            loading={loading}
            intent={getWindowDisplayStateMode(mode)}
        >
            <PersonDetailContent isDisabled={isDisabled} setFormData={setFormData} formData={formData} />
        </ProDetailWindow>
    );
};
