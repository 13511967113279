import { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { showWarningToast } from '../utils/helpers';
import { Components } from '../utils/types';

export const useWBSDetailRouting = ({
    selectedWBSElement,
    path,
    filteredData,
    selectTableRow,
    selectedWBSStructure,
    openLowerNodes,
    link,
}: {
    selectedWBSElement?: Components.Schemas.WBSElementDeltaDTO;
    path: string;
    link: string;
    filteredData: Components.Schemas.WBSElementDeltaDTO[];
    selectTableRow: (id: number) => void;
    selectedWBSStructure?: Components.Schemas.WBSStructureDTO;
    openLowerNodes: (id: string) => string | null | undefined;
}) => {
    const { wbsElementId } = useParams<{ wbsElementId: string }>();
    const [error, setError] = useState<JSX.Element>();
    const history = useHistory();
    const route = useRouteMatch();

    useEffect(() => {
        // console.log('selectedWBSElement', selectedWBSElement);
        // Here we need to check if no selection mode is on
        if (route.path.includes(path) && !wbsElementId && selectedWBSElement) {
            history.push(generatePath(path, { wbsElementId: selectedWBSElement.id }));
        }
    }, [selectedWBSElement]);

    useEffect(() => {
        if (wbsElementId && wbsElementId !== '00000000-0000-0000-0000-000000000000') {
            const newRow = filteredData.findIndex((cur) => cur.id === wbsElementId);

            selectTableRow(newRow);

            if (newRow < 0 && filteredData.length > 1) {
                //when element is deleted?
                // setError(<NotFoundPage message={`Sorry, no element with id: ${wbsElementId} found`} />);
                showWarningToast(`Sorry, no element with id: ${wbsElementId} found`);
            } else {
                setError(undefined);
            }
        }
    }, [wbsElementId, filteredData]);

    useEffect(() => {
        if (selectedWBSStructure) {
            openLowerNodes(wbsElementId);
        }
    }, [wbsElementId, selectedWBSStructure]);

    const setUrlEmpty = () => {
        history.push({
            pathname: link,
        });
    };

    return { error, setUrlEmpty };
};
