import React, { PropsWithChildren } from 'react';
import { ProIconType } from '../../constants/types';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIcon } from '../Icons/Icon';

type Props = PropsWithChildren<{
    name: string;
}>;

const ProSummaryWindow = ({ name, children }: Props) => (
    <div className="SummaryWindow">
        {/* <h2>{name}</h2> */}
        <div className="SummaryWindow__content">{children}</div>
    </div>
);

type SummaryAttrFieldProps = {
    name: string;
    val?: string | null | number;
    icon: ProIconType;
};

const SummaryAttrField = ({ icon, name, val }: SummaryAttrFieldProps) => (
    <div className="SummaryAttrField">
        <ProIcon icon={icon} size={ProSize.XLarge} intent={ProIntent.PrimaryLight} />
        <div className="SummaryAttrField__content">
            <div className="SummaryAttrField__name">{name}</div>
            <div className="SummaryAttrField__val">{val !== '' && val ? val : '-'}</div>
        </div>
    </div>
);

export const ProSummary = {
    Attr: SummaryAttrField,
    Window: ProSummaryWindow,
};
