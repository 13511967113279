import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProIconData } from '../../atoms/Icons/IconData';
import { BreadCrumpItem, renderBreadcrump } from '../../components/DetailWindow/breadCrump.helpers';
import { ProIconType } from '../../constants/types';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIcon } from '../Icons/Icon';

type Props = PropsWithChildren<{
    isOpen: boolean;
    setIsOpen: (value: SetStateAction<boolean>) => void;
    title: string;
    buttons?: ReactNode;
    breadCrump: BreadCrumpItem[];
    icon: ProIconType;
    editModeOn?: boolean;
}>;

export const ProDialog = ({
    isOpen,
    setIsOpen,
    title,
    children,
    buttons,
    breadCrump,
    icon,
    editModeOn = false,
}: Props) => {
    const { t } = useTranslation();
    return (
        <Dialog isOpen={isOpen} hasBackdrop={true} className="ProDialog">
            <div className={classNames('ProDialog--header', editModeOn && 'ProDialog--header--intent-edit')}>
                <div className="ProDialog--header__box">
                    <p className="ProDialog--breadCrump">{breadCrump ? renderBreadcrump(breadCrump) : 'no path'}</p>
                    <div className="ProDialog--header__title">
                        <ProIcon icon={icon} intent={ProIntent.Light} />
                        <h2>{title}</h2>
                    </div>
                </div>

                <div className="ProDialog--buttons">
                    {buttons}
                    {!editModeOn && (
                        <ProIconButton
                            icon={ProIconData.close_stroke}
                            tooltip={t('buttons.close')}
                            mainIntent={ProIntent.PrimaryLight}
                            hoverIntent={ProIntent.Secondary}
                            onClick={() => setIsOpen(false)}
                            size={ProSize.Small}
                        />
                    )}
                </div>
            </div>
            <div className="ProDialog--content">{children}</div>
        </Dialog>
    );
};
