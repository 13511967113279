import { AnchorButton } from '@blueprintjs/core';
import { Placement, Tooltip2 } from '@blueprintjs/popover2';
import classnames from 'classnames';
import React from 'react';
import { ProIconType } from '../../constants/types';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIcon } from '../Icons/Icon';

type Props = {
    tooltip: string;
    icon: ProIconType;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
    size?: ProSize;
    mainIntent?: ProIntent;
    hoverIntent?: ProIntent;
    lineIntent?: ProIntent;
    placement?: Placement;
    small?: boolean;
    tooltipClass?: string;
};

export const ProIconButton = ({
    className,
    mainIntent = ProIntent.Secondary,
    onClick,
    icon,
    tooltip,
    disabled,
    size = ProSize.Small,
    hoverIntent,
    lineIntent,
    placement,
    small = false,
    tooltipClass,
}: Props) => (
    <Tooltip2
        content={tooltip}
        className={classnames(tooltipClass, 'ProButton-Tooltip')}
        usePortal
        lazy
        hoverCloseDelay={300}
        hoverOpenDelay={300}
        placement={placement}
    >
        <AnchorButton
            className={classnames(
                'ProButton ProButton--icon-button',
                className,
                `ProButton--main-${mainIntent}`,
                hoverIntent && `ProButton--hover-${hoverIntent}`,
                lineIntent && `ProButton--line-${lineIntent}`,
                `ProButton--size-${size}`,
                disabled && `ProButton__disabled`,
                small && 'ProButton--icon-button__small'
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {icon && <ProIcon icon={icon} size={size} />}
        </AnchorButton>
    </Tooltip2>
);

export const ProTableButton = ({ className, mainIntent, onClick, icon, tooltip, disabled, size }: Props) => (
    <ProIconButton
        icon={icon}
        onClick={onClick}
        tooltip={tooltip}
        className={`ProButton--table-button ${className}`}
        disabled={disabled}
        mainIntent={mainIntent}
        size={size}
    />
);
