import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ProNumberField } from '../../../atoms/AttrField/NumberField';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { MaterialAssingmentList } from '../../../components/MaterialAssingmentList/MaterialAssingmentList';
import { TeamMemberList } from '../../../components/TeamMemberList/TeamMemberList';
import { ProIntent } from '../../../utils/enums';
import { Components } from '../../../utils/types';
import { getCostIntent } from './WBSElement.helpers';
import { CostConstraintField } from './atoms/CostConstraintField/CostConstraintField';

export const getCostTimeDeltaIntent = (state?: Components.Schemas.DeltaStateType) => {
    if (state === 'CautionMaximum' || state === 'CautionMinimum') return ProIntent.Warning;
    if (state === 'CriticalMaximum' || state === 'CriticalMinimum') return ProIntent.Error;
    if (state === 'DivisionByZero') return ProIntent.Gray;
};

type Props = {
    data: Components.Schemas.WBSElementDeltaDTO;
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>;
    isDisabled: boolean;
    syncElement: () => void;
};

export const CostDetailWindowForecastTab = ({ setData, data, isDisabled, syncElement }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <ProRow>
                <ProCol span={2}>
                    <ProNumberField
                        name={t('windows.forcastedCosts')}
                        value={data.costs}
                        disabled={isDisabled}
                        entity="€"
                        handleChange={(value) => {
                            setData((cur) => ({ ...cur, costs: Number(value) }));
                        }}
                        intent={getCostIntent(data)}
                    />
                </ProCol>
                <ProCol span={2}>
                    <ProNumberField
                        name={t('windows.planedCosts')}
                        value={data?.wbsElementDelta?.costs}
                        entity="€"
                        disabled={isDisabled}
                    />
                </ProCol>
                <ProCol span={2}>
                    <ProNumberField
                        name={t('windows.costDeviation')}
                        value={data?.deltaCostState === 'DivisionByZero' ? '-' : data?.deltaCostPercent}
                        entity="%"
                        intent={getCostTimeDeltaIntent(data?.deltaCostState)}
                        disabled={isDisabled}
                    />
                </ProCol>
                <ProCol span={2}></ProCol>
                <ProCol span={4}>
                    <CostConstraintField data={data} setData={setData} isDisabled={isDisabled} />
                </ProCol>
            </ProRow>
            <ProRow spacer>
                <ProCol span={8}></ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.costLimit')}
                        value={data.constraintCost}
                        disabled={isDisabled}
                        entity="€"
                        handleChange={(value) => {
                            setData((cur) => ({ ...cur, constraintCost: Number(value) }));
                        }}
                    />
                </ProCol>
            </ProRow>
        </>
    );
};
export const CostDetailWindowAllocationTab = ({ data, isDisabled, syncElement }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <ProRow spacer>
                <TeamMemberList
                    disabled={isDisabled}
                    name={t('windows.assignedTeamMember')}
                    syncElement={syncElement}
                    dependencies={data.humanResourceAssignments}
                    wbsElement={data}
                />
            </ProRow>

            <ProRow spacer>
                <MaterialAssingmentList
                    disabled={isDisabled}
                    name={t('windows.assignedMaterials')}
                    syncElement={syncElement}
                    dependencies={data.materialAssignments}
                    wbsElement={data}
                />
            </ProRow>
        </>
    );
};
