import { Tooltip2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ProIconType } from '../../constants/types';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';
import { ProIcon } from '../Icons/Icon';
import { ProIconData } from '../Icons/IconData';

type Props = {
    delta?: Components.Schemas.WBSElementDeltaDTO | null;
    id?: string;
};

const statusText = (
    name: string,
    stateType?: Components.Schemas.DeltaStateType,
    constraintType?: (Components.Schemas.ConstraintType | undefined)[]
) => {
    if (constraintType?.includes('Bigger')) {
        return `${name} Cost contraint violated - to big`;
    } else if (constraintType?.includes('Smaller')) {
        return `${name} Cost contraint violated - to small`;
    } else {
        if (stateType === 'CautionMaximum') return `${name} is caution maximum`;
        if (stateType === 'CautionMinimum') return `${name} is caution minimum`;
        if (stateType === 'CriticalMaximum') return `${name} is in critical maximum`;
        if (stateType === 'CriticalMinimum') return `${name} is in critical minimum`;
    }

    return undefined;
};

const statusClassName = (
    stateType?: Components.Schemas.DeltaStateType,
    constraintType?: (Components.Schemas.ConstraintType | undefined)[]
): string => {
    if (constraintType?.includes('Bigger')) {
        return 'ProStatus--icon__criticalMax';
    } else if (constraintType?.includes('Smaller')) {
        return 'ProStatus--icon__criticalMin';
    } else {
        return `ProStatus--icon__${stateType}`;
    }
};

export const ProjectStatus = ({ delta, id }: Props) => (
    <div className="ProStatus">
        <Link to={`/app/project/quality/${id}`}>
            <Tooltip2
                content={statusText('Quality', delta?.deltaQualityState, [delta?.constraintQualityType])}
                usePortal
                lazy
                hoverCloseDelay={300}
                hoverOpenDelay={300}
                placement="top"
            >
                <div
                    className={classNames(
                        `ProStatus--icon`,
                        statusClassName(delta?.deltaQualityState, [delta?.constraintQualityType])
                    )}
                >
                    <ProIcon icon={ProIconData.quality_fill} intent={ProIntent.Light} size={ProSize.Small} />
                </div>
            </Tooltip2>
        </Link>
        <Link to={`/app/project/time/${id}`}>
            <Tooltip2
                content={statusText('Time', delta?.deltaTimeState, [
                    delta?.constraintEndDateType,
                    delta?.constraintStartDateType,
                ])}
                usePortal
                lazy
                hoverCloseDelay={300}
                hoverOpenDelay={300}
                placement="top"
            >
                <div
                    className={classNames(
                        `ProStatus--icon`,
                        statusClassName(delta?.deltaTimeState, [
                            delta?.constraintEndDateType,
                            delta?.constraintStartDateType,
                        ])
                    )}
                >
                    <ProIcon icon={ProIconData.time_fill} intent={ProIntent.Light} size={ProSize.Small} />
                </div>
            </Tooltip2>
        </Link>
        <Link to={`/app/project/cost/${id}`}>
            <Tooltip2
                content={statusText('Costs', delta?.deltaCostState, [delta?.constraintCostType])}
                usePortal
                lazy
                hoverCloseDelay={300}
                hoverOpenDelay={300}
                placement="top"
            >
                <div
                    className={classNames(
                        `ProStatus--icon`,
                        statusClassName(delta?.deltaCostState, [delta?.constraintCostType]),
                        'ProStatus--icon__criticalMin'
                    )}
                >
                    <ProIcon icon={ProIconData.cost_fill} intent={ProIntent.Light} size={ProSize.Small} />
                </div>
            </Tooltip2>
        </Link>
    </div>
);
export const ProjectStatusTest = ({ delta, id }: Props) => {
    const icon = ProIconData.quality_fill;
    return (
        <div className="ProStatus">
            <StatusIcon icon={icon} name="Quality" constraintType={['None']} stateType="DivisionByZero" />
            <StatusIcon icon={icon} name="Quality" constraintType={['None']} stateType="Normal" />
            <StatusIcon icon={icon} name="Quality" constraintType={['None']} stateType="CautionMinimum" />
            <StatusIcon icon={icon} name="Quality" constraintType={['None']} stateType="CautionMaximum" />
            <StatusIcon icon={icon} name="Quality" constraintType={['None']} stateType="CriticalMinimum" />
            <StatusIcon icon={icon} name="Quality" constraintType={['None']} stateType="CriticalMaximum" />

            <StatusIcon icon={icon} name="Quality" constraintType={['Equals']} stateType="Normal" />
            <StatusIcon icon={icon} name="Quality" constraintType={['Smaller']} stateType="Normal" />
            <StatusIcon icon={icon} name="Quality" constraintType={['Bigger']} stateType="Normal" />
        </div>
    );
};

type StatusIconProps = {
    name: string;
    icon: ProIconType;
    stateType?: Components.Schemas.DeltaStateType;
    constraintType?: (Components.Schemas.ConstraintType | undefined)[];
};

const StatusIcon = ({ icon, constraintType, stateType, name }: StatusIconProps) => (
    <Tooltip2
        content={statusText(name, stateType, constraintType)}
        usePortal
        lazy
        hoverCloseDelay={300}
        hoverOpenDelay={300}
        placement="top"
    >
        <div className={classNames(`ProStatus--icon`, statusClassName(stateType, constraintType))}>
            <ProIcon icon={icon} intent={ProIntent.Light} size={ProSize.Small} />
        </div>
    </Tooltip2>
);
