import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { ProIconType } from '../../constants/types';
import { WindowDisplayState } from '../../modules/Windows/WBSElement/WBSElement.helpers';
import { ProIntent, ProSize } from '../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../utils/helpers';
import { ProDeleteButton } from '../Button/ProDeleteButton';
import { ProIconButton } from '../Button/ProIconButton';
import { ProIconDataButtons } from '../Icons/IconData';

type Props = {
    i: number;
    deleteAction?: (id: number) => void;
    detailWindowMode?: WindowDisplayState | WindowDisplayState[];
    createElement?: () => void;
    createSecondElement?: () => void;
    hightlightOnHover?: boolean;
    elmentId?: string;
    selectTableRow?: (id: number) => void;
    createElementName?: string;
    createSecondElementName?: string;
    secondElementeIcon?: ProIconType;
    deleteMessage?: string;
};

export const ActionCell = ({
    createElement,
    i,
    detailWindowMode = 'view',
    hightlightOnHover = false,
    deleteAction,
    elmentId,
    selectTableRow,
    createSecondElement,
    createElementName = 'Add inside',
    createSecondElementName,
    secondElementeIcon,
    deleteMessage,
}: Props) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { t } = useTranslation();

    const navigate = () =>
        elmentId
            ? history.push(generatePath(match.path, { wbsElementId: elmentId }))
            : selectTableRow && selectTableRow(i);
    return (
        <div className={classNames('ProTable--actionCell', hightlightOnHover && 'ProTable--actionCell--hidden')}>
            {deleteAction && (
                <ProDeleteButton
                    disabled={getHiddenForWindowDisplayState(detailWindowMode)}
                    minimal
                    onClick={() => deleteAction(i)}
                    message={deleteMessage}
                />
            )}
            {createElement && createElementName && (
                <ProIconButton
                    icon={ProIconDataButtons.add_xs}
                    onClick={createElement}
                    tooltip={createElementName}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Success}
                    lineIntent={ProIntent.Success}
                    disabled={getHiddenForWindowDisplayState(detailWindowMode)}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
            )}
            {createSecondElement && createSecondElementName && (
                <ProIconButton
                    icon={secondElementeIcon ?? ProIconDataButtons.add_xs}
                    onClick={createSecondElement}
                    tooltip={createSecondElementName}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Warning}
                    lineIntent={ProIntent.Warning}
                    disabled={getHiddenForWindowDisplayState(detailWindowMode)}
                    size={secondElementeIcon ? ProSize.Small : ProSize.XSmall}
                    placement="bottom"
                />
            )}
            <ProIconButton
                icon={ProIconDataButtons.arrow_right_xs}
                tooltip={t('buttons.open')}
                mainIntent={ProIntent.Transpared}
                hoverIntent={ProIntent.Secondary}
                size={ProSize.XSmall}
                onClick={navigate}
                lineIntent={ProIntent.Secondary}
                disabled={getHiddenForWindowDisplayState(detailWindowMode)}
                placement="bottom"
            />
        </div>
    );
};
