import classNames from 'classnames';
import React, { Fragment, ReactNode } from 'react';

export type TableColData = {
    name: string;
    colSpace: number | 'extend';
    cellRender(index: number): ReactNode;
    // hint?: ReactNode;
    className?: string | false;
    hidden?(index: number): boolean;
    rightAligned?: boolean;
};

type Props = {
    data: TableColData[];
    rowsCount: number;
    disabled?: boolean;
    selectedTableRow?: number;
    reference?(el?: HTMLDivElement | null): void;
    getRowClass?(index: number): string;
    buttons?: ReactNode;
    detailDisplayed?: boolean;
    typeForErrorMsg?: string;
    isMultiTable?: boolean;
};

export const ProTable = ({
    data,
    disabled,
    rowsCount,
    selectedTableRow,
    reference,
    getRowClass,
    buttons,
    detailDisplayed,
    typeForErrorMsg,
    isMultiTable = false,
}: Props) => (
    <div
        className={classNames(
            'ProTable--wrapper',
            detailDisplayed && 'ProTable--wrapper__shrinked',
            isMultiTable && 'ProTable--wrapper__multiTable'
        )}
    >
        <div
            className={classNames('ProTable--content', disabled && 'ProTable__disabled')}
            ref={(el) => reference && reference(el)}
            key="table"
        >
            <div className="ProTable--head" key="table-head">
                {data.map((cur, i) => (
                    <Fragment key={`table-head-cell_${i}_fragment`}>
                        <div
                            key={`th_name_${cur.name}_${i}`}
                            className={classNames(
                                'ProTable--head-cell',
                                `ProTable--colspace-${cur.colSpace}`,
                                cur.rightAligned && 'ProTable--head-cell-right',
                                cur.hidden && !cur.hidden(i) && 'ProTable--head__hidden'
                            )}
                        >
                            {cur.name}
                        </div>
                    </Fragment>
                ))}
            </div>
            <div className="ProTable--body" key="table-body">
                {[...Array(rowsCount)].map((_, i) => (
                    <Fragment key={`tr_row_${i}_fragment`}>
                        <div
                            key={`tr_row_${i}`}
                            className={classNames(
                                'ProTable--row',
                                selectedTableRow === i && 'ProTable--row--selected',
                                getRowClass && getRowClass(i)
                            )}
                        >
                            {data.map((cur) => (
                                <>
                                    <div
                                        key={`table_cell_${cur.name}`}
                                        className={classNames(
                                            cur.className,
                                            cur.hidden && !cur.hidden(i) && 'ProTable__hidden',
                                            'ProTable--cell',
                                            `ProTable--colspace-${cur.colSpace}`
                                        )}
                                    >
                                        {cur.cellRender(i)}
                                    </div>
                                </>
                            ))}
                        </div>
                    </Fragment>
                ))}
            </div>
            {rowsCount === 0 && <div className="ProTable--noContentMsg">No {typeForErrorMsg ?? 'data'}</div>}
        </div>
        {buttons && buttons}
    </div>
);
