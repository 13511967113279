import classNames from 'classnames';
import React, { CSSProperties } from 'react';

type Props = {
    style?: CSSProperties;
    length: number;
    relative?: boolean;
};

export const FlexibleArrow = ({ style, length, relative = false }: Props) => {
    return (
        <div style={style} className={classNames('CeremoniesArrow', relative && 'CeremoniesArrow--relative')}>
            <svg
                width={`${length + 2}px`}
                height="4px"
                viewBox={`0 0 ${length + 2} 4`}
                version="1.1"
                className="CeremoniesArrow__Line"
            >
                <title>Line</title>
                <g
                    id="Artboard"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <line
                        x1={`${length}`}
                        y1="1.78947368"
                        x2="2"
                        y2="1.78947368"
                        id="Path"
                        stroke="#F9AB45"
                        strokeWidth="2.5"
                    ></line>
                </g>
            </svg>
            <svg width="11px" height="18px" viewBox="0 0 11 18" version="1.1" className="CeremoniesArrow__Arrow">
                <title>Arrow</title>
                <g id="Artboard-Copy-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M0.366116524,0.366116524 C0.854271888,-0.122038841 1.64572811,-0.122038841 2.13388348,0.366116524 L9.88388348,8.11611652 C10.3720388,8.60427189 10.3720388,9.39572811 9.88388348,9.88388348 L2.13388348,17.6338835 C1.64572811,18.1220388 0.854271888,18.1220388 0.366116524,17.6338835 C-0.122038841,17.1457281 -0.122038841,16.3542719 0.366116524,15.8661165 L7.233,9 L0.366116524,2.13388348 C-0.0894951502,1.6782718 -0.119869262,0.958451381 0.274994189,0.467611874 L0.366116524,0.366116524 Z"
                        id="Path"
                        fill="#F9AB45"
                        fillRule="nonzero"
                    ></path>
                </g>
            </svg>
        </div>
    );
};
