import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProCol } from '../../atoms/Grid/Col';
import { ProRow } from '../../atoms/Grid/Row';
import { Components } from '../../utils/types';
import { DependencyWindow } from '../PreProcessorList/DependencyWindow';

type SuccessorListType = {
    name: string;
    dependencies?: Components.Schemas.DependencyDTO[] | null;
};

export const SuccessorList = ({ dependencies, name }: SuccessorListType) => {
    const { t } = useTranslation();
    return (
        <div className="ProAttrField ProAttrField__dependencies">
            <div className="ProAttrField__head">
                <span className="ProAttrField__name">
                    <h3>{name}</h3>
                </span>
            </div>
            {dependencies && dependencies.length !== 0 && (
                <div className="ProAttrField__dependencies--header">
                    <ProRow>
                        <ProCol span={5}>{t('table.cols.name')}</ProCol>
                        <ProCol span={2}>{t('table.cols.finished')}</ProCol>
                        <ProCol span={2}>{t('table.cols.delay')}</ProCol>
                        <ProCol span={2}>{t('table.cols.type')}</ProCol>
                        <ProCol span={3}></ProCol>
                    </ProRow>
                </div>
            )}
            {dependencies?.map((cur) => (
                <DependencyWindow dependency={cur} displayWBS={cur.targetWBSElement} disabled={true} />
            ))}
            {dependencies && dependencies.length === 0 && (
                <ProRow className="ProAttrField__dependencies--box ProAttrField__dependencies--box__empty">
                    {t('error.noData.sucsessorDefined')}
                </ProRow>
            )}
        </div>
    );
};
