import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProPage } from '../../../components/Page/Page';
import { getBASE_MenuData } from '../../../constants/menu';
import { ApplicationDetailStartPage } from '../../../modules/AreaStartPage/AreaStartPage';

export const PortfolioWelcomePage = () => {
    const { t } = useTranslation();
    return (
        <ProPage
            // breadCrump={getBaseBCItemForType([ApplicationElementType.PortfolioMan], t)}
            name={t('navigation.portfolioMan.base')}
        >
            <ApplicationDetailStartPage data={getBASE_MenuData(t)[3]} />
        </ProPage>
    );
};
