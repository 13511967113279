import { TFunction } from 'i18next';
import React from 'react';
import { WBS_ELEMENT_BASE_URL } from '../../../api/api';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../atoms/Button/ProIconButton';
import { ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import { PersonalWBSDetailWindow } from '../../../modules/Windows/PersonalWBSDetailWindow/PersonalWBSDetailWindow';
import { WindowDisplayState } from '../../../modules/Windows/WBSElement/WBSElement.helpers';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';

export const usePersonalWBSElemDetailFrame = (closeAction: () => void, itemId?: string, externalSync?: () => void) => {
    const emptyItem: Components.Schemas.WBSElementDTO = {
        id: '0',
        projectId: '0',
        wbsStructureId: '0',
        name: 'new element',
    };

    return useGenericDetailWindow({
        apiConstant: WBS_ELEMENT_BASE_URL,
        closeAction,
        emptyItem,
        genericDetailWindow: PersonalWBSDetailWindow,
        externalSync,
        selectedItemId: itemId,
    });
};

export const ProjectActionCell = ({
    addAction,
    windowMode,
    selectAction,
    t,
}: {
    windowMode: WindowDisplayState | WindowDisplayState[];
    addAction: () => void;
    selectAction?: () => void;
    t: TFunction;
}) => (
    <>
        <div className="ProTable--actionCell">
            <ProButton
                leftIcon={ProIconDataButtons.add_xs}
                onClick={addAction}
                mainIntent={ProIntent.Transpared}
                hoverIntent={ProIntent.Success}
                lineIntent={ProIntent.Success}
                disabled={getHiddenForWindowDisplayState(windowMode)}
                size={ProSize.XSmall}
                text={t('buttons.addTeam')}
                placement="bottom"
            />
            {selectAction && (
                <ProIconButton
                    icon={ProIconDataButtons.arrow_right_xs}
                    tooltip={t('buttons.open')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Secondary}
                    size={ProSize.XSmall}
                    onClick={selectAction}
                    lineIntent={ProIntent.Secondary}
                    disabled={getHiddenForWindowDisplayState(windowMode)}
                    placement="bottom"
                />
            )}
        </div>
    </>
);
