import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { NotImplemented } from '../../../modules/ProErrorPage/NotImplemented';

export const PortfolioCostPage = () => {
    const { t } = useTranslation();
    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [ApplicationElementType.PortfolioMan, ApplicationElementType.CapacityMan],
                t
            )}
            name={t('navigation.portfolioMan.capacityMan.capacity')}
            loading={false}
        >
            <NotImplemented message={t('navigation.portfolioMan.capacityMan.capacity')} />
        </ProPage>
    );
};
