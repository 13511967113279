import { Components } from '../../utils/types';

type WBSDTO = Components.Schemas.WBSElementDeltaDTO;
type Requirement = Components.Schemas.RequirementDTO;

// reverse parentID, returnes the children of the node
export const getChildren = <T extends { id?: string; parentId?: string | null }>(node: T, elements: T[]): T[] => {
    return elements.filter((cur) => cur.parentId === node.id) ?? [];
};

// returns the open node or false
export const isNodeOpen = <T extends { id?: string; parentId?: string | null }>(openNodes: T[], nodeId?: string) => {
    if (!nodeId) return false;
    return openNodes.find((cur) => cur.id === nodeId) !== undefined;
};

export const getVisibleNodes = <T extends { id?: string; parentId?: string | null; name?: string | null | undefined }>(
    elm: T,
    elements: T[],
    openNodes: T[]
): T[] => {
    if (openNodes.map((cur) => cur.id).includes(elm.id)) {
        const lowerNodes = getChildren(elm, elements).map((cur) => getVisibleNodes(cur, elements, openNodes));
        if (lowerNodes) {
            return [elm, ...lowerNodes.flat()];
        }
    }
    return [elm];
};

export const getVisibleNodesWithSubtasks = <
    T extends {
        id?: string;
        parentId?: string | null;
        name?: string | null | undefined;
        taskLists?: Components.Schemas.TaskListDTO[] | null;
        level?: number;
    }
>(
    elm: T,
    elements: T[],
    openNodes: T[]
): T[] => {
    if (openNodes.map((cur) => cur.id).includes(elm.id)) {
        const lowerNodes = getChildren(elm, elements).map((cur) =>
            getVisibleNodesWithSubtasks(cur, elements, openNodes)
        );
        if (lowerNodes) {
            const tasks = elm.taskLists?.find((akt) => akt.tasklistType === 'Userlist')?.tasks || [];
            const tasksWithLevel = tasks.map((cur) => ({ ...cur, level: elm.level ? elm.level + 1 : undefined }));
            return [elm, ...lowerNodes.flat(), ...(tasksWithLevel as T[])];
        }
    }
    return [elm];
};

export const areElementsRelated = (i: number, data: WBSDTO[], selectedElement?: WBSDTO): boolean => {
    if (data[i]?.id === selectedElement?.id) return true;
    if (selectedElement && findAllParentsIDs(selectedElement, data).includes(data[i]?.id!)) return true;
    if (selectedElement && findAllChildrenIDs(selectedElement, data).includes(data[i]?.id!)) return true;

    return false;
};

export const findAllParentsIDs = (elm: WBSDTO, data: WBSDTO[]): string[] => {
    const parent = data.find((cur) => cur.id === elm.parentId);
    if (parent) {
        return [...findAllParentsIDs(parent, data), elm.id!];
    }
    return [];
};

export const findAllParents = <T extends { parentId?: string | null; id?: string | null }>(elm: T, data: T[]): T[] => {
    const parent = data.find((cur) => cur.id === elm.parentId);
    if (parent) {
        return [...findAllParents(parent, data), elm];
    }
    return [];
};

export const findAllChildrenIDs = (elm: WBSDTO, data: WBSDTO[]): string[] => {
    const children = data.filter((cur) => cur.parentId === elm.id);
    if (children) {
        const allChildren = children.map((cur) => findAllChildrenIDs(cur, data));
        return [...allChildren.flat(), elm.id!];
    }
    return [];
};
