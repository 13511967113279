import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COST_FUNCTION_API_BASE, MATERIAL_DEFINITION_API_BASE, PORTFOLIO_API_BASE } from '../../../api/api';
import { deleteAPIRequest, useGenericAPI } from '../../../api/useGenericApi';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { DetaiWindowWrapper } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { UserContext } from '../../../contexts/UserContext';
import { useMaterialDetailRouting } from '../../../hooks/useDetailRouting';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import { MaterialDetailWindow } from '../../../modules/Windows/MaterialDetailWindow/MaterialDetailWindow';
import { MaterialSummaryWindow } from '../../../modules/Windows/SummaryDetails/MaterialSummaryWindow';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';

export const useMaterialDetailWindow = ({
    closeAction,
    externalSync,
    roleId,
}: {
    closeAction: () => void;
    roleId?: string;
    externalSync?: () => void;
}) => {
    const { portfolio } = useContext(UserContext);
    return useGenericDetailWindow({
        apiConstant: MATERIAL_DEFINITION_API_BASE,
        closeAction,
        emptyItem: getNewMaterialDTO(portfolio),
        genericDetailWindow: MaterialDetailWindow,
        externalSync,
        selectedItemId: roleId,
    });
};

export const getNewMaterialDTO = (
    portfolio?: Components.Schemas.PortfolioDTO
): Components.Schemas.MaterialDefinitionDTO =>
    portfolio
        ? {
              name: 'Unnamed material',
              priceUnit: portfolio.units![0] ?? undefined,
              portfolioId: portfolio.id,
          }
        : {};

export const MaterialPage = ({ isHidden = false, pageTitle }: { isHidden?: boolean; pageTitle?: string }) => {
    const { portfolio: initPortfolio } = useContext(UserContext);
    const [selectedTableRow, setSelectedTableRow] = useState<number>();
    const { selectionMode, renderSelectionButton } = useContext(SelectionContext);
    const { t } = useTranslation();

    const [selectedMaterial, setSelectedRole] = useState<Components.Schemas.CostFunctionRoleDTO>();

    const deselectTableRow = () => {
        setSelectedRole(undefined);
        setSelectedTableRow(undefined);
        openMaterial('hidden');
    };

    const {
        data: portfolio,
        error,
        loading,
        sync,
    } = useGenericAPI<Components.Schemas.PortfolioDTO>(PORTFOLIO_API_BASE, initPortfolio?.id);

    const { materialDefinitions } = portfolio ?? {};

    const {
        isDisplayed: materialIsDisabled,
        mode: materialMode,
        open: openMaterial,
        renderDetailWindow: renderMaterialDefinitionsDetailWindow,
    } = useMaterialDetailWindow({ closeAction: deselectTableRow, externalSync: sync, roleId: selectedMaterial?.id });

    const selectTableRow = (i: number) => {
        if (materialDefinitions) {
            setSelectedRole(materialDefinitions[i - 1]);
            openMaterial('view', materialDefinitions[i - 1]);
            setSelectedTableRow(i);
        }
    };

    useMaterialDetailRouting({
        selectTableRow,
        materialDefinitions,
        portfolio,
        selectedTableRow,
    });

    const addRole = () => {
        setSelectedRole(undefined);
        openMaterial('new');
        setSelectedTableRow(undefined);
    };

    const deleteRole = (id: string) => {
        deleteAPIRequest<Components.Schemas.CostFunctionRoleDTO>(id, COST_FUNCTION_API_BASE).then(() => {
            openMaterial('hidden');
            sync();
        });
    };

    const tableData: TableColData[] = useMemo(
        () =>
            materialDefinitions === null || materialDefinitions === undefined
                ? []
                : [
                      {
                          name: t('table.cols.name'),
                          cellRender: (i) =>
                              i === 0 ? (
                                  <>
                                      <div className="ProTable__levelBars "></div>
                                      <div className="ProTable__level ProTable__level--open">
                                          <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                                      </div>
                                      <span className="ProTable__name">
                                          {t('table.cols.summaryIn')} {portfolio?.name ?? 'Portfolio'}
                                      </span>
                                  </>
                              ) : (
                                  <>
                                      <div className="ProTable__levelBars ">
                                          <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                                      </div>
                                      <span className="ProTable__name" onClick={() => selectTableRow(i)}>
                                          <ProIcon size={ProSize.Small} icon={ProIconData.person_fill} />
                                          {materialDefinitions[i - 1]?.name}
                                      </span>
                                  </>
                              ),
                          className: 'TableRowName ProTable__levels',
                          colSpace: 'extend',
                      },
                      {
                          name: t('table.cols.action'),
                          cellRender: (i) =>
                              i === 0 ? (
                                  <>
                                      <div className="ProTable--actionCell">
                                          <ProButton
                                              leftIcon={ProIconDataButtons.add_xs}
                                              onClick={() => addRole()}
                                              tooltip={t('buttons.add')}
                                              mainIntent={ProIntent.Transpared}
                                              hoverIntent={ProIntent.Success}
                                              lineIntent={ProIntent.Success}
                                              disabled={getHiddenForWindowDisplayState([materialMode])}
                                              size={ProSize.XSmall}
                                              text={t('buttons.add', { var: t('types.materialDefinition') })}
                                              placement="bottom"
                                          />
                                      </div>
                                  </>
                              ) : selectionMode === SelectionType.Material ? (
                                  renderSelectionButton && renderSelectionButton(materialDefinitions![i - 1])
                              ) : (
                                  <>
                                      <ActionCell
                                          i={i}
                                          selectTableRow={selectTableRow}
                                          detailWindowMode={materialMode}
                                          deleteAction={() => deleteRole(materialDefinitions![i - 1].id!)}
                                      />
                                  </>
                              ),
                          colSpace: 5,
                      },
                      {
                          name: t('table.cols.description'),
                          cellRender: (i) => materialDefinitions[i - 1]?.description ?? '',
                          colSpace: 4,
                          hidden: () => !materialIsDisabled,
                      },
                      {
                          name: t('table.cols.price'),
                          cellRender: (i) => materialDefinitions[i - 1]?.price,
                          colSpace: 4,
                          hidden: () => !materialIsDisabled,
                      },
                      {
                          name: t('table.cols.priceUnit'),
                          cellRender: (i) => materialDefinitions[i - 1]?.priceUnit?.name,
                          colSpace: 4,
                          hidden: () => !materialIsDisabled,
                      },
                  ],
        [materialDefinitions, materialMode, selectionMode, materialIsDisabled]
    );

    if (!isHidden && !materialDefinitions) {
        return <ProNoDataOrRightsPage message={[t('error.noData.material')]} loading={loading} />;
    }

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.PortfolioMan], t)}
            name={pageTitle ?? t('navigation.portfolioMan.baseData.material')}
            loading={loading}
            error={error}
            isHidden={isHidden}
            displyProjectSelector
        >
            <ProTable
                data={tableData}
                rowsCount={materialDefinitions ? materialDefinitions.length + 1 : 0}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
            />

            <DetaiWindowWrapper hidden={[materialIsDisabled]}>
                <MaterialSummaryWindow materials={materialDefinitions} />
                {renderMaterialDefinitionsDetailWindow()}
            </DetaiWindowWrapper>
        </ProPage>
    );
};
