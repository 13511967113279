import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProAttrField } from '../../atoms/AttrField/AttrField';
import { ProDropdown } from '../../atoms/AttrField/Dropdown';
import { ProNumberField } from '../../atoms/AttrField/NumberField';
import { ProDeleteButton } from '../../atoms/Button/ProDeleteButton';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProCol } from '../../atoms/Grid/Col';
import { ProRow } from '../../atoms/Grid/Row';
import { ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';

const DependencyTypes: Components.Schemas.DependencyType[] = ['EndEnd', 'EndStart', 'StartEnd', 'StartStart'];

type Props = {
    dependency: Components.Schemas.DependencyDTO;
    displayWBS?: Components.Schemas.WBSElementDTO;
    delteAction?: (id: string) => void;
    disabled: boolean;
    save?: (dep: Components.Schemas.DependencyDTO) => void;
};

export const DependencyWindow = ({ dependency, delteAction, displayWBS, disabled, save }: Props) => {
    const [dep, setDep] = useState(dependency);
    const { t } = useTranslation();

    return (
        <ProRow className="ProAttrField__dependencies--box">
            <ProCol span={4}>
                <ProAttrField value={displayWBS?.name ?? 'no element'} />
            </ProCol>
            <ProCol span={1}>
                <ProNumberField disabled={true} entity="%" value={displayWBS?.degreeOfCompletion} />
            </ProCol>
            <ProCol span={2}>
                <ProNumberField
                    disabled={disabled}
                    entity={t('entities.days')}
                    value={dep.delay}
                    handleChange={(val) =>
                        setDep({
                            ...dep,
                            delay: Number(val),
                        })
                    }
                />
            </ProCol>
            <ProCol span={2}>
                <ProDropdown
                    value={dep.dependencyType}
                    setValue={(val) =>
                        setDep({
                            ...dep,
                            dependencyType: DependencyTypes.find((cur) => cur === val),
                        })
                    }
                    items={DependencyTypes}
                    disabled={disabled}
                />
            </ProCol>
            <ProCol span={2} direction="row" className="ProAttrField__dependencies--buttons">
                {save && (
                    <ProIconButton
                        icon={ProIconDataButtons.save_s}
                        size={ProSize.XSmall}
                        mainIntent={ProIntent.Success}
                        onClick={() => save(dep)}
                        tooltip={t('buttons.save')}
                        disabled={disabled}
                    />
                )}
                {delteAction && <ProDeleteButton onClick={() => delteAction(dep.id!)} inline />}

                <Link to={`/app/project/time/${displayWBS?.id}`}>
                    <ProIconButton
                        tooltip={t('buttons.goTo')}
                        icon={ProIconDataButtons.arrow_right_xs}
                        onClick={() => {}}
                        size={ProSize.XSmall}
                        hoverIntent={ProIntent.Secondary}
                        lineIntent={ProIntent.Secondary}
                        mainIntent={ProIntent.Transpared}
                    />
                </Link>
            </ProCol>
        </ProRow>
    );
};
