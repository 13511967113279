import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { PortfolioPeoplePage } from '../../PortfolioManagement/People/PortfolioPeoplePage';
import { TeamPage } from '../../Team/Team';
import { ProjectTeamPage } from './Team';

export const TeamSelectionFrame = () => {
    const { selectionMode } = useContext(SelectionContext);
    const { t } = useTranslation();

    //Selection mode is for selecting the team resource
    return (
        <>
            <PortfolioPeoplePage
                isHidden={selectionMode !== SelectionType.HumanResource}
                pageTitle={t('windows.selectPerson')}
            />
            <ProjectTeamPage isHidden={selectionMode === SelectionType.HumanResource} />
        </>
    );
};

export const SingleTeamSelectionFrame = () => {
    const { selectionMode } = useContext(SelectionContext);
    const { t } = useTranslation();

    //Selection mode is for selecting the team resource
    return (
        <>
            <PortfolioPeoplePage
                isHidden={selectionMode !== SelectionType.HumanResource}
                pageTitle={t('windows.selectPerson')}
            />
            <TeamPage isHidden={selectionMode === SelectionType.HumanResource} />
        </>
    );
};
