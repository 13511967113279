import classNames from 'classnames';
import React from 'react';
import { ProAttrField, ProHeaderAttrField } from '../../../atoms/AttrField/AttrField';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { Components } from '../../../utils/types';
import { DetailWindowProps } from '../GenericDetailWindow';
import { getWindowDisplayStateMode } from '../WBSElement/WBSElement.helpers';

export const LevelDetailWindow = ({
    cancelChanges,
    closeAction,
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    createAction,
    deleteAction,
    error,
    formData,
    t,
}: DetailWindowProps<Components.Schemas.CostFunctionCriteriaLevelDTO>) => (
    <ProDetailWindow
        title={
            isDisabled ? (
                <h2
                    className={classNames(
                        formData?.name?.length &&
                            formData?.name?.length >= 180 &&
                            'ProDetailWindow--header--title__large'
                    )}
                >
                    {formData?.name ?? t('windows.details')}
                </h2>
            ) : (
                <ProHeaderAttrField
                    name={t('windows.name')}
                    value={formData?.name ?? String(t('windows.details'))}
                    disabled={false}
                    handleChange={(input) => setFormData((old) => ({ ...old, name: String(input).substring(0, 180) }))}
                />
            )
        }
        breadCrump={getBaseBCItemForType([ApplicationElementType.Level], t)}
        displayState={isDisplayed}
        icon={ProIconData.person_fill}
        buttonSection={
            <WindowButtonsForMode
                cancelAction={cancelChanges}
                closeAction={closeAction}
                createAction={createAction}
                deleteAction={deleteAction}
                saveAction={saveAction}
                setMode={setMode}
                mode={mode}
                t={t}
            />
        }
        error={error}
        loading={loading}
        intent={getWindowDisplayStateMode(mode)}
    >
        <ProRow>
            <ProCol span={12}>
                <ProAttrField
                    name={t('windows.description')}
                    disabled={isDisabled}
                    value={formData?.description}
                    handleChange={(value) => setFormData((cur) => ({ ...cur, description: String(value) }))}
                />
            </ProCol>
        </ProRow>
    </ProDetailWindow>
);
