import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIconButton } from '../Button/ProIconButton';
import { ProIconData, ProIconDataButtons } from '../Icons/IconData';
import { ProAttrFieldForSubElements } from './ProAttrFieldForSubElements';

type Props = {
    name: string;
    values?: (string | undefined | null)[];
    isSelectionModeOn: boolean;
    isDisabled: boolean;
    toggleSelectionMode: () => void;
    getInlineButtons?: (elm: string) => ReactNode;
    t: TFunction;
};

export const ProSubElementAttrField = ({
    name,
    values,
    isDisabled,
    isSelectionModeOn,
    toggleSelectionMode,
    getInlineButtons,
    t,
}: Props) => (
    <ProAttrFieldForSubElements
        name={name}
        values={values}
        getInlineButtons={getInlineButtons}
        buttons={
            !isSelectionModeOn ? (
                <ProIconButton
                    tooltip={t('buttons.addOrChange')}
                    icon={ProIconDataButtons.pen_s}
                    onClick={toggleSelectionMode}
                    size={ProSize.XSmall}
                    mainIntent={isDisabled ? ProIntent.LightBlue : ProIntent.Success}
                    disabled={isDisabled}
                />
            ) : (
                <ProIconButton
                    tooltip={t('buttons.cancel')}
                    icon={ProIconData.close_stroke}
                    onClick={toggleSelectionMode}
                    size={ProSize.XSmall}
                    mainIntent={ProIntent.Error}
                />
            )
        }
    />
);
