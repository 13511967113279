import { TFunction } from 'i18next';
import React from 'react';
import { TEAM_API_BASE } from '../../../api/api';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../atoms/Button/ProIconButton';
import { ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import { TeamDetailWindow } from '../../../modules/Windows/TeamDetailWindow/TeamDetailWindow';
import { WindowDisplayState } from '../../../modules/Windows/WBSElement/WBSElement.helpers';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';

export type ProjectDTO = Components.Schemas.ProjectDTO;
export type TeamDTO = Components.Schemas.TeamDTO;
export type HumanResourceDTO = Components.Schemas.HumanResourceDTO;

export const isProject = (item: ProjectDTO | TeamDTO | HumanResourceDTO): item is ProjectDTO => {
    return (item as ProjectDTO).portfolioId !== undefined;
};

export const isTeam = (item: ProjectDTO | TeamDTO | HumanResourceDTO): item is TeamDTO => {
    return (item as TeamDTO).teamStructureId !== undefined;
};

export const isHumanResource = (item: ProjectDTO | TeamDTO | HumanResourceDTO): item is HumanResourceDTO => {
    return (item as HumanResourceDTO).type === 'HumanResourceDTO';
};

const getEmptyTeamDTO = (project?: ProjectDTO): TeamDTO => ({
    name: 'new Team',
    projectId: project?.id,
    teamStructureId: (project?.teamStructures && project?.teamStructures[0].id) ?? '0',
    parentId: (project?.teams && project?.teams[0].id) ?? '0',
    teamType: 'Team',
});

export const useTeamDetailFrame = (
    closeAction: () => void,
    teamId?: string,
    project?: ProjectDTO,
    externalSync?: () => void
) => {
    const emptyItem = getEmptyTeamDTO(project);

    return useGenericDetailWindow({
        apiConstant: TEAM_API_BASE,
        closeAction,
        emptyItem,
        genericDetailWindow: TeamDetailWindow,
        externalSync,
        selectedItemId: teamId,
    });
};

export const ProjectActionCell = ({
    addAction,
    windowMode,
    selectAction,
    t,
}: {
    windowMode: WindowDisplayState | WindowDisplayState[];
    addAction: () => void;
    selectAction?: () => void;
    t: TFunction;
}) => (
    <>
        <div className="ProTable--actionCell">
            <ProButton
                leftIcon={ProIconDataButtons.add_xs}
                onClick={addAction}
                mainIntent={ProIntent.Transpared}
                hoverIntent={ProIntent.Success}
                lineIntent={ProIntent.Success}
                disabled={getHiddenForWindowDisplayState(windowMode)}
                size={ProSize.XSmall}
                text={t('buttons.addTeam')}
                placement="bottom"
            />
            {selectAction && (
                <ProIconButton
                    icon={ProIconDataButtons.arrow_right_xs}
                    tooltip={t('buttons.open')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Secondary}
                    size={ProSize.XSmall}
                    onClick={selectAction}
                    lineIntent={ProIntent.Secondary}
                    disabled={getHiddenForWindowDisplayState(windowMode)}
                    placement="bottom"
                />
            )}
        </div>
    </>
);

export const getTableRowClass = (
    item: Components.Schemas.ProjectDTO | Components.Schemas.TeamDTO | Components.Schemas.HumanResourceDTO
) => {
    if (isProject(item)) return '';
    if (isHumanResource(item)) return 'ProTable--row__level-2';
    if (isTeam(item)) return 'ProTable--row__level-1';

    return '';
};

export const getIsHidden = (states: boolean[]) => !states.reduce((sum, cur) => cur || sum);
