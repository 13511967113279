import axios from 'axios';
import { useEffect, useState } from 'react';
import { showErrorToast } from '../utils/helpers';
import { Components } from '../utils/types';
import { API_BASE_URL, WBS_BASE_URL } from './api';

export const useWBSStructure = (id?: string, deltaId?: string) => {
    const jwtObj = localStorage.getItem('auth');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [data, setData] = useState<Components.Schemas.WBSStructureDTO>();

    const sync = async () => {
        if (id && deltaId) {
            setLoading(true);
            setError('initial');
            try {
                const res = await axios.get<Components.Schemas.WBSStructureDTO>(
                    `${API_BASE_URL}${WBS_BASE_URL}/${id}/Delta/${deltaId}`,
                    {
                        headers: { Authorization: `Bearer ${jwtObj}` },
                    }
                );
                setData(res.data);
                setError(undefined);
            } catch (e) {
                setData(undefined);
                showErrorToast(`cant sync - Error: ${e}`);
                setError(String(e));
                throw e;
            } finally {
                setLoading(false);
            }

            return { data, error, loading };
        } else {
            setError('no wbs id given');
            setLoading(false);
        }
    };

    useEffect(() => {
        sync();
    }, [id]);

    return { data, error, loading, sync };
};
