import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadCrumpItem } from '../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../components/Page/Page';
import notFoundImg from './../../utils/images/streamline-icon-app-builder.png';

export const emptyBreadcrumpPath: BreadCrumpItem[] = [{ name: 'no path', href: '/' }];

type Props = {
    message?: string;
    loading?: boolean;
};

export const NotFoundPage = ({ message, loading = false }: Props) => (
    <ProPage breadCrump={emptyBreadcrumpPath} name="Not found" loading={loading}>
        <NotFound message={message} />
    </ProPage>
);

type NotFoundProps = {
    message?: string;
};

export const NotFound = ({ message }: NotFoundProps) => {
    const { t } = useTranslation();
    return (
        <div className="ProErrorPage">
            <h1 className="ProErrorPage--head">
                <span>{t('error.notFound.number')}</span>
                <img src={notFoundImg} alt="not found" />
            </h1>
            <p className="ProErrorPage--sub">{t('error.notFound.header')}</p>
            <p className="ProErrorPage--text">{t('error.notFound.message')}</p>
            <p>{message}</p>
        </div>
    );
};
