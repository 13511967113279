import axios from 'axios';
import { useEffect, useState } from 'react';
import { Components } from '../utils/types';
import { API_BASE_URL } from './api';

export const usePeople = (portfolioID?: string) => {
    const jwtObj = localStorage.getItem('auth');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [data, setData] = useState<Components.Schemas.PersonDTO[]>();

    const sync = async () => {
        if (portfolioID) {
            try {
                const res = await axios.get<Components.Schemas.PersonDTO[]>(
                    `${API_BASE_URL}/Person/Portfolio/${portfolioID}`,
                    {
                        headers: { Authorization: `Bearer ${jwtObj}` },
                    }
                );
                setData(res.data);
                setLoading(false);
                setError(undefined);
            } catch (e) {
                setData(undefined);
                setLoading(false);
                setError('error');
                throw e;
            }

            return { data, error, loading };
        }
    };

    useEffect(() => {
        sync();
    }, [portfolioID]);

    return { data, error, loading, sync };
};
