import React, { useState } from 'react';
import { Components } from '../../../../utils/types';
import { SprintPlanningPage } from './SprintPlanningPage';
import { SprintSelectionPage } from './SprintSelectionPage';

export const SprintPlanningFrame = () => {
    const [selectedSprint, setSelectedSprint] = useState<Components.Schemas.WBSElementDeltaDTO>();

    return !selectedSprint ? (
        <SprintSelectionPage setSelectedSprint={setSelectedSprint} />
    ) : (
        <SprintPlanningPage setSelectedSprint={setSelectedSprint} selectedSprint={selectedSprint} />
    );
};
