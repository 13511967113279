import { Tooltip2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import React from 'react';
import { ProIcon } from '../../../../atoms/Icons/Icon';
import { ProIconData, ProIconTypeData } from '../../../../atoms/Icons/IconData';
import { ProIconType } from '../../../../constants/types';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { Components } from '../../../../utils/types';

type Props = {
    name?: string | null;
    openAction?: () => void;
    selectAction: () => void;
    icon: ProIconType;
    level?: number;
    open?: boolean;
};

export const RequirementTableNameCell = ({ icon, name, openAction, level, open, selectAction }: Props) => (
    <>
        <div className="ProTable__levelBars">
            {level && <div className={`ProTable__levelBar ProTable__levelBar-${level}`}>{level}</div>}
        </div>
        {open !== undefined ? (
            <div className={classNames('ProTable__level', open && 'ProTable__level--open')} onClick={openAction}>
                <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
            </div>
        ) : (
            <div className={classNames('ProTable__level ProTable__level--empty')}> </div>
        )}
        <span className="ProTable__name" onClick={selectAction}>
            <ProIcon size={ProSize.Small} icon={icon} />
            {name ?? 'not named'}
        </span>
    </>
);

type TaskDTO = Components.Schemas.TaskDTO;
type RequirementDTO = Components.Schemas.RequirementDTO;

export const isTaskNotReq = (elm: TaskDTO | RequirementDTO): elm is TaskDTO => {
    return (elm as TaskDTO).taskCompletionState !== undefined;
};

export const isReqNotTask = (elm: TaskDTO | RequirementDTO): elm is RequirementDTO => {
    return (elm as RequirementDTO).inScope !== undefined;
};

export const getReqCellIcon = (elm: TaskDTO | RequirementDTO) => {
    if (isTaskNotReq(elm)) return ProIconData.checkBox_stroke;

    switch (elm.requirementType) {
        case 'Epic':
        case 'Specification':
            return ProIconData.list_outline;
        case 'UserStory':
        case 'ChangeRequest':
            return ProIconData.scope_fill;
        case 'ProjectBacklog':
            return ProIconData.project_fill;
        case 'TeamBacklog':
            return ProIconData.team_fill;
        case 'Bug':
            return ProIconTypeData.Bug;
        default:
            return ProIconData.scope_fill;
    }
};

export const isReqNotBacklog = (elm: TaskDTO | RequirementDTO) => {
    if (isTaskNotReq(elm)) return false;
    switch (elm.requirementType) {
        case 'Epic':
        case 'Specification':
        case 'UserStory':
        case 'ChangeRequest':
        case 'Bug':
            return true;
        case 'ProjectBacklog':
        case 'TeamBacklog':
        default:
            return false;
    }
};

export const isReqOrSimilar = (elm: TaskDTO | RequirementDTO) => {
    if (isTaskNotReq(elm)) return false;
    switch (elm.requirementType) {
        case 'UserStory':
        case 'ChangeRequest':
        case 'Bug':
            return true;
        case 'Epic':
        case 'Specification':
        case 'ProjectBacklog':
        case 'TeamBacklog':
        default:
            return false;
    }
};

export const getReqStatusIcon = (type?: string, name?: string | null) => {
    if (type === 'TeamBacklog' || type === 'ProjectBacklog' || type === 'Epic' || type === 'Specification') return '';

    switch (name) {
        case 'not ready':
            return (
                <Tooltip2 content={'not ready'}>
                    <ProIcon icon={ProIconTypeData.NotReady} intent={ProIntent.PrimaryLight} size={ProSize.Small} />
                </Tooltip2>
            );
        case 'ready':
            return (
                <Tooltip2 content={'ready'}>
                    <ProIcon icon={ProIconTypeData.Ready} intent={ProIntent.Success} size={ProSize.Small} />
                </Tooltip2>
            );
        case 'in funnel':
            return (
                <Tooltip2 content={'not ready'}>
                    <ProIcon icon={ProIconTypeData.Funnel} size={ProSize.Small} intent={ProIntent.PrimaryLight} />
                </Tooltip2>
            );
        case 'on hold':
            return (
                <Tooltip2 content={'on hold'}>
                    <ProIcon icon={ProIconTypeData.OnHold} size={ProSize.Small} intent={ProIntent.Warning} />
                </Tooltip2>
            );
        case 'finished':
            return (
                <Tooltip2 content={'finished'}>
                    <ProIcon icon={ProIconTypeData.Finnished} size={ProSize.Small} intent={ProIntent.Gray} />
                </Tooltip2>
            );
        case 'under development':
            return (
                <Tooltip2 content={'under development'}>
                    <ProIcon icon={ProIconTypeData.UnderDev} size={ProSize.Small} intent={ProIntent.Secondary} />
                </Tooltip2>
            );
        case 'rejected':
            return (
                <Tooltip2 content={'rejected'}>
                    <ProIcon icon={ProIconTypeData.Rejected} size={ProSize.Small} intent={ProIntent.Error} />
                </Tooltip2>
            );
        case 'scheduled':
            return (
                <Tooltip2 content={'scheduled'}>
                    <ProIcon icon={ProIconTypeData.Scheduled} size={ProSize.Small} intent={ProIntent.Success} />
                </Tooltip2>
            );
        case 'ready for review':
            return (
                <Tooltip2 content={'ready for review'}>
                    <ProIcon icon={ProIconTypeData.Review} size={ProSize.Small} intent={ProIntent.PrimaryLight} />
                </Tooltip2>
            );
        default:
            return name;
    }
};
