import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { deleteWBSElement } from '../../../api/api';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProTable } from '../../../atoms/Table/Table';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { getQualityTableData } from '../../../components/WBSTable/getQualityTableData';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { SelectionContext } from '../../../contexts/SelectionContext';
import { WBSContext } from '../../../contexts/WBSContext';
import { WBSSelectionContext } from '../../../contexts/WBSSelectionContext';
import { useWBSDetailRouting } from '../../../hooks/useWBSDetailRouting';
import { proLinkStructure } from '../../../modules/Menu/Navigate';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { getEmptyWBSElement } from '../../../modules/Windows/WBSElement/WBSElement.helpers';
import { useWBSDetailWindow } from '../../../modules/Windows/WBSElement/WBSElementHook/WBSDetailFrame';
import { Components } from '../../../utils/types';

export const allowedActionForWBSCreate = (
    wbsElementType: Components.Schemas.WBSElementType | undefined,
    func: () => void
) => {
    return wbsElementType === 'WorkPackage' || wbsElementType === 'Sprint' || wbsElementType === 'Milestone'
        ? undefined
        : func;
};

export const ProjectQualityPage = ({ isHidden = false, pageTitle }: { isHidden?: boolean; pageTitle?: string }) => {
    const { selectedElement, selectionMode, selectionFunction, turnSelectionModeOff } = useContext(WBSSelectionContext);
    const { selectionMode: externalSelectionMode, renderSelectionButton: renderExternalSelectionButton } =
        useContext(SelectionContext);
    const { t } = useTranslation();
    const {
        selectedWBSStructure,
        selectedWBSElement,
        setSelectedWBSElement,
        filteredData,
        openNodes,
        deselectWBSElement,
        tableData,
        setTableData,
        selectedTableRow,
        setSelectedTableRow,
        toggleOpenNode,
        syncWBSScructure,
        wbsStructureError,
        wbsStructureLoading,
        openLowerNodes,
        setRoute,
    } = useContext(WBSContext);

    const { selectedProject, loading, selectedTeam } = useContext(ProjectContext);

    const history = useHistory();

    useEffect(() => {
        setRoute(history.location.pathname);
    }, [history.location.pathname, setRoute]);

    const {
        open,
        renderWBSDetailWindow,
        isDisplayed,
        mode: detailWindowMode,
    } = useWBSDetailWindow('Quality', deselectWBSElement, selectedWBSElement, syncWBSScructure);

    useEffect(() => {
        open('view', false);
    }, [selectedWBSStructure]);

    const selectTableRow = (id: number) => {
        if (selectedWBSStructure) {
            setSelectedWBSElement(filteredData![id]);
            open('view');
            setSelectedTableRow(id);
        }
    };

    useEffect(() => {
        const newPosition = filteredData?.findIndex((cur) => cur.id === selectedWBSElement?.id);
        newPosition > 0 && selectTableRow(newPosition);
    }, [selectedWBSStructure]);

    const deleteWBSTableRow = (id: string) => {
        deleteWBSElement(id)
            .then(() => {
                syncWBSScructure();
                setUrlEmpty();
            })
            .catch((e) => {
                console.log('error', e);
            });
    };

    useEffect(() => {
        setTableData(
            getQualityTableData({
                createWBSElement,
                deleteWBSTableRow,
                detailWindowMode,
                filteredData,
                isDisplayed,
                openNodes,
                selectedWBSStructure,
                toggleOpenNode,
                selectionMode,
                turnSelectionModeOff,
                selectionFunction,
                selectedElement,
                t,
                externalSelectionMode,
                renderExternalSelectionButton,
            })
        );
    }, [
        selectedWBSStructure,
        filteredData,
        isDisplayed,
        detailWindowMode,
        selectionMode,
        selectedElement,
        externalSelectionMode,
        renderExternalSelectionButton,
    ]);

    const createWBSElement = (i: number) => {
        setSelectedWBSElement(getEmptyWBSElement(filteredData![i]));
        open('new');
        setSelectedTableRow(i);
    };

    const { error: notFoundError, setUrlEmpty } = useWBSDetailRouting({
        path: proLinkStructure.projectManagement.quality.path,
        link: proLinkStructure.projectManagement.quality.link,
        selectedWBSElement,
        filteredData,
        selectTableRow,
        openLowerNodes,
        selectedWBSStructure,
    });

    if (notFoundError) {
        return notFoundError;
    }

    if (!loading && (!selectedTeam || !selectedProject)) {
        return <ProNoDataOrRightsPage message={[t('error.noData.noTeam'), t('error.noData.noPortfolio')]} />;
    }
    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.ProjectMan], t)}
            name={pageTitle ?? t('navigation.projectMan.qualityMan')}
            error={wbsStructureError}
            loading={wbsStructureLoading}
            icon={ProIconData.quality_fill}
            displyWBSSelector
            displyProjectSelector
            isHidden={isHidden}
        >
            {selectedWBSStructure && tableData && (
                <ProTable
                    data={tableData}
                    rowsCount={filteredData?.length ?? 0}
                    selectedTableRow={selectedTableRow}
                    getRowClass={(i) => `ProTable--row__level-${filteredData![i]?.level ?? 0}`}
                />
            )}
            <div
                className={classNames(
                    'ProApp--detailWindowSection',
                    isDisplayed && 'ProApp--detailWindowSection__expanded'
                )}
            >
                {renderWBSDetailWindow()}
            </div>
        </ProPage>
    );
};
