import { DateInput } from '@blueprintjs/datetime';
import classNames from 'classnames';
import React from 'react';
import { ProIntent } from '../../utils/enums';

type Props = {
    valueString?: string | null;
    valueDate?: Date | null;
    name: string;
    handleChange?(value: string): void;
    disabled?: boolean;
    intent?: ProIntent;
};

export const ProDateField = ({ name, disabled = true, handleChange, valueDate, valueString, intent }: Props) => {
    const getDate = (): Date | undefined => {
        if (valueDate) return valueDate;
        if (valueString && new Date(valueString)) return new Date(valueString);
        return undefined;
    };
    return (
        <div className={classNames('ProAttrField', intent && `ProValue__intent-${intent}`)}>
            <span className="ProAttrField__name">{name}</span>
            <DateInput
                placeholder={'no value'}
                value={getDate()}
                onChange={(e) => !disabled && handleChange && handleChange(e.toISOString())}
                className={classNames('ProInput ProAttrField__input', disabled && 'ProAttrField--disabled')}
                fill={false}
                formatDate={(date) => date.toLocaleDateString()}
                parseDate={(date) => new Date(date)}
                disabled={disabled}
                locale="en-US"
            />
        </div>
    );
};
