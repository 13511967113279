import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TASK_API_BASE } from '../../../../api/api';
import { ProAttrField, ProHeaderAttrField, ProTextField } from '../../../../atoms/AttrField/AttrField';
import { ProDateField } from '../../../../atoms/AttrField/DateField';
import { ProDropdown } from '../../../../atoms/AttrField/Dropdown';
import { ProButton } from '../../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../../atoms/Button/ProIconButton';
import { ProCol } from '../../../../atoms/Grid/Col';
import { ProRow } from '../../../../atoms/Grid/Row';
import { ProIconData, ProIconDataButtons } from '../../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../../components/DetailWindow/breadCrump.helpers';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import { SelectionContext, SelectionType } from '../../../../contexts/SelectionContext';
import { DetailWindowProps, useGenericDetailWindow } from '../../../../modules/Windows/GenericDetailWindow';
import { getWindowDisplayStateMode } from '../../../../modules/Windows/WBSElement/WBSElement.helpers';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { Components } from '../../../../utils/types';

export const useTaskDetailsWindowForRequirements = ({
    closeAction,
    selectAction,
    externalSync,
    id,
    selectedRequirement,
}: {
    closeAction: () => void;
    selectAction: (id: string) => void;
    id?: string;
    externalSync?: () => Promise<void>;
    selectedRequirement?: Components.Schemas.RequirementDTO;
}) => {
    const { selectedProject } = useContext(ProjectContext);

    const emptyItem: Components.Schemas.TaskDTO = useMemo(() => {
        return {
            name: 'new Task',
            projectId: selectedProject?.id,
            requirement: { id: selectedRequirement?.id },
            taskListId: selectedRequirement?.taskLists?.find((cur) => cur.tasklistType === 'Userlist')?.id,
        };
    }, [selectedRequirement, selectedProject?.id]);

    return useGenericDetailWindow({
        apiConstant: TASK_API_BASE,
        closeAction: closeAction,
        emptyItem: emptyItem,
        genericDetailWindow: TaskDetailWindowForRequirements,
        externalSync,
        selectedItemId: id,
        selectAction,
    });
};

export const TaskDetailWindowForRequirements = ({
    createAction,
    deleteAction,
    formData,
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    error,
    cancelChanges,
    closeAction,
    t,
}: DetailWindowProps<Components.Schemas.TaskDTO>) => {
    if (!formData) return <></>;

    return (
        <ProDetailWindow
            title={
                isDisabled ? (
                    <h2
                        className={classNames(
                            formData?.name?.length &&
                                formData?.name?.length >= 180 &&
                                'ProDetailWindow--header--title__large'
                        )}
                    >
                        {formData?.name ?? t('windows.details')}
                    </h2>
                ) : (
                    <ProHeaderAttrField
                        name={t('windows.name')}
                        value={formData?.name ?? String(t('windows.details'))}
                        disabled={false}
                        handleChange={(input) =>
                            setFormData((old) => ({ ...old, name: String(input).substring(0, 180) }))
                        }
                    />
                )
            }
            displayState={isDisplayed}
            icon={ProIconData.checkBox_stroke}
            buttonSection={
                <WindowButtonsForMode
                    cancelAction={cancelChanges}
                    closeAction={closeAction}
                    createAction={createAction}
                    deleteAction={deleteAction}
                    mode={mode}
                    setMode={setMode}
                    saveAction={saveAction}
                    t={t}
                />
            }
            intent={getWindowDisplayStateMode(mode)}
            error={mode !== 'new' ? undefined : error}
            loading={loading}
            breadCrump={getBaseBCItemForType([ApplicationElementType.Tasks], t)}
        >
            <ProRow>
                <ProCol span={12} verticalAlign="bottom">
                    <ProRow>
                        <ProCol span={4}>
                            <ProDropdown
                                name={t('windows.type')}
                                value="Requirement task"
                                disabled={true}
                                setValue={() => {}}
                                // setValue={() => setFormData((cur) => ({ ...cur?.taskType }))}
                            />
                        </ProCol>
                        <ProCol span={4} block>
                            <ResponsiblePersonField
                                data={formData}
                                isDisabled={isDisabled}
                                setData={setFormData}
                                type="responsible"
                            />
                        </ProCol>
                        <ProCol span={4} block>
                            <ProDropdown
                                name={t('windows.completion')}
                                disabled={isDisabled}
                                setValue={(val) =>
                                    setFormData((cur) => ({
                                        ...cur,
                                        taskCompletionState: {
                                            ...cur?.taskCompletionState,
                                            completionStateType: val as Components.Schemas.WBSCompletionStateType,
                                        },
                                    }))
                                }
                                value={formData.taskCompletionState?.completionStateType}
                                items={['NotStarted', 'InProgress', 'Finished', 'CustomerDefined']}
                            />
                        </ProCol>
                    </ProRow>
                    <ProRow spacer>
                        <ProCol span={4} block>
                            <ProDateField
                                name={t('windows.created')}
                                disabled={true}
                                valueString={formData.creationDate}
                            />
                        </ProCol>
                        <ProCol span={4} block>
                            <ProDateField
                                name={t('windows.deadline')}
                                disabled={true}
                                valueString={formData.deadLine}
                                handleChange={(val) => setFormData((cur) => ({ ...cur, deadLine: val }))}
                            />
                        </ProCol>
                        <ProCol span={4} block>
                            <ProDropdown
                                name={t('windows.importance')}
                                disabled={isDisabled}
                                setValue={(val) =>
                                    setFormData((cur) => ({
                                        ...cur,
                                        taskImportanceState: {
                                            ...cur?.taskImportanceState,
                                            taskCompletionStateType: val as Components.Schemas.TaskCompletionStateType,
                                        },
                                    }))
                                }
                                value={formData.taskImportanceState?.taskCompletionStateType}
                                items={['NotStarted', 'InProgress', 'Finished', 'CustomerDefined']}
                            />
                        </ProCol>
                    </ProRow>
                </ProCol>
            </ProRow>

            <ProRow>
                <ProCol span={12}>
                    <ProTextField
                        name={t('windows.description')}
                        value={formData?.description}
                        handleChange={(val) => setFormData((cur) => ({ ...cur, description: String(val) }))}
                        disabled={isDisabled}
                    />
                </ProCol>
            </ProRow>
        </ProDetailWindow>
    );
};

const ResponsiblePersonField = ({
    data,
    isDisabled,
    setData,
    type,
}: {
    data: Components.Schemas.RequirementDTO;
    isDisabled: boolean;
    setData: Dispatch<SetStateAction<Components.Schemas.RequirementDTO | undefined>>;
    type: 'contact' | 'responsible';
}) => {
    const { t } = useTranslation();
    const { setRenderSelectionButton, setSelectedElementId, turnSelectionOff, setSelectionMode } =
        useContext(SelectionContext);

    const setSelectFunction = (person: Components.Schemas.PersonDTO) => {
        if (type === 'contact') {
            setData((cur) => ({ ...cur, contactPerson: person }));
        } else {
            setData((cur) => ({ ...cur, responsiblePerson: person }));
        }
    };

    const selectFunction = () => {
        setSelectedElementId(data?.responsiblePerson?.id);
        setSelectionMode(SelectionType.HumanResource);

        // eslint-disable-next-line react/display-name
        setRenderSelectionButton(() => (humanResource: Components.Schemas.HumanResourceDTO) => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        setSelectFunction(humanResource);
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
    };

    useEffect(() => {
        setRenderSelectionButton(() => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setRenderSelectionButton]);

    return (
        <ProAttrField
            name={type === 'responsible' ? t('windows.responsiblePerson') : t('windows.contactPerson')}
            value={`${data.responsiblePerson?.firstName ?? ''} ${data.responsiblePerson?.lastName ?? 'not defined'}`}
        >
            <ProIconButton
                tooltip={
                    type === 'responsible' ? t('buttons.assignResponsiblePerson') : t('buttons.assignContactPerson')
                }
                icon={ProIconDataButtons.add_xs}
                onClick={selectFunction}
                size={ProSize.XSmall}
                mainIntent={isDisabled ? ProIntent.LightBlue : ProIntent.Success}
                disabled={isDisabled}
                placement="top"
                small
            />
        </ProAttrField>
    );
};
