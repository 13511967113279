import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProPage } from '../../components/Page/Page';
import notImplementedImg from './../../utils/images/streamline-icon-seo-error-2.png';
import { emptyBreadcrumpPath } from './NotFound';

type Props = {
    message?: string;
    loading?: boolean;
};

export const NotImplementedPage = ({ message, loading }: Props) => (
    <ProPage breadCrump={emptyBreadcrumpPath} name="Not found" loading={loading}>
        <NotImplemented message={message} />
    </ProPage>
);

type NotImplementedProps = {
    message?: string;
};

export const NotImplemented = ({ message }: NotImplementedProps) => {
    const { t } = useTranslation();
    return (
        <div className="ProErrorPage ProErrorPage__NotImplemented">
            <h1 className="ProErrorPage--head">
                <img src={notImplementedImg} alt="not found" />
                <span>{t('error.notImplemented.number')}</span>
            </h1>
            <p className="ProErrorPage--sub">
                {message ?? <>This page</>} {t('error.notImplemented.header')}
            </p>
            <p className="ProErrorPage--text">
                {t('error.notImplemented.message')}
                <span role="img" aria-label="emoji">
                    😉
                </span>
            </p>
        </div>
    );
};
