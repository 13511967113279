import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { Components } from '../utils/types';

export enum WBSSelectionMode {
    Predecessor = 'Predecessor',
    Move = 'Move',
}

type WBSSelectionContextType = {
    selectedPreprocessorId: string | undefined;
    setSelectedPreprocessorId: Dispatch<SetStateAction<string | undefined>>;
    selectedElement: Components.Schemas.WBSElementDeltaDTO | undefined;
    setSelectedElement: Dispatch<SetStateAction<Components.Schemas.WBSElementDeltaDTO | undefined>>;
    selectionMode: WBSSelectionMode | undefined;
    setSelectionMode: Dispatch<SetStateAction<WBSSelectionMode | undefined>>;
    turnSelectionModeOff: () => void;
    selectionFunction: ((elm: Components.Schemas.DependencyDTO | Components.Schemas.WBSElementDTO) => void) | undefined;
    setSelectionFunction: Dispatch<
        SetStateAction<((elm: Components.Schemas.DependencyDTO | Components.Schemas.WBSElementDTO) => void) | undefined>
    >;
};

export const WBSSelectionContext = createContext<WBSSelectionContextType>({} as unknown as WBSSelectionContextType);

export const WBSSelectionContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [selectedPreprocessorId, setSelectedPreprocessorId] = useState<string>();
    const [selectedElement, setSelectedElement] = useState<Components.Schemas.WBSElementDeltaDTO>();
    const [selectionMode, setSelectionMode] = useState<WBSSelectionMode>();

    const turnSelectionModeOff = () => setSelectionMode(undefined);

    const [selectionFunction, setSelectionFunction] =
        useState<(elm: Components.Schemas.DependencyDTO | Components.Schemas.WBSElementDTO) => void>();

    return (
        <WBSSelectionContext.Provider
            value={{
                selectedElement,
                setSelectedElement,
                selectedPreprocessorId,
                setSelectedPreprocessorId,
                turnSelectionModeOff,
                selectionFunction,
                selectionMode,
                setSelectionFunction,
                setSelectionMode,
            }}
        >
            {children}
        </WBSSelectionContext.Provider>
    );
};
