import React from 'react';
import { ProIconData } from '../../atoms/Icons/IconData';
import { ProIconType } from '../../constants/types';
import { BreadCrumpItem, renderBreadcrump } from '../DetailWindow/breadCrump.helpers';

type Props = {
    name: string;
    breadCrump?: BreadCrumpItem[];
    icon?: ProIconType;
};

export const ProHeader = ({ name, breadCrump, icon = ProIconData.cog_outline }: Props) => (
    <div className="ProHeader">
        <header className="ProHeader--headline">
            <div>
                {breadCrump ? renderBreadcrump(breadCrump) : <span> </span>}
                <h1>{name}</h1>
            </div>
        </header>
    </div>
);
