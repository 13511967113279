import { TFunction } from 'i18next';
import React from 'react';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProDeleteButton } from '../Button/ProDeleteButton';
import { ProIconButton } from '../Button/ProIconButton';
import { ProIconData } from '../Icons/IconData';

type Props = {
    closeAction(): void;
    deleteAction?(): void;
    t: TFunction;
};

export const WindowButtons = ({ deleteAction, closeAction, t }: Props) => (
    <>
        {deleteAction && <ProDeleteButton onClick={deleteAction} />}
        <ProIconButton
            icon={ProIconData.close_stroke}
            tooltip={t('buttons.close')}
            mainIntent={ProIntent.PrimaryLight}
            hoverIntent={ProIntent.Secondary}
            onClick={closeAction}
            size={ProSize.Small}
        />
    </>
);
