import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProPage } from '../../components/Page/Page';
import { getBASE_MenuData } from '../../constants/menu';
import { ApplicationDetailStartPage } from '../../modules/AreaStartPage/AreaStartPage';

export const ProjectWelcomePage = () => {
    const { t } = useTranslation();
    return (
        <ProPage
            // breadCrump={getBaseBCItemForType([ApplicationElementType.ProjectMan], t)}
            name={t('navigation.projectMan.base', { break: '' })}
        >
            <ApplicationDetailStartPage data={getBASE_MenuData(t)[2]} />
        </ProPage>
    );
};
