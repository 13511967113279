import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ACCOUNT_BASE_URL, API_BASE_URL } from '../../api/api';
import { ProAttr, ProTextField } from '../../atoms/AttrField/AttrField';
import { ProButton } from '../../atoms/Button/ProButton';
import { ProIcon } from '../../atoms/Icons/Icon';
import { ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProSpinner } from '../../atoms/ProSpinner/Spinner';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';
import { AppBase } from '../Menu/Navigate';

export const InviteWindow = () => {
    const { email, token } = useParams<{ email?: string; token?: string }>();
    const { search } = useLocation();

    const [mail, setMail] = useState<string | undefined>(email);
    const [invite, setInvite] = useState<string | undefined>(token);
    const [password, setPassword] = useState<string>('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(search);
        const mail = params.get('email');

        const token = params.get('token');
        if (mail) setMail(decodeURIComponent(mail));
        if (token) setInvite(decodeURIComponent(token));
    }, [search]);

    const register = () => {
        setLoading(true);
        setError(false);
        setSuccess(false);

        axios
            .put<Components.Schemas.ApplicationUserDTO>(`${API_BASE_URL}${ACCOUNT_BASE_URL}/ResetPassword/${mail}`, {
                email: mail,
                passwordResetToken: invite,
                password,
            })
            .then(() => {
                setSuccess(true);
            })
            .catch((e) => {
                console.log('register error', e);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <form id="registerForm">
            <a href={AppBase}>
                <ProButton
                    text="< Back to login"
                    mainIntent={ProIntent.Transpared}
                    lineIntent={ProIntent.Primary}
                    className="LoginWindow__backButton"
                    onClick={() => {}}
                />
            </a>
            <ProIcon icon={ProIconDataButtons.register_s} size={ProSize.XLarge} intent={ProIntent.Primary} />
            <h1>Register</h1>

            <ProAttr.Val
                value={mail}
                handleChange={(val) => setMail(String(val))}
                name="EMail"
                fullWidth
                disabled={false}
            />
            <ProAttr.Val
                value={password}
                handleChange={(val) => setPassword(String(val))}
                name="Password"
                fullWidth
                disabled={false}
            />
            <span className={classNames(password.length < 16 ? 'LoginWindow__warning' : 'LoginWindow__success')}>
                The password needs min. 16 character
            </span>
            <ProTextField
                value={invite}
                handleChange={(val) => setInvite(String(val))}
                name="Invite code"
                fullWidth
                disabled={false}
            />
            {loading && <ProSpinner />}
            {!loading && !success && (
                <div className="ProButton--group">
                    <ProButton
                        text="Register"
                        size={ProSize.Medium}
                        mainIntent={ProIntent.Primary}
                        leftIcon={ProIconDataButtons.register_s}
                        onClick={register}
                    />
                </div>
            )}
            {success && (
                <div className="ProMessage">
                    <p className="ProMessage--success">
                        Your account is now ready with the given password. Please log in.
                    </p>
                </div>
            )}
            {error && (
                <div className="ProMessage">
                    <p className="ProMessage--error">Ups, something went wrong</p>
                </div>
            )}
        </form>
    );
};
