import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ProjectContextProvider } from '../../contexts/ProjectContext';
import { SelectionContextProvider } from '../../contexts/SelectionContext';
import { UserContext, UserContextProvider } from '../../contexts/UserContext';
import { WBSContextProvider } from '../../contexts/WBSContext';
import { WBSSelectionContextProvider } from '../../contexts/WBSSelectionContext';
import { LoginWindow, RegisterWindow } from '../../modules/LoginWindow/LoginWindow';
import { ProMenu } from '../../modules/Menu/Menu';
import {
    AppBase,
    Home_BASE,
    Personal_BASE,
    Portfolio_BASE,
    Project_BASE,
    Team_BASE,
    proLinkStructure,
} from '../../modules/Menu/Navigate';
import { HomePage } from '../Homepage/HomePage';
import { PersonalForecastSessionPage as PersonalSimulationPage } from '../PersonalManagement/ForecastSession/ForecastSession';
import { PersonalTasksPage } from '../PersonalManagement/Tasks/Tasks';
import { PersonalWelcomePage } from '../PersonalManagement/Welcome/Welcome';
import { PersonalWorkPackagesFrame } from '../PersonalManagement/WorkPackages/WorkPackages';
import { AgileSettingsProjectsPage } from '../PortfolioManagement/BaseData/AgileSetings';
import { BaseDataOverview } from '../PortfolioManagement/BaseData/BaseData';
import { FunctionalRolesPage } from '../PortfolioManagement/BaseData/FunctionalRoles';
import { FunctionsPage } from '../PortfolioManagement/BaseData/Functions';
import { LevelsPage } from '../PortfolioManagement/BaseData/Level';
import { MaterialPage } from '../PortfolioManagement/BaseData/Material';
import { TeamPositionsPage } from '../PortfolioManagement/BaseData/TeamPositions';
import { UnitsPage } from '../PortfolioManagement/BaseData/Units';
import { PortfolioCostManagementPage } from '../PortfolioManagement/Cost/CostManagement';
import { PortfolioCostPage } from '../PortfolioManagement/Cost/Costs';
import { PortfolioIntegrationPage } from '../PortfolioManagement/Integration/Integration';
import { PortfolioPeoplePage } from '../PortfolioManagement/People/PortfolioPeoplePage';
import { PortfolioProjectsPage } from '../PortfolioManagement/Projects/Projects';
import { PortfolioRoadmapPage } from '../PortfolioManagement/Time/Time';
import { PortfolioWelcomePage } from '../PortfolioManagement/Welcome/PortfolioWelcome';
import { CostSelectionFrame } from '../ProjectManagement/Cost/CostSelectionFrame';
import { TeamStatusReportPage } from '../ProjectManagement/Integration/Integration';
import { ProjectWelcomePage } from '../ProjectManagement/ProjectWelcomePage';
import { QualitySelectionFrame } from '../ProjectManagement/Quality/QualitySelectionFrame';
import { ProjectRiskPage } from '../ProjectManagement/Risk/Risk';
import { SingleTeamSelectionFrame, TeamSelectionFrame } from '../ProjectManagement/Team/TeamSelectionFrame';
import { TimeSelectionFrame } from '../ProjectManagement/Time/TimeSelectionFrame';
import { TeamCeremoniesPage } from '../TeamManagement/Ceremonies/Ceremonies';
import { BackglogGroomingPage } from '../TeamManagement/Ceremonies/Grooming/BackglogGroomingPage';
import { SprintPlanningFrame } from '../TeamManagement/Ceremonies/Planning/SprintPlanningFrame';
import { RequirementsSelectionFrame } from '../TeamManagement/Requirements/RequirementsSelectionFrame';
import { TeamStakeholdersPage } from '../TeamManagement/Stakeholders/Stakeholders';
import { TeamTasksPage } from '../TeamManagement/Tasks/Tasks';
import { TeamWelcomePage } from '../TeamManagement/TeamWelcome/TeamWelcome';
import { TeamWorkPackagesPage } from '../TeamManagement/WorkPackages/WorkPackages';

export const App = () => (
    <div className="pm-app">
        <BrowserRouter>
            <UserContextProvider>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/app" />
                    </Route>
                    <Route path={'/app'} component={AuthApp} />
                    <Route path={'*'}>
                        <Redirect to="/app" />
                    </Route>
                </Switch>
            </UserContextProvider>
        </BrowserRouter>
    </div>
);

const AuthApp = () => {
    const { user } = useContext(UserContext);

    if (user) {
        return (
            <BrowserRouter>
                <div className="ProApp">
                    <ProMenu />
                    <Switch>
                        <ProjectContextProvider>
                            <Route path={Home_BASE} exact component={HomeRouter} />
                            <WBSContextProvider>
                                <WBSSelectionContextProvider>
                                    <Route path={Personal_BASE} component={PersonalManagementRouter} />
                                    <Route path={Team_BASE} component={TeamManagementRouter} />
                                    <Route path={Project_BASE} component={ProjectManagementRouter} />
                                    <Route path={Portfolio_BASE} component={PortfolioManagementRouter} />
                                </WBSSelectionContextProvider>
                            </WBSContextProvider>
                        </ProjectContextProvider>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={`${AppBase}/register`} component={RegisterWindow} />;
                <Route path={``} component={LoginWindow} />;
            </Switch>
        </BrowserRouter>
    );
};

const PersonalManagementRouter = () => (
    <SelectionContextProvider>
        <Switch>
            <Route path={proLinkStructure.personalManagement.welcome.link} exact component={PersonalWelcomePage} />
            <Route
                path={proLinkStructure.personalManagement.workPackages.path}
                exact
                component={PersonalWorkPackagesFrame}
            />
            <Route path={proLinkStructure.personalManagement.tasks.path} exact component={PersonalTasksPage} />
            <Route
                path={proLinkStructure.personalManagement.simulation.link}
                exact
                component={PersonalSimulationPage}
            />
            <Route path={proLinkStructure.personalManagement.link} exact component={PersonalWelcomePage} />
        </Switch>
    </SelectionContextProvider>
);

const TeamManagementRouter = () => (
    <SelectionContextProvider>
        <Switch>
            <Route path={proLinkStructure.teamManagement.team.path} exact component={SingleTeamSelectionFrame} />
            <Route path={proLinkStructure.teamManagement.stakeholders.link} exact component={TeamStakeholdersPage} />
            <Route
                path={proLinkStructure.teamManagement.requirements.path}
                exact
                component={RequirementsSelectionFrame}
            />
            <Route path={proLinkStructure.teamManagement.workPackages.link} exact component={TeamWorkPackagesPage} />
            <Route path={proLinkStructure.teamManagement.tasks.link} exact component={TeamTasksPage} />
            <Route path={proLinkStructure.teamManagement.ceremonies.link} exact component={TeamCeremoniesPage} />
            <Route
                path={proLinkStructure.teamManagement.ceremonies.grooming.link}
                exact
                component={BackglogGroomingPage}
            />
            <Route
                path={proLinkStructure.teamManagement.ceremonies.planning.link}
                exact
                component={SprintPlanningFrame}
            />
            <Route path={proLinkStructure.teamManagement.link} exact component={TeamWelcomePage} />
        </Switch>
    </SelectionContextProvider>
);

const ProjectManagementRouter = () => (
    <SelectionContextProvider>
        <Switch>
            <Route path={proLinkStructure.projectManagement.teamStructure.path} exact component={TeamSelectionFrame} />
            <Route path={proLinkStructure.projectManagement.riskManagement.link} exact component={ProjectRiskPage} />
            <Route path={proLinkStructure.projectManagement.quality.path} component={QualitySelectionFrame} />
            <Route path={proLinkStructure.projectManagement.cost.path} component={CostSelectionFrame} />
            <Route path={proLinkStructure.projectManagement.time.path} component={TimeSelectionFrame} />
            <Route path={proLinkStructure.projectManagement.integration.link} exact component={TeamStatusReportPage} />
            <Route path={proLinkStructure.projectManagement.link} exact component={ProjectWelcomePage} />
        </Switch>
    </SelectionContextProvider>
);

const PortfolioManagementRouter = () => (
    <SelectionContextProvider>
        <Switch>
            {/* <Route
                path={proLinkStructure.portfolioManagement.peoplePositions.link}
                exact
                component={PeopleManagementOverview}
            /> */}
            <Route path={proLinkStructure.portfolioManagement.people.path} exact component={PortfolioPeoplePage} />
            <Route path={proLinkStructure.portfolioManagement.baseData.link} exact component={BaseDataOverview} />
            <Route
                path={proLinkStructure.portfolioManagement.baseData.teamPositions.link}
                exact
                component={TeamPositionsPage}
            />
            {/* <Route
                path={proLinkStructure.portfolioManagement.stakeholder.link}
                exact
                component={PortfolioStakeholderPage}
            /> */}
            <Route path={proLinkStructure.portfolioManagement.projects.link} exact component={PortfolioProjectsPage} />
            <Route path={proLinkStructure.portfolioManagement.roadmap.link} exact component={PortfolioRoadmapPage} />
            <Route
                path={proLinkStructure.portfolioManagement.capacity.link}
                exact
                component={PortfolioCostManagementPage}
            />
            <Route
                path={proLinkStructure.portfolioManagement.capacity.capacity.link}
                exact
                component={PortfolioCostPage}
            />
            <Route
                path={proLinkStructure.portfolioManagement.baseData.functionalRoles.link}
                exact
                component={FunctionalRolesPage}
            />
            <Route path={proLinkStructure.portfolioManagement.baseData.material.path} exact component={MaterialPage} />
            <Route path={proLinkStructure.portfolioManagement.baseData.level.path} exact component={LevelsPage} />
            <Route
                path={proLinkStructure.portfolioManagement.baseData.functions.path}
                exact
                component={FunctionsPage}
            />
            <Route path={proLinkStructure.portfolioManagement.baseData.units.link} exact component={UnitsPage} />
            <Route
                path={proLinkStructure.portfolioManagement.baseData.agile.link}
                exact
                component={AgileSettingsProjectsPage}
            />
            <Route
                path={proLinkStructure.portfolioManagement.integration.link}
                exact
                component={PortfolioIntegrationPage}
            />
            <Route path={proLinkStructure.portfolioManagement.link} component={PortfolioWelcomePage} />
        </Switch>
    </SelectionContextProvider>
);

const HomeRouter = () => (
    <div className="ProApp">
        <Route path={`/app`} component={HomePage} />
    </div>
);
