import { TFunction } from 'i18next';
import React from 'react';
import { ProButton } from '../../atoms/Button/ProButton';
import { ActionCell } from '../../atoms/Cells/ActionCell';
import { ProjectStatus } from '../../atoms/ProjectStatus/ProjectStatus';
import { TableColData } from '../../atoms/Table/Table';
import { ProValue } from '../../atoms/Value/ProValue';
import { SelectionType } from '../../contexts/SelectionContext';
import { WBSSelectionMode } from '../../contexts/WBSSelectionContext';
import { WindowDisplayState } from '../../modules/Windows/WBSElement/WBSElement.helpers';
import { allowedActionForWBSCreate } from '../../routes/ProjectManagement/Quality/ProjectQualityPage';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';
import { NameCell } from './WBSNameCell';
import { areElementsRelated } from './wbsTabeHelpers';

type Props = {
    selectedWBSStructure: Components.Schemas.WBSStructureDTO | undefined;
    filteredData: Components.Schemas.WBSElementDeltaDTO[];
    createWBSElement: (id: number) => void;
    openNodes: Components.Schemas.WBSElementDeltaDTO[];
    toggleOpenNode: (node?: Components.Schemas.WBSElementDeltaDTO | undefined) => void;
    detailWindowMode: WindowDisplayState;
    deleteWBSTableRow: (id: string) => void;
    isDisplayed: boolean;
    selectedElement?: Components.Schemas.WBSElementDeltaDTO;
    selectionMode?: WBSSelectionMode;
    turnSelectionModeOff?: () => void;
    selectionFunction?: (elm: Components.Schemas.WBSElementDTO | Components.Schemas.DependencyDTO) => void;
    t: TFunction;
    externalSelectionMode?: SelectionType;
    renderExternalSelectionButton?: (elm: any) => JSX.Element;
};

export const getQualityTableData = ({
    selectedWBSStructure,
    filteredData,
    createWBSElement,
    openNodes,
    toggleOpenNode,
    detailWindowMode,
    deleteWBSTableRow,
    isDisplayed,
    selectionFunction,
    selectionMode,
    turnSelectionModeOff,
    selectedElement,
    t,
    externalSelectionMode,
    renderExternalSelectionButton,
}: Props): TableColData[] => [
    {
        name: t('table.cols.wbsName'),
        cellRender: (i) => (
            <NameCell
                baseData={selectedWBSStructure}
                displayData={filteredData}
                i={i}
                openNodes={openNodes}
                toggleOpenNode={toggleOpenNode}
            />
        ),
        className: 'TableRowName ProTable__levels',
        colSpace: 'extend',
    },
    {
        name: t('table.cols.action'),
        cellRender: (i) => (
            <>
                {externalSelectionMode === SelectionType.WBSElement &&
                    renderExternalSelectionButton &&
                    renderExternalSelectionButton(filteredData[i])}
                {selectionMode && selectionFunction && turnSelectionModeOff && (
                    <div className="ProTable--actionCell">
                        <ProButton
                            onClick={turnSelectionModeOff}
                            text={t('buttons.cancel')}
                            mainIntent={ProIntent.Transpared}
                            hoverIntent={ProIntent.Error}
                            lineIntent={ProIntent.Error}
                            size={ProSize.XSmall}
                            placement="bottom"
                        />
                        <ProButton
                            text={t('buttons.select')}
                            mainIntent={ProIntent.Success}
                            disabled={areElementsRelated(i, filteredData, selectedElement)}
                            onClick={() => {
                                filteredData[i] && selectionFunction(filteredData[i]);
                                turnSelectionModeOff();
                            }}
                        />
                    </div>
                )}
                {!selectionMode && !externalSelectionMode && (
                    <ActionCell
                        createElement={allowedActionForWBSCreate(filteredData![i]?.wbsElementType, () =>
                            createWBSElement(i)
                        )}
                        i={i}
                        elmentId={filteredData[i]?.id}
                        detailWindowMode={detailWindowMode}
                        deleteAction={() => filteredData![i]?.id && deleteWBSTableRow(filteredData[i]!.id!)}
                        hightlightOnHover
                    />
                )}
            </>
        ),
        className: `ProTable__buttons`,
        colSpace: 4,
    },
    {
        name: t('table.cols.status'),
        cellRender: (i) => <ProjectStatus delta={filteredData[i]} id={filteredData[i]?.id} />,
        className: 'ProTable__status',
        colSpace: 3,
    },
    {
        name: t('table.cols.responsible'),
        cellRender: (i) =>
            filteredData![i]?.responsiblePerson
                ? `${filteredData![i]?.responsiblePerson?.firstName ?? ''} ${
                      filteredData![i]?.responsiblePerson?.lastName ?? 'no data'
                  }`
                : '',
        hidden: () => !isDisplayed,
        colSpace: 4,
    },
    {
        name: t('table.cols.complete'),
        cellRender: (i) => <ProValue type="value" entity="%" value={filteredData![i]?.degreeOfCompletion} />,
        hidden: () => !isDisplayed,
        colSpace: 3,
        rightAligned: true,
    },
    {
        name: t('table.cols.forQuality'),
        cellRender: (i) => <ProValue type="value" entity="%" value={filteredData![i]?.quality} />,
        hidden: () => !isDisplayed,
        colSpace: 3,
        rightAligned: true,
    },
    {
        name: t('table.cols.planQuality'),
        cellRender: (i) => <ProValue type="value" entity="%" value={filteredData![i]?.wbsElementDelta?.quality} />,
        hidden: () => !isDisplayed,
        colSpace: 2,
        rightAligned: true,
    },
    {
        name: t('table.cols.delta'),
        cellRender: (i) => <ProValue type="value" entity="%" value={filteredData![i]?.deltaQualityPercent} />,
        hidden: () => !isDisplayed,
        colSpace: 3,
        rightAligned: true,
    },
    {
        name: t('table.cols.constraint'),
        cellRender: (i) => filteredData![i]?.constraintQualityType,
        hidden: () => !isDisplayed,
        colSpace: 4,
    },
];
