import React from 'react';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData, ProIconTypeData } from '../../../atoms/Icons/IconData';
import { ProIntent, ProSize } from '../../../utils/enums';
import { Components } from '../../../utils/types';

export const getEmptyWBSElement = (parent: Components.Schemas.WBSElementDTO): Components.Schemas.WBSElementDTO => ({
    id: '00000000-0000-0000-0000-000000000000',
    projectId: parent.projectId,
    wbsStructureId: parent.wbsStructureId,
    parentId: parent.id,
    name: 'Name',
    description: 'Description',
    wbsElementType: 'WorkPackage',
    constraintEndDateType: 'None',
    duration: 0,
    degreeOfCompletion: 0,
});

export type WBSType = 'ProjectWBSElement' | 'Milestone' | 'Sprint' | 'Deliverable' | 'WorkPackage' | undefined;

export const getIconForType = (type?: WBSType) => {
    switch (type) {
        case 'Milestone':
            return ProIconTypeData.Milestone;
        case 'ProjectWBSElement':
            return ProIconTypeData.Project;
        case 'WorkPackage':
            return ProIconTypeData.Package;
        case 'Sprint':
            return ProIconTypeData.Sprint;
        case 'Deliverable':
            return ProIconData.list_outline;
        // return ProIconTypeData.Folder;
        default:
            return ProIconTypeData.Package;
    }
};

export const getIconForTypeInNameCell = (type?: WBSType) => {
    // if (type && type === 'Deliverable') return <></>;

    return <ProIcon size={ProSize.Small} icon={getIconForType(type)} />;
};

export const mockConstraintType: Components.Schemas.ConstraintType[] = ['None', 'Smaller', 'Equals', 'Bigger'];

export const mockStartConstraintType: { name: string; val: Components.Schemas.ConstraintType }[] = [
    { name: 'None', val: 'None' },
    { name: 'Start not earlier than', val: 'Smaller' },
    { name: 'Starts exactly at', val: 'Equals' },
    { name: 'Start lot later than', val: 'Bigger' },
];

export const mockWBSElementType: Components.Schemas.WBSElementType[] = [
    // 'ProjectWBSElement',
    'WorkPackage',
    'Milestone',
    'Sprint',
    'Deliverable',
];

export const WBSCompletionStateType: Components.Schemas.WBSCompletionStateType[] = [
    'NotStarted',
    'InProgress',
    'Finished',
    'CustomerDefined',
];

export const QualityStateType: Components.Schemas.QualityStateType[] = [
    'None',
    'Minor',
    'Major',
    'Critcal',
    'CustomerDefined',
];

export type WindowDisplayState = 'edit' | 'new' | 'view' | 'hidden';
export const getWindowDisplayStateMode = (mode: WindowDisplayState) => {
    switch (mode) {
        case 'edit':
            return ProIntent.Edit;
        case 'new':
            return ProIntent.Create;
        case 'view':
            return undefined;
    }
};

export const getQualityIntent = (elm: Components.Schemas.WBSElementDeltaDTO) => {
    if (elm.criticalQuality) return ProIntent.Error;
};

export const getCostIntent = (elm: Components.Schemas.WBSElementDeltaDTO) => {
    if (elm.criticalCosts) return ProIntent.Error;
};

export const getCompletionState = (data?: Components.Schemas.TaskCompletionStateType) => {
    if (data === 'NotStarted') return 'not started';
    if (data === 'Finished') return 'completet';
    if (data === 'CustomerDefined' || data === 'InProgress') return 'in progress';

    return 'not defined';
};
