import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProSummary } from '../../../atoms/SummaryWindow/SummaryWindow';
import { Components } from '../../../utils/types';

export const FunctionsSummaryWindow = ({
    funcs,
}: {
    funcs?: Components.Schemas.CostFunctionCriteriaFunctionDTO[] | null;
}) => {
    const { t } = useTranslation();

    return (
        <ProSummary.Window name={t('windows.summary')}>
            <ProSummary.Attr name={t('windows.totalFunctions')} icon={ProIconData.base_data} val={funcs?.length} />
        </ProSummary.Window>
    );
};
