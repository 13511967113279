import { TFunction } from 'i18next';
import React, { useContext, useState } from 'react';
import { ACCOUNT_BASE_URL, APP_BASE_URL } from '../../api/api';
import { getAPIRequest, useGenericAPI } from '../../api/useGenericApi';
import { ProAttrField } from '../../atoms/AttrField/AttrField';
import { ProButton } from '../../atoms/Button/ProButton';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProCol } from '../../atoms/Grid/Col';
import { ProRow } from '../../atoms/Grid/Row';
import { ProIconData, ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProDialog } from '../../atoms/ProDialog/ProDialog';
import { UserContext } from '../../contexts/UserContext';
import { AppBase } from '../../modules/Menu/Navigate';
import { ProIntent, ProSize } from '../../utils/enums';
import { showErrorToast } from '../../utils/helpers';
import { Components } from '../../utils/types';
import { getPersonBreadCrump } from '../DetailWindow/breadCrump.helpers';

type Props = {
    account?: Components.Schemas.ApplicationUserDTO;
    person: Components.Schemas.PersonDTO;
    t: TFunction;
    disabled: boolean;
};
export const AccountWindow = ({ account, t, disabled, person }: Props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [copiedString, setCopiedString] = useState<string>();
    const [userForPWReset, setUserForPWReset] = useState<Components.Schemas.ApplicationUserDTO>();
    const getNewAccount = (): Components.Schemas.ApplicationUserDTO => ({
        email: person?.eMail ?? '',
        firstName: person?.firstName,
        lastName: person?.lastName,
        token: '',
        endCustomerId: user?.endCustomerId,
        personId: person.id,
    });

    const {
        createRequest,
        deleteRequest,
        sync,
        data: createdUser,
    } = useGenericAPI<Components.Schemas.ApplicationUserDTO>(ACCOUNT_BASE_URL, person.applicationUser?.id);

    const createUser = () => {
        createRequest(getNewAccount())
            .then(() => {
                setModalIsOpen(true);
            })
            .catch(() => {
                showErrorToast('Cant create user');
            });
    };

    const { user } = useContext(UserContext);

    const getRegisterPath = (mail?: string | null, token?: string | null) => {
        if (mail && token) {
            return `${APP_BASE_URL}${AppBase}/register?email=${mail}&token=${token}`;
        }
        return '';
    };

    const copyClip = (clip?: string | null) => {
        if (clip) navigator.clipboard.writeText(clip);
        setCopiedString(clip ?? undefined);
    };

    const resetPW = () => {
        if (person.eMail) {
            getAPIRequest<Components.Schemas.ApplicationUserDTO>(person.eMail, '/Account/ResetPassword')
                .then((data) => {
                    setUserForPWReset(data);
                    setModalIsOpen(true);
                })
                .catch(() => {
                    showErrorToast('Cant restet PW');
                });
        }
    };

    const delteAccount = () => {
        deleteRequest()
            .then(() => {
                sync();
            })
            .catch(() => {
                showErrorToast('Cant delete user');
            });
    };
    return (
        <ProRow className="ProAttrField__dependencies--box">
            <ProCol span={11}>
                <ProAttrField value={account?.email ?? 'not connected'} />
            </ProCol>
            <ProCol span={1} direction="row" className="ProAttrField__dependencies__buttons">
                {!account ? (
                    <ProIconButton
                        icon={ProIconDataButtons.add_xs}
                        size={ProSize.XSmall}
                        mainIntent={ProIntent.Success}
                        onClick={createUser}
                        tooltip={
                            !(person.eMail && person.firstName && person.lastName)
                                ? 'Please fill mail, first and last name'
                                : t('buttons.inviteUser')
                        }
                        disabled={disabled || !(person.eMail && person.firstName && person.lastName)}
                    />
                ) : (
                    <>
                        <ProIconButton
                            icon={ProIconDataButtons.pen_s}
                            size={ProSize.XSmall}
                            mainIntent={ProIntent.Edit}
                            onClick={resetPW}
                            tooltip={'Reset Password'}
                            disabled={disabled}
                        />
                        <ProIconButton
                            icon={ProIconDataButtons.bin_s}
                            size={ProSize.XSmall}
                            mainIntent={ProIntent.Error}
                            onClick={delteAccount}
                            tooltip={'Delte user'}
                            disabled={disabled}
                        />
                    </>
                )}
            </ProCol>
            <ProDialog
                breadCrump={[getPersonBreadCrump(person), { name: 'Account' }]}
                icon={ProIconData.person_fill}
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
                title="Account"
            >
                {createdUser && (
                    <CreatedUserWindowContent
                        copiedString={copiedString}
                        copyClip={copyClip}
                        createdUser={createdUser}
                        getRegisterPath={getRegisterPath}
                        person={person}
                        t={t}
                    />
                )}
                {userForPWReset && (
                    <WindowContentForPWReset
                        copiedString={copiedString}
                        copyClip={copyClip}
                        getRegisterPath={getRegisterPath}
                        person={person}
                        t={t}
                        userForPWReset={userForPWReset}
                    />
                )}
            </ProDialog>
        </ProRow>
    );
};

const CreatedUserWindowContent = ({
    createdUser,
    person,
    t,
    copiedString,
    copyClip,
    getRegisterPath,
}: {
    t: TFunction;
    person: Components.Schemas.PersonDTO;
    createdUser: Components.Schemas.ApplicationUserDTO;
    copiedString: string | undefined;
    copyClip: (clip?: string | null) => void;
    getRegisterPath: (mail?: string | null, token?: string | null) => string;
}) => (
    <>
        <ProRow spacer>
            <ProCol span={12}>
                {t('information.portfolioMan.account.newAccountInfoText', {
                    mail: person.eMail,
                    url: `${APP_BASE_URL}${AppBase}/register`,
                })}
            </ProCol>
        </ProRow>
        <>
            <ProRow>
                <ProCol span={12}>
                    <ProAttrField value={createdUser.passwordResetToken} name="Token" />
                    <ProButton
                        text={
                            copiedString === createdUser.passwordResetToken
                                ? t('information.portfolioMan.account.tokenCopied')
                                : t('information.portfolioMan.account.copyTokenToClipboad')
                        }
                        leftIcon={
                            copiedString === createdUser.passwordResetToken
                                ? ProIconDataButtons.save_s
                                : ProIconDataButtons.pen_s
                        }
                        onClick={() => copyClip(createdUser.passwordResetToken)}
                        mainIntent={
                            copiedString === createdUser.passwordResetToken ? ProIntent.Success : ProIntent.Primary
                        }
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={12}>
                    <ProAttrField
                        value={getRegisterPath(person.eMail, createdUser.passwordResetToken)}
                        name={t('information.portfolioMan.account.token')}
                    />
                    <ProButton
                        text={
                            copiedString === getRegisterPath(person.eMail, createdUser.passwordResetToken)
                                ? t('information.portfolioMan.account.urlCopied')
                                : t('information.portfolioMan.account.copyURLToClipboad')
                        }
                        leftIcon={
                            copiedString === getRegisterPath(person.eMail, createdUser.passwordResetToken)
                                ? ProIconDataButtons.save_s
                                : ProIconDataButtons.pen_s
                        }
                        onClick={() => copyClip(getRegisterPath(person.eMail, createdUser.passwordResetToken))}
                        mainIntent={
                            copiedString === getRegisterPath(person.eMail, createdUser.passwordResetToken)
                                ? ProIntent.Success
                                : ProIntent.Primary
                        }
                    />
                </ProCol>
            </ProRow>
        </>
    </>
);

const WindowContentForPWReset = ({
    person,
    t,
    userForPWReset,
    copiedString,
    copyClip,
    getRegisterPath,
}: {
    t: TFunction;
    person: Components.Schemas.PersonDTO;
    userForPWReset: Components.Schemas.ApplicationUserDTO;
    copiedString: string | undefined;
    copyClip: (clip?: string | null) => void;
    getRegisterPath: (mail?: string | null, token?: string | null) => string;
}) => (
    <>
        <ProRow spacer>
            <ProCol span={12}>
                {t('information.portfolioMan.account.pwResetInfoText', {
                    mail: person.eMail,
                    url: `${APP_BASE_URL}${AppBase}/register`,
                })}
            </ProCol>
        </ProRow>
        <ProRow>
            <ProCol span={12}>
                <ProAttrField value={userForPWReset.passwordResetToken} name="Token" />
                <ProButton
                    text={
                        copiedString === userForPWReset.passwordResetToken
                            ? t('information.portfolioMan.account.tokenCopied')
                            : t('information.portfolioMan.account.copyTokenToClipboad')
                    }
                    leftIcon={
                        copiedString === userForPWReset.passwordResetToken
                            ? ProIconDataButtons.save_s
                            : ProIconDataButtons.pen_s
                    }
                    onClick={() => copyClip(userForPWReset.passwordResetToken)}
                    mainIntent={
                        copiedString === userForPWReset.passwordResetToken ? ProIntent.Success : ProIntent.Primary
                    }
                />
            </ProCol>
        </ProRow>
        <ProRow>
            <ProCol span={12}>
                <ProAttrField
                    value={getRegisterPath(person.eMail, userForPWReset.passwordResetToken)}
                    name={t('information.portfolioMan.account.token')}
                />
                <ProButton
                    text={
                        copiedString === getRegisterPath(person.eMail, userForPWReset.passwordResetToken)
                            ? t('information.portfolioMan.account.urlCopied')
                            : t('information.portfolioMan.account.copyURLToClipboad')
                    }
                    leftIcon={
                        copiedString === getRegisterPath(person.eMail, userForPWReset.passwordResetToken)
                            ? ProIconDataButtons.save_s
                            : ProIconDataButtons.pen_s
                    }
                    onClick={() => copyClip(getRegisterPath(person.eMail, userForPWReset.passwordResetToken))}
                    mainIntent={
                        copiedString === getRegisterPath(person.eMail, userForPWReset.passwordResetToken)
                            ? ProIntent.Success
                            : ProIntent.Primary
                    }
                />
            </ProCol>
        </ProRow>
    </>
);
