import { Tooltip2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import React from 'react';
import { ProIconType } from '../../constants/types';
import { ProIntent, ProSize } from '../../utils/enums';

export const getIconPixelSize = (size: ProSize): number => {
    switch (size) {
        case ProSize.XSmall:
            return 12;
        case ProSize.Small:
            return 18;
        case ProSize.Medium:
            return 24;
        case ProSize.Large:
            return 36;
        case ProSize.XLarge:
            return 48;
        default:
            return 24;
    }
};

type Props = {
    icon: ProIconType;
    size?: ProSize;
    className?: string;
    intent?: ProIntent;
    customSize?: string;
    hoverIntent?: ProIntent;
    onClick?: () => void;
    tooltip?: string;
};

export const ProIcon = ({
    icon,
    size = ProSize.Medium,
    className,
    intent = ProIntent.Primary,
    customSize,
    hoverIntent,
    onClick,
    tooltip,
}: Props) => {
    const getSVG = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={customSize ?? getIconPixelSize(size) + 'px'}
            height={customSize ?? getIconPixelSize(size) + 'px'}
            className={classNames(
                'ProIcon ',
                className,
                `ProIcon--fill-${intent}`,
                hoverIntent && `ProIcon--hover-${hoverIntent}`
            )}
            viewBox={`0 0 ${icon.height} ${icon.width} `}
            onClick={onClick}
        >
            <path vectorEffect="non-scaling-stroke" d={icon.d} />
        </svg>
    );

    if (tooltip)
        return (
            <Tooltip2
                content={tooltip}
                className="ProButton-Tooltip"
                usePortal
                lazy
                hoverCloseDelay={300}
                hoverOpenDelay={300}
                placement="top"
            >
                {getSVG}
            </Tooltip2>
        );

    return getSVG;
};
