import classNames from 'classnames';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ProHeaderAttrField } from '../../../../atoms/AttrField/AttrField';
import { ProButton } from '../../../../atoms/Button/ProButton';
import { ThresholdCriteriaList } from '../../../../atoms/CriteriaList/CriteriaList';
import { ProRow } from '../../../../atoms/Grid/Row';
import { ProIconData, ProIconDataButtons, ProIconTypeData } from '../../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../../components/DetailWindow/DetailWindow';
import { ProTab } from '../../../../components/DetailWindow/DetailWindowTab';
import { ApplicationElementType, getBaseBCItemForType } from '../../../../components/DetailWindow/breadCrump.helpers';
import { SelectionContext, SelectionType } from '../../../../contexts/SelectionContext';
import { UserContext } from '../../../../contexts/UserContext';
import { DetailWindowProps } from '../../../../modules/Windows/GenericDetailWindow';
import { getWindowDisplayStateMode } from '../../../../modules/Windows/WBSElement/WBSElement.helpers';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { Components } from '../../../../utils/types';
import { isReqNotBacklog } from '../components/RequirementTableNameCell';
import { ReqDetailWindowAnalyseTab } from './ReqDetailWindowAnalyseTab';
import { ReqDetailWindowBaseTab } from './ReqDetailWindowBaseTab';
import { ReqDetailsWindowDetailsTab } from './ReqDetailsWindowDetailsTab';
import { RequirementDetailWindowType } from './RequirementDetailsFrame';

export const RequirementDetailWindow = ({
    createAction,
    deleteAction,
    formData,
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    error,
    cancelChanges,
    closeAction,
    t,
    internalSync,
    externalSync,
    type,
}: DetailWindowProps<Components.Schemas.RequirementDTO> & { type: RequirementDetailWindowType }) => {
    const { portfolio } = useContext(UserContext);
    const readyCiteria = formData?.taskLists?.find((cur) => cur.tasklistType === 'ReadyCiteria');
    const acceptanceCiteria = formData?.taskLists?.find((cur) => cur.tasklistType === 'AcceptanceCiteria');

    const RequirementTypes = ['Epic', 'UserStory', 'Bug', 'Specification', 'ChangeRequest'];

    const canHasRequirements = ['UserStory', 'ChangeRequest', 'CustomerDefined'];
    const hasRequirements = canHasRequirements.includes(formData?.requirementType ?? '');
    const { push: navigate } = useHistory();

    const { selectionMode, setSelectionMode, setRenderSelectionButton, setSelectedElementId, turnSelectionOff } =
        useContext(SelectionContext);

    const selectionAction = () => {
        setSelectedElementId(formData?.id);
        setSelectionMode(SelectionType.WBSElement);

        setRenderSelectionButton(() => (elm: Components.Schemas.RequirementDTO) => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        setFormData((cur) => ({ ...cur, wbsElement: elm }));
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
    };

    const move = () => {
        setSelectionMode(SelectionType.Move);
        setRenderSelectionButton(() => (elm: Components.Schemas.RequirementDTO) => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        saveAction({ ...formData, parentId: elm.id });
                        externalSync && externalSync();
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
    };
    const notComplete = readyCiteria?.tasks?.filter((cur) => cur.degreeOfCompletion !== 1).length !== 0;

    if (!formData) return <></>;

    return (
        <ProDetailWindow
            title={
                isDisabled ? (
                    <h2
                        className={classNames(
                            formData?.name?.length &&
                                formData?.name?.length >= 180 &&
                                'ProDetailWindow--header--title__large'
                        )}
                    >
                        {formData?.name ?? t('windows.details')}
                    </h2>
                ) : (
                    <ProHeaderAttrField
                        name={t('windows.name')}
                        value={formData?.name ?? String(t('windows.details'))}
                        disabled={false}
                        handleChange={(input) =>
                            setFormData((old) => ({ ...old, name: String(input).substring(0, 180) }))
                        }
                    />
                )
            }
            displayState={isDisplayed}
            icon={ProIconData.scope_fill}
            buttonSection={
                <>
                    {(type === RequirementDetailWindowType.Grooming ||
                        type === RequirementDetailWindowType.Planning) && (
                        <ProButton
                            mainIntent={ProIntent.PrimaryLight}
                            onClick={() => navigate(`/app/teams/requirements/${formData?.id}`)}
                            text={'Go to element'}
                            hoverIntent={ProIntent.Secondary}
                            rightIcon={ProIconDataButtons.arrow_right_s}
                        />
                    )}
                    <WindowButtonsForMode
                        cancelAction={cancelChanges}
                        closeAction={closeAction}
                        createAction={createAction}
                        deleteAction={deleteAction}
                        mode={mode}
                        setMode={setMode}
                        saveAction={() => saveAction(formData)}
                        t={t}
                        move={
                            type === RequirementDetailWindowType.Default && isReqNotBacklog(formData) ? move : undefined
                        }
                        moveModeOn={selectionMode === SelectionType.Move}
                        cancelMove={() => setSelectionMode(undefined)}
                    />
                </>
            }
            intent={getWindowDisplayStateMode(mode)}
            error={mode !== 'new' ? undefined : error}
            loading={loading}
            breadCrump={getBaseBCItemForType([ApplicationElementType.Requirements], t)}
        >
            {type === RequirementDetailWindowType.Grooming && (
                <ProTab name="Grooming">
                    <ProRow spacer className="ReadyRow">
                        {/* // TODO: SP feld */}
                        If all criteria are done set it to:
                        <ProButton
                            text="Ready"
                            leftIcon={ProIconTypeData.Ready}
                            disabled={notComplete}
                            onClick={() => {
                                const ready = portfolio?.requirementStates?.find((cur) => cur.name === 'ready');
                                // setFormData((cur) => ({ ...cur, requirementState: ready }));
                                saveAction({ ...formData, requirementState: ready });
                            }}
                        />
                    </ProRow>
                    <ProRow spacer>
                        <ThresholdCriteriaList
                            cirteria={readyCiteria}
                            sync={internalSync}
                            name={t('windows.readyCeriteria')}
                        />
                    </ProRow>
                </ProTab>
            )}

            <ProTab name={t('windows.tabs.base')}>
                <ReqDetailsWindowDetailsTab
                    RequirementTypes={RequirementTypes}
                    formData={formData}
                    isDisabled={isDisabled}
                    setFormData={setFormData}
                    t={t}
                    portfolio={portfolio}
                />
            </ProTab>

            <ProTab name={t('windows.tabs.details')}>
                <ReqDetailWindowBaseTab
                    formData={formData}
                    internalSync={internalSync}
                    isDisabled={isDisabled}
                    selectionAction={selectionAction}
                    selectionMode={selectionMode}
                    t={t}
                    portfolio={portfolio}
                />
            </ProTab>
            <ProTab name={t('windows.tabs.analyse')}>
                <ReqDetailWindowAnalyseTab
                    formData={formData}
                    isDisabled={isDisabled}
                    setFormData={setFormData}
                    t={t}
                />
            </ProTab>

            {hasRequirements &&
                (type === RequirementDetailWindowType.Default || type === RequirementDetailWindowType.Planning) && (
                    <ProTab name={t('windows.tabs.criteria')}>
                        <>
                            <ProRow spacer>
                                <ThresholdCriteriaList
                                    cirteria={readyCiteria}
                                    sync={internalSync}
                                    name={t('windows.readyCeriteria')}
                                />
                            </ProRow>
                            <ProRow>
                                <ThresholdCriteriaList
                                    cirteria={acceptanceCiteria}
                                    name={t('windows.accaptanceCeriteria')}
                                    sync={internalSync}
                                />
                            </ProRow>
                        </>
                    </ProTab>
                )}
            {hasRequirements && type === RequirementDetailWindowType.Grooming && (
                <ProTab name={t('windows.tabs.accaptance')}>
                    <ProRow>
                        <ThresholdCriteriaList
                            cirteria={acceptanceCiteria}
                            name={t('windows.accaptanceCeriteria')}
                            sync={internalSync}
                        />
                    </ProRow>
                </ProTab>
            )}
        </ProDetailWindow>
    );
};
