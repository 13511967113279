import { Popover2 } from '@blueprintjs/popover2';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIconData, ProIconDataButtons } from '../Icons/IconData';
import { ProButton } from './ProButton';
import { ProIconButton } from './ProIconButton';

type DeleteButtonProps = {
    className?: string;
    minimal?: boolean;
    disabled?: boolean;
    onClick: () => void;
    inline?: boolean;
    message?: string;
};

export const ProDeleteButton = ({
    className,
    onClick,
    disabled,
    minimal = false,
    inline = false,
    message,
}: DeleteButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const action = () => {
        setIsOpen(false);
        onClick && onClick();
    };

    const renderPopoverContent = (
        <>
            <h3>{t('windows.deleteElementConfirmation')}</h3>
            {message && <h3 className="ProButton__deleteMessage">{message}</h3>}
            <ProButton
                className={className}
                text={t('buttons.cancel')}
                onClick={() => setIsOpen(false)}
                disabled={disabled}
                mainIntent={ProIntent.Light}
                lineIntent={ProIntent.Primary}
                leftIcon={ProIconData.close_stroke}
            />
            <ProButton
                className={className}
                text={t('buttons.delete')}
                onClick={action}
                disabled={disabled}
                leftIcon={ProIconDataButtons.bin_s}
                mainIntent={ProIntent.Error}
            />
        </>
    );
    return (
        <Popover2
            canEscapeKeyClose
            content={renderPopoverContent}
            minimal={false}
            isOpen={isOpen}
            popoverClassName="ProPopover"
        >
            {minimal ? (
                <ProIconButton
                    icon={ProIconDataButtons.bin_xs}
                    onClick={() => setIsOpen(true)}
                    tooltip={t('buttons.delete')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    disabled={disabled}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
            ) : (
                <ProIconButton
                    className={className}
                    onClick={() => setIsOpen(true)}
                    disabled={disabled}
                    icon={ProIconDataButtons.bin_s}
                    tooltip={t('buttons.delete')}
                    mainIntent={inline ? ProIntent.Transpared : ProIntent.PrimaryLight}
                    hoverIntent={ProIntent.Error}
                    lineIntent={inline ? ProIntent.Error : undefined}
                    placement="bottom"
                />
            )}
        </Popover2>
    );
};
