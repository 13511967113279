import { TFunction } from 'i18next';
import React, { SetStateAction } from 'react';
import { ProAttrField, ProTextField } from '../../../../atoms/AttrField/AttrField';
import { ProDropdown } from '../../../../atoms/AttrField/Dropdown';
import { ProNumberField } from '../../../../atoms/AttrField/NumberField';
import { ProObjectDropdown } from '../../../../atoms/AttrField/ProObjectDropdown';
import { ProCol } from '../../../../atoms/Grid/Col';
import { ProRow } from '../../../../atoms/Grid/Row';
import { getPersonsName } from '../../../../utils/helpers';
import { Components } from '../../../../utils/types';
import { getReqStatusIcon, isReqNotBacklog, isReqOrSimilar } from '../components/RequirementTableNameCell';
import { RequirementsResponsiblePersonField } from './RequirementsResponsiblePersonField';
import { orderReqStates } from './requirementHelpers';

type Props = {
    t: TFunction;
    formData: Components.Schemas.RequirementDTO;
    isDisabled: boolean;
    RequirementTypes: string[];
    setFormData: (value: SetStateAction<Components.Schemas.RequirementDTO | undefined>) => void;
    portfolio?: Components.Schemas.PortfolioDTO;
};

export const ReqDetailsWindowDetailsTab = ({
    t,
    formData,
    isDisabled,
    RequirementTypes,
    setFormData,
    portfolio,
}: Props) => (
    <>
        <ProRow>
            <ProCol span={12} verticalAlign="bottom">
                <ProRow>
                    <ProCol span={4}>
                        <ProDropdown
                            name={t('windows.type')}
                            value={formData?.requirementType}
                            disabled={isDisabled || !isReqNotBacklog(formData)}
                            items={RequirementTypes}
                            setValue={(value) => {
                                setFormData((cur) => ({
                                    ...cur,
                                    requirementType: value as Components.Schemas.RequirementType,
                                }));
                            }}
                        />
                    </ProCol>
                    <ProCol span={4} block>
                        <RequirementsResponsiblePersonField
                            data={formData}
                            isDisabled={isDisabled}
                            setData={setFormData}
                            type="responsible"
                        />
                    </ProCol>
                    <ProCol span={4} block>
                        <ProAttrField
                            name={t('windows.reporter')}
                            value={getPersonsName(t, formData.reportingPerson)}
                        />
                    </ProCol>
                </ProRow>
            </ProCol>
        </ProRow>
        <ProRow spacer>
            <ProCol span={12} verticalAlign="bottom">
                <ProRow>
                    <ProCol span={6}>
                        <RequirementsResponsiblePersonField
                            data={formData}
                            isDisabled={isDisabled}
                            setData={setFormData}
                            type="contact"
                        />
                    </ProCol>
                    {!isReqOrSimilar(formData) ? (
                        <ProCol span={3}>
                            <ProNumberField
                                name={t('windows.completion')}
                                disabled={isDisabled || !isReqOrSimilar(formData)}
                                entity="%"
                                value={formData?.degreeOfCompletion}
                                handleChange={(val) =>
                                    setFormData((cur) => ({ ...cur, degreeOfCompletion: Number(val) }))
                                }
                                max={100}
                                min={0}
                                entityLengh="s"
                                accuracy={0}
                                stepSize={25}
                            />
                        </ProCol>
                    ) : (
                        <>
                            <ProCol span={3}>
                                <ProObjectDropdown
                                    name={'State'}
                                    value={formData.requirementState}
                                    valueSelector={'name'}
                                    setValue={(val) => setFormData((cur) => ({ ...cur, requirementState: val }))}
                                    items={orderReqStates(portfolio?.requirementStates)}
                                    disabled={isDisabled}
                                />
                            </ProCol>
                            <ProCol span={1} verticalAlign="center" customSpan="5%">
                                {getReqStatusIcon(formData.requirementType, formData.requirementState?.name)}
                            </ProCol>
                        </>
                    )}
                    <ProCol span={3}>
                        <ProObjectDropdown
                            name={'Priority'}
                            value={formData.requirementPriorityState}
                            valueSelector={'name'}
                            setValue={(val) => setFormData((cur) => ({ ...cur, requirementPriorityState: val }))}
                            items={portfolio?.requirementPriorityStates}
                            disabled={isDisabled}
                        />
                    </ProCol>
                </ProRow>
            </ProCol>
        </ProRow>

        <ProRow spacer>
            <ProCol span={12}>
                <ProTextField
                    name={t('windows.description')}
                    value={formData?.description}
                    handleChange={(val) => setFormData((cur) => ({ ...cur, description: String(val) }))}
                    disabled={isDisabled}
                />
            </ProCol>
        </ProRow>
    </>
);
