import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ProDateField } from '../../../atoms/AttrField/DateField';
import { ProNumberField } from '../../../atoms/AttrField/NumberField';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { PreDecessorList } from '../../../components/PreProcessorList/PreProcessorList';
import { SuccessorList } from '../../../components/SuccessorList/SuccessorList';
import { ProIntent } from '../../../utils/enums';
import { Components } from '../../../utils/types';
import { WBSTimeConstrainField } from './atoms/WBSTimeConstrainField/WBSTimeConstrainField';

const getIntentForDelay = (elm: Components.Schemas.WBSElementDTO) => {
    if (elm.criticalTime) return ProIntent.Error;
};

type Props = {
    data: Components.Schemas.WBSElementDeltaDTO;
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>;
    isDisabled: boolean;
    syncElement: () => void;
};

export const TimeDetailWindowForcastTab = ({ setData, data, isDisabled }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <ProRow>
                <ProCol span={4}>
                    <ProDateField
                        name={t('windows.forcastedStart')}
                        valueString={data.startDate}
                        handleChange={(value) => setData((cur) => ({ ...cur, startDate: String(value) }))}
                        disabled={isDisabled}
                        intent={
                            data?.wbsElementDelta && data.constraintStartDate
                                ? getIntentForDelay(data?.wbsElementDelta)
                                : undefined
                        }
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProDateField
                        name={t('windows.plannedStart')}
                        valueString={data?.wbsElementDelta?.startDate}
                        handleChange={(value) => setData((cur) => ({ ...cur, wbsElementDelta: String(value) }))}
                        disabled={isDisabled}
                    />
                </ProCol>
                <ProCol span={1}></ProCol>
                <ProCol span={3}>
                    <ProNumberField
                        name={t('windows.startDeviation')}
                        value={data?.startDelay}
                        entity="days"
                        entityLengh="l"
                        disabled={isDisabled}
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={4}>
                    <ProDateField
                        name={t('windows.forcastedEnd')}
                        valueString={data?.endDate}
                        disabled={isDisabled}
                        intent={
                            data?.wbsElementDelta && data.constraintEndDate
                                ? getIntentForDelay(data?.wbsElementDelta)
                                : undefined
                        }
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProDateField
                        name={t('windows.plannedEnd')}
                        valueString={data?.wbsElementDelta?.endDate}
                        disabled={isDisabled}
                    />
                </ProCol>
                <ProCol span={1}></ProCol>
                <ProCol span={3}>
                    {/* // Todo: da fehlt noch das datum */}
                    <ProNumberField
                        name={t('windows.timeDeviation')}
                        value={'0'}
                        entityLengh="s"
                        entity="%"
                        disabled={isDisabled}
                    />
                </ProCol>
            </ProRow>
            <ProRow spacer>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.forcastedDur')}
                        value={data.duration}
                        handleChange={(value) => setData((cur) => ({ ...cur, duration: Number(value) }))}
                        disabled={isDisabled}
                        entity={t('entities.days')}
                        entityLengh="l"
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.plannedDuration')}
                        value={data?.wbsElementDelta?.duration}
                        entity="days"
                        entityLengh="l"
                        disabled={isDisabled}
                    />
                </ProCol>

                <ProCol span={3}></ProCol>
            </ProRow>

            <ProRow>
                <ProRow>
                    <WBSTimeConstrainField data={data} setData={setData} isDisabled={isDisabled} />
                    <ProCol span={2}></ProCol>
                    <ProCol span={8}>
                        <ProNumberField
                            name={t('windows.completion')}
                            value={data.degreeOfCompletion}
                            handleChange={(value) => setData((cur) => ({ ...cur, degreeOfCompletion: Number(value) }))}
                            disabled={isDisabled}
                            min={0}
                            max={100}
                            entity="%"
                            entityLengh="s"
                        />
                    </ProCol>
                </ProRow>
            </ProRow>
        </>
    );
};

export const TimeDetailWindowDependenciesTab = ({ setData, data, isDisabled, syncElement }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <ProRow spacer>
                <PreDecessorList
                    dependencies={data.targetingDependencies}
                    name={t('windows.predecessor')}
                    wbsElement={data}
                    syncElement={syncElement}
                    disabled={isDisabled}
                />
            </ProRow>

            <ProRow spacer>
                <SuccessorList dependencies={data.startingDependencies} name={t('windows.successor')} />
            </ProRow>
        </>
    );
};
