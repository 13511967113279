import React from 'react';
import { Imprint } from '../../atoms/Imprint/Imprint';
import { ProLogo } from '../../atoms/Logo/Logo';
import { InviteWindow } from './InviteWindow';
import { LoginWindowContent } from './LoginWindowContent';

export const RegisterWindow = () => (
    <div className="LoginWindow">
        <div className="LoginWindow__content">
            <ProLogo big className="LoginWindow__logo" />
            <div className="LoginWindow__window">
                <InviteWindow />
            </div>
        </div>
    </div>
);

export const LoginWindow = () => (
    <div className="LoginWindow">
        <div className="LoginWindow__content">
            <ProLogo big className="LoginWindow__logo" />
            <div className="LoginWindow__window">
                <LoginWindowContent />
            </div>
        </div>
        <Imprint className="login-window" />
    </div>
);
