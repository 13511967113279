import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

export enum SelectionType {
    Material = 'Material',
    Person = 'Person',
    HumanResource = 'HumanResource',
    WBSElement = 'WBSElement',
    WBSToReq = 'WBSToReq',
    WBSToWPTask = 'WBSToWPTask',
    Move = 'Move',
}

type SelectionContextType = {
    renderSelectionButton: ((elm: any) => JSX.Element) | undefined;
    setRenderSelectionButton: Dispatch<SetStateAction<((elm: any) => JSX.Element) | undefined>>;
    selectedElementId: string | undefined;
    setSelectedElementId: Dispatch<SetStateAction<string | undefined>>;
    contextId: string | undefined;
    setContextId: Dispatch<SetStateAction<string | undefined>>;
    selectionMode: SelectionType | undefined;
    setSelectionMode: Dispatch<SetStateAction<SelectionType | undefined>>;
    turnSelectionOff: () => void;
    pageTitle?: string;
    setPageTitle: (title?: string) => void;
};

export const SelectionContext = createContext<SelectionContextType>({} as unknown as SelectionContextType);

export const SelectionContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [selectionMode, setSelectionMode] = useState<SelectionType>();
    const [selectedElementId, setSelectedElementId] = useState<string>();
    const [renderSelectionButton, setRenderSelectionButton] = useState<(elm: any) => JSX.Element>();
    const [pageTitle, setPageTitle] = useState<string>();

    // used for human resource selection to set the team
    const [contextId, setContextId] = useState<string>();

    const turnSelectionOff = () => {
        setSelectionMode(undefined);
        setSelectedElementId(undefined);
        setPageTitle(undefined);
    };

    return (
        <SelectionContext.Provider
            value={{
                renderSelectionButton,
                setRenderSelectionButton,
                selectedElementId,
                setSelectedElementId,
                contextId,
                setContextId,
                selectionMode,
                setSelectionMode,
                turnSelectionOff,
                setPageTitle,
                pageTitle,
            }}
        >
            {children}
        </SelectionContext.Provider>
    );
};
