import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProSummary } from '../../../atoms/SummaryWindow/SummaryWindow';
import { Components } from '../../../utils/types';

export const MaterialSummaryWindow = ({
    materials,
}: {
    materials?: Components.Schemas.MaterialDefinitionDTO[] | null;
}) => {
    const { t } = useTranslation();
    return (
        <ProSummary.Window name={t('windows.summary')}>
            <ProSummary.Attr
                name={t('windows.totalMaterials')}
                icon={ProIconData.material_fill}
                val={materials?.length}
            />
        </ProSummary.Window>
    );
};
