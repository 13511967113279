import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { proLinkStructure } from '../modules/Menu/Navigate';
import { Components } from '../utils/types';

export const useMaterialDetailRouting = ({
    materialDefinitions,
    selectTableRow,
    portfolio,
    selectedTableRow,
}: {
    selectTableRow: (id: number) => void;
    materialDefinitions?: Components.Schemas.MaterialDefinitionDTO[] | null;
    portfolio?: Components.Schemas.PortfolioDTO;
    selectedTableRow?: number;
}) => {
    const route = useRouteMatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [error, setError] = useState<JSX.Element>();
    const path = proLinkStructure.portfolioManagement.baseData.material.link;

    useEffect(() => {
        const data = materialDefinitions && portfolio ? [portfolio, ...materialDefinitions] : undefined;
        const selectedElementId = data && selectedTableRow ? data[selectedTableRow].id : undefined;

        if (
            data &&
            id &&
            id !== '00000000-0000-0000-0000-000000000000' &&
            selectedElementId !== id &&
            route.path.includes(path)
        ) {
            const newRow = data?.findIndex((cur) => cur && cur.id === id);

            if (newRow && newRow >= 0) {
                selectTableRow(newRow);
            } else {
                setError(<>Sorry, no element with this id found</>);
            }
        }
    }, [id, portfolio, materialDefinitions]);

    useEffect(() => {
        const data = materialDefinitions && portfolio ? [portfolio, ...materialDefinitions] : undefined;
        const selectedElementId = data && selectedTableRow ? data[selectedTableRow].id : undefined;

        if (id !== selectedElementId && selectedElementId && route.path.includes(path)) {
            const newPath =
                route.path === path
                    ? `${path}/${selectedElementId}`
                    : generatePath(route.path, { id: selectedElementId });

            history.push({
                pathname: newPath,
            });
        }
        if (data && !selectedElementId && route.path.includes(path)) {
            history.push({
                pathname: path,
            });
        }
    }, [selectedTableRow, materialDefinitions, portfolio]);

    return error;
};

export const usePersonDetailRouting = ({
    persons,
    selectTableRow,
    selectedPerson,
}: {
    selectTableRow: (id: number) => void;
    persons?: Components.Schemas.PersonDTO[] | null;
    selectedPerson?: Components.Schemas.PersonDTO;
}) => {
    const route = useRouteMatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [error, setError] = useState<JSX.Element>();
    const link = proLinkStructure.portfolioManagement.people.link;
    const path = proLinkStructure.portfolioManagement.people.path;

    // select person if rout changes
    useEffect(() => {
        if (id && id !== '00000000-0000-0000-0000-000000000000' && route.path.includes(path)) {
            const newRow = persons?.findIndex((cur) => cur && cur.id === id);

            if (newRow !== undefined && newRow >= 0) {
                selectTableRow(newRow + 1);
            } else {
                setError(<>Sorry, no element with this id found</>);
            }
        }
    }, [id, persons]);

    // set new route if person gets selected
    useEffect(() => {
        if (selectedPerson && route.path.includes(path) && selectedPerson.id !== id) {
            const newPath =
                route.path === path
                    ? `${link}/${selectedPerson.id}`
                    : generatePath(route.path, { id: selectedPerson.id });

            history.push({
                pathname: newPath,
            });
        }

        // if (!selectedPerson && route.path.includes(path)) {
        //     history.push({
        //         pathname: link,
        //     });
        // }
    }, [selectedPerson, persons]);

    return error;
};

type DetailRoutingProps<T> = {
    selectElement: (id: string) => void;
    link: string;
    path: string;
    data?: T[] | null;
    selectedElement?: T;
};

export const useDetailRouting = ({
    data,
    selectElement,
    selectedElement,
    link,
    path,
}: DetailRoutingProps<Components.Schemas.PersonDTO>) => {
    const route = useRouteMatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [error, setError] = useState<JSX.Element>();

    // select person if rout changes
    useEffect(() => {
        if (id && id !== '00000000-0000-0000-0000-000000000000' && route.path.includes(path)) {
            const newRow = data?.findIndex((cur) => cur && cur.id === id);
            const elm = data?.find((cur) => cur && cur.id === id);

            if (newRow !== undefined && newRow >= 0) {
                selectElement(elm?.id!);
            } else {
                // setError(<>Sorry, no element with this id found</>);
            }
        }
    }, [id, data]);

    // set new route if person gets selected
    useEffect(() => {
        if (route.path.includes(path)) {
            if (selectedElement && selectedElement.id !== id) {
                const newPath =
                    route.path === path
                        ? `${link}/${selectedElement.id}`
                        : generatePath(route.path, { id: selectedElement.id });

                history.push({
                    pathname: newPath,
                });
            }
        }
    }, [selectedElement, data]);

    const setUrlEmpty = () => {
        history.push({
            pathname: path,
        });
    };

    return { error, setUrlEmpty };
};
