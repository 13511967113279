import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL, WBS_ELEMENT_BASE_URL } from '../../../../api/api';
import { useWBSElement } from '../../../../api/useWBSElement';
import { WindowButtonsForMode } from '../../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProTab } from '../../../../components/DetailWindow/DetailWindowTab';
import { WBSContext } from '../../../../contexts/WBSContext';
import { WBSSelectionContext, WBSSelectionMode } from '../../../../contexts/WBSSelectionContext';
import { Components } from '../../../../utils/types';

import { CostDetailWindowAllocationTab, CostDetailWindowForecastTab } from '../CostDetailWindowContent';
import { QualityDetailWindowAllocationTab } from '../Quality/QualityDetailWindowAllocationTab';
import { QualityDetailWindowAnalysisTab } from '../Quality/QualityDetailWindowAnalysisTab';
import { WBSDetailWindowContentTab } from '../Quality/QualityDetailWindowContentTab';
import { TimeDetailWindowDependenciesTab, TimeDetailWindowForcastTab } from '../TimeDetailWindowContent';
import { WindowDisplayState, getWindowDisplayStateMode } from '../WBSElement.helpers';
import { WBSDetailWindow } from './WBSDetailWindow';

export const duplicateWBSElement = async (element: Components.Schemas.WBSElementDTO) => {
    const jwtObj = localStorage.getItem('auth');

    try {
        const res = await axios.post<Components.Schemas.WBSElementDTO>(
            `${API_BASE_URL}${WBS_ELEMENT_BASE_URL}/${element.id}`,
            { copyHierachry: true },
            {
                headers: { Authorization: `Bearer ${jwtObj}` },
            }
        );
        return res;
    } catch (e) {
        throw e;
    }
};

export const useWBSDetailWindow = (
    aspect: 'Time' | 'Cost' | 'Quality',
    deselectWBSElement: () => void,
    WBSElement?: Components.Schemas.WBSElementDTO,
    externalSync?: () => void
) => {
    const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
    const [mode, setMode] = useState<WindowDisplayState>('view');
    const { t } = useTranslation();

    const { error, data, loading, deleteWBSElement, updateWBSElement, createWBSElement, clear, sync } = useWBSElement(
        WBSElement,
        mode === 'new'
    );

    const { selectedWBSStructure } = useContext(WBSContext);
    const { setSelectionMode, setSelectionFunction, turnSelectionModeOff, selectionMode } =
        useContext(WBSSelectionContext);
    const [formData, setFormData] = useState<Components.Schemas.WBSElementDTO | undefined>(data);

    useEffect(() => {
        data && setFormData(data);
    }, [data, WBSElement, mode]);

    const open = (newMode: WindowDisplayState, state?: boolean) => {
        if (state !== undefined) {
            setIsDisplayed(state);
        } else {
            setIsDisplayed(true);
        }
        setMode(newMode);
        if (newMode === 'new') clear();
    };

    useEffect(() => {
        open('view', false);
    }, [selectedWBSStructure]);

    const saveAction = () => {
        if (formData) {
            updateWBSElement(formData).then(() => {
                setMode('view');
                externalSync && externalSync();
            });
        }
    };

    const createAction = () => {
        if (formData)
            createWBSElement(formData).then(() => {
                setMode('view');
                externalSync && externalSync();
            });
    };

    const deleteAction = () => {
        if (formData)
            deleteWBSElement().then(() => {
                setIsDisplayed(false);
                externalSync && externalSync();
            });
    };

    const closeAction = () => {
        deselectWBSElement();
        setIsDisplayed(false);
        setMode('view');
    };

    const duplicateAction = () => {
        data && duplicateWBSElement(data);
        externalSync && externalSync();
    };

    const move = () => {
        setSelectionMode(WBSSelectionMode.Move);
        setSelectionFunction(() => (elm: Components.Schemas.WBSElementDTO) => {
            if (formData) {
                updateWBSElement({ ...formData, parentId: elm.id }).then(() => {
                    turnSelectionModeOff();
                    externalSync && externalSync();
                });
            }
        });
    };

    const renderWBSDetailWindow = () => (
        <WBSDetailWindow
            error={error}
            loading={loading}
            data={formData}
            isDisplayed={isDisplayed}
            setData={setFormData}
            buttons={
                <WindowButtonsForMode
                    cancelAction={() => {
                        setMode('view');
                    }}
                    closeAction={closeAction}
                    createAction={createAction}
                    deleteAction={deleteAction}
                    saveAction={saveAction}
                    setMode={setMode}
                    mode={mode}
                    duplicate={duplicateAction}
                    move={move}
                    moveModeOn={selectionMode === WBSSelectionMode.Move}
                    cancelMove={turnSelectionModeOff}
                    t={t}
                />
            }
            intent={getWindowDisplayStateMode(mode)}
            isDisabled={mode === 'view'}
            t={t}
        >
            {formData && (
                <ProTab name={t('windows.tabs.content')}>
                    <WBSDetailWindowContentTab data={formData} isDisabled={mode === 'view'} setData={setFormData} />
                </ProTab>
            )}
            {formData && aspect === 'Time' && (
                <ProTab name={t('windows.tabs.forecast')}>
                    <TimeDetailWindowForcastTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        syncElement={sync}
                    />
                </ProTab>
            )}
            {formData && aspect === 'Time' && (
                <ProTab name={t('windows.tabs.dependencies')}>
                    <TimeDetailWindowDependenciesTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        syncElement={sync}
                    />
                </ProTab>
            )}
            {formData && aspect === 'Cost' && (
                <ProTab name={t('windows.tabs.forecast')}>
                    <CostDetailWindowForecastTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        syncElement={sync}
                    />
                </ProTab>
            )}
            {formData && aspect === 'Cost' && (
                <ProTab name={t('windows.tabs.allocation')}>
                    <CostDetailWindowAllocationTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        syncElement={sync}
                    />
                </ProTab>
            )}

            {formData && aspect === 'Quality' && (
                <ProTab name={t('windows.tabs.forecast')}>
                    <QualityDetailWindowAnalysisTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                    />
                </ProTab>
            )}
            {formData && aspect === 'Quality' && (
                <ProTab name={t('windows.tabs.assignment')}>
                    <QualityDetailWindowAllocationTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        selectedElementName={WBSElement?.name}
                    />
                </ProTab>
            )}
        </WBSDetailWindow>
    );
    return { open, renderWBSDetailWindow, isDisplayed, mode };
};
