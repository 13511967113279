import { TFunction } from 'i18next';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProSubElementAttrField } from '../../../../atoms/AttrField/WBSSubElementAttrField';
import { ProIconButton } from '../../../../atoms/Button/ProIconButton';
import { ProCol } from '../../../../atoms/Grid/Col';
import { ProRow } from '../../../../atoms/Grid/Row';
import { ProIconDataButtons } from '../../../../atoms/Icons/IconData';
import { ProFileList } from '../../../../components/FileList/FileList';
import { SelectionType } from '../../../../contexts/SelectionContext';
import { proLinkStructure } from '../../../../modules/Menu/Navigate';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { Components } from '../../../../utils/types';

type Props = {
    t: TFunction;
    formData: Components.Schemas.RequirementDTO;
    isDisabled: boolean;
    portfolio?: Components.Schemas.PortfolioDTO;
    selectionMode: SelectionType | undefined;
    selectionAction: () => void;
    internalSync: (() => void) | undefined;
};

export const ReqDetailWindowBaseTab = ({
    isDisabled,
    t,
    formData,
    selectionMode,
    portfolio,
    selectionAction,
    internalSync,
}: Props) => {
    const { push } = useHistory();
    return (
        <>
            <ProRow spacer>
                <ProSubElementAttrField
                    name={t('windows.wbsElement')}
                    isDisabled={isDisabled || selectionMode !== undefined}
                    isSelectionModeOn={selectionMode === SelectionType.WBSElement}
                    toggleSelectionMode={selectionAction}
                    values={[`${formData?.wbsElement?.name}`]}
                    getInlineButtons={() => (
                        <ProIconButton
                            tooltip={formData?.wbsElement ? 'go to element' : 'no element defined'}
                            icon={ProIconDataButtons.arrow_right_xs}
                            onClick={() => {
                                formData?.wbsElement &&
                                    push(
                                        `${proLinkStructure.projectManagement.quality.link}/${formData?.wbsElement?.id}`
                                    );
                            }}
                            size={ProSize.XSmall}
                            mainIntent={ProIntent.LightBlue}
                            disabled={!formData?.wbsElement}
                        />
                    )}
                    t={t}
                />
            </ProRow>

            <ProRow spacer>
                <ProCol span={12}>
                    <ProFileList
                        objectId={formData.id!}
                        files={formData.requirementDocuments}
                        portfolioId={portfolio?.id}
                        sync={internalSync}
                    />
                </ProCol>
            </ProRow>
        </>
    );
};
