import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PORTFOLIO_API_BASE, TEAM_POSITION_API_BASE } from '../../../api/api';
import { deleteAPIRequest, useGenericAPI } from '../../../api/useGenericApi';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { DetaiWindowWrapper } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { UserContext } from '../../../contexts/UserContext';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import { TeamPositionSummaryWindow } from '../../../modules/Windows/SummaryDetails/TeamPositionSummaryWindow';
import { TeamRoleDetailWindow } from '../../../modules/Windows/TeamRoleDetailWindow/TeamRoleDetailWindow';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';

export const getEmptyTeamRoleDTO = (portfolioId: string): Components.Schemas.TeamPositionDTO => ({
    name: 'Unnamed Position',
    description: '-',
    portfolioId: portfolioId,
});

export const useNewTeamRoleDetailWindow = ({
    closeAction,
    externalSync,
    roleId,
}: {
    roleId?: string;
    externalSync: () => void;
    closeAction: () => void;
}) => {
    const { user } = useContext(UserContext);
    const emptyItem = getEmptyTeamRoleDTO(user?.portfolios![0]?.id ?? '0');

    return useGenericDetailWindow({
        apiConstant: TEAM_POSITION_API_BASE,
        closeAction,
        emptyItem,
        genericDetailWindow: TeamRoleDetailWindow,
        externalSync,
        selectedItemId: roleId,
    });
};

export const TeamPositionsPage = () => {
    const { portfolio: initPortfolio } = useContext(UserContext);
    const { t } = useTranslation();

    const [selectedRole, setSelectedRole] = useState<Components.Schemas.TeamPositionDTO>();

    const deselectTableRow = () => {
        setSelectedTableRow(undefined);
        setSelectedRole(undefined);
        openPosition('hidden');
    };

    const {
        data: portfolio,
        error,
        loading,
        sync,
    } = useGenericAPI<Components.Schemas.PortfolioDTO>(PORTFOLIO_API_BASE, initPortfolio?.id);

    const { teamPositions } = portfolio ?? {};

    const {
        isDisplayed: positionIsDisplayed,
        mode: positionMode,
        open: openPosition,
        renderDetailWindow: renderTeamRoleDetailWindow,
    } = useNewTeamRoleDetailWindow({ closeAction: deselectTableRow, externalSync: sync, roleId: selectedRole?.id });
    const [selectedTableRow, setSelectedTableRow] = useState<number>();

    if (!teamPositions) {
        return <ProNoDataOrRightsPage message={[t('error.noData.teamRoles')]} loading={loading} />;
    }

    const selectTableRow = (i: number) => {
        setSelectedRole(teamPositions[i - 1]);
        openPosition('view', teamPositions[i - 1]);
        setSelectedTableRow(i);
    };

    const addPosition = () => {
        setSelectedRole(undefined);
        openPosition('new');
        setSelectedTableRow(undefined);
    };

    const deletePerson = (id: string) => {
        deleteAPIRequest<Components.Schemas.TeamPositionDTO>(id, TEAM_POSITION_API_BASE).then(() => {
            openPosition('hidden');
            sync();
        });
    };

    const peopleData: TableColData[] = [
        {
            name: t('table.cols.name'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable__levelBars "></div>
                        <div className="ProTable__level ProTable__level--open">
                            <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                        </div>
                        <span className="ProTable__name">
                            {t('table.cols.summaryIn')} {portfolio?.name ?? 'Portfolio'}
                        </span>
                    </>
                ) : (
                    <>
                        <div className="ProTable__levelBars ">
                            <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                        </div>
                        <span className="ProTable__name" onClick={() => selectTableRow(i)}>
                            <ProIcon size={ProSize.Small} icon={ProIconData.person_fill} />
                            {teamPositions[i - 1].name}
                        </span>
                    </>
                ),
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable--actionCell">
                            <ProButton
                                leftIcon={ProIconDataButtons.add_xs}
                                onClick={() => addPosition()}
                                mainIntent={ProIntent.Transpared}
                                hoverIntent={ProIntent.Success}
                                lineIntent={ProIntent.Success}
                                disabled={getHiddenForWindowDisplayState([positionMode])}
                                size={ProSize.XSmall}
                                text={t('buttons.add', { var: t('types.position') })}
                                placement="bottom"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <ActionCell
                            i={i}
                            selectTableRow={selectTableRow}
                            detailWindowMode={positionMode}
                            deleteAction={() => deletePerson(teamPositions[i - 1].id!)}
                        />
                    </>
                ),
            colSpace: 5,
        },
    ];

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.PortfolioMan, ApplicationElementType.BaseData], t)}
            name={t('navigation.portfolioMan.peopleAndPositions.positions')}
            error={error}
            loading={loading}
        >
            <ProTable
                data={peopleData}
                rowsCount={teamPositions.length + 1}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
            />

            <DetaiWindowWrapper hidden={[positionIsDisplayed]}>
                <TeamPositionSummaryWindow teamPositions={teamPositions} />
                {renderTeamRoleDetailWindow()}
            </DetaiWindowWrapper>
        </ProPage>
    );
};
