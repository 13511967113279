import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProHeaderAttrField } from '../../../atoms/AttrField/AttrField';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../components/DetailWindow/DetailWindow';
import { ProTab } from '../../../components/DetailWindow/DetailWindowTab';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProIntent } from '../../../utils/enums';
import { Components } from '../../../utils/types';
import { DetailWindowProps } from '../GenericDetailWindow';
import { CostDetailWindowAllocationTab, CostDetailWindowForecastTab } from '../WBSElement/CostDetailWindowContent';
import { QualityDetailWindowAllocationTab } from '../WBSElement/Quality/QualityDetailWindowAllocationTab';
import { QualityDetailWindowAnalysisTab } from '../WBSElement/Quality/QualityDetailWindowAnalysisTab';
import { WBSDetailWindowContentTab } from '../WBSElement/Quality/QualityDetailWindowContentTab';
import { TimeDetailWindowDependenciesTab, TimeDetailWindowForcastTab } from '../WBSElement/TimeDetailWindowContent';
import { getWindowDisplayStateMode } from '../WBSElement/WBSElement.helpers';

export const PersonalWBSDetailWindow = ({
    createAction,
    deleteAction,
    formData,
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    error,
    cancelChanges,
    closeAction,
    t,
}: DetailWindowProps<Components.Schemas.WBSElementDeltaDTO>) => {
    const { push: navigate } = useHistory();
    return (
        <ProDetailWindow
            title={
                mode === 'view' ? (
                    <h2
                        className={classNames(
                            formData?.name?.length &&
                                formData?.name?.length >= 180 &&
                                'ProDetailWindow--header--title__large'
                        )}
                    >
                        {formData?.name ?? t('windows.details')}
                    </h2>
                ) : (
                    <ProHeaderAttrField
                        name={t('windows.name')}
                        value={formData?.name ?? String(t('windows.details'))}
                        disabled={false}
                        handleChange={(input) =>
                            setFormData((old) => ({ ...old, name: String(input).substring(0, 180) }))
                        }
                    />
                )
            }
            displayState={isDisplayed}
            icon={ProIconData.team_fill}
            buttonSection={
                <>
                    <ProButton
                        mainIntent={ProIntent.PrimaryLight}
                        onClick={() => navigate(`/app/project/quality/${formData?.id}`)}
                        text={'Go to element'}
                        hoverIntent={ProIntent.Secondary}
                        rightIcon={ProIconDataButtons.arrow_right_s}
                    />
                    <WindowButtonsForMode
                        cancelAction={cancelChanges}
                        closeAction={closeAction}
                        createAction={createAction}
                        // deleteAction={deleteAction}
                        mode={mode}
                        setMode={setMode}
                        // saveAction={() => saveAction(formData)}
                        t={t}
                    />
                </>
            }
            intent={getWindowDisplayStateMode(mode)}
            error={mode !== 'new' ? undefined : error}
            loading={loading}
            breadCrump={getBaseBCItemForType([ApplicationElementType.WBSElement], t)}
        >
            {formData && (
                <ProTab name={t('windows.tabs.content')}>
                    <WBSDetailWindowContentTab data={formData} isDisabled={mode === 'view'} setData={setFormData} />
                </ProTab>
            )}
            {formData && (
                <ProTab name="Forcast">
                    <h3>Time</h3>
                    <TimeDetailWindowForcastTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        syncElement={() => {}}
                    />
                    <h3>Costs</h3>
                    <CostDetailWindowForecastTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        syncElement={() => {}}
                    />
                    <h3>Quality</h3>
                    <QualityDetailWindowAnalysisTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                    />
                </ProTab>
            )}
            {formData && (
                <ProTab name={t('windows.tabs.assignment')}>
                    <QualityDetailWindowAllocationTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        selectedElementName={formData?.name}
                    />
                </ProTab>
            )}
            {formData && (
                <ProTab name={t('windows.tabs.allocation')}>
                    <CostDetailWindowAllocationTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        syncElement={() => {}}
                    />
                </ProTab>
            )}
            {formData && (
                <ProTab name={t('windows.tabs.dependencies')}>
                    <TimeDetailWindowDependenciesTab
                        data={formData}
                        isDisabled={mode === 'view'}
                        setData={setFormData}
                        syncElement={() => {}}
                    />
                </ProTab>
            )}
        </ProDetailWindow>
    );
};
