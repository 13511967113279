import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProAttrField, ProTextField } from '../../../../atoms/AttrField/AttrField';
import { ProDropdown } from '../../../../atoms/AttrField/Dropdown';
import { ProCol } from '../../../../atoms/Grid/Col';
import { ProRow } from '../../../../atoms/Grid/Row';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import { Components } from '../../../../utils/types';

import { getCompletionState, mockWBSElementType } from '../WBSElement.helpers';
import { ResponsiblePersonFieldForQuality } from './ResponsiblePersonFieldForQuality';

type Props = {
    data: Components.Schemas.WBSElementDeltaDTO;
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>;
    isDisabled: boolean;
};

export const WBSDetailWindowContentTab = ({ setData, data, isDisabled }: Props) => {
    const { selectedProject } = useContext(ProjectContext);
    const { t } = useTranslation();

    return (
        <>
            <ProRow>
                <ProCol span={8}>
                    <ProDropdown
                        name={t('windows.projectElementType')}
                        value={String(data.wbsElementType)}
                        items={mockWBSElementType}
                        disabled={isDisabled}
                        setValue={(newVal) =>
                            setData((cur) => ({
                                ...cur,
                                wbsElementType: mockWBSElementType.find((val) => val === newVal),
                            }))
                        }
                    />
                </ProCol>
                <ProCol span={4} block>
                    <ProAttrField
                        name={t('windows.progressStatus')}
                        value={getCompletionState(data.wbsCompletionState?.completionStateType)}
                    />
                </ProCol>
            </ProRow>
            <ProRow spacer>
                <ProCol span={6}>
                    <ResponsiblePersonFieldForQuality data={data} isDisabled={isDisabled} setData={setData} />
                </ProCol>
                <ProCol span={6}>
                    <ProDropdown
                        name={t('windows.responsibleTeam')}
                        value={data.team?.name}
                        disabled={isDisabled}
                        items={selectedProject?.teams?.map((cur) => cur.name ?? '-')} //where do i get the teams from
                        setValue={(value) => {
                            setData((cur) => ({
                                ...cur,
                                team: selectedProject?.teams?.find((cur) => cur.name === value),
                            }));
                        }}
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={12}>
                    <ProTextField
                        name={t('windows.description')}
                        value={data?.description}
                        handleChange={(val) => setData((cur) => ({ ...cur, description: String(val) }))}
                        disabled={isDisabled}
                    />
                </ProCol>
            </ProRow>
        </>
    );
};
