import axios from 'axios';
import { useEffect, useState } from 'react';
import { showErrorToast } from '../utils/helpers';
import { Components } from '../utils/types';
import { API_BASE_URL } from './api';

export async function deleteAPIRequest<T>(id: string, apiConstant: string) {
    const jwtObj = localStorage.getItem('auth');
    try {
        const res = await axios.delete<T>(`${API_BASE_URL}${apiConstant}/${id}`, {
            headers: { Authorization: `Bearer ${jwtObj}` },
        });
        return res.data;
    } catch (e) {
        showErrorToast(`cant delete element - Error: ${e}`);
        throw e;
    }
}

export async function getAPIRequest<T>(apiConstant: string, id?: string | null) {
    const jwtObj = localStorage.getItem('auth');
    try {
        const res = await axios.get<T>(`${API_BASE_URL}${apiConstant}/${id}`, {
            headers: { Authorization: `Bearer ${jwtObj}` },
        });
        return res.data;
    } catch (e) {
        showErrorToast(`cant get element - Error: ${e}`);
        throw e;
    }
}

export async function getSimpleAPIRequest<T>(apiConstant: string) {
    const jwtObj = localStorage.getItem('auth');
    try {
        const res = await axios.get<T>(`${API_BASE_URL}${apiConstant}`, {
            headers: { Authorization: `Bearer ${jwtObj}` },
        });
        return res.data;
    } catch (e) {
        showErrorToast(`cant get element - Error: ${e}`);
        throw e;
    }
}

export async function updateAPIRequest<T>(newData: T, apiConstant: string, id: string) {
    const jwtObj = localStorage.getItem('auth');
    try {
        const res = await axios.put<T>(
            `${API_BASE_URL}${apiConstant}/${id}`,
            { ...newData },
            {
                headers: { Authorization: `Bearer ${jwtObj}` },
            }
        );
        return res.data;
    } catch (e) {
        showErrorToast(`cant update element - Error: ${e}`);
        throw e;
    }
}

export async function createAPIRequest<T>(newData: T, apiConstant: string) {
    const jwtObj = localStorage.getItem('auth');
    try {
        const res = await axios.post<T>(
            `${API_BASE_URL}${apiConstant}`,
            { ...newData },
            {
                headers: { Authorization: `Bearer ${jwtObj}` },
            }
        );
        return res.data;
    } catch (e) {
        showErrorToast(`cant create element - Error: ${e}`);
        throw e;
    }
}

export function useGenericAPI<T>(apiConstant: string, id?: string | null) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [data, setData] = useState<T | undefined>();

    const sync = async () => {
        if (id !== undefined) {
            setLoading(true);
            try {
                const res = id ? await getAPIRequest<T>(apiConstant, id) : await getSimpleAPIRequest<T>(apiConstant);
                setData(res as unknown as T);
                setError(undefined);
            } catch (e) {
                setError(String(e));
            } finally {
                setLoading(false);
            }
        } else {
            setData(undefined);
            setLoading(false);
            setError(undefined);
        }
    };

    useEffect(() => {
        sync();
    }, [id, apiConstant]);

    const updateRequest = async (newData: T) => {
        setLoading(true);
        setError(undefined);
        const data = newData as Components.Schemas.PersonDTO;
        try {
            const res = await updateAPIRequest<T>(newData, apiConstant, data.id!);
            setData(res as unknown as T);
            setLoading(false);
            setError(undefined);
        } catch (e) {
            setLoading(false);
            setError(String(e));
            throw e;
        }
    };

    const deleteRequest = async () => {
        if (id) {
            setLoading(true);
            setError(undefined);
            try {
                const res = await deleteAPIRequest<T>(id, apiConstant);
                setData(res as unknown as T);
                setLoading(false);
                setError(undefined);
            } catch (e) {
                setLoading(false);
                setError(String(e));
                throw e;
            }
        }
    };
    const createRequest = async (newData: T) => {
        setLoading(true);
        setError(undefined);
        try {
            const res = await createAPIRequest<T>(newData, apiConstant);
            setData(res as unknown as T);
            setLoading(false);
            setError(undefined);
            return res;
        } catch (e) {
            setLoading(false);
            setError(String(e));
            throw e;
        }
    };

    return { data, error, loading, createRequest, deleteRequest, updateRequest, sync };
}
