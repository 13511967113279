import { useContext, useMemo } from 'react';
import { HUMAN_RESOUCE_API_BASE } from '../../../../api/api';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import { UserContext } from '../../../../contexts/UserContext';
import { useGenericDetailWindow } from '../../../../modules/Windows/GenericDetailWindow';
import { HumanResourceDetailWindow } from '../../../../modules/Windows/HumanResourceDetailWindow/HumanResourceDetailWindow';
import { HumanResourceDTO } from '../Team.helpers';

export const useHumanResourceDetailFrame = (
    closeAction: () => void,
    id?: string,
    projectId?: string,
    teamId?: string,
    externalSync?: () => void
) => {
    const { portfolio } = useContext(UserContext);
    const { selectedProject } = useContext(ProjectContext);

    const emptyItem: HumanResourceDTO = useMemo(
        () => ({
            projectId: projectId,
            teamPosition: portfolio?.teamPositions ? portfolio?.teamPositions[0] : undefined,
            team: { id: teamId },
            name: 'New human resource',
            costFunctionRole: selectedProject?.costFunctionRoles ? selectedProject?.costFunctionRoles[0] : undefined,
            capacityUnit: portfolio?.units ? portfolio?.units[0] : undefined,
            capacity: 0,
        }),
        [portfolio?.teamPositions, portfolio?.units, projectId, selectedProject?.costFunctionRoles, teamId]
    );

    return useGenericDetailWindow({
        apiConstant: HUMAN_RESOUCE_API_BASE,
        closeAction: closeAction,
        emptyItem: emptyItem,
        genericDetailWindow: HumanResourceDetailWindow,
        externalSync,
        selectedItemId: id,
    });
};
