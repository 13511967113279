import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ProTextField } from '../../../atoms/AttrField/AttrField';
import { ProDropdown } from '../../../atoms/AttrField/Dropdown';
import { ProNumberField } from '../../../atoms/AttrField/NumberField';
import { ProSubElementAttrField } from '../../../atoms/AttrField/WBSSubElementAttrField';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../atoms/Button/ProIconButton';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { UserContext } from '../../../contexts/UserContext';
import { ProIntent, ProSize } from '../../../utils/enums';
import { Components } from '../../../utils/types';
import { proLinkStructure } from '../../Menu/Navigate';
import { DetailWindowProps } from '../GenericDetailWindow';
import { getWindowDisplayStateMode } from '../WBSElement/WBSElement.helpers';

export const getHumanResourceDescription = (data?: Components.Schemas.HumanResourceDTO): string => {
    if (!data) return 'Invalid data';
    return `${data.person?.firstName ?? ''} ${data.person ? data.person?.lastName : 'No person'} • ${
        data.costFunctionRole?.name ?? 'no function'
    } • ${data.teamPosition?.name ?? 'no position'}`;
};

export const HumanResourceDetailWindow = ({
    createAction,
    deleteAction,
    formData,
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    error,
    cancelChanges,
    closeAction,
    t,
}: DetailWindowProps<Components.Schemas.HumanResourceDTO>) => {
    const { portfolio } = useContext(UserContext);
    const { selectedProject } = useContext(ProjectContext);
    const { selectionMode, setSelectionMode, setRenderSelectionButton, setSelectedElementId, turnSelectionOff } =
        useContext(SelectionContext);

    const selectionAction = () => {
        setSelectedElementId(formData?.person?.id);

        setRenderSelectionButton(() => (elm: Components.Schemas.PersonDTO) => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        setFormData((cur) => ({ ...cur, person: elm }));
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
        setSelectionMode(SelectionType.HumanResource);
    };

    return (
        <ProDetailWindow
            title={<h2>{getHumanResourceDescription(formData)}</h2>}
            displayState={isDisplayed}
            icon={ProIconData.person_fill}
            buttonSection={
                <WindowButtonsForMode
                    cancelAction={cancelChanges}
                    closeAction={closeAction}
                    createAction={createAction}
                    deleteAction={deleteAction}
                    mode={mode}
                    setMode={setMode}
                    saveAction={saveAction}
                    t={t}
                />
            }
            intent={getWindowDisplayStateMode(mode)}
            error={mode !== 'new' ? undefined : error}
            loading={loading}
            breadCrump={getBaseBCItemForType(
                [{ type: ApplicationElementType.Team, id: formData?.team?.id, name: formData?.team?.name }],
                t
            )}
        >
            <ProRow>
                <ProCol span={6}>
                    <ProDropdown
                        name={t('windows.functionalRole')}
                        value={formData?.costFunctionRole?.name}
                        setValue={(newVal) =>
                            setFormData((cur) => ({
                                ...cur,
                                costFunctionRole: selectedProject?.costFunctionRoles?.find(
                                    (iter) => iter.name === newVal
                                ),
                            }))
                        }
                        items={selectedProject?.costFunctionRoles?.map((cur) => cur.name ?? 'unnamed')}
                        disabled={isDisabled}
                        definitionLink={proLinkStructure.portfolioManagement.baseData.functionalRoles.link}
                    />
                </ProCol>

                <ProCol span={6}>
                    <ProDropdown
                        name={t('windows.position')}
                        value={formData?.teamPosition?.name}
                        setValue={(newVal) =>
                            setFormData((cur) => ({
                                ...cur,
                                teamPosition: portfolio?.teamPositions?.find((cur) => cur.name === newVal),
                            }))
                        }
                        items={portfolio?.teamPositions?.map((cur) => cur.name ?? 'unnamed')}
                        disabled={isDisabled}
                        definitionLink={proLinkStructure.portfolioManagement.baseData.teamPositions.link}
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={6}>
                    <ProNumberField
                        name={t('windows.capacity')}
                        value={formData?.capacity}
                        handleChange={(input) => setFormData((old) => ({ ...old, capacity: Number(input) }))}
                        disabled={isDisabled}
                        entityLengh="none"
                    />
                </ProCol>
                <ProCol span={6}>
                    <ProDropdown
                        name={t('windows.capacityUnit')}
                        value={formData?.capacityUnit?.name}
                        setValue={(newVal) =>
                            setFormData((cur) => ({
                                ...cur,
                                capacityUnit: portfolio?.units?.find((cur) => cur.name === newVal),
                            }))
                        }
                        items={portfolio?.units?.map((cur) => cur.name ?? t('error.noData.name'))}
                        disabled={isDisabled}
                    />
                </ProCol>
            </ProRow>
            <ProRow spacer>
                <ProCol span={12}>
                    <ProTextField
                        name={t('windows.description')}
                        value={formData?.description ?? String(t('error.noData.description'))}
                        disabled={isDisabled}
                        handleChange={(input) => setFormData((old) => ({ ...old, description: String(input) }))}
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProSubElementAttrField
                    name={t('windows.person')}
                    isDisabled={isDisabled || selectionMode !== undefined}
                    isSelectionModeOn={selectionMode === SelectionType.HumanResource}
                    toggleSelectionMode={selectionAction}
                    values={[
                        `${formData?.person?.firstName ?? '-'} ${formData?.person?.lastName ?? '-'} ${
                            !formData?.person?.lastName && !formData?.person?.firstName ? '-' : ''
                        }`,
                    ]}
                    getInlineButtons={() => (
                        <Link to={`${proLinkStructure.portfolioManagement.people.link}/${formData?.person?.id}`}>
                            <ProIconButton
                                tooltip={t('buttons.goToPerson')}
                                icon={ProIconDataButtons.arrow_right_xs}
                                onClick={() => {}}
                                size={ProSize.XSmall}
                                mainIntent={ProIntent.LightBlue}
                                disabled={false}
                            />
                        </Link>
                    )}
                    t={t}
                />
            </ProRow>
        </ProDetailWindow>
    );
};
