import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProSummary } from '../../../atoms/SummaryWindow/SummaryWindow';
import { Components } from '../../../utils/types';

export const TeamPositionSummaryWindow = ({
    teamPositions,
}: {
    teamPositions?: Components.Schemas.TeamPositionDTO[] | null;
}) => {
    const { t } = useTranslation();
    return (
        <ProSummary.Window name={t('windows.summary')}>
            <ProSummary.Attr
                name={t('windows.totalPositions')}
                icon={ProIconData.team_fill}
                val={teamPositions?.length}
            />
        </ProSummary.Window>
    );
};

export const FunctionalRolesSummaryWindow = ({
    roles,
}: {
    roles?: Components.Schemas.CostFunctionRoleDTO[] | null;
}) => {
    const { t } = useTranslation();
    return (
        <ProSummary.Window name={t('windows.summary')}>
            <ProSummary.Attr name={t('windows.totalPositions')} icon={ProIconData.team_fill} val={roles?.length} />
            <ProSummary.Attr
                name={t('windows.totalHRs')}
                icon={ProIconData.person_fill}
                val={roles?.reduce((sum, iter) => {
                    if (iter.humanResourceAssignments) {
                        return sum + iter.humanResourceAssignments?.length;
                    }
                    return sum;
                }, 0)}
            />
            <ProSummary.Attr
                name={t('windows.level')}
                icon={ProIconData.package_fill}
                val={roles?.reduce((sum, iter) => {
                    if (iter.level) {
                        return `${sum} ${iter.level.name},`;
                    }
                    return sum;
                }, '')}
            />
            <ProSummary.Attr
                name={t('windows.origins')}
                icon={ProIconData.quality_fill}
                val={roles?.reduce((sum, iter) => {
                    if (iter.origin) {
                        return `${sum} ${iter.origin},`;
                    }
                    return sum;
                }, '')}
            />
        </ProSummary.Window>
    );
};
