import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ProMenuCard } from '../../../atoms/MenuCard/MenuCard';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { getPortfolio_MenuData } from '../../../constants/menu';
import { UserContext } from '../../../contexts/UserContext';

export const BaseDataOverview = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { portfolio } = useContext(UserContext);

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([{ type: ApplicationElementType.PortfolioMan, name: portfolio?.name }], t)}
            name={t('navigation.portfolioMan.baseData.base')}
        >
            <div className="ProSubMenu ProCol--notHidden">
                {getPortfolio_MenuData(t)
                    .find((cur) => pathname.includes(cur.link))
                    ?.sublinks?.map((cur, i) =>
                        cur.name === 'spacer' ? (
                            <div className="spacer"></div>
                        ) : (
                            <ProMenuCard item={cur} key={`${cur}_${i}`} />
                        )
                    )}
            </div>
        </ProPage>
    );
};
