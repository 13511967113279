/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REQUIREMENTSTRUCTURE_API_BASE, REQUIREMENTS_API_BASE } from '../../../../api/api';
import { deleteAPIRequest, useGenericAPI } from '../../../../api/useGenericApi';
import { ProButton } from '../../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../../atoms/Button/ProIconButton';
import { ProIcon } from '../../../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../../atoms/Table/Table';
import { ApplicationElementType, getBaseBCItemForType } from '../../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../../components/Page/Page';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import { getIconForTypeInNameCell } from '../../../../modules/Windows/WBSElement/WBSElement.helpers';
import { useWBSDetailWindow } from '../../../../modules/Windows/WBSElement/WBSElementHook/WBSDetailFrame';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { showErrorToast } from '../../../../utils/helpers';
import { Components } from '../../../../utils/types';
import { RequirementContextProvider } from '../../Requirements/context/RequirementContext';
import {
    RequirementDetailWindowType,
    useRequirementDetailsFrame,
} from '../../Requirements/modules/RequirementDetailsFrame';
import { getSprintPlanningTableData } from './SprintPlanning.helpers';

export const SprintPlanningPage = ({
    setSelectedSprint,
    selectedSprint,
}: {
    selectedSprint: Components.Schemas.WBSElementDeltaDTO;
    setSelectedSprint: Dispatch<SetStateAction<Components.Schemas.WBSElementDeltaDTO | undefined>>;
}) => {
    const { t } = useTranslation();
    const { selectedProject, loading: projectLoading, error: projectError, selectedTeam } = useContext(ProjectContext);
    const [selectedRequirement, setSelectedRequirement] = useState<Components.Schemas.RequirementDTO>();
    const [selectedTableRow, setSelectedTableRow] = useState<number>();
    const [sprintOpen, setSprintOpen] = useState(false);

    const toggleSprintOpen = () => {
        setSprintOpen((cur) => !cur);
    };

    const deselectTableRow = () => {
        setSelectedTableRow(undefined);
        setSelectedRequirement(undefined);
        open('hidden');
        openWBS('hidden', false);
    };

    const selectSprint = () => {
        setSelectedTableRow(undefined);
        setSelectedRequirement(undefined);
        setSprintOpen(true);
        open('hidden');
        openWBS('view');
    };

    const syncWBSScructure = () => {};

    const {
        open: openWBS,
        renderWBSDetailWindow,
        isDisplayed: wbsIsDisplayed,
        mode: detailWindowMode,
    } = useWBSDetailWindow('Quality', deselectTableRow, selectedSprint, syncWBSScructure);

    const selectedRequStruct = useMemo(
        () => selectedProject?.requirementStructures?.find((cur) => cur.version === 'Forecast'),
        [selectedProject]
    );

    const selectElement = (id: string) => {
        const req = requirements?.find((cur) => cur.id === id);
        open('view');
        openWBS('hidden', false);
        setSelectedRequirement(req);
    };

    const {
        data: requirementStructure,
        error,
        loading,
        sync,
    } = useGenericAPI<Components.Schemas.RequirementStructureDTO>(
        `${REQUIREMENTSTRUCTURE_API_BASE}/${selectedRequStruct?.id}/Team`,
        selectedTeam?.id
    );

    const { isDisplayed, mode, open, renderDetailWindow } = useRequirementDetailsFrame({
        closeAction: deselectTableRow,
        id: selectedRequirement?.id,
        selectAction: selectElement,
        externalSync: sync,
        type: RequirementDetailWindowType.Planning,
    });

    const requirements = useMemo(
        () =>
            requirementStructure?.requirements
                ?.filter(
                    (cur) =>
                        cur.requirementType === 'Bug' ||
                        cur.requirementType === 'ChangeRequest' ||
                        cur.requirementType === 'UserStory'
                )
                .filter((cur) => cur.requirementState?.name === 'ready')
                .filter((cur) => !cur.wbsElement) ?? [],
        [requirementStructure]
    );

    const deleteAction = useMemo(
        () => (i: number) => {
            const req = requirements[i];
            if (req.id) {
                deleteAPIRequest(req.id ?? '0', REQUIREMENTS_API_BASE)
                    .then(() => {
                        sync();
                    })
                    .catch(() => {
                        showErrorToast('Cant delete element.');
                    });
            }
        },
        [requirements, sync]
    );

    const tableData = useMemo(
        () =>
            getSprintPlanningTableData({
                deleteAction,
                isDisplayed: isDisplayed || wbsIsDisplayed,
                mode,
                requirements,
                selectElement,
                t,
            }),
        [deleteAction, isDisplayed, mode, requirements, selectElement, t]
    );

    //set the corresponding row to active when selected item changed
    useEffect(() => {
        // +1 -1 is a hack to make findIndex => -1 into 0 wich is nullable
        const row = requirements.findIndex((cur) => cur.id === selectedRequirement?.id) + 1;
        setSelectedTableRow(row - 1);
    }, [requirements, selectedRequirement]);

    const sprintTableData: TableColData[] = [
        {
            name: 'Sprint',
            cellRender: (i) => (
                <>
                    <div className="ProTable__levelBars ">
                        <div className={classNames('ProTable__levelBar', `ProTable__levelBar-1`)}>1</div>
                    </div>

                    <div
                        className={classNames('ProTable__level', sprintOpen && 'ProTable__level--open')}
                        onClick={toggleSprintOpen}
                    >
                        <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                    </div>

                    <span className="ProTable__name" onClick={() => selectSprint()}>
                        {selectedSprint.wbsElementType && getIconForTypeInNameCell(selectedSprint.wbsElementType)}
                        {selectedSprint.name}
                    </span>
                </>
            ),
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.responsible'),
            cellRender: (i) =>
                selectedSprint.responsiblePerson
                    ? `${selectedSprint.responsiblePerson?.firstName ?? ''} ${
                          selectedSprint.responsiblePerson?.lastName ?? 'no data'
                      }`
                    : '',
            hidden: () => !wbsIsDisplayed,
            colSpace: 4,
        },
        {
            name: t('table.cols.constraint'),
            cellRender: (i) => selectedSprint.constraintQualityType,
            hidden: () => !wbsIsDisplayed,
            colSpace: 4,
        },
        {
            name: 'SP',
            cellRender: (i) => selectedSprint.scopePoints,
            colSpace: 1,
        },

        {
            name: t('table.cols.action'),
            cellRender: (i) => (
                <div className={classNames('ProTable--actionCell')}>
                    <ProIconButton
                        icon={ProIconDataButtons.arrow_right_xs}
                        tooltip={t('buttons.open')}
                        mainIntent={ProIntent.Transpared}
                        hoverIntent={ProIntent.Secondary}
                        size={ProSize.XSmall}
                        onClick={() => selectSprint()}
                        lineIntent={ProIntent.Secondary}
                        placement="bottom"
                    />
                </div>
            ),
            className: `ProTable__buttons`,
            colSpace: 2,
        },
    ];

    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [
                    ApplicationElementType.TeamManagement,
                    { type: ApplicationElementType.TeamManagement, name: selectedTeam?.name },
                ],
                t
            )}
            name={'Sprint planning'}
            loading={loading}
            filter={
                <>
                    <ProButton
                        text={'Finish planning'}
                        mainIntent={ProIntent.Success}
                        onClick={() => setSelectedSprint(undefined)}
                    />
                </>
            }
        >
            <RequirementContextProvider>
                {selectedProject && tableData && (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <ProTable
                            data={sprintTableData}
                            rowsCount={1}
                            selectedTableRow={selectedTableRow}
                            getRowClass={(i) => `ProTable--row__level-0`}
                            isMultiTable
                        />
                        <ProTable
                            data={tableData}
                            rowsCount={requirements?.length}
                            selectedTableRow={selectedTableRow}
                            getRowClass={(i) => `ProTable--row__level-0 `}
                            typeForErrorMsg="ready stories"
                        />
                    </div>
                )}
                <div
                    className={classNames(
                        'ProApp--detailWindowSection',
                        (isDisplayed || wbsIsDisplayed) && 'ProApp--detailWindowSection__expanded'
                    )}
                >
                    {renderDetailWindow()}
                    {renderWBSDetailWindow()}
                </div>
            </RequirementContextProvider>
        </ProPage>
    );
};
