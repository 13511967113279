import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LOGIN_START_PAGE } from '../../api/api';
import { ProAttr } from '../../atoms/AttrField/AttrField';
import { ProButton } from '../../atoms/Button/ProButton';
import { ProIcon } from '../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProSpinner } from '../../atoms/ProSpinner/Spinner';
import { UserContext } from '../../contexts/UserContext';
import { ProIntent, ProSize } from '../../utils/enums';
import { AppBase } from '../Menu/Navigate';

export const LoginWindowContent = ({ openInvite }: { openInvite?(): void }) => {
    const [name, setName] = useState<string>('admin@4opm.com');
    const [password, setPassword] = useState<string>('$Portfolio1');

    const { initialLoading } = useContext(UserContext);
    const { login, error, loading } = useContext(UserContext);
    const history = useHistory();

    const innerLogin = async () => {
        await login(name, password, true).then(() => {
            history.push(LOGIN_START_PAGE);
        });
    };
    return (
        <>
            <ProIcon icon={ProIconData.user_full} size={ProSize.XLarge} intent={ProIntent.Primary} />
            <form id="loginForm">
                <h1>Login</h1>
                <ProAttr.Val
                    value={name}
                    handleChange={(val) => setName(String(val))}
                    name="Email"
                    fullWidth
                    disabled={false}
                    className="LoginWindow--input"
                    type="text"
                />
                <ProAttr.Password
                    value={password}
                    handleChange={(val) => setPassword(String(val))}
                    name="Password"
                    fullWidth
                    disabled={false}
                    type="password"
                    // enterkeyhint='go'
                />
                <div className="ProButton--group">
                    {loading || !initialLoading ? (
                        <ProSpinner />
                    ) : (
                        <ProButton
                            text="Login"
                            onClick={innerLogin}
                            size={ProSize.Medium}
                            mainIntent={ProIntent.PrimaryHightlight}
                            lineIntent={ProIntent.Primary}
                            leftIcon={ProIconDataButtons.login_s}
                            className="LoginWindow--loginButton"
                        />
                    )}
                </div>
                <div className="ProMessage">
                    {error && <p className="ProMessage--error">Ups, something went wrong</p>}
                </div>
                <a href={`${AppBase}/register`}>
                    <ProButton
                        text="I got a invite link"
                        mainIntent={ProIntent.Transpared}
                        lineIntent={ProIntent.Primary}
                        className="LoginWindow__inviteButton"
                        onClick={openInvite}
                    />
                </a>
            </form>
        </>
    );
};
