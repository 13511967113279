import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ProMenuCard } from '../../../atoms/MenuCard/MenuCard';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { getPortfolio_MenuData } from '../../../constants/menu';

export const PortfolioCostManagementPage = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.Capacities], t)}
            name={t('navigation.portfolioMan.capacityMan.capacity')}
            loading={false}
        >
            <div className="MenuCardContainer">
                {getPortfolio_MenuData(t)
                    .find((cur) => pathname.includes(cur.link))
                    ?.sublinks?.map((cur) => (
                        <ProMenuCard item={cur} />
                    ))}
            </div>
        </ProPage>
    );
};
