import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProPage } from '../../../components/Page/Page';
import { getBASE_MenuData } from '../../../constants/menu';
import { ApplicationDetailStartPage } from '../../../modules/AreaStartPage/AreaStartPage';

export const PersonalWelcomePage = () => {
    const { t } = useTranslation();
    return (
        <ProPage name={t('navigation.personalMan.base')}>
            <ApplicationDetailStartPage data={getBASE_MenuData(t)[0]} />
        </ProPage>
    );
};
