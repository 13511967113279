import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PORTFOLIO_API_BASE } from '../../../api/api';
import { useGenericAPI } from '../../../api/useGenericApi';
import { UserContext } from '../../../contexts/UserContext';
import { Components } from '../../../utils/types';
import { WindowDisplayState } from '../WBSElement/WBSElement.helpers';
import { PortfolioDetailFrame } from './PortfolioDetailWindow';

export const usePortfolioDetailFrame = (portfolioId: string, deselectTableRow: () => void) => {
    const { portfolio } = useContext(UserContext);
    const { t } = useTranslation();

    const {
        data,
        error,
        loading,
        updateRequest: updatePortfolio,
        sync,
    } = useGenericAPI<Components.Schemas.PortfolioDTO>(PORTFOLIO_API_BASE, portfolio?.id);

    const [formData, setFormData] = useState<Components.Schemas.PortfolioDTO>();
    const [mode, setMode] = useState<WindowDisplayState>('view');
    const [isDisplayed, setIsDisplayed] = useState<boolean>(false);

    useEffect(() => {
        if (data) setFormData(data);
    }, [data, portfolioId, mode]);

    const saveChanges = async () => {
        if (formData) {
            await updatePortfolio(formData)
                .then(() => {
                    setMode('view');
                    sync();
                })
                .catch((e) => {
                    console.log('not saved ', e);
                });
        }
    };

    useEffect(() => {
        if (!data) setFormData(undefined);
        else setFormData(data);
    }, [data, portfolioId, mode]);

    const open = (newMode: WindowDisplayState, element?: Components.Schemas.PersonDTO) => {
        setIsDisplayed(newMode !== 'hidden');
        setMode(newMode);
        setFormData(element);
    };

    const cancelChanges = () => {
        if (mode === 'new') setFormData(data);
        if (data) setFormData(data);
        open('view');
    };

    const closeAction = () => {
        deselectTableRow();
        setIsDisplayed(false);
    };

    const renderDetailWindow = () => (
        <PortfolioDetailFrame
            cancelChanges={cancelChanges}
            closeAction={closeAction}
            isDisabled={mode === 'view'}
            isDisplayed={isDisplayed}
            loading={loading}
            mode={mode}
            saveAction={saveChanges}
            setFormData={setFormData}
            setMode={setMode}
            error={error}
            formData={formData}
            t={t}
        />
    );

    return { open, renderDetailWindow, isDisplayed, mode, data, sync, loading };
};
