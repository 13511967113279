import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { proLinkStructure } from '../modules/Menu/Navigate';

type RoutingHistoryType = Record<string, string>;

export enum RoutingHistorySections {
    personalManagement = 'personalManagement',
    teamManagement = 'teamManagement',
    projectManagement = 'projectManagement',
    portfolioManagement = 'portfolioManagement',
}

export const useRouting = () => {
    const { push: navigate } = useHistory();

    const newRoutingHistory = {
        personalManagement: proLinkStructure.personalManagement.workPackages.link,
        teamManagement: proLinkStructure.teamManagement.requirements.link,
        projectManagement: proLinkStructure.projectManagement.quality.link,
        portfolioManagement: proLinkStructure.portfolioManagement.baseData.link,
    };

    const storage = localStorage.getItem('routingHistory');
    const storageObject = storage ? JSON.parse(storage) : null;
    const [routingHistory, setRoutingHistory] = useState<RoutingHistoryType | null>(storageObject);

    const writeRouting = (newObject: RoutingHistoryType) => {
        localStorage.setItem('routingHistory', JSON.stringify(newObject));
        setRoutingHistory(newObject);
    };

    const navigateSection = (section: string) => {
        if (!routingHistory) {
            writeRouting(newRoutingHistory);
        }

        const newRoute = routingHistory?.[section];
        // console.log('new section', newRoute, section);
        newRoute && navigate(newRoute);
    };

    const navigateSubRoute = (section: RoutingHistorySections, subRoute: string) => {
        // const oldLink = proLinkStructure[section].link;
        const newObj = { ...routingHistory, [section]: subRoute };

        writeRouting(newObj);
        // console.log('navigate', newObj, oldLink, subRoute);
        navigate(subRoute);
    };

    return { navigateSection, navigateSubRoute };
};
