import { useEffect } from 'react';

export const usePageTitle = (title: string) => {
    useEffect(() => {
        document.title = `4oPM - ${title}`;

        return () => {
            document.title = '4oPM';
        };
    }, [title]);
};
