import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { deleteMaterialAssignment, updateMaterialAssignment } from '../../api/api';
import { ProAttrField } from '../../atoms/AttrField/AttrField';
import { ProNumberField } from '../../atoms/AttrField/NumberField';
import { ProDeleteButton } from '../../atoms/Button/ProDeleteButton';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProCol } from '../../atoms/Grid/Col';
import { ProRow } from '../../atoms/Grid/Row';
import { ProIconDataButtons } from '../../atoms/Icons/IconData';
import { proLinkStructure } from '../../modules/Menu/Navigate';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';

type Props = {
    dependency: Components.Schemas.MaterialAssignmentDTO;
    disabled: boolean;
    sync?: () => void;
};

export const MateriaAssignmentWindow = ({ dependency, disabled, sync }: Props) => {
    const [dep, setDep] = useState(dependency);
    const { t } = useTranslation();

    const saveDep = () => {
        updateMaterialAssignment(dep)
            .then(() => {
                sync && sync();
            })
            .catch(() => {});
    };

    const delteAction = () => {
        deleteMaterialAssignment(dep)
            .then(() => {
                sync && sync();
            })
            .catch(() => {});
    };

    return (
        <ProRow className="ProAttrField__dependencies--box">
            <ProCol span={7}>
                <ProAttrField value={dependency.materialDefinition?.name} />
            </ProCol>

            <ProCol span={2}>
                <ProNumberField
                    disabled={disabled}
                    entity={t('entities.pieces')}
                    value={dep.amount}
                    handleChange={(val) =>
                        setDep({
                            ...dep,
                            amount: Number(val),
                        })
                    }
                    entityLengh="xl"
                />
            </ProCol>
            <ProCol span={3} direction="row" className="ProAttrField__dependencies__buttons">
                <ProIconButton
                    icon={ProIconDataButtons.save_s}
                    size={ProSize.XSmall}
                    mainIntent={ProIntent.Success}
                    onClick={saveDep}
                    tooltip={t('buttons.save')}
                    disabled={disabled}
                />
                {delteAction && <ProDeleteButton onClick={delteAction} inline disabled={disabled} />}

                {/* Todo: Write id to the hr list url  */}

                <Link
                    to={`${proLinkStructure.portfolioManagement.baseData.material.link}/${dependency.materialDefinition?.id}`}
                >
                    <ProIconButton
                        tooltip={t('buttons.goTo')}
                        icon={ProIconDataButtons.arrow_right_xs}
                        onClick={() => {}}
                        size={ProSize.XSmall}
                        hoverIntent={ProIntent.Secondary}
                        lineIntent={ProIntent.Secondary}
                        mainIntent={ProIntent.Transpared}
                    />
                </Link>
            </ProCol>
        </ProRow>
    );
};
