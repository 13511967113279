import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    span: number;
    className?: string;
    direction?: 'row' | 'col';
    customSpan?: string;
    verticalAlign?: 'top' | 'center' | 'bottom' | 'spaceAround';
    block?: boolean;
}>;

export const ProCol = ({ children, className, span, direction, customSpan, verticalAlign, block }: Props) => (
    <div
        className={classNames(
            'ProCol',
            `ProCol--span-${span}`,
            direction && `ProCol--direction-${direction}`,
            verticalAlign && `ProCol--${verticalAlign}`,
            className,
            block && 'ProCol--block'
        )}
        style={customSpan ? { width: customSpan } : undefined}
    >
        {children}
    </div>
);
