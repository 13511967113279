import { useContext, useEffect, useMemo, useState } from 'react';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { HumanResourceDTO, TeamDTO, getIsHidden, useTeamDetailFrame } from './Team.helpers';
import { useHumanResourceDetailFrame } from './modules/HumanResourceDetailFrame';

export const useTeamPageData = (deselectTableRow: () => void, teamId?: string) => {
    const { selectedProject, sync: syncProjects, loading } = useContext(ProjectContext);
    const [teams, setTeams] = useState<TeamDTO[] | null | undefined>(
        selectedProject?.teams?.filter((cur) => cur.teamType === 'ProjectTeam')
    );
    const [selectedHuman, setSelectedHuman] = useState<HumanResourceDTO>();
    const [selectedTeam, setSelectedTeam] = useState<TeamDTO>();

    useEffect(() => {
        setTeams(selectedProject?.teams?.filter((cur) => cur.teamType !== 'ProjectTeam'));
    }, [selectedProject]);

    const {
        isDisplayed: humanIsDisplayed,
        mode: humanMode,
        open: openHuman,
        renderDetailWindow: renderHumanDetailWindow,
    } = useHumanResourceDetailFrame(deselectTableRow, selectedHuman?.id, selectedProject?.id ?? '0', teamId, () => {
        teamSync();
    });

    const {
        isDisplayed: teamIsDisplayed,
        mode: teamMode,
        open: openTeam,
        renderDetailWindow: renderTeamDetailWindow,
        sync: teamSync,
        data: openTeamData,
    } = useTeamDetailFrame(deselectTableRow, selectedTeam?.id, selectedProject, syncProjects);

    useEffect(() => {
        if (openTeamData) {
            setTeams(teams?.map((cur) => (openTeamData.id === cur.id ? openTeamData : cur)));
        }
    }, [openTeamData]);

    const allElements = useMemo(() => {
        return [
            selectedProject ?? {},
            ...(teams ?? [])
                .map((cur) => (cur.id && selectedTeam?.id === cur.id ? [cur, ...(cur?.humanResources ?? [])] : [cur]))
                .flat()
                .filter((cur) => cur),
        ];
    }, [selectedProject, teams, selectedTeam]);

    const hidden = useMemo(() => getIsHidden([teamIsDisplayed, humanIsDisplayed]), [teamIsDisplayed, humanIsDisplayed]);

    return {
        allElements,
        selectedProject,
        setSelectedHuman,
        openTeam,
        openHuman,
        // setSelectedTeamForCreation,
        syncProjects,
        selectedTeam,
        setSelectedTeam,
        teamMode,
        humanMode,
        renderTeamDetailWindow,
        renderHumanDetailWindow,
        hidden,
        selectedHuman,
        loading,
        teams,
    };
};
