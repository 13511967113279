import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProPage } from '../../components/Page/Page';
import notImplementedImg from './../../utils/images/streamline-icon-seo-error-2.png';
import { emptyBreadcrumpPath } from './NotFound';

type Props = {
    message?: string;
    loading?: boolean;
};

export const ProErrorPage = ({ message, loading = false }: Props) => (
    <ProPage breadCrump={emptyBreadcrumpPath} name="Error" loading={loading}>
        <ProErrorWindow message={message} />
    </ProPage>
);

type ProErrorProps = {
    message?: string;
};

export const ProErrorWindow = ({ message }: ProErrorProps) => {
    const { t } = useTranslation();
    return (
        <div className="ProErrorPage ProErrorPage__NotImplemented">
            <h1 className="ProErrorPage--head">
                <img src={notImplementedImg} alt="not found" />
                <span>{t('error.genericError.number')}</span>
            </h1>
            <p className="ProErrorPage--sub">{t('error.genericError.header')}</p>
            <p className="ProErrorPage--text">{message}</p>
        </div>
    );
};
