import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { NotImplemented } from '../../../modules/ProErrorPage/NotImplemented';

export const PersonalForecastSessionPage = () => {
    const { t } = useTranslation();
    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [ApplicationElementType.PersonalManagement, ApplicationElementType.ForecastSession],
                t
            )}
            name="Forecast Session"
        >
            <NotImplemented message="Forcast sessions" />
        </ProPage>
    );
};
