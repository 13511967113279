import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { formateDate } from '../../constants/helpers';

type Props = {
    type: 'string' | 'date' | 'value';
    entity?: string;
    value?: number | string;
    className?: string;
};

export const ProValue = ({ type, entity, value = '-', className }: Props) => (
    <div className={classNames('ProValue', className)}>
        {type === 'date' && formateDate(String(value))}
        {type === 'value' && (
            <div className="ProValue--value">
                {isNaN(Number(value)) ? '-' : Number(value).toFixed(2)} {entity && entity}
            </div>
        )}
        {type === 'string' && `${value} ${entity && entity}`}
    </div>
);

type Props2 = PropsWithChildren<{
    type: 'string' | 'date' | 'value';
    entity?: string;
}>;

export const ProValue2 = ({ type, children, entity }: Props2) => (
    <div className="ProValue">
        {type === 'date' && formateDate(String(children))}
        {type === 'value' && (
            <div className={classNames('ProValue--value')}>
                {children} {entity && entity}
            </div>
        )}
        {type === 'string' && `${children} ${entity && entity}`}
    </div>
);

type DateProps = PropsWithChildren<{
    local?: string;
}>;

export const ProDate = ({ children, local = 'en-US' }: DateProps) => (
    <div className="ProValue">{children && new Date(String(children)).toLocaleDateString(local)}</div>
);

type CurrencyProps = PropsWithChildren<{
    currency?: string;
}>;

export const ProCurrency = ({ children, currency = '€' }: CurrencyProps) => (
    <div className="ProValue ProValue--value">
        {children ? children : '0'} {currency}
    </div>
);
