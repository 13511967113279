import classNames from 'classnames';
import React from 'react';
import { ProHeaderAttrField, ProTextField } from '../../../atoms/AttrField/AttrField';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { WindowButtonsForMode } from '../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { Components } from '../../../utils/types';
import { DetailWindowProps } from '../GenericDetailWindow';
import { getWindowDisplayStateMode } from '../WBSElement/WBSElement.helpers';

export const PortfolioDetailFrame = ({
    isDisabled,
    isDisplayed,
    loading,
    mode,
    saveAction,
    setFormData,
    setMode,
    error,
    formData,
    cancelChanges,
    closeAction,
    t,
}: DetailWindowProps<Components.Schemas.PortfolioDTO>) => (
    <ProDetailWindow
        title={
            mode === 'view' ? (
                <h2
                    className={classNames(
                        formData?.name?.length &&
                            formData?.name?.length >= 180 &&
                            'ProDetailWindow--header--title__large'
                    )}
                >
                    {formData?.name ?? t('windows.details')}
                </h2>
            ) : (
                <ProHeaderAttrField
                    name="Name"
                    value={formData?.name ?? String(t('windows.details'))}
                    disabled={false}
                    handleChange={(input) =>
                        setFormData((old) => (old ? { ...old, name: String(input).substring(0, 180) } : undefined))
                    }
                />
            )
        }
        displayState={isDisplayed}
        icon={ProIconData.project_fill}
        buttonSection={
            <WindowButtonsForMode
                cancelAction={cancelChanges}
                closeAction={closeAction}
                mode={mode}
                setMode={setMode}
                saveAction={saveAction}
                t={t}
            />
        }
        intent={getWindowDisplayStateMode(mode)}
        error={error}
        loading={loading}
        breadCrump={getBaseBCItemForType(
            [ApplicationElementType.PortfolioMan, ApplicationElementType.Projects, ApplicationElementType.Portfolio],
            t
        )}
    >
        <ProRow>
            <ProCol span={12}>
                <ProTextField
                    name={t('windows.description')}
                    value={formData?.description ?? 'not set'}
                    disabled={isDisabled}
                    handleChange={(input) =>
                        setFormData((old) => (old ? { ...old, description: String(input) } : undefined))
                    }
                />
            </ProCol>
        </ProRow>
    </ProDetailWindow>
);
