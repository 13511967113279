import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TASKLIST_API_BASE, TASK_API_BASE } from '../../../api/api';
import { deleteAPIRequest } from '../../../api/useGenericApi';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { DetaiWindowWrapper } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { UserContext } from '../../../contexts/UserContext';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import {
    TaskDetailWindow,
    TaskListDetailWindow,
} from '../../../modules/Windows/SettingsDetails/AgileProjectDetailWindow';
import { ProSize } from '../../../utils/enums';
import { Components } from '../../../utils/types';

const useTasklistDetailFrame = ({
    closeAction,
    externalSync,
    id,
}: {
    closeAction: () => void;
    id?: string;
    externalSync?: () => Promise<void>;
}) => {
    const emptyItem = {};

    return useGenericDetailWindow({
        apiConstant: TASKLIST_API_BASE,
        closeAction,
        emptyItem,
        genericDetailWindow: TaskListDetailWindow,
        externalSync,
        selectedItemId: id,
    });
};

const useTaskDetailFrame = ({
    closeAction,
    externalSync,
    id,
    newElement,
}: {
    closeAction: () => void;
    id?: string;
    externalSync?: () => Promise<void> | void;
    newElement: Components.Schemas.TaskDTO;
}) => {
    return useGenericDetailWindow({
        apiConstant: TASK_API_BASE,
        closeAction,
        emptyItem: newElement,
        genericDetailWindow: TaskDetailWindow,
        externalSync,
        selectedItemId: id,
    });
};

export const AgileSettingsProjectsPage = () => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const { acceptanceCiteriaList, readyCiteriaList, selectedProject, loading, sync } = useContext(ProjectContext);

    // const acceptanceCiteriaList = useMemo(
    //     () => selectedProject?.taskList?.find((cur) => cur.tasklistType === 'AcceptanceCiteria'),
    //     [selectedProject]
    // );
    // const readyCiteriaList = useMemo(
    //     () => selectedProject?.taskList?.find((cur) => cur.tasklistType === 'ReadyCiteria'),
    //     [selectedProject]
    // );

    const [selectedAccaptanceTableRow, setSelectedAccaptanceTableRow] = useState<number>();
    const [selectedReadyTableRow, setSelectedReadyTableRow] = useState<number>();
    const [selectedTask, setSelectedTask] = useState<Components.Schemas.TaskDTO>();

    const deselectTableRow = () => {
        setSelectedAccaptanceTableRow(undefined);
        setSelectedReadyTableRow(undefined);
        openAcceptanceCiteriaList('hidden');
        openReadyCiteriaList('hidden');
        openTask('hidden');
    };

    const emptyItem: Components.Schemas.TaskDTO = useMemo(
        () => ({
            name: 'Test',
            projectId: selectedProject?.id,
            taskListId: selectedAccaptanceTableRow !== undefined ? acceptanceCiteriaList?.id : readyCiteriaList?.id,
        }),
        [selectedAccaptanceTableRow, selectedReadyTableRow]
    );

    const {
        data: acceptanceCiteriaListInternal,
        isDisplayed: acceptanceCiteriaListIsDisplayed,
        renderDetailWindow: renderAcceptanceCiteriaList,
        open: openAcceptanceCiteriaList,
        mode: acceptanceCiteriaListMode,
        sync: syncAccaptanceCriteriaList,
    } = useTasklistDetailFrame({
        closeAction: deselectTableRow,
        externalSync: sync,
        id: acceptanceCiteriaList?.id,
    });

    const {
        data: readyCiteriaListInternal,
        isDisplayed: readyCiteriaListIsDisplayed,
        renderDetailWindow: renderReadyCiteriaList,
        open: openReadyCiteriaList,
        mode: readyCiteriaListMode,
        sync: syncReadyCriteriaList,
    } = useTasklistDetailFrame({
        closeAction: deselectTableRow,
        externalSync: sync,
        id: readyCiteriaList?.id,
    });

    const {
        data: task,
        isDisplayed: taskIsDisplayed,
        loading: taskIsLoading,
        mode: taskMode,
        open: openTask,
        renderDetailWindow: renderTaskDetailWindow,
        sync: syncTask,
    } = useTaskDetailFrame({
        closeAction: deselectTableRow,
        externalSync: () => {
            syncReadyCriteriaList();
            syncAccaptanceCriteriaList();
        },
        id: selectedTask?.id,
        newElement: emptyItem,
    });

    const addTask = (type: 'accaptance' | 'ready') => {
        if (type === 'accaptance') setSelectedAccaptanceTableRow(0);
        else setSelectedReadyTableRow(0);
        setSelectedTask(undefined);
        openTask('new');
        // setSelectedReadyTableRow(undefined);
        // setSelectedAccaptanceTableRow(undefined);
    };

    const deleteTask = (id: string) => {
        deleteAPIRequest(id, TASK_API_BASE).then(() => {
            setSelectedTask(undefined);
            setSelectedReadyTableRow(undefined);
            setSelectedAccaptanceTableRow(undefined);
            openAcceptanceCiteriaList('hidden');
            sync();
        });
    };

    const selectAccaptanceTableRow = (i: number) => {
        if (acceptanceCiteriaListInternal?.tasks) setSelectedTask(acceptanceCiteriaListInternal?.tasks[i]);
        openAcceptanceCiteriaList('hidden');
        openReadyCiteriaList('hidden');
        openTask('view', selectedTask);
        setSelectedAccaptanceTableRow(i + 1);
        setSelectedReadyTableRow(undefined);
    };

    const selectReadyTableRow = (i: number) => {
        if (readyCiteriaListInternal?.tasks) setSelectedTask(readyCiteriaListInternal?.tasks[i]);
        openAcceptanceCiteriaList('hidden');
        openReadyCiteriaList('hidden');
        openTask('view', selectedTask);
        setSelectedReadyTableRow(i + 1);
        setSelectedAccaptanceTableRow(undefined);
    };

    const selectAccaptanceTasklist = () => {
        setSelectedReadyTableRow(1);
        setSelectedAccaptanceTableRow(undefined);
        openTask('hidden');
        openReadyCiteriaList('hidden');
        openAcceptanceCiteriaList('view');
    };

    const selectReadyTasklist = () => {
        setSelectedAccaptanceTableRow(1);
        setSelectedReadyTableRow(undefined);
        openTask('hidden');
        openReadyCiteriaList('view');
        openAcceptanceCiteriaList('hidden');
    };

    const acceptanceCiteriaProjectData: TableColData[] = useMemo(
        () => [
            {
                name: '',
                cellRender: (i) =>
                    i === 0 ? (
                        <>
                            <div className="ProTable__levelBars "></div>
                            <div className="ProTable__level ProTable__level--open">
                                <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                            </div>
                            <span className="ProTable__name" onClick={selectAccaptanceTasklist}>
                                {acceptanceCiteriaListInternal?.name ?? 'Not named task list'}
                            </span>
                        </>
                    ) : (
                        <>
                            <div className="ProTable__levelBars ">
                                <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                            </div>
                            <span className="ProTable__name" onClick={() => selectAccaptanceTableRow(i)}>
                                <ProIcon size={ProSize.Small} icon={ProIconData.project_fill} />
                                {acceptanceCiteriaListInternal?.tasks
                                    ? acceptanceCiteriaListInternal?.tasks[i - 1].name
                                    : 'not named'}
                            </span>
                        </>
                    ),
                className: 'TableRowName ProTable__levels',
                colSpace: 'extend',
            },
            {
                name: t('table.cols.action'),
                cellRender: (i) =>
                    i === 0 ? (
                        <>
                            <ActionCell
                                i={i}
                                selectTableRow={selectAccaptanceTasklist}
                                detailWindowMode={acceptanceCiteriaListMode}
                                createElement={() => addTask('accaptance')}
                            />
                        </>
                    ) : (
                        <>
                            <ActionCell
                                i={i}
                                selectTableRow={() => selectAccaptanceTableRow(i)}
                                detailWindowMode={acceptanceCiteriaListMode}
                                deleteAction={() =>
                                    deleteTask(
                                        (acceptanceCiteriaListInternal?.tasks &&
                                            acceptanceCiteriaListInternal?.tasks[i - 1]?.id) ??
                                            '0'
                                    )
                                }
                            />
                        </>
                    ),
                colSpace: 4,
            },
        ],
        [acceptanceCiteriaListInternal]
    );

    const readyCiteriaProjectData: TableColData[] = useMemo(
        () => [
            {
                name: '',
                cellRender: (i) =>
                    i === 0 ? (
                        <>
                            <div className="ProTable__levelBars "></div>
                            <div className="ProTable__level ProTable__level--open">
                                <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                            </div>
                            <span className="ProTable__name" onClick={selectReadyTasklist}>
                                {readyCiteriaListInternal?.name ?? 'Not named task list'}
                            </span>
                        </>
                    ) : (
                        <>
                            <div className="ProTable__levelBars ">
                                <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                            </div>
                            <span className="ProTable__name" onClick={() => selectReadyTableRow(i)}>
                                <ProIcon size={ProSize.Small} icon={ProIconData.project_fill} />
                                {readyCiteriaListInternal?.tasks
                                    ? readyCiteriaListInternal?.tasks[i - 1].name
                                    : 'not named'}
                            </span>
                        </>
                    ),
                className: 'TableRowName ProTable__levels',
                colSpace: 'extend',
            },
            {
                name: t('table.cols.action'),
                cellRender: (i) =>
                    i === 0 ? (
                        <>
                            <ActionCell
                                i={i}
                                selectTableRow={selectReadyTasklist}
                                detailWindowMode={readyCiteriaListMode}
                                createElement={() => addTask('ready')}
                            />
                        </>
                    ) : (
                        <>
                            <ActionCell
                                i={i}
                                selectTableRow={() => selectReadyTableRow(i)}
                                detailWindowMode={readyCiteriaListMode}
                                deleteAction={() =>
                                    deleteTask(
                                        (readyCiteriaListInternal?.tasks &&
                                            readyCiteriaListInternal?.tasks[i - 1].id) ??
                                            '0'
                                    )
                                }
                            />
                        </>
                    ),
                colSpace: 4,
            },
        ],
        [readyCiteriaListInternal]
    );

    if (!user) {
        return <ProNoDataOrRightsPage message={[t('error.noData.user')]} />;
    }
    if (!selectedProject) {
        return <ProNoDataOrRightsPage message={[t('error.noData.project')]} loading={loading} />;
    }

    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [ApplicationElementType.Capacities, ApplicationElementType.ReadyCriteria],
                t
            )}
            name={t('navigation.portfolioMan.baseData.agile')}
            displyProjectSelector
        >
            <div style={{ width: '100%' }}>
                <ProTable
                    data={readyCiteriaProjectData}
                    rowsCount={(readyCiteriaListInternal?.tasks?.length ?? 0) + 1}
                    getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
                    selectedTableRow={selectedReadyTableRow}
                />
                <ProTable
                    data={acceptanceCiteriaProjectData}
                    rowsCount={(acceptanceCiteriaListInternal?.tasks?.length ?? 0) + 1}
                    getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
                    selectedTableRow={selectedAccaptanceTableRow}
                />
            </div>

            <DetaiWindowWrapper
                hidden={[acceptanceCiteriaListIsDisplayed || taskIsDisplayed || readyCiteriaListIsDisplayed]}
            >
                {renderAcceptanceCiteriaList()}
                {renderReadyCiteriaList()}
                {renderTaskDetailWindow()}
            </DetaiWindowWrapper>
        </ProPage>
    );
};
