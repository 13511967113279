import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProPage } from '../../components/Page/Page';
import droppendIceImg from './../../utils/images/streamline-icon-dopped-icecream.png';
import { emptyBreadcrumpPath } from './NotFound';

type Props = {
    message?: string[];
    loading?: boolean;
};

export const ProNoDataOrRightsPage = ({ message, loading = false }: Props) => {
    const { t } = useTranslation();
    return (
        <ProPage breadCrump={emptyBreadcrumpPath} name={t('error.noRights.title')} loading={loading}>
            <ProNoDataWindow message={message?.reduce((akt, sum) => `${akt} ${sum}`, '')} />
        </ProPage>
    );
};

type ProErrorProps = {
    message?: string;
};

export const ProNoDataWindow = ({ message }: ProErrorProps) => {
    const { t } = useTranslation();
    return (
        <div className="ProErrorPage ProErrorPage__NotImplemented">
            <h1 className="ProErrorPage--head">
                <img src={droppendIceImg} alt="not found" />
                <span>{t('error.noRights.number')}</span>
            </h1>
            <p className="ProErrorPage--sub">{t('error.noRights.header')}</p>
            <p className="ProErrorPage--text">{message}</p>
        </div>
    );
};
