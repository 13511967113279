import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WBS_ELEMENT_BASE_URL } from '../../../api/api';
import { useGenericAPI } from '../../../api/useGenericApi';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProjectStatus } from '../../../atoms/ProjectStatus/ProjectStatus';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { ProDate, ProValue } from '../../../atoms/Value/ProValue';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { WBSContext } from '../../../contexts/WBSContext';
import { NotFoundPage } from '../../../modules/ProErrorPage/NotFound';
import { getIconForTypeInNameCell } from '../../../modules/Windows/WBSElement/WBSElement.helpers';
import { getPersonsName } from '../../../utils/helpers';
import { Components } from '../../../utils/types';
import { usePersonalWBSElemDetailFrame } from './WorkPackage.helpers';

export const PersonalWorkPackagesFrame = () => <PersonalWorkPackagesPage aspect="personal" />;
export const TeamWorkPackagesFrame = () => <PersonalWorkPackagesPage aspect="team" />;

export const PersonalWorkPackagesPage = ({ aspect }: { aspect: 'personal' | 'team' }) => {
    const { t } = useTranslation();
    const [selectedTableRow, setSelectedTableRow] = useState<number | undefined>();
    const [selectedElement, setSelectedElement] = useState<Components.Schemas.WBSElementDeltaDTO>();

    const { selectedWBSStructureId, selectedDeltaWBSStructureId } = useContext(WBSContext);

    const {
        data: wbsElements,
        error,
        loading,
        sync,
    } = useGenericAPI<Components.Schemas.WBSElementDeltaDTO[]>(
        `${WBS_ELEMENT_BASE_URL}/Personal/Baseline/${selectedWBSStructureId}/Delta`,
        selectedDeltaWBSStructureId
    );

    const deselectTableRow = () => {
        setSelectedTableRow(undefined);
        setSelectedElement(undefined);
        openWBSElement('hidden');

        // setUrlEmpty();
    };

    const selectTableRow = (i: number) => {
        setSelectedTableRow(i);
        setSelectedElement(wbsElements ? wbsElements[i] : undefined);
        openWBSElement('view');
    };

    const {
        data: detailWbsElement,
        isDisplayed,
        loading: detailLoading,
        mode: WBSDetailMode,
        open: openWBSElement,
        renderDetailWindow,
        sync: detailSync,
    } = usePersonalWBSElemDetailFrame(deselectTableRow, selectedElement?.id, sync);

    const tableData: TableColData[] = [
        {
            name: 'WBS Element',
            cellRender: (i) => {
                const item = wbsElements && wbsElements[i];
                if (item) {
                    return (
                        <>
                            <div className="ProTable__levelBars ">
                                <div className={classNames('ProTable__levelBar', `ProTable__levelBar-0`)}>0</div>
                            </div>
                            <span className="ProTable__name" onClick={() => selectTableRow(i)}>
                                {item.wbsElementType && getIconForTypeInNameCell(item.wbsElementType)}
                                {item.name}
                                {item.humanResourceAssignments && item.humanResourceAssignments[0]
                                    ? ' - ' + item.humanResourceAssignments[0].humanResource?.teamPosition?.name
                                    : ''}
                            </span>
                        </>
                    );
                }
            },
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) => {
                const item = wbsElements && wbsElements[i];

                if (item) {
                    return (
                        <ActionCell
                            i={i}
                            selectTableRow={() => {
                                selectTableRow(i);
                            }}
                            detailWindowMode={[WBSDetailMode]}
                        />
                    );
                }
            },
            colSpace: 4,
        },

        {
            name: t('table.cols.status'),
            cellRender: (i) => {
                const item = wbsElements && wbsElements[i];
                return <ProjectStatus delta={item} id={item?.id} />;
            },
            className: 'ProTable__status',
            colSpace: 3,
        },
        {
            name: 'Process status',
            cellRender: (i) => (wbsElements && wbsElements[i]?.wbsCompletionState?.completionStateType) ?? 'unknown',
            hidden: () => !isDisplayed,
            colSpace: 2,
        },
        {
            name: t('table.cols.complete'),
            cellRender: (i) => {
                const item = wbsElements && wbsElements[i];
                if (item) return <ProValue type="value" entity="%" value={item.degreeOfCompletion} />;
            },
            hidden: () => !isDisplayed,
            colSpace: 2,
            rightAligned: true,
        },
        {
            name: 'Forcasted effort',
            cellRender: (i) =>
                // (wbsElements &&
                //     wbsElements[i] &&
                //     wbsElements[i].humanResourceAssignments &&
                //     wbsElements[i].humanResourceAssignments![0] &&
                //     wbsElements[i].humanResourceAssignments![0].humanResource?.capacity) ??
                'unknown',
            hidden: () => !isDisplayed,
            colSpace: 3,
        },
        {
            name: 'Planned start',
            cellRender: (i) => <ProDate>{wbsElements && wbsElements[i]?.startDate}</ProDate> ?? '-',
            hidden: () => !isDisplayed,
            colSpace: 2,
        },
        {
            name: 'Planned end',
            cellRender: (i) => <ProDate>{wbsElements && wbsElements[i]?.endDate}</ProDate> ?? '-',
            hidden: () => !isDisplayed,
            colSpace: 2,
        },
        {
            name: aspect === 'personal' ? 'Team' : 'Person',
            cellRender: (i) => {
                if (aspect === 'personal') return (wbsElements && wbsElements[i]?.team?.name) ?? '-';
                return wbsElements && getPersonsName(t, wbsElements[i].responsiblePerson);
            },
            hidden: () => !isDisplayed,
            colSpace: 3,
        },
    ];

    if (loading !== undefined && !loading && error) {
        return <NotFoundPage message={'error'} />;
    }

    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [
                    aspect === 'personal'
                        ? ApplicationElementType.PersonalManagement
                        : ApplicationElementType.TeamManagement,
                ],
                t
            )}
            name={aspect === 'personal' ? t('navigation.personalMan.workPackages') : 'Team workpackages'}
            icon={ProIconData.package_fill}
            isHidden={false}
            displyProjectSelector
            loading={loading}
            displyTeamSelector
        >
            <ProTable
                data={tableData}
                rowsCount={wbsElements?.length ?? 1}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => 'ProTable--row__level-0'}
            />
            <div
                className={classNames(
                    'ProApp--detailWindowSection',
                    isDisplayed && 'ProApp--detailWindowSection__expanded'
                )}
            >
                {renderDetailWindow()}
            </div>
        </ProPage>
    );
};
