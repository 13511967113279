import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { PortfolioPeoplePage } from '../../PortfolioManagement/People/PortfolioPeoplePage';
import { ProjectQualityPage } from '../../ProjectManagement/Quality/ProjectQualityPage';
import { TeamRequirementsManagementPage } from './Requirements';

export const RequirementsSelectionFrame = () => {
    const { selectionMode } = useContext(SelectionContext);
    const { t } = useTranslation();
    return (
        <>
            <PortfolioPeoplePage
                isHidden={selectionMode !== SelectionType.HumanResource}
                pageTitle={t('windows.selectPerson')}
            />
            <ProjectQualityPage
                isHidden={selectionMode !== SelectionType.WBSElement}
                pageTitle={'select a WBS element'}
            />
            <TeamRequirementsManagementPage
                isHidden={selectionMode === SelectionType.Move ? false : selectionMode !== undefined}
            />
        </>
    );
};
