/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconData } from '../Icons/IconData';
import { ProDialog } from '../ProDialog/ProDialog';

type Props = {
    className?: string;
};

export const Imprint = ({ className }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();
    return (
        <>
            <a onClick={() => setIsModalOpen(true)} className={className} href="#">
                {t('navigation.inprint.inprint')}
            </a>
            <ProDialog
                breadCrump={[]}
                icon={ProIconData.cog_outline}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                title="Legal notification"
            >
                <h1>{t('navigation.inprint.inprint')}</h1>
                <p>Angaben gemäß § 5 TMG</p>
                <h3>4optimal Management GmbH </h3>
                Schützenaustr. 3 86415 Mering
                <b>Handelsregister: HRB 18664 Registergericht:</b>
                <p>
                    Amtsgericht 86150 Augsburg Vertreten durch: Dr. Manfred Friebert, Maximilian Wollfinger, Frederic
                    Wollinger
                </p>
                <b>Kontakt </b>
                <p>
                    Telefon: +49 8233 780020 <br />
                    E-Mail: info@4optimal.de
                </p>
                <p>Umsatzsteuer-ID Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE999999999</p>
                <b>Verbraucherstreitbeilegung/Universalschlichtungsstelle</b>
                <p>
                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.
                </p>
                <b>Cookies</b>
                <p>Wir verwenden keine Cookies!</p>
            </ProDialog>
        </>
    );
};
