import i18next from 'i18next';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_BASE_URL, PERSON_API_BASE, noop } from '../../api/api';
import { updateAPIRequest, useGenericAPI } from '../../api/useGenericApi';
import { ProButton } from '../../atoms/Button/ProButton';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProIconData, ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProDialog } from '../../atoms/ProDialog/ProDialog';
import { ProSelector } from '../../atoms/ProSelector/ProSelector';
import { UserContext } from '../../contexts/UserContext';
import { PersonDetailContent } from '../../modules/Windows/PersonDetailWindow/PersonDetailWindow';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';

type Props = {
    userDialogIsOpen: boolean;
    setUserDialogIsOpen: Dispatch<SetStateAction<boolean>>;
};

type LanguageIntent = 'de' | 'en';
type Language = { name: string; ident: LanguageIntent };

const Languages: Language[] = [
    { name: 'Deutsch', ident: 'de' },
    { name: 'English', ident: 'en' },
];

export const isLanuage = (item: any): item is Language => {
    return (item as Language).name === 'en' || (item as Language).name === 'de';
};

export const UserSettings = ({ setUserDialogIsOpen, userDialogIsOpen }: Props) => {
    const { user, logout } = useContext(UserContext);
    const [formData, setFormData] = useState<Components.Schemas.PersonDTO | undefined>();
    const [isUserInEditMode, setIsUserInEditMode] = useState<boolean>(false);
    const [language, setLanguage] = useState<Language>(
        Languages.find((cur) => cur.ident === user?.localization) ?? Languages[0]
    );
    const { t } = useTranslation();

    const personID = useMemo(() => user?.personId ?? undefined, [user]);

    const {
        data: personData,
        updateRequest: updatePerson,
        sync,
    } = useGenericAPI<Components.Schemas.PersonDTO>(PERSON_API_BASE, personID);

    useEffect(() => {
        if (personData) setFormData(personData);
    }, [personData]);

    useEffect(() => {
        const changedLang = Languages.find((cur) => cur.ident === user?.localization);
        if (changedLang) {
            setLanguage(changedLang);
        }
    }, [user]);

    const saveUser = () => {
        if (formData) {
            updatePerson(formData)
                .then((res) => {
                    setIsUserInEditMode(false);
                    sync();
                })
                .catch((e) => {});
        }
    };

    const changeLanguage = (newVal: Language) => {
        if (user) {
            updateAPIRequest<Components.Schemas.ApplicationUserDTO>(
                { ...user, localization: newVal.ident },
                ACCOUNT_BASE_URL,
                user.email
            );
        }

        setLanguage(newVal);
        i18next
            .changeLanguage(newVal.ident, (err) => {
                if (err) {
                    console.log('cant change user lang', err);
                }
            })
            .then(noop, noop);
    };

    return (
        <ProDialog
            isOpen={userDialogIsOpen}
            setIsOpen={setUserDialogIsOpen}
            title={user?.firstName ?? 'User'}
            icon={ProIconData.user_outline}
            breadCrump={[{ name: 'User' }, { name: 'Account' }]}
            editModeOn={isUserInEditMode}
            buttons={
                isUserInEditMode ? (
                    <>
                        <ProButton
                            leftIcon={ProIconData.close_stroke}
                            text={t('buttons.cancel')}
                            onClick={() => setIsUserInEditMode(false)}
                            hoverIntent={ProIntent.Transpared}
                            mainIntent={ProIntent.Transpared}
                            lineIntent={ProIntent.Transpared}
                        />
                        <ProButton
                            mainIntent={ProIntent.Primary}
                            hoverIntent={ProIntent.Secondary}
                            onClick={saveUser}
                            leftIcon={ProIconDataButtons.save_s}
                            text={t('buttons.save')}
                        />
                    </>
                ) : (
                    <>
                        <ProSelector
                            name={t('windows.language')}
                            items={Languages.map((cur) => cur.name)}
                            onChange={(newVal) => {
                                const newLang = Languages.find((cur) => cur.name === newVal);
                                if (newLang) {
                                    changeLanguage(newLang);
                                }
                            }}
                            size={ProSize.Medium}
                            icon={ProIconData.windows_stroke}
                            selectedItem={language?.name}
                            tooltipPlacement="left"
                            intent={ProIntent.PrimaryLight}
                        />
                        <ProButton
                            leftIcon={ProIconData.user_outline}
                            text={t('buttons.logout')}
                            onClick={logout}
                            mainIntent={ProIntent.PrimaryLight}
                            hoverIntent={ProIntent.Secondary}
                        />
                        <ProIconButton
                            tooltip={t('buttons.edit')}
                            mainIntent={ProIntent.PrimaryLight}
                            hoverIntent={ProIntent.Warning}
                            onClick={() => setIsUserInEditMode(true)}
                            icon={ProIconDataButtons.pen_s}
                        />
                    </>
                )
            }
        >
            <PersonDetailContent isDisabled={!isUserInEditMode} formData={personData} setFormData={setFormData} />
        </ProDialog>
    );
};
