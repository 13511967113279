import classNames from 'classnames';
import React from 'react';
import { MenuItemType } from '../../constants/types';
import { useRouting } from '../../hooks/useRouting';
import { ProIcon } from '../Icons/Icon';

type Props = {
    item: MenuItemType;
    className?: string;
};

export const ProMenuCard = ({ item, className }: Props) => {
    const { navigateSubRoute } = useRouting();

    const card = (
        <div
            className={classNames(
                'ProMenuCardWrapper',
                item.bar === 'horisontal' ? 'ProMenuCardWrapper--horisontal' : 'ProMenuCardWrapper--vertical'
            )}
        >
            <div
                className={classNames(
                    'ProMenuCard',
                    className,
                    item.bar === 'horisontal' ? 'ProMenuCardWrapper--horisontal' : 'ProMenuCardWrapper--vertical'
                )}
            >
                <div
                    className={classNames(
                        'ProMenuCard--bar',
                        item.bar === 'horisontal' ? 'ProMenuCard--bar--horisontal' : 'ProMenuCard--bar--vertical'
                    )}
                >
                    bar
                </div>
                <div className="ProMenuCard--content">
                    <div className="ProMenuCard--titleRow">
                        {item.icon && <ProIcon icon={item.icon} className="ProMenuCard--icon" />}
                        <div className="ProMenuCard--name">{item.name}</div>
                    </div>
                    <div className="ProMenuCard--description">{item.description}</div>
                    {item.iconSection && <div className="ProMenuCard__iconSection">{item.iconSection}</div>}
                </div>
            </div>
        </div>
    );
    return item.sublinks ? (
        card
    ) : (
        <div className="NoHover ProMenu--link" onClick={() => navigateSubRoute(item.section, item.link)}>
            {card}
        </div>
    );
};
