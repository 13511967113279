import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProNumberField } from '../../../../atoms/AttrField/NumberField';
import { ProCol } from '../../../../atoms/Grid/Col';
import { ProRow } from '../../../../atoms/Grid/Row';
import { Components } from '../../../../utils/types';
import { getCostTimeDeltaIntent } from '../CostDetailWindowContent';

import { ProObjectDropdown } from '../../../../atoms/AttrField/ProObjectDropdown';
import { UserContext } from '../../../../contexts/UserContext';
import { getQualityIntent } from '../WBSElement.helpers';

type Props = {
    data: Components.Schemas.WBSElementDeltaDTO;
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>;
    isDisabled: boolean;
};

export const QualityDetailWindowAnalysisTab = ({ setData, data, isDisabled }: Props) => {
    const { portfolio } = useContext(UserContext);
    const wbsCompletionStates = portfolio?.wbsCompletionStates;
    const qualityStates = portfolio?.qualityStates;
    const { t } = useTranslation();

    return (
        <>
            <ProRow>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.forcastetQuality')}
                        value={data.quality}
                        handleChange={(value) => setData((cur) => ({ ...cur, quality: Number(value) }))}
                        disabled={isDisabled}
                        entity="%"
                        intent={getQualityIntent(data)}
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.plannedQuality')}
                        value={data?.wbsElementDelta?.quality}
                        entity="%"
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.qualityDeviation')}
                        value={data?.deltaQualityState === 'DivisionByZero' ? '-' : data?.deltaQualityPercent}
                        intent={getCostTimeDeltaIntent(data?.deltaCostState)}
                        entity="%"
                    />
                </ProCol>
            </ProRow>
            <ProRow spacer>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.forcastedScope')}
                        value={data.scopePoints}
                        handleChange={(value) => setData((cur) => ({ ...cur, scopePoints: Number(value) }))}
                        disabled={isDisabled}
                        entity="SP"
                    />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField name={t('windows.plannedScope')} value={data?.scopePoints} entity="SP" />
                </ProCol>
                <ProCol span={4}>
                    <ProNumberField
                        name={t('windows.scopeDeviation')}
                        value={(data.scopePoints ?? 1) - (data.wbsElementDelta?.scopePoints ?? 1)}
                        entity={t('entities.sp')}
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={6}>
                    <ProObjectDropdown
                        name={'Completion state'}
                        value={data.wbsCompletionState}
                        items={wbsCompletionStates}
                        disabled={isDisabled}
                        setValue={(newVal) =>
                            setData((cur) => ({
                                ...cur,
                                wbsCompletionState: newVal,
                            }))
                        }
                        valueSelector={'name'}
                    />
                </ProCol>
                <ProCol span={4}></ProCol>
                <ProCol span={2}>
                    <ProNumberField
                        name={'Progress'}
                        value={data?.degreeOfCompletion}
                        entity="%"
                        entityLengh="s"
                        accuracy={0}
                        disabled={isDisabled}
                        handleChange={(val) => {
                            setData((cur) => ({ ...cur, degreeOfCompletion: Number(val) }));
                        }}
                    />
                </ProCol>
            </ProRow>
            <ProRow>
                <ProCol span={6}>
                    <ProObjectDropdown
                        name={'Quality state'}
                        value={data.qualityState}
                        items={qualityStates}
                        disabled={isDisabled}
                        setValue={(newVal) =>
                            setData((cur) => ({
                                ...cur,
                                qualityState: newVal,
                            }))
                        }
                        valueSelector={'name'}
                    />
                </ProCol>
                <ProCol span={6}></ProCol>
                {/* <ProCol span={2}>
                    <ProNumberField
                        name={'Quallity'}
                        value={data?.quality}
                        entity="%"
                        entityLengh="s"
                        accuracy={0}
                        disabled={isDisabled}
                        handleChange={(val) => {
                            setData((cur) => ({ ...cur, quality: Number(val) }));
                        }}
                    />
                </ProCol> */}
            </ProRow>
        </>
    );
};
