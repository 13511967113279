import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { NotImplemented } from '../../../modules/ProErrorPage/NotImplemented';

export const PortfolioRoadmapPage = () => {
    const { t } = useTranslation();
    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.PortfolioMan], t)}
            name={t('navigation.portfolioMan.portfolio')}
        >
            <NotImplemented message={t('navigation.portfolioMan.portfolio')} />
        </ProPage>
    );
};
