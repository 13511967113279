import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL, deleteWBSDependency, DEPENDENCY_API_BASE, updateWBSDependency } from '../../api/api';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProCol } from '../../atoms/Grid/Col';
import { ProRow } from '../../atoms/Grid/Row';
import { ProIconData, ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProjectContext } from '../../contexts/ProjectContext';
import { WBSSelectionContext, WBSSelectionMode } from '../../contexts/WBSSelectionContext';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';
import { DependencyWindow } from './DependencyWindow';

type Props = {
    name: string;
    dependencies?: Components.Schemas.DependencyDTO[] | null;
    wbsElement?: Components.Schemas.WBSElementDeltaDTO;
    syncElement: () => void;
    disabled: boolean;
};

export const PreDecessorList = ({ dependencies, name, wbsElement, syncElement, disabled }: Props) => {
    const { selectionMode, turnSelectionModeOff, setSelectionFunction, setSelectedElement, setSelectionMode } =
        useContext(WBSSelectionContext);
    const { t } = useTranslation();

    const { selectedProject } = useContext(ProjectContext);

    useEffect(() => {
        setSelectionFunction(() => async (elm: Components.Schemas.WBSElementDTO) => {
            const newDependency: Components.Schemas.DependencyDTO = {
                dependencyType: 'EndStart',
                projectId: selectedProject?.id,
                startWBSElement: {
                    id: elm.id,
                },
                targetWBSElement: {
                    id: wbsElement?.id,
                },
            };
            const jwtObj = localStorage.getItem('auth');

            try {
                await axios.post<Components.Schemas.DependencyDTO>(
                    `${API_BASE_URL}${DEPENDENCY_API_BASE}`,
                    { ...newDependency },
                    {
                        headers: { Authorization: `Bearer ${jwtObj}` },
                    }
                );
                syncElement();
            } catch (e) {
                throw e;
            }
        });
    }, [wbsElement?.id, selectionMode]);

    const delteAction = (id: string) => {
        deleteWBSDependency(id)
            .then(() => {
                syncElement();
            })
            .catch(() => {});
    };

    const saveDep = (dep: Components.Schemas.DependencyDTO) => {
        updateWBSDependency(dep)
            .then(() => {
                syncElement();
            })
            .catch(() => {});
    };

    const turnPreprosSelectionOn = () => {
        setSelectedElement(wbsElement);
        setSelectionMode(WBSSelectionMode.Predecessor);
    };
    const turnPreprosSelectionOf = () => {
        setSelectedElement(undefined);
        turnSelectionModeOff();
    };

    return (
        <div className="ProAttrField ProAttrField__dependencies">
            <div className="ProAttrField__head">
                <span className="ProAttrField__name">
                    <h3>{name}</h3>
                </span>
                <span className="ProAttrField__buttons">
                    {selectionMode !== WBSSelectionMode.Predecessor ? (
                        <ProIconButton
                            tooltip={t('buttons.addElement')}
                            icon={ProIconDataButtons.add_xs}
                            onClick={turnPreprosSelectionOn}
                            size={ProSize.XSmall}
                            mainIntent={ProIntent.Success}
                        />
                    ) : (
                        <ProIconButton
                            tooltip={t('buttons.cancel')}
                            icon={ProIconData.close_stroke}
                            onClick={turnPreprosSelectionOf}
                            size={ProSize.XSmall}
                            mainIntent={ProIntent.Error}
                        />
                    )}
                </span>
            </div>
            {dependencies && dependencies.length !== 0 && (
                <div className="ProAttrField__dependencies--header">
                    <ProRow>
                        <ProCol span={4}>{t('table.cols.name')}</ProCol>
                        <ProCol span={1}>{t('table.cols.finished')}</ProCol>
                        <ProCol span={2}>{t('table.cols.delay')}</ProCol>
                        <ProCol span={2}>{t('table.cols.type')}</ProCol>
                        <ProCol span={3}></ProCol>
                    </ProRow>
                </div>
            )}
            {dependencies?.map((cur) => (
                <>
                    <DependencyWindow
                        dependency={cur}
                        displayWBS={cur.startWBSElement}
                        delteAction={delteAction}
                        key={`dependency_${cur.id}`}
                        disabled={disabled}
                        save={saveDep}
                    />
                </>
            ))}
            {dependencies && dependencies.length === 0 && (
                <ProRow className="ProAttrField__dependencies--box ProAttrField__dependencies--box__empty">
                    {t('error.noData.predecessorDefined')}
                </ProRow>
            )}
        </div>
    );
};
