import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProIcon } from '../../atoms/Icons/Icon';
import { ProIconData } from '../../atoms/Icons/IconData';
import { Imprint } from '../../atoms/Imprint/Imprint';
import { getBASE_MenuData } from '../../constants/menu';
import { MenuItemType } from '../../constants/types';
import { UserContext } from '../../contexts/UserContext';
import { useRouting } from '../../hooks/useRouting';
import { ProIntent, ProSize } from '../../utils/enums';

export const ApplicationStartPage = () => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();
    const MenuData = useMemo(() => getBASE_MenuData(t), [getBASE_MenuData]);
    const { navigateSubRoute } = useRouting();

    return (
        <div className="ProAreaWrapper">
            <div className="ProArea ProArea__application">
                {MenuData.map((cur, i) => (
                    <div key={`homeMenuItem_${i}`} className="ProArea--section">
                        <div className="ProArea--section-head">
                            <Link to={cur.link}>
                                <ProIcon icon={cur.icon} size={ProSize.XLarge} />
                                <h1>{cur.name}</h1>
                            </Link>
                        </div>

                        <div>
                            <ul>
                                {cur.sublinks?.map((act, i) =>
                                    act.name === 'spacer' ? (
                                        <div className="ProArea--spacer" key={`homeSubMenuItem_${i}`}>
                                            spacer
                                        </div>
                                    ) : (
                                        <div
                                            className="ProMenuItem"
                                            onClick={() => navigateSubRoute(act.section, act.link)}
                                            key={`homeSubMenuItem_${i}`}
                                        >
                                            <li className={classNames(act.disabled && 'ProArea__disabled')}>
                                                <ProIcon icon={act.icon} size={ProSize.Medium} />
                                                {act.name}
                                                <ProIcon
                                                    icon={ProIconData.question}
                                                    size={ProSize.Small}
                                                    intent={ProIntent.Primary}
                                                    className="ProArea__infoIcon"
                                                />
                                            </li>
                                        </div>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
            <div className="ProNotification--portfolio">
                <ProIcon icon={ProIconData.portfolio_fill} size={ProSize.Medium} />
                <h3>{user?.portfolios![0]?.name ?? 'No portfolio name given'}</h3>
                <p>{user?.portfolios![0]?.description ?? 'No portfolio description given'}</p>
            </div>
            <Imprint className="imprint" />
        </div>
    );
};

type Props = {
    data: MenuItemType;
};

export const ApplicationDetailStartPage = ({ data }: Props) => {
    const { navigateSubRoute } = useRouting();
    return (
        <div className="ProArea ProArea__details">
            <div className="ProArea--section">
                <div className="ProArea--section-head ProArea--section-head__details"></div>

                <div>
                    <ul>
                        {data.sublinks?.map((act, i) =>
                            act.name === 'spacer' ? (
                                <div className="ProArea--spacer" key={`homeSubMenuItem_${i}`}>
                                    spacer
                                </div>
                            ) : (
                                <div
                                    className="ProMenuItem"
                                    onClick={() => navigateSubRoute(act.section, act.link)}
                                    key={`homeSubMenuItem_${i}`}
                                >
                                    <li className={classNames(act.disabled && 'ProArea__disabled')}>
                                        <div className="ProMenuItem__content">
                                            <div>
                                                <ProIcon icon={act.icon} size={ProSize.Medium} />
                                                {act.name}
                                            </div>
                                            <p>{act.description}</p>
                                        </div>
                                    </li>
                                </div>
                            )
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
