import { BreadcrumbProps, Breadcrumbs } from '@blueprintjs/core';
import React from 'react';
import { TFunction } from 'react-i18next';
import { PROJECTS_BASE_URL } from '../../api/api';
import { Components } from '../../utils/types';

export enum ApplicationElementType {
    Project = 'Project',
    Projects = 'Projects',
    WBSElement = 'WBSElement',
    Person = 'Person',
    Role = 'Role',
    People = 'People',
    HumanResources = 'HumanResources',
    Portfolio = 'Portfolio',
    Team = 'Team',
    TeamStruc = 'TeamStruc',
    PersonalManagement = 'PersonalManagement',
    ForecastSession = 'ForecastSession',
    TeamManagement = 'TeamManagement',
    Tasks = 'Tasks',
    Material = 'Material',
    Capacities = 'Capacities',
    ReadyCriteria = 'ReadyCriteria',
    PortfolioMan = 'PortfolioMan',
    CapacityMan = 'CapacityMan',
    PeopleAndPositions = 'PeopleAndPositions',
    ProjectMan = 'ProjectMan',
    Integration = 'Integration',
    Requirements = 'Requirements',
    Requirement = 'Requirement',
    Quality = 'Quality',
    WBS = 'WBS',
    TeamPosition = 'TeamPosition',
    FuctionalRoles = 'FuctionalRoles',
    Units = 'Units',
    Functions = 'Functions',
    BaseData = 'Base Data',
    Level = 'Level',
}

export type ApplicationElementTypeObject = { type: ApplicationElementType; id?: string; name?: string | null };

const isApplicationElementTypeObject = (
    slides: ApplicationElementType | ApplicationElementTypeObject
): slides is ApplicationElementTypeObject => {
    return (
        (slides as ApplicationElementTypeObject).id !== undefined ||
        (slides as ApplicationElementTypeObject).name !== undefined
    );
};

// const isApplicationElementTypeObject = (
//     slides: ApplicationElementType | ApplicationElementTypeObject
// ): slides is ApplicationElementTypeObject => {
//     return (
//         typeof (slides as ApplicationElementTypeObject).id === 'string' ||
//         typeof (slides as ApplicationElementTypeObject).name === 'string'
//     );
// };

export const translateStatus = (t: TFunction, state?: string | null) => {
    switch (state) {
        case 'in funnel':
            return t('status.inFunnel');
        case 'not ready':
            return t('status.notReady');
        case 'rejected':
            return t('status.rejected');
        case 'ready':
            return t('status.ready');
        case 'ready for review':
            return t('status.readyForReview');
        case 'on hold':
            return t('status.onHold');
        case 'scheduled':
            return t('status.scheduled');
        case 'finished':
            return t('status.finished');
        default:
            return state;
    }
};

export const getBaseBCItemForType = (
    types: (ApplicationElementType | ApplicationElementTypeObject)[],
    t: TFunction
): BreadCrumpItem[] => {
    const elems: ApplicationElementTypeObject[] = types.map((akt) => {
        if (isApplicationElementTypeObject(akt)) {
            return akt;
        } else {
            return { type: akt };
        }
    });

    return elems.map((cur) => {
        switch (cur.type) {
            case ApplicationElementType.ForecastSession:
                return {
                    name: t('windows.forcast'),
                    href: getItemHref(ApplicationElementType.ForecastSession, cur.id),
                };
            case ApplicationElementType.Tasks:
                return {
                    name: t('navigation.teamMan.tasks'),
                    href: getItemHref(ApplicationElementType.Tasks, cur.id),
                };
            case ApplicationElementType.TeamManagement:
                return {
                    name: cur.name ? `${cur.name} - ${t('navigation.teamMan.base')}` : t('navigation.teamMan.base'),
                    href: getItemHref(ApplicationElementType.TeamManagement, cur.id),
                };
            case ApplicationElementType.PersonalManagement:
                return {
                    name: t('navigation.personalMan.base'),
                    href: getItemHref(ApplicationElementType.PersonalManagement, cur.id),
                };
            case ApplicationElementType.TeamStruc:
                return {
                    name: cur.name
                        ? `${cur.name} - ${t('navigation.projectMan.teamStruct')}`
                        : t('navigation.projectMan.teamStruct'),
                    href: getItemHref(ApplicationElementType.TeamStruc, cur.id),
                };
            case ApplicationElementType.Project:
                return {
                    name: t('windows.project'),
                    href: getItemHref(ApplicationElementType.Project, cur.id),
                };
            case ApplicationElementType.People:
                return {
                    name: t('windows.people'),
                    href: getItemHref(ApplicationElementType.People, cur.id),
                };
            case ApplicationElementType.HumanResources:
                return {
                    name: t('windows.humanResources'),
                    href: getItemHref(ApplicationElementType.HumanResources, cur.id),
                };
            case ApplicationElementType.Material:
                return {
                    name: t('navigation.portfolioMan.capacityMan.material'),
                    href: getItemHref(ApplicationElementType.Material, cur.id),
                };
            case ApplicationElementType.Portfolio:
                return {
                    name: t('windows.portfolio'),
                    href: getItemHref(ApplicationElementType.Portfolio, cur.id),
                };
            case ApplicationElementType.Team:
                return {
                    name: cur.name ? `Team ${cur.name}` : t('navigation.teamMan.team'),
                    href: getItemHref(ApplicationElementType.Team, cur.id),
                };
            case ApplicationElementType.Capacities:
                return {
                    name: t('navigation.portfolioMan.capacityMan.base'),
                    href: getItemHref(ApplicationElementType.Capacities, cur.id),
                };
            case ApplicationElementType.PortfolioMan:
                return {
                    name: cur.name
                        ? `${cur.name} - ${t('navigation.portfolioMan.base')}`
                        : t('navigation.portfolioMan.base'),
                    href: getItemHref(ApplicationElementType.PortfolioMan, cur.id),
                };
            case ApplicationElementType.ReadyCriteria:
                return {
                    name: t('navigation.portfolioMan.capacityMan.ready'),
                    href: getItemHref(ApplicationElementType.ReadyCriteria, cur.id),
                };
            case ApplicationElementType.CapacityMan:
                return {
                    name: t('navigation.portfolioMan.capacityMan.base'),
                    href: getItemHref(ApplicationElementType.Capacities, cur.id),
                };
            case ApplicationElementType.PeopleAndPositions:
                return {
                    name: t('navigation.portfolioMan.peopleAndPositions.base'),
                    href: getItemHref(ApplicationElementType.PeopleAndPositions, cur.id),
                };
            case ApplicationElementType.ProjectMan:
                return {
                    name: t('navigation.projectMan.base', { break: '' }),
                    href: getItemHref(ApplicationElementType.ProjectMan, cur.id),
                };
            case ApplicationElementType.Integration:
                return {
                    name: t('navigation.projectMan.integrationMan'),
                    href: getItemHref(ApplicationElementType.Integration, cur.id),
                };
            case ApplicationElementType.Requirements:
                return {
                    name: t('navigation.teamMan.requirements'),
                    href: getItemHref(ApplicationElementType.Requirements, cur.id),
                };
            case ApplicationElementType.Requirement:
                return {
                    name: t('types.requirement'),
                    href: getItemHref(ApplicationElementType.Requirement, cur.id),
                };
            case ApplicationElementType.Quality:
                return {
                    name: t('navigation.projectMan.qualityMan'),
                    href: getItemHref(ApplicationElementType.Quality, cur.id),
                };
            case ApplicationElementType.Projects:
                return {
                    name: t('navigation.portfolioMan.projecs'),
                    href: getItemHref(ApplicationElementType.Projects, cur.id),
                };
            case ApplicationElementType.TeamPosition:
                return {
                    name: t('navigation.portfolioMan.peopleAndPositions.positions'),
                    href: getItemHref(ApplicationElementType.TeamPosition, cur.id),
                };
            case ApplicationElementType.FuctionalRoles:
                return {
                    name: t('navigation.portfolioMan.capacityMan.roles'),
                    href: getItemHref(ApplicationElementType.FuctionalRoles, cur.id),
                };
            case ApplicationElementType.Units:
                return {
                    name: t('navigation.portfolioMan.baseData.units'),
                    href: getItemHref(ApplicationElementType.Units, cur.id),
                };
            case ApplicationElementType.Functions:
                return {
                    name: t('navigation.portfolioMan.baseData.functions'),
                    href: getItemHref(ApplicationElementType.Functions, cur.id),
                };

            default:
                return { name: cur.type.toString() };
        }
    });
};
export const getItemHref = (type: ApplicationElementType, id?: string): string => {
    switch (type) {
        case 'Project':
            return `/app/portfolio/projects/${id}`; //TODO: Do correct fe pathes here not api pathes
        case ApplicationElementType.ProjectMan:
            return `/app/project`;
        case ApplicationElementType.PersonalManagement:
            return `/app/personal`;
        case ApplicationElementType.TeamManagement:
            return `/app/teams`;
        case ApplicationElementType.PortfolioMan:
            return `/app/portfolio`;
        case ApplicationElementType.TeamStruc:
            return `/app/project/teamStructure`;
        case ApplicationElementType.Team:
            return `/app/project/teamStructure/${id}`;
        case ApplicationElementType.HumanResources:
            return `/app/project/teamStructure/${id}`;
        case 'Person':
            return `${PROJECTS_BASE_URL}/${id}`;
        default:
            return ``;
    }
};

export type BreadCrumpItem = {
    type?: ApplicationElementType;
    id?: string;
    name: string;
    href?: string;
};

const getBreadCrumpProps = (item: BreadCrumpItem): BreadcrumbProps => {
    if (item.href) return { text: item.name, href: item.href };
    if (item.type && item.id) return { text: item.name, href: getItemHref(item.type, item.id) };
    return { text: item.name };
};

export const renderBreadcrump = (path: BreadCrumpItem[]) => {
    const Breadcrumps: BreadcrumbProps[] = path.map((cur) => getBreadCrumpProps(cur));
    return <Breadcrumbs items={Breadcrumps} />;
};

// export const renderBreadcrump = (path: Components.Schemas.StringGuidTuple[]) => {
//     const Breadcrumps: IBreadcrumbProps[] = path.map((cur) => ({ text: cur.item1, href: }));
//     return <Breadcrumbs items={Breadcrumps} />;
// };

export const getPersonBreadCrump = (person: Components.Schemas.PersonDTO): BreadCrumpItem => {
    return { name: person.lastName ?? '-', href: getItemHref(ApplicationElementType.Person, person.id) };
};

export const getProjectBreadCrump = (project: Components.Schemas.ProjectDTO): BreadCrumpItem => {
    return { name: project.name ?? 'Unnamed project', href: getItemHref(ApplicationElementType.Project, project.id) };
};

export const getWBSBreadCrump = (data: Components.Schemas.WBSElementDeltaDTO): BreadCrumpItem[] => {
    if (!data.breadCrumbPath) return [];
    return data.breadCrumbPath?.map((cur) => ({ name: cur.item1 ?? '', href: cur.item2 }));
};
