import React from 'react';
import { ProLogo } from '../../atoms/Logo/Logo';
import { ProPage } from '../../components/Page/Page';
import { PageSettings } from '../../components/PageSettings/PageSettings';
import { ApplicationStartPage } from '../../modules/AreaStartPage/AreaStartPage';

export const HomePage = () => (
    <ProPage appClassName="HomePage--App">
        <div className="HomePage">
            <ProLogo big className="HomePageLogo" />
            <PageSettings displyProjectSelector={false} displyWBSSelector={false} displyTeamSelector={false} />
            <ApplicationStartPage />
        </div>
    </ProPage>
);
