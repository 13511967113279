import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { deleteWBSElement } from '../../../api/api';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProTable } from '../../../atoms/Table/Table';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { WBSContext } from '../../../contexts/WBSContext';
import { WBSSelectionContext } from '../../../contexts/WBSSelectionContext';
import { useWBSDetailRouting } from '../../../hooks/useWBSDetailRouting';
import { proLinkStructure } from '../../../modules/Menu/Navigate';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { getEmptyWBSElement } from '../../../modules/Windows/WBSElement/WBSElement.helpers';
import { useWBSDetailWindow } from '../../../modules/Windows/WBSElement/WBSElementHook/WBSDetailFrame';
import { getCostTableData } from './../../../components/WBSTable/getCostTableData';

export const ProjectCostPage = ({ isHidden = false }: { isHidden?: boolean }) => {
    const { selectedElement, selectionMode, selectionFunction, turnSelectionModeOff } = useContext(WBSSelectionContext);
    const { t } = useTranslation();

    const { selectedProject, loading, selectedTeam } = useContext(ProjectContext);

    const {
        selectedWBSStructure,
        selectedWBSElement,
        setSelectedWBSElement,
        filteredData,
        openNodes,
        deselectWBSElement,
        tableData,
        setTableData,
        selectedTableRow,
        setSelectedTableRow,
        toggleOpenNode,
        syncWBSScructure,
        wbsStructureError,
        wbsStructureLoading,
        openLowerNodes,
        setRoute,
    } = useContext(WBSContext);

    const history = useHistory();

    useEffect(() => {
        setRoute(history.location.pathname);
    }, []);

    const {
        open,
        renderWBSDetailWindow,
        isDisplayed,
        mode: detailWindowMode,
    } = useWBSDetailWindow('Cost', deselectWBSElement, selectedWBSElement, syncWBSScructure);

    useEffect(() => {
        open('view', false);
    }, [selectedWBSStructure]);

    const selectTableRow = (id: number) => {
        if (selectedWBSStructure) {
            setSelectedWBSElement(filteredData![id]);
            open('view');
            setSelectedTableRow(id);
        }
    };

    const deleteWBSTableRow = (id: string) => {
        deleteWBSElement(id)
            .then(() => {
                syncWBSScructure();
                setUrlEmpty();
            })
            .catch((e) => {
                console.log('error', e);
            });
    };

    useEffect(() => {
        const newPosition = filteredData.findIndex((cur) => cur.id === selectedWBSElement?.id);
        newPosition > 0 && selectTableRow(newPosition);
    }, [selectedWBSStructure, filteredData]);

    const createWBSElement = (id: number) => {
        setSelectedWBSElement(getEmptyWBSElement(filteredData[id]));
        open('new');
        setSelectedTableRow(id);
    };

    useEffect(() => {
        setTableData(
            getCostTableData({
                createWBSElement,
                deleteWBSTableRow,
                detailWindowMode,
                filteredData,
                isDisplayed,
                openNodes,
                selectedWBSStructure,
                toggleOpenNode,
                selectionMode,
                turnSelectionModeOff,
                selectionFunction,
                selectedElement,
                t,
            })
        );
    }, [selectedWBSStructure, filteredData, isDisplayed, detailWindowMode, selectionMode, selectedElement]);

    const { error: notFoundError, setUrlEmpty } = useWBSDetailRouting({
        path: proLinkStructure.projectManagement.cost.path,
        link: proLinkStructure.projectManagement.cost.link,
        selectedWBSElement,
        filteredData,
        selectTableRow,
        openLowerNodes,
        selectedWBSStructure,
    });

    if (!loading && (!selectedTeam || !selectedProject)) {
        return <ProNoDataOrRightsPage message={[t('error.noData.noTeamOrPortfolio')]} />;
    }

    if (!wbsStructureLoading && notFoundError) return notFoundError;

    return (
        <ProPage
            // breadCrump={getBreadcrumpPath([t('navigation.projectMan.base', { break: '' })])}
            breadCrump={getBaseBCItemForType([ApplicationElementType.ProjectMan], t)}
            name={t('navigation.projectMan.costMan')}
            error={wbsStructureError}
            loading={wbsStructureLoading}
            icon={ProIconData.cost_fill}
            displyWBSSelector
            displyProjectSelector
            isHidden={isHidden}
        >
            {selectedWBSStructure && tableData && (
                <ProTable
                    data={tableData}
                    rowsCount={filteredData?.length ?? 0}
                    selectedTableRow={selectedTableRow}
                    getRowClass={(i) => `ProTable--row__level-${filteredData![i]?.level ?? 0}`}
                />
            )}
            <div
                className={classNames(
                    'ProApp--detailWindowSection',
                    isDisplayed && 'ProApp--detailWindowSection__expanded'
                )}
            >
                {renderWBSDetailWindow()}
            </div>
        </ProPage>
    );
};
