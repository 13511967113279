import classNames from 'classnames';
import { TFunction } from 'i18next';
import React, { Dispatch, PropsWithChildren, ReactNode, SetStateAction } from 'react';
import { ProHeaderAttrField } from '../../../../atoms/AttrField/AttrField';
import { ProIconData } from '../../../../atoms/Icons/IconData';
import { ProDetailWindow } from '../../../../components/DetailWindow/DetailWindow';
import { getWBSBreadCrump } from '../../../../components/DetailWindow/breadCrump.helpers';
import { ProIntent } from '../../../../utils/enums';
import { Components } from '../../../../utils/types';
import { getIconForType } from '../WBSElement.helpers';

type Props = PropsWithChildren<{
    data?: Components.Schemas.WBSElementDeltaDTO;
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>;
    intent?: ProIntent;
    buttons: ReactNode;
    isDisplayed: boolean;
    isDisabled?: boolean;
    error?: string;
    loading: boolean;
    t: TFunction;
}>;

export const WBSDetailWindow = ({
    data,
    setData,
    error,
    loading,
    buttons,
    intent,
    isDisplayed,
    children,
    isDisabled,
    t,
}: Props) => {
    const curBreadCrump = data ? getWBSBreadCrump(data) : [{ name: 'No Path' }];
    return (
        <ProDetailWindow
            title={
                isDisabled ? (
                    <h2
                        className={classNames(
                            data?.name?.length && data?.name?.length >= 180 && 'ProDetailWindow--header--title__large'
                        )}
                    >
                        {data?.name ?? t('windows.details')}
                    </h2>
                ) : (
                    <ProHeaderAttrField
                        name="Name"
                        value={data?.name ?? String(t('windows.details'))}
                        disabled={false}
                        handleChange={(input) => setData((old) => ({ ...old, name: String(input).substring(0, 180) }))}
                    />
                )
            }
            breadCrump={curBreadCrump}
            displayState={isDisplayed}
            icon={data ? getIconForType(data.wbsElementType) : ProIconData.cog_outline}
            buttonSection={buttons}
            intent={intent}
            error={error}
            loading={loading}
        >
            {children}
        </ProDetailWindow>
    );
};
