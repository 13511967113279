import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ProSpinner } from '../../atoms/ProSpinner/Spinner';
import { ProIconType } from '../../constants/types';
import { usePageTitle } from '../../hooks/usePageTitle';
import { ProErrorWindow } from '../../modules/ProErrorPage/ProError';
import { BreadCrumpItem } from '../DetailWindow/breadCrump.helpers';
import { ProHeader } from '../Header/Header';
import { PageSettings } from '../PageSettings/PageSettings';

type Props = PropsWithChildren<{
    name?: string;
    breadCrump?: BreadCrumpItem[];
    className?: string;
    appClassName?: string;
    error?: string;
    loading?: boolean;
    icon?: ProIconType;
    isHidden?: boolean;
    displyWBSSelector?: boolean;
    displyProjectSelector?: boolean;
    displyTeamSelector?: boolean;
    noSettings?: boolean;
    filter?: ReactNode;
}>;

export const ProPage = ({
    name,
    breadCrump,
    children,
    className,
    error,
    loading = false,
    icon,
    isHidden = false,
    displyProjectSelector = false,
    displyTeamSelector = false,
    displyWBSSelector = false,
    appClassName,
    noSettings = false,
    filter,
}: Props) => {
    usePageTitle(name ?? '4oPM');
    const { t } = useTranslation();
    return (
        <div className={classNames('ProApp__mainframe', isHidden && 'ProApp__hidden', appClassName)}>
            {name && <ProHeader name={name} breadCrump={breadCrump} icon={icon} />}
            {!noSettings && (
                <PageSettings
                    displyProjectSelector={displyProjectSelector}
                    displyWBSSelector={displyWBSSelector}
                    displyTeamSelector={displyTeamSelector}
                    filter={filter}
                />
            )}
            <main className={classNames('ProApp--content', loading && 'ProApp__loading', className)}>
                {loading && !error && (
                    <div>
                        <ProSpinner />
                        <p>{t('error.noData.loading')}</p>
                    </div>
                )}
                {!loading && error && <ProErrorWindow message={error} />}
                {!loading && !error && children}
            </main>
        </div>
    );
};
