import axios from 'axios';
import { ChangeEvent } from 'react';
import { Components } from '../utils/types';
import { API_BASE_URL, PERSON_API_BASE } from './api';

export const handleImageUpload = async (files: FileList, id: string): Promise<string> => {
    const formData = new FormData();
    Array.from(files).forEach((file) => {
        formData.append('images', file);
    });

    try {
        const res = await axios.post(`${API_BASE_URL}${PERSON_API_BASE}/assets/${id}`, formData);
        let newImageString: string = res.headers.location;
        newImageString = newImageString.split('/').slice(-1)[0];
        newImageString = `${API_BASE_URL}${PERSON_API_BASE}/${id}/${newImageString}`;

        return newImageString;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const uploadSelectedImage = async (event: ChangeEvent<HTMLInputElement>, id?: string) => {
    const eventFiles = event.target.files;
    if (id && eventFiles !== null) {
        return handleImageUpload(eventFiles, id);
    }
};

export type FileDokumentType = {
    portfolioId: string;
    objectId: string;
};

export const handleFileUpload = async (files: FileList, document: FileDokumentType): Promise<void> => {
    const jwtObj = localStorage.getItem('auth');
    const formData = new FormData();
    Array.from(files).forEach((file) => {
        formData.append('Data', file);
    });

    await axios
        .post<Components.Schemas.DocumentDTO>(
            `${API_BASE_URL}/Document`,
            {
                contentType: files[0].type,
                objectId: document.objectId,
                portfolioId: document.portfolioId,
                documentType: 'RequirementDocument',
            },
            {
                headers: { Authorization: `Bearer ${jwtObj}` },
            }
        )
        .then(async (res) => {
            await axios
                .put<void>(`${API_BASE_URL}/Document/FormData/${res.data.id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${jwtObj}` },
                })
                .then((res) => {
                    console.log(res.data);
                });
            return res.data;
        });
};

const handleReqFileUpload = async (id: string, formData: FormData) => {
    const jwtObj = localStorage.getItem('auth');
    await axios
        .put<void>(`${API_BASE_URL}/Document/FormData/${id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${jwtObj}` },
        })
        .then((res) => {
            return res.data;
        });
};
const handleDocumentUpload = async (
    type: string,
    portfolioId: string,
    objectId: string
): Promise<Components.Schemas.DocumentDTO> => {
    const jwtObj = localStorage.getItem('auth');
    return await axios
        .post<Components.Schemas.DocumentDTO>(
            `${API_BASE_URL}/Document`,
            {
                contentType: type,
                objectId: objectId,
                portfolioId: portfolioId,
                documentType: 'RequirementDocument',
            },
            {
                headers: { Authorization: `Bearer ${jwtObj}` },
            }
        )
        .then(async (res) => {
            return res.data;
        });
};
