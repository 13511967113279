import React from 'react';
import { TFunction } from 'react-i18next';
import { ProButton } from '../../../../atoms/Button/ProButton';
import { ActionCell } from '../../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../../atoms/Icons/Icon';
import { TableColData } from '../../../../atoms/Table/Table';
import { WindowDisplayState } from '../../../../modules/Windows/WBSElement/WBSElement.helpers';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { Components } from '../../../../utils/types';
import {
    getReqCellIcon,
    getReqStatusIcon,
    isReqNotBacklog,
    isTaskNotReq,
} from '../../Requirements/components/RequirementTableNameCell';

// Display tasks here?
export const getSprintPlanningTableData = ({
    requirements,
    selectElement,
    t,
    deleteAction,
    isDisplayed,
    mode,
}: {
    selectElement: (id: string) => void;
    requirements: Components.Schemas.RequirementDTO[];
    t: TFunction;
    isDisplayed: boolean;
    mode: WindowDisplayState;
    deleteAction: (i: number) => void;
}): TableColData[] => [
    {
        name: 'Requirement',
        cellRender: (i) => {
            return (
                <>
                    <div className="ProTable__levelBars">
                        <div className={`ProTable__levelBar ProTable__levelBar-1`}>1</div>
                    </div>
                    <span className="ProTable__name" onClick={() => selectElement(requirements[i].id!)}>
                        <ProIcon size={ProSize.Small} icon={getReqCellIcon(requirements[i])} />
                        {requirements[i].name ?? 'not named'}
                    </span>
                </>
            );
        },
        className: 'TableRowName ProTable__levels',
        colSpace: 'extend',
    },
    {
        name: t('table.cols.action'),
        cellRender: (i) => (
            <div className="ProTable--actionCell">
                <ProButton
                    text={'Take to sprint'}
                    mainIntent={ProIntent.Success}
                    // onClick={() => setSelectedSprint(filteredData[i])}
                />
            </div>
        ),
        className: `ProTable__buttons`,
        colSpace: 4,
    },
    {
        name: 'Status',
        cellRender: (i) => (
            <>{getReqStatusIcon(requirements[i].requirementType, requirements[i].requirementState?.name)}</>
        ),
        colSpace: 3,
        className: 'ProTable__statusIcon',
    },
    {
        name: 'Responsible',
        cellRender: (i) =>
            isReqNotBacklog(requirements[i])
                ? `${requirements[i].responsiblePerson?.firstName ?? ''} ${
                      requirements[i].responsiblePerson?.lastName ?? '-'
                  }`
                : '',
        colSpace: 4,
        hidden: () => !isDisplayed,
    },
    {
        name: 'Scope',
        cellRender: (i) => {
            if (isTaskNotReq(requirements[i])) return '-';
            return `${requirements[i].storyPoints} SP`;
        },
        colSpace: 2,
        hidden: () => !isDisplayed,
    },
    {
        name: 'Benefit',
        cellRender: (i) => {
            if (isTaskNotReq(requirements[i])) return '-';
            return `${requirements[i].businessValue} BP`;
        },
        colSpace: 2,
        hidden: () => !isDisplayed,
    },
    {
        name: 'Priority',
        cellRender: (i) => (isReqNotBacklog(requirements[i]) ? requirements[i].requirementPriorityState?.name : ''),
        colSpace: 2,
        hidden: () => !isDisplayed,
    },
    // {
    //     cellRender: (i) => <>{requirements[i].wbsElement ? <>{requirements[i].wbsElement?.name}</> : '-'}</>,
    //     colSpace: 3,
    //     name: 'Assigned WBS',
    //     hidden: () => !isDisplayed,
    // },
    {
        name: t('table.cols.action'),
        cellRender: (i) => (
            <ActionCell
                i={i}
                selectTableRow={() => selectElement(requirements[i].id!)}
                detailWindowMode={[mode]}
                deleteAction={() => {
                    deleteAction(i);
                }}
            />
        ),

        colSpace: 2,
    },
];
