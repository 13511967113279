import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProDateField } from '../../../../../atoms/AttrField/DateField';
import { ProDropdown } from '../../../../../atoms/AttrField/Dropdown';
import { ProCol } from '../../../../../atoms/Grid/Col';
import { Components } from '../../../../../utils/types';
import {
    DateConstraintDataType,
    getDateConstraintType,
    getDateConstraintTypes,
    setDateConstraintType,
} from './TimeConstrainField.helpers';

type Props = {
    data: Components.Schemas.WBSElementDTO;
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>;
    isDisabled?: boolean;
};

export const WBSTimeConstrainField = ({ data, setData, isDisabled }: Props) => {
    const { t } = useTranslation();
    const dateConstraintTypes = useMemo(() => getDateConstraintTypes(t), [t]);
    const [constraint, setConstraint] = useState<DateConstraintDataType>(
        getDateConstraintType(t, data) ?? dateConstraintTypes[0]
    );
    const [date, setDate] = useState<string | undefined>(
        data.constraintEndDate ?? data.constraintStartDate ?? undefined
    );

    return (
        <>
            <ProCol span={12} verticalAlign="bottom">
                <ProDropdown
                    name={t('windows.constraint')}
                    value={constraint.name}
                    items={dateConstraintTypes.map((cur) => cur.name)}
                    disabled={isDisabled}
                    setValue={(newVal) => {
                        setConstraint(dateConstraintTypes.find((cur) => cur.name === newVal) ?? dateConstraintTypes[0]);
                        setDateConstraintType(newVal, date, setData, t);
                    }}
                />
            </ProCol>

            <ProCol span={12} verticalAlign="top">
                <ProDateField
                    name={t('windows.restrictionDate')}
                    valueString={date}
                    disabled={isDisabled}
                    handleChange={(newVal) => {
                        setDate(newVal);
                        setDateConstraintType(constraint.name, newVal, setData, t);
                    }}
                />
            </ProCol>
        </>
    );
};
