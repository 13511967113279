import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TASK_API_BASE } from '../../api/api';
import { createAPIRequest, deleteAPIRequest, updateAPIRequest } from '../../api/useGenericApi';
import { ProjectContext } from '../../contexts/ProjectContext';
import { RequirementContext } from '../../routes/TeamManagement/Requirements/context/RequirementContext';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';
import { ProDeleteButton } from '../Button/ProDeleteButton';
import { ProIconButton } from '../Button/ProIconButton';
import { ProIcon } from '../Icons/Icon';
import { ProIconDataButtons } from '../Icons/IconData';

export type ThresholdCirteria = {
    id: string;
    name: string;
    done: boolean;
};

type Props = {
    name: string;
    cirteria?: Components.Schemas.TaskListDTO;
    sync?: () => void;
    disabled?: boolean;
};

export const ThresholdCriteriaList = ({ cirteria, name, sync, disabled = false }: Props) => {
    const [stateCriteria, setStateCriteria] = useState<Components.Schemas.TaskListDTO | undefined>(cirteria);
    const { selectedProject } = useContext(ProjectContext);
    const [areaHeight, setAreaHeight] = useState<number[]>([]);
    const { t } = useTranslation();

    const createAction = () => {
        const newElement: Components.Schemas.TaskDTO = {
            name: 'new Element',
            projectId: selectedProject?.id,
            taskListId: cirteria?.id,
        };
        createAPIRequest(newElement, TASK_API_BASE)
            .then(() => {
                sync && sync();
            })
            .catch(() => {
                console.error('cant create');
            });
    };

    const openRenameAction = () => {};

    const renameAction = (elm: Components.Schemas.TaskDTO) => {
        updateAPIRequest(elm, TASK_API_BASE, elm.id!)
            .then(() => {
                sync && sync();
            })
            .catch(() => {
                console.error('cant rename');
            });
    };
    const doneAction = (elm: Components.Schemas.TaskDTO) => {
        updateAPIRequest({ ...elm, degreeOfCompletion: elm.degreeOfCompletion === 0 ? 1 : 0 }, TASK_API_BASE, elm.id!)
            .then(() => {
                sync && sync();
            })
            .catch(() => {
                console.error('cant set done');
            });
    };
    const deletAction = (elm: Components.Schemas.TaskListDTO) => {
        deleteAPIRequest(elm.id!, TASK_API_BASE)
            .then(() => {
                sync && sync();
            })
            .catch(() => {
                console.error('cant delete');
            });
    };

    const { acceptanceCiteriaList, readyCiteriaList } = useContext(RequirementContext);

    const isPredefined = (task: string) => {
        const ac = readyCiteriaList?.tasks || [];
        const rc = acceptanceCiteriaList?.tasks || [];

        return [...ac, ...rc].map((cur) => cur.name).includes(task);
    };

    return (
        <div className="CriteriaList">
            <div className="CriteriaList--name">
                {name}

                <ProIconButton
                    icon={ProIconDataButtons.add_xs}
                    onClick={createAction}
                    tooltip={t('buttons.create')}
                    size={ProSize.XSmall}
                    mainIntent={ProIntent.Create}
                    tooltipClass="CriteriaList--button"
                    disabled={disabled}
                />
            </div>
            <ul>
                {(!stateCriteria || stateCriteria?.tasks?.length === 0) && (
                    <li key={'nothing'} className="CriteriaList--task__empty">
                        no tasks defined
                    </li>
                )}
                {stateCriteria?.tasks?.map((cur, i) => (
                    <li key={cur.id}>
                        <ProIcon
                            icon={
                                cur.degreeOfCompletion
                                    ? ProIconDataButtons.todo_checked_s
                                    : ProIconDataButtons.todo_circle_s
                            }
                            onClick={() => !disabled && doneAction(cur)}
                            // tooltip={cur.degreeOfCompletion ? 'not done' : 'done'}
                            customSize="16px"
                        />
                        <span onClick={openRenameAction} className="CriteriaList--task">
                            <textarea
                                rows={Math.ceil(cur.name!.length / 90)}
                                value={cur.name ?? 'not named'}
                                onChange={(e) => {
                                    setAreaHeight((cur) => {
                                        cur[i] = e.target.scrollHeight;
                                        return cur;
                                    });
                                    setStateCriteria((akt) => ({
                                        ...akt,
                                        tasks: [...akt!.tasks!].map((iter) =>
                                            iter.id === cur.id ? { ...iter, name: e.target.value } : iter
                                        ),
                                    }));
                                }}
                                onBlur={() => renameAction(cur)}
                                disabled={isPredefined(cur.name!) || disabled}
                                style={{ height: areaHeight[i] }}
                            />
                        </span>

                        {!isPredefined(cur.name!) && (
                            <ProDeleteButton disabled={disabled} minimal onClick={() => deletAction(cur)} />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};
