import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ProIconData } from '../../atoms/Icons/IconData';
import { ProLogo } from '../../atoms/Logo/Logo';
import { ProMenuButton } from '../../atoms/MenuItems/ProMenuButton';
import { ProMenuIcon } from '../../atoms/MenuItems/ProMenuItem';
import { getBASE_MenuData } from '../../constants/menu';
import { MenuItemType } from '../../constants/types';
import { useRouting } from '../../hooks/useRouting';
import { ProSize } from '../../utils/enums';

export const ProMenu = () => {
    const [open, setCollabsed] = useState(false);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { navigateSection, navigateSubRoute } = useRouting();

    const activeLinks = pathname
        .split('/')
        .filter((cur) => cur !== '')
        .map((cur) => `/app/${cur}`);

    const MenuData = useMemo(() => getBASE_MenuData(t), [getBASE_MenuData]);
    const subMenuData = MenuData.find((cur) => activeLinks.includes(cur.link))?.sublinks;

    if (activeLinks.length <= 1) return <div className="ProMenu ProMenu--hidden"></div>;

    const isActive = (cur: MenuItemType) => {
        return (pathname.includes(cur.link) || cur.sublinks?.map((cur) => cur.link).includes(pathname)) ?? false;
    };

    return (
        <nav className={classNames('ProMenu', open && 'ProMenu--open')}>
            <div className="ProApp__logo">
                <ProLogo />
            </div>
            <div className="ProMenu__lists">
                <ul className="ProMenu__area-list">
                    {activeLinks.length !== 0 &&
                        MenuData?.map((cur, i) => (
                            <li key={`menuItem_${i}`}>
                                <ProMenuIcon
                                    item={cur}
                                    isActive={activeLinks.includes(cur.link)}
                                    size={ProSize.XLarge}
                                    onClick={() => navigateSection(cur.section)}
                                />
                            </li>
                        ))}
                    {activeLinks.length === 0 &&
                        MenuData?.map((cur, i) => (
                            <li key={`menuItem_${i}`}>
                                <ProMenuIcon
                                    item={cur}
                                    isActive={MenuData[0] === cur}
                                    size={ProSize.XLarge}
                                    onClick={() => navigateSection(cur.section)}
                                />
                            </li>
                        ))}
                </ul>

                <ul className="ProMenu__item-list">
                    {subMenuData?.map((cur, i) =>
                        cur.name === 'spacer' ? (
                            <li className="ProMenu__item-list--spacer" key={`menu-spacer${i}`}>
                                space
                            </li>
                        ) : (
                            <li key={`menuItem_${i}`} className={classNames(isActive(cur) && 'active')}>
                                <ProMenuIcon
                                    item={cur}
                                    isActive={isActive(cur)}
                                    title={open ? cur.name : undefined}
                                    key={`menu-item-${i}`}
                                    onClick={() => navigateSubRoute(cur.section, cur.link)}
                                />
                            </li>
                        )
                    )}
                </ul>
            </div>
            {activeLinks.length !== 0 && (
                <ProMenuButton
                    icon={ProIconData.chevron_outline_down}
                    onClick={() => setCollabsed((cur) => !cur)}
                    tooltip={open ? 'close' : 'open'}
                    rotation={open ? '90' : '270'}
                    className="ProMenu--extendButton"
                />
            )}
        </nav>
    );
};
