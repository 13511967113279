import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FUNCTIONS_API_BASE, PORTFOLIO_API_BASE } from '../../../api/api';
import { deleteAPIRequest, useGenericAPI } from '../../../api/useGenericApi';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { DetaiWindowWrapper } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { UserContext } from '../../../contexts/UserContext';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { FunctionsDetailWindow } from '../../../modules/Windows/FunctionsDetailWindow/FunctionsDetailWindow';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import { FunctionsSummaryWindow } from '../../../modules/Windows/SummaryDetails/FunctionsSummaryWindow';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';

export const useFunctionDetailWindow = ({
    closeAction,
    externalSync,
    unitId,
}: {
    closeAction: () => void;
    unitId?: string;
    externalSync?: () => void;
}) => {
    const { portfolio } = useContext(UserContext);
    return useGenericDetailWindow({
        apiConstant: FUNCTIONS_API_BASE,
        closeAction,
        emptyItem: getNewFunctionDTO(portfolio?.id),
        genericDetailWindow: FunctionsDetailWindow,
        externalSync,
        selectedItemId: unitId,
    });
};

export const getNewFunctionDTO = (id?: string): Components.Schemas.CostFunctionCriteriaFunctionDTO => ({
    name: 'Unnamed Function',
    portfolioId: id,
});

export const FunctionsPage = () => {
    const { portfolio: initPortfolio } = useContext(UserContext);
    const [selectedTableRow, setSelectedTableRow] = useState<number>();
    const [selectedUnit, setSelectedUnit] = useState<Components.Schemas.UnitDTO>();
    const { t } = useTranslation();

    const deselectTableRow = () => {
        setSelectedUnit(undefined);
        setSelectedTableRow(undefined);
        openUnit('hidden');
    };

    const {
        data: portfolio,
        error,
        loading,
        sync,
    } = useGenericAPI<Components.Schemas.PortfolioDTO>(PORTFOLIO_API_BASE, initPortfolio?.id);

    const { costFunctionCriteriaFunctions: functs } = portfolio ?? {};

    const {
        isDisplayed: roleIsDisplayed,
        mode: unitMode,
        open: openUnit,
        renderDetailWindow: renderUnitDetailWindow,
    } = useFunctionDetailWindow({ closeAction: deselectTableRow, externalSync: sync, unitId: selectedUnit?.id });

    const addUnit = () => {
        setSelectedUnit(undefined);
        openUnit('new');
        setSelectedTableRow(undefined);
    };

    if (!functs) {
        return <ProNoDataOrRightsPage message={['no functions found']} loading={loading} />;
    }

    const selectTableRow = (i: number) => {
        setSelectedUnit(functs[i]);
        openUnit('view', functs[i]);
        setSelectedTableRow(i);
    };

    const deleteRole = (id: string) => {
        deleteAPIRequest<Components.Schemas.CostFunctionRoleDTO>(id, FUNCTIONS_API_BASE).then(() => {
            openUnit('hidden');
            sync();
        });
    };

    const tableData: TableColData[] = [
        {
            name: t('table.cols.name'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable__levelBars "></div>
                        <div className="ProTable__level ProTable__level--open">
                            <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                        </div>
                        <span className="ProTable__name">
                            {t('table.cols.summaryIn')} {portfolio?.name ?? 'Portfolio'}
                        </span>
                    </>
                ) : (
                    <>
                        <div className="ProTable__levelBars ">
                            <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                        </div>
                        <span className="ProTable__name" onClick={() => selectTableRow(i - 1)}>
                            <ProIcon size={ProSize.Small} icon={ProIconData.person_fill} />
                            {functs![i - 1]?.name}
                        </span>
                    </>
                ),
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable--actionCell">
                            <ProButton
                                leftIcon={ProIconDataButtons.add_xs}
                                onClick={() => addUnit()}
                                tooltip={t('buttons.add', { var: 'types.inside' })}
                                mainIntent={ProIntent.Transpared}
                                hoverIntent={ProIntent.Success}
                                lineIntent={ProIntent.Success}
                                disabled={getHiddenForWindowDisplayState([unitMode])}
                                size={ProSize.XSmall}
                                text={t('buttons.add', { var: t('types.function') })}
                                placement="bottom"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <ActionCell
                            i={i}
                            selectTableRow={selectTableRow}
                            detailWindowMode={unitMode}
                            deleteAction={() => deleteRole(functs[i - 1].id!)}
                        />
                    </>
                ),
            colSpace: 4,
        },
    ];

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.PortfolioMan, ApplicationElementType.BaseData], t)}
            name={t('navigation.portfolioMan.baseData.functions')}
            displyProjectSelector
        >
            <ProTable
                data={tableData}
                rowsCount={functs.length + 1}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
            />

            <DetaiWindowWrapper hidden={[roleIsDisplayed]}>
                <FunctionsSummaryWindow funcs={functs} />
                {renderUnitDetailWindow()}
            </DetaiWindowWrapper>
        </ProPage>
    );
};
