import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PERSON_API_BASE } from '../../../api/api';
import { deleteAPIRequest } from '../../../api/useGenericApi';
import { usePeople } from '../../../api/usePeople';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { UserContext } from '../../../contexts/UserContext';
import { usePersonDetailRouting } from '../../../hooks/useDetailRouting';
import { proLinkStructure } from '../../../modules/Menu/Navigate';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import { PersonDetailWindow } from '../../../modules/Windows/PersonDetailWindow/PersonDetailWindow';
import { PeopleSummaryWindow } from '../../../modules/Windows/SummaryDetails/PeopleSummaryWindow';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';

export const getNewPerson = (portfolioId: string): Components.Schemas.PersonDTO => {
    return { firstName: 'First name', lastName: 'Last named', portfolioId: portfolioId };
};

export const usePersonDetailWindow = (closeAction: () => void, roleId?: string, externalSync?: () => void) => {
    const { user } = useContext(UserContext);
    const emptyItem = getNewPerson(user?.portfolios![0]?.id ?? '0');

    return useGenericDetailWindow<Components.Schemas.PersonDTO>({
        apiConstant: PERSON_API_BASE,
        closeAction,
        emptyItem,
        genericDetailWindow: PersonDetailWindow,
        externalSync,
        selectedItemId: roleId,
    });
};

export const PortfolioPeoplePage = ({ isHidden = false, pageTitle }: { isHidden?: boolean; pageTitle?: string }) => {
    const { user } = useContext(UserContext);
    const { selectionMode, renderSelectionButton, selectedElementId } = useContext(SelectionContext);
    const { data: allUsers, error, loading, sync } = usePeople(user?.portfolios![0]?.id);
    const [selectedPerson, setSelectedPerson] = useState<Components.Schemas.PersonDTO | undefined>(
        allUsers?.find((cur) => cur.id === selectedElementId)
    );
    const history = useHistory();
    const { t } = useTranslation();

    const selectTableRow = (i: number) => {
        if (allUsers) setSelectedPerson(allUsers[i - 1]);
        openPerson('view', selectedPerson);
        setSelectedTableRow(i);
    };

    const routingError = usePersonDetailRouting({ selectTableRow, persons: allUsers, selectedPerson });

    useEffect(() => {
        const index = allUsers?.findIndex((cur) => cur.id === selectedElementId);
        if (index && index >= 0) selectTableRow(index + 1);
    }, [selectedElementId, allUsers]);

    const deselectTableRow = () => {
        setSelectedPerson(undefined);
        setSelectedTableRow(undefined);
        openPerson('hidden');

        history.push({
            pathname: proLinkStructure.portfolioManagement.people.link,
        });
    };

    const {
        isDisplayed: personIsDisplayed,
        mode: personMode,
        open: openPerson,
        renderDetailWindow: renderPersonDetailWindow,
    } = usePersonDetailWindow(deselectTableRow, selectedPerson?.id, sync);
    const [selectedTableRow, setSelectedTableRow] = useState<number>();

    const { portfolio } = useContext(UserContext);

    const addPerson = () => {
        setSelectedPerson(undefined);
        openPerson('new');
        setSelectedTableRow(undefined);
    };

    const deletePerson = (id: string) => {
        deleteAPIRequest<Components.Schemas.PersonDTO>(id, PERSON_API_BASE).then(() => {
            openPerson('hidden');
            sync();
        });
    };

    const peopleData: TableColData[] = [
        {
            name: t('table.cols.name'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable__levelBars "></div>
                        <div className="ProTable__level ProTable__level--open">
                            <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                        </div>
                        <span className="ProTable__name">
                            {t('table.cols.summaryIn')}
                            {portfolio?.name ?? 'Portfolio'}
                        </span>
                    </>
                ) : (
                    <>
                        <div className="ProTable__levelBars ">
                            <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                        </div>
                        <span className="ProTable__name" onClick={() => selectTableRow(i)}>
                            <ProIcon size={ProSize.Small} icon={ProIconData.person_fill} />
                            {`${allUsers![i - 1]?.firstName} ${allUsers![i - 1]?.lastName}`}
                        </span>
                    </>
                ),
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable--actionCell">
                            <ProButton
                                leftIcon={ProIconDataButtons.add_xs}
                                onClick={() => addPerson()}
                                tooltip={t('table.buttons.addPerson')}
                                mainIntent={ProIntent.Transpared}
                                hoverIntent={ProIntent.Success}
                                lineIntent={ProIntent.Success}
                                disabled={getHiddenForWindowDisplayState([personMode])}
                                size={ProSize.XSmall}
                                text={t('table.buttons.addPerson')}
                                placement="bottom"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {selectionMode === SelectionType.HumanResource &&
                            renderSelectionButton &&
                            allUsers &&
                            renderSelectionButton(allUsers[i - 1])}
                        <ActionCell
                            i={i}
                            selectTableRow={selectTableRow}
                            detailWindowMode={personMode}
                            deleteAction={allUsers ? () => deletePerson(allUsers[i - 1]?.id!) : undefined}
                        />
                    </>
                ),
            colSpace: selectionMode === SelectionType.HumanResource ? 7 : 4,
        },
    ];

    if (!isHidden && !allUsers) {
        return <ProNoDataOrRightsPage message={[t('error.noData.people'), 'test']} loading={loading} />;
    }

    return (
        <ProPage
            breadCrump={getBaseBCItemForType(
                [ApplicationElementType.PortfolioMan, ApplicationElementType.PeopleAndPositions],
                t
            )}
            name={pageTitle ?? t('navigation.portfolioMan.peopleAndPositions.people')}
            error={error}
            loading={loading}
            isHidden={isHidden}
        >
            <ProTable
                data={peopleData}
                rowsCount={allUsers ? allUsers.length + 1 : 0}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
            />

            <div
                className={classNames(
                    'ProApp--detailWindowSection',
                    personIsDisplayed && 'ProApp--detailWindowSection__expanded'
                )}
            >
                <PeopleSummaryWindow people={allUsers} />
                {renderPersonDetailWindow()}
            </div>
        </ProPage>
    );
};
