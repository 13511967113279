import classNames from 'classnames';
import React from 'react';
import { ProIcon } from '../../../../atoms/Icons/Icon';
import { ProIconData } from '../../../../atoms/Icons/IconData';
import { ProIconType } from '../../../../constants/types';
import { ProSize } from '../../../../utils/enums';
import { HumanResourceDTO, isHumanResource, ProjectDTO, TeamDTO } from '../Team.helpers';

export const TeamPageItemCell = ({
    elm,
    onClick,
    level,
    open,
    icon,
    openAction,
}: {
    elm: ProjectDTO | TeamDTO | HumanResourceDTO;
    onClick: () => void;
    level?: number;
    open?: boolean;
    icon?: ProIconType;
    openAction?: () => void;
}) => (
    <>
        <div className="ProTable__levelBars">
            {level && <div className={`ProTable__levelBar ProTable__levelBar-${level}`}>{level}</div>}
        </div>
        {open !== undefined && (
            <div className={classNames('ProTable__level', open && 'ProTable__level--open')} onClick={openAction}>
                <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
            </div>
        )}
        <span className="ProTable__name" onClick={onClick}>
            {icon && <ProIcon size={ProSize.Small} icon={icon} />}
            {isHumanResource(elm)
                ? `${elm.person?.firstName ?? ''} ${elm.person ? elm.person?.lastName : 'No person'}  • ${
                      elm.costFunctionRole?.name ?? 'no function'
                  } • ${elm.teamPosition?.name ?? 'no position'} • ${elm.capacity ?? ''} ${
                      elm.capacityUnit?.name ?? 'no capacity'
                  }`
                : elm.name}
        </span>
    </>
);
