import { NumericInput } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import React from 'react';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProIcon } from '../Icons/Icon';
import { ProIconData } from '../Icons/IconData';

type Props = {
    value?: string | number | null;
    name?: string;
    handleChange?(value: string | number): void;
    disabled?: boolean;
    min?: number;
    max?: number;
    stepSize?: number;
    entity?: string;
    intent?: ProIntent;
    info?: string;
    allignLeft?: boolean;
    entityLengh?: 'none' | 's' | 'm' | 'l' | 'xl';
    accuracy?: 0 | 1 | 2;
};

export const ProNumberField = ({
    name,
    value,
    disabled = true,
    handleChange,
    min,
    max,
    stepSize = 1,
    entity,
    intent,
    info,
    allignLeft,
    entityLengh,
    accuracy = 2,
}: Props) => {
    const handleChangeInternal = (e: number) => {
        if (handleChange) {
            if (min && e < min) return handleChange(min);
            if (max && e > max) return handleChange(max);
            return handleChange(e);
        }
    };

    return (
        <div
            className={classNames(
                'ProAttrField',
                disabled && 'ProAttrField--disabled',
                intent && `ProValue__intent-${intent}`
            )}
        >
            <div className="ProAttrField__head">{name && <span className="ProAttrField__name">{name}</span>}</div>
            <div className={classNames('ProAttrField__input')}>
                <NumericInput
                    placeholder={name}
                    value={value ? (Math.round(Number(value) * 100) / 100).toFixed(accuracy) ?? 'no data' : 0}
                    onValueChange={(e) => !disabled && handleChangeInternal(e)}
                    className={classNames(
                        'ProInput ProAttrField__input ProValue ProValue--value',
                        entity === '€' && 'ProAttrField__currency',
                        info && 'ProAttrField__has-info',
                        !allignLeft && 'ProAttrField__allignRight',
                        entityLengh && `ProAttrField__value--entityLengh-${entityLengh}`
                    )}
                    fill={false}
                    disabled={disabled}
                    min={min}
                    max={max}
                    stepSize={stepSize}
                    majorStepSize={stepSize}
                    step={stepSize}
                    buttonPosition="none"
                    rightElement={
                        <span
                            className={classNames('ProAttrField__entity', disabled && `ProAttrField__entity--disabled`)}
                        >
                            {entity}
                        </span>
                    }
                />
                {info && (
                    <span className="ProAttrField__info">
                        <InfoTag val={info} />
                    </span>
                )}
            </div>
        </div>
    );
};

const InfoTag = ({ val }: { val: string }) => (
    <Tooltip2 content={val}>
        <ProIcon icon={ProIconData.question} size={ProSize.Small} intent={ProIntent.PrimaryLight} />
    </Tooltip2>
);
