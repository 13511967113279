import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COST_FUNCTION_API_BASE, PORTFOLIO_API_BASE } from '../../../api/api';
import { deleteAPIRequest, useGenericAPI } from '../../../api/useGenericApi';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { DetaiWindowWrapper } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { UserContext } from '../../../contexts/UserContext';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { CostFunctionDetailWindow } from '../../../modules/Windows/CostFunctionDetailWindow/CostFunctionDetailWindow';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import { FunctionalRolesSummaryWindow } from '../../../modules/Windows/SummaryDetails/TeamPositionSummaryWindow';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';

export const useCostFunctionDetailWindow = ({
    closeAction,
    externalSync,
    roleId,
}: {
    closeAction: () => void;
    roleId?: string;
    externalSync?: () => void;
}) => {
    const { portfolio } = useContext(UserContext);
    const { selectedProject } = useContext(ProjectContext);
    return useGenericDetailWindow({
        apiConstant: COST_FUNCTION_API_BASE,
        closeAction,
        emptyItem: getNewCostFunctionDTO(selectedProject?.id, portfolio),
        genericDetailWindow: CostFunctionDetailWindow,
        externalSync,
        selectedItemId: roleId,
    });
};

export const getNewCostFunctionDTO = (
    projectId?: string,
    portfolio?: Components.Schemas.PortfolioDTO
): Components.Schemas.CostFunctionRoleDTO =>
    portfolio
        ? {
              name: 'Unnamed function',
              projectId: projectId,
              priceUnit: portfolio.units![0] ?? undefined,
          }
        : {};

export const FunctionalRolesPage = () => {
    const { portfolio: initPortfolio } = useContext(UserContext);
    const { selectedProject } = useContext(ProjectContext);
    const [selectedTableRow, setSelectedTableRow] = useState<number>();
    const { costFunctionRoles } = selectedProject ?? {};
    const [selectedRole, setSelectedRole] = useState<Components.Schemas.CostFunctionRoleDTO>();
    const { t } = useTranslation();

    const deselectTableRow = () => {
        setSelectedRole(undefined);
        setSelectedTableRow(undefined);
        openFunction('hidden');
    };

    const {
        data: portfolio,
        error,
        loading,
        updateRequest: updatePortfolio,
        sync,
    } = useGenericAPI<Components.Schemas.PortfolioDTO>(PORTFOLIO_API_BASE, initPortfolio?.id);

    const {
        isDisplayed: functionIsDisabled,
        mode: functionMode,
        open: openFunction,
        renderDetailWindow: renderTeamRoleDetailWindow,
    } = useCostFunctionDetailWindow({ closeAction: deselectTableRow, externalSync: sync, roleId: selectedRole?.id });

    if (!costFunctionRoles) {
        return <ProNoDataOrRightsPage message={[t('error.noData.functionalRoles')]} loading={loading} />;
    }

    const selectTableRow = (i: number) => {
        setSelectedRole(costFunctionRoles[i - 1]);
        openFunction('view', costFunctionRoles[i - 1]);
        setSelectedTableRow(i);
    };

    const addRole = () => {
        setSelectedRole(undefined);
        openFunction('new');
        setSelectedTableRow(undefined);
    };

    const deleteRole = (id: string) => {
        deleteAPIRequest<Components.Schemas.CostFunctionRoleDTO>(id, COST_FUNCTION_API_BASE).then(() => {
            openFunction('hidden');
            sync();
        });
    };

    const peopleData: TableColData[] = [
        {
            name: t('table.cols.name'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable__levelBars "></div>
                        <div className="ProTable__level ProTable__level--open">
                            <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                        </div>

                        <span className="ProTable__name">
                            {t('table.cols.summaryIn')}
                            {portfolio?.name ?? 'Portfolio'}
                        </span>
                    </>
                ) : (
                    <>
                        <div className="ProTable__levelBars ">
                            <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                        </div>
                        <span className="ProTable__name" onClick={() => selectTableRow(i)}>
                            <ProIcon size={ProSize.Small} icon={ProIconData.person_fill} />
                            {costFunctionRoles![i - 1]?.name}
                        </span>
                    </>
                ),
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable--actionCell">
                            <ProButton
                                leftIcon={ProIconDataButtons.add_xs}
                                onClick={() => addRole()}
                                tooltip={t('buttons.add', { var: t('types.inside') })}
                                mainIntent={ProIntent.Transpared}
                                hoverIntent={ProIntent.Success}
                                lineIntent={ProIntent.Success}
                                disabled={getHiddenForWindowDisplayState([functionMode])}
                                size={ProSize.XSmall}
                                text={t('buttons.add', { var: t('types.function') })}
                                placement="bottom"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <ActionCell
                            i={i}
                            selectTableRow={selectTableRow}
                            detailWindowMode={functionMode}
                            deleteAction={() => deleteRole(costFunctionRoles[i - 1].id!)}
                        />
                    </>
                ),
            colSpace: 4,
        },
        {
            name: t('table.cols.description'),
            cellRender: (i) => costFunctionRoles![i - 1]?.description ?? '',
            colSpace: 4,
            // hidden: () => !functionIsDisabled,
        },
        {
            name: t('table.cols.price'),
            cellRender: (i) => costFunctionRoles![i - 1]?.price,
            colSpace: 2,
            // hidden: () => !functionIsDisabled,
        },
        {
            name: t('table.cols.unit'),
            cellRender: (i) => costFunctionRoles![i - 1]?.priceUnit?.name,
            colSpace: 2,
            // hidden: () => !functionIsDisabled,
        },
        {
            name: t('table.cols.level'),
            cellRender: (i) => costFunctionRoles![i - 1]?.level,
            colSpace: 2,
            // hidden: () => !functionIsDisabled,
        },
    ];

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.PortfolioMan, ApplicationElementType.BaseData], t)}
            name={t('navigation.portfolioMan.capacityMan.roles')}
            loading={loading}
            displyProjectSelector
            error={error}
        >
            <ProTable
                data={peopleData}
                rowsCount={costFunctionRoles.length + 1}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
            />

            <DetaiWindowWrapper hidden={[functionIsDisabled]}>
                <FunctionalRolesSummaryWindow roles={costFunctionRoles} />
                {renderTeamRoleDetailWindow()}
            </DetaiWindowWrapper>
        </ProPage>
    );
};
