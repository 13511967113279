import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HUMAN_RESOUCE_API_BASE } from '../../../api/api';
import { deleteAPIRequest } from '../../../api/useGenericApi';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { SelectionContext, SelectionType } from '../../../contexts/SelectionContext';
import { useDetailRouting } from '../../../hooks/useDetailRouting';
import { proLinkStructure } from '../../../modules/Menu/Navigate';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { NotFoundPage } from '../../../modules/ProErrorPage/NotFound';
import { showErrorToast } from '../../../utils/helpers';
import { HumanResourceDTO, useTeamDetailFrame } from './Team.helpers';
import { useHumanResourceDetailFrame } from './modules/HumanResourceDetailFrame';
import { TeamPageItemCell } from './modules/TeamPageItemCell';

export const SingleTeamPage = ({
    isHidden = false,
    pageTitle,
    teamId,
}: {
    teamId?: string;
    isHidden?: boolean;
    pageTitle?: string;
}) => {
    const { selectedProject, sync: syncProjects, loading } = useContext(ProjectContext);
    const { selectionMode, renderSelectionButton } = useContext(SelectionContext);

    const [selectedTableRow, setSelectedTableRow] = useState<number | undefined>();
    const [selectedHuman, setSelectedHuman] = useState<HumanResourceDTO>();
    const { t } = useTranslation();
    const { selectedTeam } = useContext(ProjectContext);

    const deselectTableRow = () => {
        setSelectedTableRow(undefined);
        setSelectedHuman(undefined);
        openTeam('hidden');
        openHuman('hidden');

        setUrlEmpty();
    };

    const {
        mode: humanMode,
        open: openHuman,
        renderDetailWindow: renderHumanDetailWindow,
        isDisplayed: humanIsDisplayed,
    } = useHumanResourceDetailFrame(
        deselectTableRow,
        selectedHuman?.id,
        selectedProject?.id ?? '0',
        selectedTeam?.id,
        async () => teamSync()
    );

    const {
        mode: teamMode,
        open: openTeam,
        renderDetailWindow: renderTeamDetailWindow,
        sync: teamSync,
        data: teamData,
        loading: teamLoading,
        isDisplayed: teamIsDisplayed,
    } = useTeamDetailFrame(deselectTableRow, teamId, selectedProject, syncProjects);

    const selectTableRow = (i: number) => {
        if (teamData?.humanResources && teamData?.humanResources[i]) {
            selectElement(teamData?.humanResources[i].id!);
        }
    };

    const selectTeam = (i: number) => {
        setSelectedTableRow(i);
        openTeam('view');
        openHuman('hidden');
    };

    const selectElement = (id: string) => {
        const i = teamData?.humanResources?.findIndex((cur) => cur.id === id);
        const item = teamData?.humanResources?.find((cur) => cur.id === id);

        if (i !== undefined && i >= 0 && item) {
            setSelectedTableRow(i + 1);
            setSelectedHuman(item);
            openTeam('hidden');
            openHuman('view');
        }
    };

    const { setUrlEmpty } = useDetailRouting({
        link: proLinkStructure.teamManagement.team.link,
        path: proLinkStructure.teamManagement.team.path,
        selectElement,
        data: teamData?.humanResources,
        selectedElement: selectedHuman,
    });

    const addHumanResource = () => {
        deselectTableRow();
        openHuman('new');
    };

    const deleteHuman = (item: HumanResourceDTO) => {
        deleteAPIRequest(item.id!, HUMAN_RESOUCE_API_BASE)
            .then(() => {
                syncProjects();
                teamSync();
            })
            .catch(() => {
                showErrorToast('Cant delete human resource');
            });
    };

    if (!teamData && !isHidden)
        return <ProNoDataOrRightsPage message={[t('error.noData.team')]} loading={teamLoading} />;

    const tableData: TableColData[] = [
        {
            name: 'Team',
            cellRender: (i) => {
                const item = teamData?.humanResources && teamData.humanResources[i - 1];

                if (i === 0 && teamData) {
                    return (
                        <TeamPageItemCell
                            elm={teamData}
                            onClick={() => selectTeam(i)}
                            open={true}
                            icon={ProIconData.team_fill}
                            level={1}
                        />
                    );
                } else if (item) {
                    return (
                        <TeamPageItemCell
                            elm={item}
                            onClick={() => selectTableRow(i - 1)}
                            icon={ProIconData.person_fill}
                            level={2}
                        />
                    );
                }
            },
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) => {
                const item = teamData?.humanResources && teamData.humanResources[i - 1];

                if (i === 0) {
                    return (
                        <ActionCell
                            i={i}
                            selectTableRow={selectTeam}
                            detailWindowMode={[teamMode, humanMode]}
                            createElement={addHumanResource}
                        />
                    );
                } else if (item) {
                    return selectionMode === SelectionType.Person ? (
                        renderSelectionButton && renderSelectionButton(item)
                    ) : (
                        <ActionCell
                            i={i}
                            selectTableRow={() => {
                                selectTableRow(i - 1);
                            }}
                            detailWindowMode={[teamMode, humanMode]}
                            deleteAction={() => deleteHuman(item)}
                        />
                    );
                }
            },
            colSpace: 4,
        },
    ];

    if (!isHidden && loading !== undefined && !loading && !selectedProject) {
        return <NotFoundPage message={t('error.noData.people')} />;
    }

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.ProjectMan], t)}
            name={pageTitle ?? t('navigation.projectMan.teamStruct')}
            icon={ProIconData.team_fill}
            isHidden={isHidden}
            displyProjectSelector
            loading={loading}
            displyTeamSelector
        >
            <ProTable
                data={tableData}
                rowsCount={(teamData?.humanResources?.length ?? 1) + 1}
                selectedTableRow={selectedTableRow}
                getRowClass={(i) => (i === 0 ? 'ProTable--row__level-0' : 'ProTable--row__level-1')}
            />
            <div
                className={classNames(
                    'ProApp--detailWindowSection',
                    (teamIsDisplayed || humanIsDisplayed) && 'ProApp--detailWindowSection__expanded'
                )}
            >
                {renderTeamDetailWindow()}
                {renderHumanDetailWindow()}
            </div>
        </ProPage>
    );
};
