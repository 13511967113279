import React, { createContext, PropsWithChildren, useContext } from 'react';
import { TASKLIST_API_BASE } from '../../../../api/api';
import { useGenericAPI } from '../../../../api/useGenericApi';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import { Components } from '../../../../utils/types';

type RequirementContextType = {
    acceptanceCiteriaList?: Components.Schemas.TaskListDTO;
    readyCiteriaList?: Components.Schemas.TaskListDTO;
};

export const RequirementContext = createContext<RequirementContextType>({} as unknown as RequirementContextType);

export const RequirementContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const { acceptanceCiteriaList: acceptanceCiteriaListInternal, readyCiteriaList: readyCiteriaListInternal } =
        useContext(ProjectContext);

    const { data: acceptanceCiteriaList } = useGenericAPI<Components.Schemas.TaskListDTO>(
        TASKLIST_API_BASE,
        acceptanceCiteriaListInternal?.id
    );
    const { data: readyCiteriaList } = useGenericAPI<Components.Schemas.TaskListDTO>(
        TASKLIST_API_BASE,
        readyCiteriaListInternal?.id
    );

    return (
        <RequirementContext.Provider
            value={{
                acceptanceCiteriaList,
                readyCiteriaList,
            }}
        >
            {children}
        </RequirementContext.Provider>
    );
};
