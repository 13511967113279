import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Components } from '../utils/types';
import { API_BASE_URL, PERSONAL_TASK_API_BASE } from './api';

export const usePersonalTasks = () => {
    const jwtObj = localStorage.getItem('auth');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [data, setData] = useState<Components.Schemas.TaskDTO[]>([]);

    const sync = useCallback(async () => {
        await axios
            .get<Components.Schemas.TaskDTO[]>(`${API_BASE_URL}${PERSONAL_TASK_API_BASE}`, {
                headers: { Authorization: `Bearer ${jwtObj}` },
            })
            .then((res) => {
                setData(res.data);
                setError(undefined);
            })
            .catch(() => {
                setData([]);
                setError('error');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [jwtObj]);

    useEffect(() => {
        sync();
    }, [sync]);

    return { data, error, loading, sync };
};
