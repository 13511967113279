import { HotkeysTarget2, MenuItem } from '@blueprintjs/core';
import { Omnibar } from '@blueprintjs/select';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { deleteWBSElement } from '../../../api/api';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProTable } from '../../../atoms/Table/Table';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { WBSContext } from '../../../contexts/WBSContext';
import { WBSSelectionContext } from '../../../contexts/WBSSelectionContext';
import { useWBSDetailRouting } from '../../../hooks/useWBSDetailRouting';
import { proLinkStructure } from '../../../modules/Menu/Navigate';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';
import { getEmptyWBSElement } from '../../../modules/Windows/WBSElement/WBSElement.helpers';
import { useWBSDetailWindow } from '../../../modules/Windows/WBSElement/WBSElementHook/WBSDetailFrame';
import { Components } from '../../../utils/types';
import { getTimeTableData } from './../../../components/WBSTable/getTimeTableData';

export const ProjectTimePage = ({ isHidden = false }: { isHidden?: boolean }) => {
    const {
        selectedWBSStructure,
        selectedWBSElement,
        setSelectedWBSElement,
        filteredData,
        openNodes,
        deselectWBSElement,
        tableData,
        setTableData,
        selectedTableRow,
        setSelectedTableRow,
        toggleOpenNode,
        syncWBSScructure,
        selectedWBSStructureId,
        wbsStructureError,
        wbsStructureLoading,
        openLowerNodes,
        setRoute,
    } = useContext(WBSContext);

    const { selectedElement, selectionMode, selectionFunction, turnSelectionModeOff } = useContext(WBSSelectionContext);
    const history = useHistory();
    const { t } = useTranslation();
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const match = useRouteMatch();

    const { selectedProject, loading, selectedTeam } = useContext(ProjectContext);

    useEffect(() => {
        setRoute(history.location.pathname);
    }, []);

    const deleteWBSTableRow = (id: string) => {
        deleteWBSElement(id)
            .then(() => {
                syncWBSScructure();
                setUrlEmpty();
            })
            .catch((e) => {
                console.log('error', e);
            });
    };

    const {
        open,
        renderWBSDetailWindow,
        isDisplayed,
        mode: detailWindowMode,
    } = useWBSDetailWindow('Time', deselectWBSElement, selectedWBSElement, syncWBSScructure);

    const selectTableRow = (id: number) => {
        if (selectedWBSStructure) {
            setSelectedWBSElement(filteredData![id]);
            open('view');
            setSelectedTableRow(id);
        }
    };

    // explain what i do
    useEffect(() => {
        const newPosition = filteredData?.findIndex((cur) => cur.id === selectedWBSElement?.id);
        newPosition > 0 && selectTableRow(newPosition);
    }, [selectedWBSStructure, filteredData]);

    useEffect(() => {
        open('view', false);
    }, [selectedWBSStructureId]);

    const createWBSElement = (id: number) => {
        setSelectedWBSElement(getEmptyWBSElement(filteredData[id]));
        open('new');
        setSelectedTableRow(id);
    };

    useEffect(() => {
        setTableData(
            getTimeTableData({
                createWBSElement,
                deleteWBSTableRow,
                detailWindowMode,
                filteredData,
                isDisplayed,
                openNodes,
                selectedWBSStructure,
                toggleOpenNode,
                selectedElement,
                selectionMode,
                turnSelectionModeOff,
                selectionFunction,
                t,
            })
        );
    }, [selectedWBSStructure, filteredData, isDisplayed, detailWindowMode, selectionMode, selectedElement]);

    // Rewrite route when new url gets pushed and update url when element changes
    const { error: notFoundError, setUrlEmpty } = useWBSDetailRouting({
        path: proLinkStructure.projectManagement.time.path,
        link: proLinkStructure.projectManagement.time.link,
        selectedWBSElement,
        filteredData,
        selectTableRow,
        openLowerNodes,
        selectedWBSStructure,
    });

    if (!loading && (!selectedTeam || !selectedProject)) {
        return <ProNoDataOrRightsPage message={[t('error.noData.noTeamOrPortfolio')]} />;
    }

    if (notFoundError) return notFoundError;

    const SearchOmnibar = Omnibar.ofType<Components.Schemas.WBSElementDTO>();

    const navigate = (id?: string) => {
        setSearchIsOpen(false);
        const selectedItem = selectedWBSStructure?.wbsElements?.find((cur) => cur.id === id)?.id;
        const to = generatePath(match.path, { wbsElementId: selectedItem });

        history.push(to);
    };

    return (
        <ProPage
            // breadCrump={getBreadcrumpPath([t('navigation.projectMan.base', { break: '' })])}
            breadCrump={getBaseBCItemForType([ApplicationElementType.ProjectMan], t)}
            name={t('navigation.projectMan.timeMan')}
            error={wbsStructureError}
            loading={wbsStructureLoading}
            icon={ProIconData.time_fill}
            displyWBSSelector
            displyProjectSelector
            isHidden={isHidden}
        >
            {selectedWBSStructure && tableData && (
                <ProTable
                    data={tableData}
                    rowsCount={filteredData?.length ?? 0}
                    selectedTableRow={selectedTableRow}
                    getRowClass={(i) => `ProTable--row__level-${filteredData![i]?.level ?? 0}`}
                    detailDisplayed={isDisplayed}
                />
            )}
            <div
                className={classNames(
                    'ProApp--detailWindowSection',
                    isDisplayed && 'ProApp--detailWindowSection__expanded'
                )}
            >
                {renderWBSDetailWindow()}
            </div>
            <HotkeysTarget2
                hotkeys={[
                    {
                        combo: 'shift + s',
                        global: true,
                        label: 'Show Search',
                        onKeyDown: () => setSearchIsOpen((cur) => !cur),
                        // prevent typing "O" in omnibar input
                        preventDefault: true,
                    },
                ]}
            >
                <>
                    <span>
                        {/* <Button text="Click to show search" onClick={() => setSearchIsOpen((cur) => !cur)} />
                        {' or press '} */}
                        {/* <KeyCombo combo="shift + S" /> */}
                    </span>

                    <SearchOmnibar
                        noResults={<MenuItem disabled={true} text="No results." />}
                        onItemSelect={(item) => {
                            toggleOpenNode(item);
                        }}
                        onClose={() => setSearchIsOpen(false)}
                        isOpen={searchIsOpen}
                        itemRenderer={(item) => (
                            <MenuItem
                                text={item.name ?? 'not named'}
                                onClick={() => {
                                    navigate(item.id);
                                }}
                            />
                        )}
                        items={selectedWBSStructure?.wbsElements ?? []}
                        className="ProSearch"
                    />
                </>
            </HotkeysTarget2>
        </ProPage>
    );
};
