import axios from 'axios';
import i18next from 'i18next';
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { API_BASE_URL, apiGetAccount, apiLogin, USER_ENV_BASE_URL } from '../api/api';
import { Components } from '../utils/types';

type UserContextType = {
    user: Components.Schemas.ApplicationUserDTO | undefined;
    error: boolean;
    loading: boolean;
    login(username: string, password: string, remember: boolean): Promise<void>;
    logout(): void;
    initialLoading: boolean;
    portfolio: Components.Schemas.PortfolioDTO | undefined;
    projects: Components.Schemas.ProjectDTO[] | undefined;
    portfolioPersonUnits: Components.Schemas.UnitDTO[] | undefined;
    sync: () => Promise<void>;
};

export const UserContext = createContext<UserContextType>({} as unknown as UserContextType);

export const UserContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [user, setUser] = useState<Components.Schemas.ApplicationUserDTO>();

    const [initialLoading, setInitialLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [projects, setProjects] = useState<Components.Schemas.ProjectDTO[]>();
    const [portfolio, setPortfolio] = useState<Components.Schemas.PortfolioDTO>();
    const [portfolioPersonUnits, setPortfolioPersonUnits] = useState<Components.Schemas.UnitDTO[]>();

    const whoAmI = async () => {
        const jwtObj = localStorage.getItem('auth');

        try {
            setLoading(true);
            const res = await apiGetAccount();
            setUser(res);

            //Todo: set to user locale
            i18next.changeLanguage(res.localization ?? 'en', (err) => {
                if (err) {
                    console.log('cant change user lang', err);
                }
            });

            const appRes = await axios.get<Components.Schemas.ApplicationUserDTO>(
                `${API_BASE_URL}${USER_ENV_BASE_URL}`,
                {
                    headers: { Authorization: `Bearer ${jwtObj}` },
                }
            );
            setProjects(appRes.data.projects ?? undefined);
            setPortfolio(appRes.data.portfolios![0]);
        } catch (e) {
            setProjects(undefined);
            setPortfolio(undefined);
        } finally {
            setInitialLoading(true);
            setLoading(false);
        }
    };

    const login = async (username: string, password: string, remember: boolean): Promise<void> => {
        try {
            setLoading(true);
            setUser(await apiLogin(username, password, remember));
            setError(false);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
            whoAmI();
        }
    };

    const logout = () => {
        localStorage.removeItem('auth');
        setUser(undefined);
        setLoading(false);
        setError(false);
    };

    useEffect(() => {
        if (!initialLoading) {
            whoAmI();
        }
    }, [initialLoading]);

    useEffect(() => {
        setPortfolioPersonUnits(portfolio?.units?.filter((cur) => cur.dimensionType === 'Time'));
    }, [portfolio]);

    return (
        <UserContext.Provider
            value={{
                user,
                login,
                logout,
                error,
                loading,
                initialLoading,
                projects,
                portfolio,
                portfolioPersonUnits,
                sync: whoAmI,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

// Todo: Hier kann diese methode dann noch einen error objekt haben und vielleich ein loading.
// Todo: Wen ein token vorlieft soll man sich mit dem token einen get user hohlen um dann autentifiziert zu sein, das aber natürlich nur wenn kein user im state liegt.
