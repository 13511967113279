import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProSubElementAttrField } from '../../../../atoms/AttrField/WBSSubElementAttrField';
import { ProButton } from '../../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../../atoms/Button/ProIconButton';
import { ProRow } from '../../../../atoms/Grid/Row';
import { ProIconDataButtons } from '../../../../atoms/Icons/IconData';
import { SelectionContext, SelectionType } from '../../../../contexts/SelectionContext';
import { ProIntent, ProSize } from '../../../../utils/enums';
import { Components } from '../../../../utils/types';
import { proLinkStructure } from '../../../Menu/Navigate';

type Props = {
    data: Components.Schemas.WBSElementDeltaDTO;
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>;
    isDisabled: boolean;
    selectedElementName?: string | null;
};

export const QualityDetailWindowAllocationTab = ({ data, isDisabled, setData, selectedElementName }: Props) => {
    const { t } = useTranslation();
    const {
        selectionMode,
        setSelectionMode,
        setRenderSelectionButton,
        setSelectedElementId,
        turnSelectionOff,
        setPageTitle,
    } = useContext(SelectionContext);

    const removeReqFromWBS = (elm: string) => {
        const selectedReq = data.requirements?.find((cur) => cur.name === elm);
        const newReqs = data.requirements?.filter((cur) => cur.id !== selectedReq?.id);
        setData((cur) => ({ ...cur, requirements: newReqs }));
    };

    const getLinkToReq = (elm: string) => {
        const selectedReq = data.requirements?.find((cur) => cur.name === elm);
        return `${proLinkStructure.teamManagement.requirements.link}/${selectedReq?.id}`;
    };

    const reqSelectionModeOn = () => {
        setSelectedElementId(data?.id);
        setPageTitle(`Please select a requirement for assigning to ${selectedElementName}`);

        setRenderSelectionButton(() => (elm: Components.Schemas.PersonDTO) => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        setData((cur) => {
                            if (cur?.requirements) return { ...cur, requirements: [...cur.requirements, elm] };
                            else return { ...cur, requirements: [elm] };
                        });

                        turnSelectionOff();
                    }}
                />
            </div>
        ));
        setSelectionMode(SelectionType.WBSToReq);
    };

    return (
        <>
            <ProRow spacer>
                <ProSubElementAttrField
                    name={t('windows.assignedRequirements')}
                    isDisabled={isDisabled}
                    isSelectionModeOn={selectionMode === SelectionType.WBSToReq}
                    toggleSelectionMode={reqSelectionModeOn}
                    values={data.requirements?.map((cur) => cur?.name)}
                    t={t}
                    getInlineButtons={(elm: string) => (
                        <>
                            <ProIconButton
                                tooltip={'delete allocation'}
                                icon={ProIconDataButtons.bin_s}
                                onClick={() => removeReqFromWBS(elm)}
                                size={ProSize.XSmall}
                                mainIntent={ProIntent.Error}
                                disabled={false}
                            />
                            <Link to={getLinkToReq(elm)}>
                                <ProIconButton
                                    tooltip={'go to requirement'}
                                    icon={ProIconDataButtons.arrow_right_xs}
                                    onClick={() => {}}
                                    size={ProSize.XSmall}
                                    mainIntent={ProIntent.LightBlue}
                                    disabled={false}
                                />
                            </Link>
                        </>
                    )}
                />
            </ProRow>

            <ProRow spacer>
                <ProSubElementAttrField
                    name={t('windows.assignedWorkPackageTasks')}
                    isDisabled={isDisabled}
                    isSelectionModeOn={false}
                    toggleSelectionMode={() => {}}
                    values={data.startingDependencies?.map((cur) => cur.targetWBSElement?.name)} // das stimmt bestimmt nicht so
                    t={t}
                />
            </ProRow>
        </>
    );
};
