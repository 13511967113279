import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useWBSStructure } from '../api/useWBSStructure';
import { TableColData } from '../atoms/Table/Table';
import { getVisibleNodes, isNodeOpen } from '../components/WBSTable/wbsTabeHelpers';
import { Components } from '../utils/types';
import { ProjectContext } from './ProjectContext';
import { UserContext } from './UserContext';

export type WBSCompName = 'Forecast vs Plan' | 'Plan vs Baseline' | 'Simulation vs Baseline';
export const WBSCompNames = ['Forecast vs Plan', 'Plan vs Baseline', 'Simulation vs Baseline'];

type ProjectContextType = {
    selectedWBSStructureId: string | undefined;
    selectedDeltaWBSStructureId: string | undefined;
    selectedWBSStructure: Components.Schemas.WBSStructureDTO | undefined;
    selectWBS(name: WBSCompName): void;
    selectedWBSElement: Components.Schemas.WBSElementDeltaDTO | undefined;
    setSelectedWBSElement(el: Components.Schemas.WBSElementDeltaDTO | undefined): void;
    getInitialData: () => Components.Schemas.WBSElementDeltaDTO | undefined;
    filteredData: Components.Schemas.WBSElementDeltaDTO[];
    setFilteredData: Dispatch<SetStateAction<Components.Schemas.WBSElementDeltaDTO[]>>;
    openNodes: Components.Schemas.WBSElementDeltaDTO[];
    setOpenNodes: Dispatch<SetStateAction<Components.Schemas.WBSElementDeltaDTO[]>>;
    deselectWBSElement(): void;
    tableData: TableColData[] | undefined;
    setTableData: Dispatch<SetStateAction<TableColData[] | undefined>>;
    selectedTableRow: number | undefined;
    setSelectedTableRow: Dispatch<SetStateAction<number | undefined>>;
    toggleOpenNode: (node?: Components.Schemas.WBSElementDeltaDTO | undefined) => void;
    syncWBSScructure: () => Promise<
        | {
              data: Components.Schemas.WBSStructureDTO | undefined;
              error: string | undefined;
              loading: boolean;
          }
        | undefined
    >;
    wbsStructureError: string | undefined;
    wbsStructureLoading: boolean;
    openLowerNodes: (id: string) => string | undefined | null;
    setRoute: Dispatch<SetStateAction<string | undefined>>;
};

export const WBSContext = createContext<ProjectContextType>({} as unknown as ProjectContextType);

export const WBSContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const { user, portfolio, projects } = useContext(UserContext);
    const { selectedProject, loading } = useContext(ProjectContext);

    const [selectedWBSStructureId, setSelectedWBSStructureId] = useState<string>();
    const [selectedDeltaWBSStructureId, setSelectedDeltaWBSStructureId] = useState<string>();

    const [selectedWBSElement, setSelectedWBSElementInternal] = useState<Components.Schemas.WBSElementDeltaDTO>();
    const [initalLoadingDone, setInitalLoadingDone] = useState(false);

    const [filteredData, setFilteredData] = useState<Components.Schemas.WBSElementDeltaDTO[]>([]);
    const [openNodes, setOpenNodes] = useState<Components.Schemas.WBSElementDeltaDTO[]>([]);

    const [tableData, setTableData] = useState<TableColData[]>();
    const [selectedTableRow, setSelectedTableRow] = useState<number>();

    const [route, setRoute] = useState<string>();

    const history = useHistory();

    const {
        data: selectedWBSStructure,
        error: wbsStructureError,
        loading: wbsStructureLoading,
        sync: syncWBSScructure,
    } = useWBSStructure(selectedWBSStructureId, selectedDeltaWBSStructureId);

    // if this is active you can not set a element permanantly to undefined
    const setSelectedWBSElement = (el?: Components.Schemas.WBSElementDeltaDTO) => {
        if (el) setSelectedWBSElementInternal(el);
    };

    const deselectWBSElement = () => {
        setSelectedWBSElementInternal(undefined);
        setSelectedWBSElement(undefined);
        localStorage.removeItem('4OselectedWBSElementId');
        setSelectedTableRow(undefined);

        if (route?.includes('cost')) {
            history.push('/app/project/cost/');
        } else if (route?.includes('time')) {
            history.push('/app/project/time/');
        } else if (route?.includes('quality')) {
            history.push('/app/project/quality/');
        }
    };

    const getInitialData = () => {
        const newItemId = localStorage.getItem('4OselectedWBSElementId');
        const newItem = selectedWBSStructure?.wbsElements?.find((cur) => cur.id === newItemId);
        if (newItem !== undefined) {
            setSelectedWBSElement(newItem);
        }
        return newItem;
    };

    useEffect(() => {
        if (!initalLoadingDone) {
            getInitialData();
            setInitalLoadingDone(true);
        }
    }, [initalLoadingDone]);

    useEffect(() => {
        if (selectedWBSElement) {
            localStorage.setItem('4OselectedWBSElementId', selectedWBSElement.id!);
        }
    }, [selectedWBSElement]);

    useEffect(() => {
        selectWBS('Forecast vs Plan');
    }, [user, selectedProject, projects, portfolio]);

    const selectWBS = (name: WBSCompName): void => {
        switch (name) {
            case 'Forecast vs Plan':
                setSelectedWBSStructureId(selectedProject?.wbsStructures?.find((cur) => cur.name === 'Forecast')?.id);
                setSelectedDeltaWBSStructureId(selectedProject?.wbsStructures?.find((cur) => cur.name === 'Plan')?.id);
                break;
            case 'Plan vs Baseline':
                setSelectedWBSStructureId(selectedProject?.wbsStructures?.find((cur) => cur.name === 'Plan')?.id);
                setSelectedDeltaWBSStructureId(
                    selectedProject?.wbsStructures?.find((cur) => cur.name === 'Baseline')?.id
                );
                break;
            case 'Simulation vs Baseline':
                setSelectedWBSStructureId(selectedProject?.wbsStructures?.find((cur) => cur.name === 'Simulation')?.id);
                setSelectedDeltaWBSStructureId(selectedProject?.wbsStructures?.find((cur) => cur.name === 'Plan')?.id);
                break;
        }
    };

    useEffect(() => {
        if (selectedWBSStructure?.wbsElements) {
            const firstNode = selectedWBSStructure?.wbsElements?.find(
                (cur) => cur.parentId === undefined || cur.parentId === null
            );
            const newData = firstNode && getVisibleNodes(firstNode, selectedWBSStructure.wbsElements, openNodes);
            setFilteredData(newData ?? []);
        }
    }, [selectedWBSStructure, openNodes, setFilteredData]);

    useEffect(() => {
        const firstNode = selectedWBSStructure?.wbsElements?.find(
            (cur) => cur.parentId === undefined || cur.parentId === null
        );
        firstNode && setOpenNodes((cur) => [...cur, firstNode]);
    }, [selectedWBSStructure]);

    const toggleOpenNode = (node?: Components.Schemas.WBSElementDeltaDTO) => {
        if (!node || !node.id) return false;
        if (isNodeOpen(openNodes, node.id)) {
            setOpenNodes((cur) => cur.filter((iter) => iter.id !== node.id));
            // Todo: wenn ein child geöffnet ist und man das node schliest muss dieses ausgewählt sei, sonst ist garkeins sichtbar oder es muss einfach geschlossen werden
            deselectWBSElement();
        } else {
            setOpenNodes((cur) => [...cur, node]);
        }
    };

    const openLowerNodes = (id: string): string | undefined | null => {
        const node = selectedWBSStructure?.wbsElements?.find((cur) => cur.id === id);
        const firstNode = selectedWBSStructure?.wbsElements?.find(
            (cur) => cur.parentId === undefined || cur.parentId === null
        );

        if (node && selectedWBSStructure?.wbsElements && firstNode) {
            if (getVisibleNodes(firstNode, selectedWBSStructure.wbsElements, openNodes).find((cur) => cur.id === id)) {
                return node.parentId;
            } else {
                const parent = selectedWBSStructure.wbsElements?.find((cur) => cur.id === node.parentId);
                if (parent) setOpenNodes((cur) => [...cur, parent]);
                if (node.parentId) return openLowerNodes(node.parentId!);
                return node.parentId;
            }
        }
        return undefined;
    };

    return (
        <WBSContext.Provider
            value={{
                selectWBS,
                selectedWBSStructureId,
                selectedDeltaWBSStructureId,
                selectedWBSStructure,
                selectedWBSElement,
                setSelectedWBSElement,
                getInitialData,
                filteredData,
                openNodes,
                setFilteredData,
                setOpenNodes,
                deselectWBSElement,
                selectedTableRow,
                setSelectedTableRow,
                setTableData,
                tableData,
                toggleOpenNode,
                syncWBSScructure,
                wbsStructureError,
                wbsStructureLoading,
                openLowerNodes,
                setRoute,
            }}
        >
            {children}
        </WBSContext.Provider>
    );
};
