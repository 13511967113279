import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexibleArrow } from '../../../atoms/FlexibleArrow/FlexibleArrow';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconTypeData } from '../../../atoms/Icons/IconData';
import { ProMenuCard } from '../../../atoms/MenuCard/MenuCard';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { MenuItemType } from '../../../constants/types';
import { RoutingHistorySections } from '../../../hooks/useRouting';
import { ProIntent } from '../../../utils/enums';

export const TeamCeremoniesPage = () => {
    const { t } = useTranslation();

    const agileAMenu: MenuItemType[] = [
        {
            icon: ProIconTypeData.Ready,
            link: 'ceremonies/grooming',
            name: 'Backlog grooming',
            section: RoutingHistorySections.teamManagement,
            description: 'Scoping',
            iconSection: (
                <div className="CeremoniesCard__iconSection">
                    <FlexibleArrow style={{ left: '-36px' }} length={96} />
                    <ProIcon icon={ProIconTypeData.NotReady} intent={ProIntent.Secondary} />
                    <FlexibleArrow length={116} style={{ left: '104px' }} />
                    <ProIcon icon={ProIconTypeData.Ready} intent={ProIntent.Secondary} />
                </div>
            ),
            bar: 'horisontal',
        },
        {
            icon: ProIconTypeData.Scheduled,
            link: 'ceremonies/planning',
            name: 'Sprint panning',
            section: RoutingHistorySections.teamManagement,
            description: 'Scheduling',
            iconSection: (
                <>
                    <FlexibleArrow length={246} style={{ left: '-106px' }} />
                    <ProIcon icon={ProIconTypeData.Scheduled} intent={ProIntent.Secondary} />
                </>
            ),
            bar: 'horisontal',
        },
        {
            icon: ProIconTypeData.Review,
            link: 'something',
            name: 'Daily standup',
            section: RoutingHistorySections.teamManagement,
            description: 'Processing',
            iconSection: (
                <>
                    <FlexibleArrow style={{ left: '-196px' }} length={256} />
                    <ProIcon icon={ProIconTypeData.UnderDev} intent={ProIntent.Secondary} />
                    <FlexibleArrow style={{ left: '102px' }} length={122} />
                    <ProIcon icon={ProIconTypeData.Review} intent={ProIntent.Secondary} />
                </>
            ),
            bar: 'horisontal',
        },
        {
            icon: ProIconTypeData.Finnished,
            link: 'something',
            name: 'Sprint review',
            section: RoutingHistorySections.teamManagement,
            description: 'Acceptance',
            iconSection: (
                <>
                    <FlexibleArrow style={{ left: '-109px' }} length={256} />
                    <ProIcon icon={ProIconTypeData.Finnished} intent={ProIntent.Secondary} />
                </>
            ),
            bar: 'horisontal',
        },
    ];

    const managedMenu: MenuItemType[] = [
        {
            link: 'something',
            icon: ProIconTypeData.Finnished,
            name: 'Forecast Session',
            section: RoutingHistorySections.teamManagement,
            iconSection: (
                <>
                    SIMULATION
                    <FlexibleArrow length={146} relative />
                    PLAN
                </>
            ),
            bar: 'horisontal',
        },
        {
            icon: ProIconTypeData.Scheduled,
            link: 'something',
            name: 'Project Management Meeting',
            section: RoutingHistorySections.teamManagement,
            iconSection: (
                <>
                    FORECAST
                    <FlexibleArrow length={186} relative />
                    PLAN
                </>
            ),
            bar: 'horisontal',
        },
        {
            icon: ProIconTypeData.Review,
            link: 'something',
            name: 'Steering Committee Meeting',
            section: RoutingHistorySections.teamManagement,
            iconSection: (
                <>
                    PLAN
                    <FlexibleArrow length={206} relative />
                    BASELINE
                </>
            ),
            bar: 'horisontal',
        },
    ];

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.TeamManagement], t)}
            name={t('navigation.teamMan.ceremonies')}
        >
            <div className="Ceremonies">
                <ProRow>
                    <ProCol span={12} className="Ceremonies__firstRow">
                        <h2>Agile prcesses</h2>
                    </ProCol>
                </ProRow>
                <ProRow className="Ceremonies__spaceRow">
                    <ProIcon
                        icon={ProIconTypeData.Funnel}
                        intent={ProIntent.Secondary}
                        className="Ceremonies__firstIcon"
                        customSize="26px"
                    />

                    {agileAMenu.map((cur) => (
                        <ProCol span={3} className="ProCol--notHidden InlineCeremoniesRow" key={cur.name}>
                            <ProMenuCard item={cur} className="InlineCeremoniesCard" />
                        </ProCol>
                    ))}
                </ProRow>
                <ProRow>
                    <ProCol span={12}>
                        <h2>Managed prcesses</h2>
                    </ProCol>
                </ProRow>
                <ProRow className="Ceremonies__managedRow InlineCeremoniesRow">
                    {managedMenu.map((cur) => (
                        <ProCol span={4} className="ProCol--notHidden" key={cur.name}>
                            <ProMenuCard item={cur} />
                        </ProCol>
                    ))}
                </ProRow>
            </div>
        </ProPage>
    );
};
