import { TFunction } from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { ProValue } from '../../../../../atoms/Value/ProValue';
import { Components } from '../../../../../utils/types';

export type DateConstraintDataType = {
    name: string;
    constraintStartDateType?: Components.Schemas.ConstraintType;
    constraintEndDateType?: Components.Schemas.ConstraintType;
    schedulingType?: Components.Schemas.SchedulingType;
};

export const setDateConstraintType = (
    val: string,
    date: string | undefined,
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>,
    t: TFunction
) => {
    const newType = getDateConstraintTypes(t).find((cur) => cur.name === val);

    if (newType?.constraintStartDateType) {
        setData((cur) => ({
            ...cur,
            constraintStartDateType: newType.constraintStartDateType,
            constraintEndDateType: 'None',
            constraintStartDate: date,
            constraintEndDate: null,
        }));
    }
    if (newType?.constraintEndDateType) {
        setData((cur) => ({
            ...cur,
            constraintStartDateType: 'None',
            constraintEndDateType: newType.constraintEndDateType,
            constraintStartDate: null,
            constraintEndDate: date,
        }));
    }

    if (newType?.schedulingType) {
        setData((cur) => ({
            ...cur,
            constraintEndDate: null,
            constraintStartDate: null,
            constraintEndDateType: 'None',
            constraintStartDateType: 'None',
            schedulingType: newType?.schedulingType,
        }));
    }
};

export const getDateConstraintTypeDescription = (data: Components.Schemas.WBSElementDTO, t: TFunction) => {
    const type = getDateConstraintType(t, data);
    return (
        <>
            {type?.name}
            {type?.constraintEndDateType && type?.constraintEndDateType !== 'None' && (
                <ProValue value={data.constraintEndDate?.toString()} type="date" className="ProTableDate" />
            )}
            {type?.constraintStartDateType && type?.constraintStartDateType !== 'None' && (
                <ProValue value={data.constraintStartDate?.toString()} type="date" className="ProTableDate" />
            )}
        </>
    );
};

export const getDateConstraintType = (
    t: TFunction,
    data?: Components.Schemas.WBSElementDTO
): DateConstraintDataType | undefined => {
    if (data?.schedulingType) {
        return getDateConstraintTypes(t).find((cur) => cur.schedulingType === data.schedulingType);
    }

    if (
        data?.constraintStartDateType === 'None' &&
        data?.constraintEndDateType &&
        data?.constraintEndDateType !== 'None'
    ) {
        return getDateConstraintTypes(t).find((cur) => cur.constraintEndDateType === data.constraintEndDateType);
    }

    if (data?.constraintStartDateType === 'None' && data?.constraintEndDateType === 'None') {
        return getDateConstraintTypes(t).find((cur) => cur.constraintStartDateType === data.constraintStartDateType);
    }

    if (!data?.constraintStartDateType && !data?.constraintEndDateType) {
        return getDateConstraintTypes(t).find((cur) => cur.constraintStartDateType === 'None');
    }

    if (
        data?.constraintEndDateType === 'None' &&
        data?.constraintStartDateType &&
        data?.constraintStartDateType !== 'None'
    ) {
        return getDateConstraintTypes(t).find((cur) => cur.constraintStartDateType === data.constraintStartDateType);
    }
};

export const getDateConstraintTypes = (t: TFunction): DateConstraintDataType[] => [
    { name: t('windows.dateConstraint.finish.none'), constraintEndDateType: 'None', constraintStartDateType: 'None' },

    { name: t('windows.dateConstraint.finish.bigger'), constraintEndDateType: 'Bigger' },
    { name: t('windows.dateConstraint.finish.smaller'), constraintEndDateType: 'Smaller' },
    { name: t('windows.dateConstraint.finish.equals'), constraintEndDateType: 'Equals' },

    { name: t('windows.dateConstraint.start.bigger'), constraintStartDateType: 'Bigger' },
    { name: t('windows.dateConstraint.start.smaller'), constraintStartDateType: 'Smaller' },
    { name: t('windows.dateConstraint.start.equals'), constraintStartDateType: 'Equals' },

    {
        name: t('windows.dateConstraint.asLateAsPossible'),
        schedulingType: 'Forward',
    },
    {
        name: t('windows.dateConstraint.aSoonAsPossible'),
        schedulingType: 'Backward',
    },
];
