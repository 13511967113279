import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PROJECTS_BASE_URL } from '../../../api/api';
import { deleteAPIRequest } from '../../../api/useGenericApi';
import { ProButton } from '../../../atoms/Button/ProButton';
import { ProIconButton } from '../../../atoms/Button/ProIconButton';
import { ActionCell } from '../../../atoms/Cells/ActionCell';
import { ProIcon } from '../../../atoms/Icons/Icon';
import { ProIconData, ProIconDataButtons } from '../../../atoms/Icons/IconData';
import { ProTable, TableColData } from '../../../atoms/Table/Table';
import { DetaiWindowWrapper } from '../../../components/DetailWindow/DetailWindow';
import { ProPage } from '../../../components/Page/Page';
import { UserContext } from '../../../contexts/UserContext';
import { ProNoDataOrRightsPage } from '../../../modules/ProErrorPage/NoDataOrRights';

import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { useGenericDetailWindow } from '../../../modules/Windows/GenericDetailWindow';
import { ProjectDetailWindow } from '../../../modules/Windows/SettingsDetails/ProjectDetailWindow';
import { usePortfolioDetailFrame } from '../../../modules/Windows/SettingsDetails/UsePortfolioDetailFrame';
import { ProIntent, ProSize } from '../../../utils/enums';
import { getHiddenForWindowDisplayState } from '../../../utils/helpers';
import { Components } from '../../../utils/types';

export const getEmptyProjectDTO = (portfolioId: string): Components.Schemas.ProjectDTO => {
    return {
        description: '',
        id: '00000000-0000-0000-0000-000000000000',
        name: 'new Project',
        portfolioId: portfolioId,
        projectSetting: {
            costCautionMaximumTreshold: 0,
            costCriticalMaximumTreshold: 0,
            qualityCautionMaximumTreshold: 0,
            qualityCriticalMaximumTreshold: 0,
            timeCautionMaximumTreshold: 0,
            timeCriticalMaximumTreshold: 0,
            costCautionMinimumTreshold: 0,
            costCriticalMinimumTreshold: 0,
            qualityCautionMinimumTreshold: 0,
            qualityCriticalMinimumTreshold: 0,
            timeCautionMinimumTreshold: 0,
            timeCriticalMinimumTreshold: 0,
        },
        wbsStructures: [],
    };
};

export const useProjectDetailFrame = ({
    closeAction,
    externalSync,
    roleId,
}: {
    closeAction: () => void;
    roleId?: string;
    externalSync?: () => void;
}) => {
    const { user } = useContext(UserContext);
    const emptyItem = getEmptyProjectDTO(user?.portfolios![0]?.id ?? '0');

    return useGenericDetailWindow({
        apiConstant: PROJECTS_BASE_URL,
        closeAction,
        emptyItem,
        genericDetailWindow: ProjectDetailWindow,
        externalSync,
        selectedItemId: roleId,
    });
};

export const PortfolioProjectsPage = () => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const [selectedProject, setSelectedProject] = useState<Components.Schemas.ProjectDTO>();
    const [selectedTableRow, setSelectedTableRow] = useState<number>();

    const deselectTableRow = () => {
        setSelectedProject(undefined);
        setSelectedTableRow(undefined);
        openProject('hidden');
        openPortfolo('hidden');
    };

    const {
        mode: portfolioMode,
        open: openPortfolo,
        renderDetailWindow: renderPortfolioDetailWindow,
        data: portfolio,
        sync: syncPortfolio,
        loading,
        isDisplayed: portfolioIsDisplayed,
    } = usePortfolioDetailFrame(user?.portfolios![0]?.id ?? '0', deselectTableRow);

    const {
        mode: projectMode,
        open: openProject,
        renderDetailWindow: renderProjectWindow,
        isDisplayed: projectIsDisplayed,
    } = useProjectDetailFrame({
        closeAction: deselectTableRow,
        externalSync: syncPortfolio,
        roleId: selectedProject?.id,
    });

    const { projects } = portfolio ?? {};

    if (!user) {
        return <ProNoDataOrRightsPage message={[t('error.noData.user')]} />;
    }
    if (!projects) {
        return <ProNoDataOrRightsPage message={[t('error.noData.project')]} loading={loading} />;
    }

    const selectTableRow = (i: number) => {
        setSelectedProject(projects[i]);
        openPortfolo('hidden');
        openProject('view', selectedProject);
        setSelectedTableRow(i + 1);
    };

    const selectPortfolio = () => {
        setSelectedTableRow(0);
        openProject('hidden');
        openPortfolo('view');
    };

    const deleteProject = (id?: string) => {
        if (id) {
            deleteAPIRequest<Components.Schemas.PersonDTO>(id, PROJECTS_BASE_URL).then(() => {
                openProject('hidden');
                syncPortfolio();
            });
        } else {
            console.log('no project to delete');
        }
    };

    const createProject = (i: number) => {
        setSelectedProject(undefined);
        openProject('new');
        openPortfolo('hidden');
        setSelectedTableRow(undefined);
    };

    const ProjectData: TableColData[] = [
        {
            name: t('table.cols.name'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable__levelBars "></div>
                        <div className="ProTable__level ProTable__level--open">
                            <ProIcon icon={ProIconData.chevron_outline_down} customSize="14" />
                        </div>
                        <span className="ProTable__name" onClick={selectPortfolio}>
                            {portfolio?.name ?? 'Portfolio'}
                        </span>
                    </>
                ) : (
                    <>
                        <div className="ProTable__levelBars ">
                            <div className="ProTable__levelBar ProTable__levelBar-1">1</div>
                        </div>
                        <span className="ProTable__name" onClick={() => selectTableRow(i - 1)}>
                            <ProIcon size={ProSize.Small} icon={ProIconData.project_fill} />
                            {projects[i - 1].name}
                        </span>
                    </>
                ),
            className: 'TableRowName ProTable__levels',
            colSpace: 'extend',
        },
        {
            name: t('table.cols.action'),
            cellRender: (i) =>
                i === 0 ? (
                    <>
                        <div className="ProTable--actionCell">
                            <ProButton
                                leftIcon={ProIconDataButtons.add_xs}
                                onClick={() => createProject(i)}
                                tooltip={t('buttons.add', { var: 'types.inside' })}
                                mainIntent={ProIntent.Transpared}
                                hoverIntent={ProIntent.Success}
                                lineIntent={ProIntent.Success}
                                disabled={
                                    getHiddenForWindowDisplayState(projectMode) ||
                                    getHiddenForWindowDisplayState(portfolioMode)
                                }
                                size={ProSize.XSmall}
                                text={t('buttons.add', { var: 'types.project' })}
                                placement="bottom"
                            />

                            <ProIconButton
                                icon={ProIconDataButtons.arrow_right_xs}
                                tooltip={t('buttons.open')}
                                mainIntent={ProIntent.Transpared}
                                hoverIntent={ProIntent.Secondary}
                                size={ProSize.XSmall}
                                onClick={selectPortfolio}
                                lineIntent={ProIntent.Secondary}
                                disabled={
                                    getHiddenForWindowDisplayState(projectMode) ||
                                    getHiddenForWindowDisplayState(portfolioMode)
                                }
                                placement="bottom"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <ActionCell
                            i={i}
                            selectTableRow={() => selectTableRow(i - 1)}
                            detailWindowMode={projectMode}
                            deleteAction={() => deleteProject(projects![i - 1]?.id)}
                        />
                    </>
                ),
            colSpace: 4,
        },
    ];

    return (
        <ProPage
            breadCrump={getBaseBCItemForType([ApplicationElementType.PortfolioMan], t)}
            name={t('navigation.portfolioMan.projecs')}
        >
            <ProTable
                data={ProjectData}
                rowsCount={projects.length + 1}
                getRowClass={(i) => (i < 1 ? '' : `ProTable--row__level-1`)}
                selectedTableRow={selectedTableRow}
            />
            <DetaiWindowWrapper hidden={[portfolioIsDisplayed, projectIsDisplayed]}>
                {renderPortfolioDetailWindow()}
                {renderProjectWindow()}
            </DetaiWindowWrapper>
        </ProPage>
    );
};
