import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProSummary } from '../../../atoms/SummaryWindow/SummaryWindow';
import { Components } from '../../../utils/types';

export const TeamsSummaryWindow = ({ teams }: { teams?: Components.Schemas.TeamDTO[] | null }) => {
    const { t } = useTranslation();
    return (
        <ProSummary.Window name={t('windows.summary')}>
            <ProSummary.Attr name={t('windows.totalTeams')} icon={ProIconData.team_fill} val={teams?.length} />
            <ProSummary.Attr name={t('windows.totalHRs')} icon={ProIconData.person_fill} />
            <ProSummary.Attr name={t('windows.totalUnits')} icon={ProIconData.package_fill} />
            <ProSummary.Attr name={t('windows.totalUnits')} icon={ProIconData.quality_fill} />
            <ProSummary.Attr name={t('windows.totalUnits')} icon={ProIconData.team_fill} />
        </ProSummary.Window>
    );
};
