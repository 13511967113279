import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { ProSummary } from '../../../atoms/SummaryWindow/SummaryWindow';
import { Components } from '../../../utils/types';

export const UnitSummaryWindow = ({ units }: { units?: Components.Schemas.UnitDTO[] | null }) => {
    const dymentionsArray = units
        ?.map((cur) => cur.dimensionType ?? undefined)
        .filter((cur) => cur && cur !== 'Dimensionless')
        .filter((cur) => cur && cur !== 'Dimensionless')
        .flat();

    const dymentions = [...new Set(dymentionsArray)].join(', ');
    const { t } = useTranslation();

    return (
        <ProSummary.Window name={t('windows.summary')}>
            <ProSummary.Attr name={t('windows.totalMaterials')} icon={ProIconData.skale_fill} val={units?.length} />
            <ProSummary.Attr name={t('windows.dimensionTypes')} icon={ProIconData.package_stroke} val={dymentions} />
        </ProSummary.Window>
    );
};
