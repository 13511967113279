import { TFunction } from 'i18next';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProDropdown } from '../../../../../atoms/AttrField/Dropdown';
import { Components } from '../../../../../utils/types';

export type CostConstraintDataType = {
    name: string;
    constraintCostType: Components.Schemas.ConstraintType;
};

export const setCostConstraintType = (
    val: string,
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>,
    t: TFunction
) => {
    const newType = getCostConstraintTypes(t).find((cur) => cur.name === val) ?? undefined;
    setData((cur) => ({
        ...cur,
        constraintCostType: newType?.constraintCostType,
    }));
};

export const getCostConstraintType = (
    data: Components.Schemas.WBSElementDTO,
    t: TFunction
): CostConstraintDataType | undefined => {
    return getCostConstraintTypes(t).find((cur) => cur?.constraintCostType === data?.constraintCostType);
};

export const getCostConstraintTypes = (t: TFunction): CostConstraintDataType[] => [
    { name: t('windows.costConstraint.none'), constraintCostType: 'None' },

    { name: t('windows.costConstraint.bigger'), constraintCostType: 'Bigger' },
    { name: t('windows.costConstraint.smaller'), constraintCostType: 'Smaller' },
];

export const CostConstraintField = ({
    data,
    isDisabled,
    setData,
}: {
    data: Components.Schemas.WBSElementDTO;
    setData: Dispatch<SetStateAction<Components.Schemas.WBSElementDTO | undefined>>;
    isDisabled: boolean;
}) => {
    const { t } = useTranslation();
    const costConstraintTypes = useMemo(() => getCostConstraintTypes(t), [t]);
    const [displayConstraint, setDisplayConstraint] = useState<CostConstraintDataType>(
        getCostConstraintType(data, t) ?? getCostConstraintTypes(t)[0]
    );

    return (
        <ProDropdown
            name={String(t('windows.costConstraint.base'))}
            value={displayConstraint.name}
            items={costConstraintTypes.map((cur) => cur.name)}
            disabled={isDisabled}
            setValue={(newVal) => {
                setDisplayConstraint(costConstraintTypes.find((cur) => cur.name === newVal) ?? costConstraintTypes[0]);
                setCostConstraintType(newVal, setData, t);
            }}
        />
    );
};
