import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL, MATERIAL_ASSIGNMENT_API_BASE } from '../../api/api';
import { ProButton } from '../../atoms/Button/ProButton';
import { ProIconButton } from '../../atoms/Button/ProIconButton';
import { ProCol } from '../../atoms/Grid/Col';
import { ProRow } from '../../atoms/Grid/Row';
import { ProIconData, ProIconDataButtons } from '../../atoms/Icons/IconData';
import { ProjectContext } from '../../contexts/ProjectContext';
import { SelectionContext, SelectionType } from '../../contexts/SelectionContext';
import { ProIntent, ProSize } from '../../utils/enums';
import { Components } from '../../utils/types';
import { MateriaAssignmentWindow } from './MaterialAssignmentWindow';

type Props = {
    name: string;
    dependencies?: Components.Schemas.MaterialAssignmentDTO[] | null;
    wbsElement?: Components.Schemas.WBSElementDeltaDTO;
    syncElement: () => void;
    disabled: boolean;
};

export const MaterialAssingmentList = ({ dependencies, name, wbsElement, syncElement, disabled }: Props) => {
    const { setRenderSelectionButton, setSelectedElementId, selectionMode, setSelectionMode, turnSelectionOff } =
        useContext(SelectionContext);
    const { selectedProject } = useContext(ProjectContext);
    const { t } = useTranslation();

    const setSelectFunction = async (material: Components.Schemas.MaterialDefinitionDTO) => {
        const newDependency: Components.Schemas.MaterialAssignmentDTO = {
            materialDefinition: material,
            projectId: selectedProject?.id,
            wbsElement: wbsElement,
        };
        const jwtObj = localStorage.getItem('auth');

        try {
            await axios.post<Components.Schemas.HumanResourceAssignmentDTO>(
                `${API_BASE_URL}${MATERIAL_ASSIGNMENT_API_BASE}`,
                { ...newDependency },
                {
                    headers: { Authorization: `Bearer ${jwtObj}` },
                }
            );
            syncElement();
        } catch (e) {
            throw e;
        }
    };

    const selectFunction = () => {
        setSelectionMode(SelectionType.Material);

        setRenderSelectionButton(() => (material: Components.Schemas.MaterialDefinitionDTO) => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        setSelectFunction(material);
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
    };

    useEffect(() => {
        setRenderSelectionButton(() => (
            <div className="ProTable--actionCell">
                <ProButton
                    onClick={turnSelectionOff}
                    text={t('buttons.cancel')}
                    mainIntent={ProIntent.Transpared}
                    hoverIntent={ProIntent.Error}
                    lineIntent={ProIntent.Error}
                    size={ProSize.XSmall}
                    placement="bottom"
                />
                <ProButton
                    text={t('buttons.select')}
                    mainIntent={ProIntent.Success}
                    onClick={() => {
                        syncElement();
                        turnSelectionOff();
                    }}
                />
            </div>
        ));
    }, [setRenderSelectionButton]);

    const turnPreprosSelectionOf = () => {
        setSelectedElementId(undefined);
        setSelectionMode(undefined);
    };

    return (
        <div className="ProAttrField ProAttrField__dependencies">
            <div className="ProAttrField__head">
                <span className="ProAttrField__name">
                    <h3>{name}</h3>
                </span>
                <span className="ProAttrField__buttons">
                    {selectionMode !== SelectionType.Material ? (
                        <ProIconButton
                            tooltip={t('buttons.addElement')}
                            icon={ProIconDataButtons.add_xs}
                            onClick={selectFunction}
                            size={ProSize.XSmall}
                            mainIntent={ProIntent.Success}
                            disabled={disabled}
                        />
                    ) : (
                        <ProIconButton
                            tooltip={t('buttons.cancel')}
                            icon={ProIconData.close_stroke}
                            onClick={turnPreprosSelectionOf}
                            size={ProSize.XSmall}
                            mainIntent={ProIntent.Error}
                        />
                    )}
                </span>
            </div>
            {dependencies && dependencies.length !== 0 && (
                <div className="ProAttrField__dependencies--header">
                    <ProRow>
                        <ProCol span={7}>{t('table.cols.name')}</ProCol>
                        <ProCol span={2}>{t('table.cols.amount')}</ProCol>
                        <ProCol span={3}></ProCol>
                    </ProRow>
                </div>
            )}
            {dependencies?.map((cur) => (
                <>
                    <MateriaAssignmentWindow
                        dependency={cur}
                        key={`dependency_${cur.id}`}
                        disabled={disabled}
                        sync={syncElement}
                    />
                </>
            ))}
            {(!dependencies || (dependencies && dependencies.length) === 0) && (
                <ProRow className="ProAttrField__dependencies--box ProAttrField__dependencies--box__empty">
                    {t('error.noData.materialDefined')}
                </ProRow>
            )}
        </div>
    );
};
