export enum ProIntent {
    Light = 'white',
    Dark = 'black',
    Primary = 'primary',
    PrimaryLight = 'primary-light',
    PrimaryHightlight = 'primary-hightlight',
    Secondary = 'secondary',
    Warning = 'warning',
    Error = 'error',
    Success = 'succsess',
    Create = 'create',
    Transpared = 'transparend',
    Gray = 'gray-light',
    LightBlue = 'blue-lighter',
    Edit = 'edit',
    SecondaryLight = 'secondary-light',
}

export enum ProSize {
    XSmall = 'xs',
    Small = 's',
    Medium = 'm',
    Large = 'l',
    XLarge = 'xl',
}
