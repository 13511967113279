import { TFunction } from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { WindowDisplayState } from '../../modules/Windows/WBSElement/WBSElement.helpers';
import { ProIntent, ProSize } from '../../utils/enums';
import { ProButton } from '../Button/ProButton';
import { ProDeleteButton } from '../Button/ProDeleteButton';
import { ProIconButton } from '../Button/ProIconButton';
import { ProIconData, ProIconDataButtons } from '../Icons/IconData';

type Props = {
    mode: WindowDisplayState;
    setMode: Dispatch<SetStateAction<WindowDisplayState>>;
    cancelAction?(): void;
    closeAction(): void;
    saveAction?(): void;
    createAction?(): void;
    deleteAction?(): void;
    move?(): void;
    duplicate?(): void;
    moveModeOn?: boolean;
    cancelMove?: () => void;
    t: TFunction;
};

export const WindowButtonsForMode = ({
    cancelAction,
    createAction,
    mode,
    saveAction,
    setMode,
    deleteAction,
    closeAction,
    duplicate,
    move,
    moveModeOn,
    cancelMove,
    t,
}: Props) => (
    <>
        {mode === 'view' && (
            <>
                <div className="ProButton--buttonGroup">
                    {move &&
                        (!moveModeOn ? (
                            <ProButton
                                mainIntent={ProIntent.PrimaryLight}
                                onClick={move}
                                text={t('buttons.move')}
                                hoverIntent={ProIntent.Secondary}
                                leftIcon={ProIconDataButtons.move_s}
                            />
                        ) : (
                            <ProButton
                                mainIntent={ProIntent.Error}
                                onClick={cancelMove}
                                text={t('buttons.cancelMove')}
                                hoverIntent={ProIntent.Error}
                                leftIcon={ProIconDataButtons.move_s}
                            />
                        ))}
                    {duplicate && (
                        <ProButton
                            mainIntent={ProIntent.PrimaryLight}
                            onClick={duplicate}
                            text={t('buttons.duplicate')}
                            hoverIntent={ProIntent.Secondary}
                            leftIcon={ProIconDataButtons.duplicate_s}
                        />
                    )}
                </div>

                {deleteAction && <ProDeleteButton onClick={deleteAction} />}
                {saveAction && (
                    <ProIconButton
                        tooltip={t('buttons.edit')}
                        mainIntent={ProIntent.PrimaryLight}
                        hoverIntent={ProIntent.Warning}
                        onClick={() => setMode('edit')}
                        icon={ProIconDataButtons.pen_s}
                    />
                )}
                <ProIconButton
                    icon={ProIconData.close_stroke}
                    tooltip={t('buttons.close')}
                    mainIntent={ProIntent.PrimaryLight}
                    hoverIntent={ProIntent.Secondary}
                    onClick={closeAction}
                    size={ProSize.Small}
                />
            </>
        )}
        {mode === 'edit' && saveAction && cancelAction && (
            <>
                <ProButton
                    text={t('buttons.cancel')}
                    hoverIntent={ProIntent.Transpared}
                    mainIntent={ProIntent.Transpared}
                    onClick={cancelAction}
                    lineIntent={ProIntent.Transpared}
                    leftIcon={ProIconData.close_stroke}
                />
                <ProButton
                    text={t('buttons.save')}
                    mainIntent={ProIntent.Primary}
                    hoverIntent={ProIntent.Secondary}
                    onClick={saveAction}
                    leftIcon={ProIconDataButtons.save_s}
                />
            </>
        )}
        {mode === 'new' && createAction && (
            <>
                <ProButton
                    text={t('buttons.cancel')}
                    onClick={closeAction}
                    hoverIntent={ProIntent.Transpared}
                    mainIntent={ProIntent.Transpared}
                    lineIntent={ProIntent.Transpared}
                    leftIcon={ProIconData.close_stroke}
                />
                <ProButton
                    text={t('buttons.create')}
                    mainIntent={ProIntent.Primary}
                    hoverIntent={ProIntent.Secondary}
                    onClick={createAction}
                    leftIcon={ProIconDataButtons.save_s}
                />
            </>
        )}
    </>
);
