import classNames from 'classnames';
import React from 'react';
import { ProAttrField, ProHeaderAttrField, ProTextField } from '../../../atoms/AttrField/AttrField';
import { ProDateField } from '../../../atoms/AttrField/DateField';
import { ProCol } from '../../../atoms/Grid/Col';
import { ProRow } from '../../../atoms/Grid/Row';
import { ProIconData } from '../../../atoms/Icons/IconData';
import { WindowButtons } from '../../../atoms/WindowButtonsForMode/WindowButtons';
import { WindowButtonsForMode } from '../../../atoms/WindowButtonsForMode/WindowButtonsForMode';
import { ProDetailWindow } from '../../../components/DetailWindow/DetailWindow';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { Components } from '../../../utils/types';
import { DetailWindowProps } from '../GenericDetailWindow';
import { getWindowDisplayStateMode } from '../WBSElement/WBSElement.helpers';

export const TaskListDetailWindow = ({
    deleteAction,
    formData,
    isDisplayed,
    loading,
    mode,
    error,
    closeAction,
    t,
}: DetailWindowProps<Components.Schemas.TaskListDTO>) => (
    <ProDetailWindow
        title={
            <h2
                className={classNames(
                    formData?.name?.length && formData?.name?.length >= 180 && 'ProDetailWindow--header--title__large'
                )}
            >
                {formData?.name ?? t('windows.details')}
            </h2>
        }
        displayState={isDisplayed}
        icon={ProIconData.project_fill}
        buttonSection={<WindowButtons closeAction={closeAction} t={t} />}
        intent={getWindowDisplayStateMode(mode)}
        error={mode !== 'new' ? undefined : error}
        loading={loading}
        breadCrump={[
            ...getBaseBCItemForType([ApplicationElementType.Project], t),
            { name: t('navigation.portfolioMan.capacityMan.agile') },
        ]}
    >
        {formData?.isTemplate && (
            <ProRow>
                <ProCol span={6}>
                    <ProAttrField
                        name={'Template'}
                        disabled={true}
                        value={formData?.isTemplate ? 'This is a template you cant change' : ''}
                    />
                </ProCol>
            </ProRow>
        )}
        <ProRow>
            <ProCol span={12}>
                <ProTextField name={t('windows.description')} disabled={true} value={formData?.description} />
            </ProCol>
        </ProRow>
    </ProDetailWindow>
);

export const TaskDetailWindow = ({
    deleteAction,
    formData,
    isDisplayed,
    loading,
    mode,
    error,
    closeAction,
    t,
    cancelChanges,
    isDisabled,
    saveAction,
    setFormData,
    setMode,
    createAction,
}: DetailWindowProps<Components.Schemas.TaskDTO>) => (
    <ProDetailWindow
        title={
            isDisabled ? (
                <h2
                    className={classNames(
                        formData?.name?.length &&
                            formData?.name?.length >= 180 &&
                            'ProDetailWindow--header--title__large'
                    )}
                >
                    {formData?.name ?? t('windows.details')}
                </h2>
            ) : (
                <ProHeaderAttrField
                    name={t('windows.name')}
                    value={formData?.name ?? String(t('windows.details'))}
                    disabled={false}
                    handleChange={(input) => setFormData((old) => ({ ...old, name: String(input).substring(0, 180) }))}
                />
            )
        }
        displayState={isDisplayed}
        icon={ProIconData.project_fill}
        buttonSection={
            <WindowButtonsForMode
                closeAction={closeAction}
                deleteAction={deleteAction}
                t={t}
                cancelAction={cancelChanges}
                mode={mode}
                saveAction={saveAction}
                setMode={setMode}
                createAction={createAction}
            />
        }
        intent={getWindowDisplayStateMode(mode)}
        error={mode !== 'new' ? undefined : error}
        loading={loading}
        breadCrump={[{ name: t('navigation.portfolioMan.capacityMan.agile') }]}
    >
        <ProRow>
            <ProCol span={6}>
                <ProDateField name={t('windows.creationDate')} disabled={true} valueString={formData?.creationDate} />
            </ProCol>
        </ProRow>
        <ProRow>
            <ProCol span={12}>
                <ProTextField name={t('windows.description')} disabled={true} value={formData?.description} />
            </ProCol>
        </ProRow>
    </ProDetailWindow>
);
