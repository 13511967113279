import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationElementType, getBaseBCItemForType } from '../../../components/DetailWindow/breadCrump.helpers';
import { ProPage } from '../../../components/Page/Page';
import { NotImplemented } from '../../../modules/ProErrorPage/NotImplemented';

export const TeamStakeholdersPage = () => {
    const { t } = useTranslation();
    return (
        <ProPage breadCrump={getBaseBCItemForType([ApplicationElementType.TeamManagement], t)} name="Stakeholders">
            <NotImplemented message="Stakeholders" />
        </ProPage>
    );
};
