import React from 'react';
import { REQUIREMENTS_API_BASE } from '../../../../api/api';
import { useGenericDetailWindow } from '../../../../modules/Windows/GenericDetailWindow';
import { Components } from '../../../../utils/types';
import { RequirementDetailWindow } from './RequirementDetailWindow';

export enum RequirementDetailWindowType {
    Default = 'Default',
    Grooming = 'Grooming',
    Planning = 'Planning',
}

export const useRequirementDetailsFrame = ({
    closeAction,
    selectAction,
    externalSync,
    id,
    newElement,
    type,
}: {
    closeAction: () => void;
    selectAction: (id: string) => void;
    id?: string;
    externalSync?: () => Promise<void>;
    newElement?: Components.Schemas.RequirementDTO;
    type: RequirementDetailWindowType;
}) => {
    const emptyItem = {};

    return useGenericDetailWindow({
        apiConstant: REQUIREMENTS_API_BASE,
        closeAction: closeAction,
        emptyItem: newElement ?? emptyItem,
        genericDetailWindow: (props) => <RequirementDetailWindow {...props} type={type} />,
        externalSync,
        selectedItemId: id,
        selectAction,
    });
};
